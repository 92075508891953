import CustomBox from '../../CustomBox/CustomBox';
import CustomDialog from '../../CustomDialog/CustomDialog';
import STDynamicForms, {
  FormFieldType,
} from '../../STDynamicForm/STDynamicForm';
import { useIntl } from 'react-intl';
import useCustomTemperature from './useCustomTemperature';
import CustomButton from '../../CustomButton/CustomButton';

const UserBotCustomConfig = ({
  botId,
  open,
  handleClose,
}: {
  botId: number;
  handleClose: () => void;
  open: boolean;
}) => {
  const intl = useIntl();
  const {
    fields,
    loading,
    botName,

    handleChange,
    currentValues,
    isDisableConfirm,
    onManageBotConfig,
  } = useCustomTemperature({
    botId,

    onUpdated: handleClose,
  });
  return (
    <CustomBox
      sx={{
        ml: 'auto',
      }}
    >
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title={`
        ${intl.formatMessage({ id: 'dialog.title.custom.temperature' })} 
          (${botName})`}
        contentChildren={
          <CustomBox>
            <STDynamicForms
              fields={fields as FormFieldType[]}
              currentValues={currentValues}
              onChange={handleChange}
            />
          </CustomBox>
        }
        actionChildren={
          <CustomButton
            variant="contained"
            color="primary"
            isLoading={loading}
            disabled={isDisableConfirm}
            onClick={() => onManageBotConfig()}
            label={intl.formatMessage({
              id: 'dialog.bot.custom.config.confirm',
            })}
          />
        }
      />
    </CustomBox>
  );
};
export default UserBotCustomConfig;
