import { useTheme } from '@mui/material';
import * as React from 'react';

const StopRecording = () => {
  const theme = useTheme();
  return (
    <>
      <svg fill={theme.palette.primary.light} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 width="20" height="20" viewBox="0 0 360.72 360.72"
	 xmlSpace="preserve">
<g>
	<g id="Layer_8_68_">
		<path d="M360.72,352.99c0,4.269-3.459,7.727-7.728,7.727H7.722c-4.263,0-7.722-3.458-7.722-7.727V7.724
			c0-4.263,3.459-7.721,7.722-7.721h345.271c4.259,0,7.716,3.458,7.716,7.721V352.99H360.72z"/>
	</g>
</g>
</svg>
    </>
  );
};

export default StopRecording;
