import { BoxProps } from '@mui/system';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled, useTheme } from '@mui/material';
import { SketchPicker } from 'react-color';
import CustomDialog from '../CustomDialog/CustomDialog';

interface ColorProps extends BoxProps {
  open: boolean;
  value:string;
  handleClose(): void;
  onColorPickerInfoChange:(value:any)=>void
}
const DialogStyled = styled(CustomDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 3,
    padding: 0,
    width: 'auto',
    height: 'auto',
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
}));

export default function CustomDialogColorPicker({
  open,
  handleClose,
  value,
  onColorPickerInfoChange,
}: ColorProps) {
 const intl=useIntl()
  return (
    <>
      <DialogStyled
        open={open}
        handleClose={handleClose}
        contentChildren={
          <SketchPicker
            color={value}
            styles={{
              default: {
                picker: {
                  background: 'transparent',
                  width: 280,
                  boxShadow: 'none',
                },
              },
            }}
            onChangeComplete={(color) => onColorPickerInfoChange(color)}
            onChange={(color) => onColorPickerInfoChange(color)}
            disableAlpha={true}
          />
        }
        title={intl.formatMessage({ id: 'todo.label.color.title' })}
      />
    </>
  );
}
