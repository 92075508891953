import scrollStyle from '../../../scrollStyle';
import { Paper, styled, Theme } from '@mui/material';
import { MentionsInput } from 'react-mentions';
import config from '../../../../../config/config';

export const MessageInputStyle = ({ theme }: { theme: Theme }) => {
  return {
    fontSize: theme.typography.body1.fontSize,
    textarea: {
      border: '1px solid #E0E0E0',
    },
    '&multiLine': {
      control: {
        maxHeight: 240,
        minHeight: 40,
      },
      highlighter: {
        width: '100%',
        marginLeft: '7px',
        margintTop: '1px',
        maxHeight: 240,
      },
      input: {
        outline: 'none',
        border: 'none',
        color: theme.palette.text.primary,
        padding: '8px 0 8px 8px',
        overflowY: 'auto',
        // Scroll x
        overflowX: 'auto',
        placeholder: {
          fontSize: theme.typography.h2.fontSize,
        },
      },
    },
    suggestions: {
      list: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        maxHeight: '232px',
        overflowY: 'auto',
        fontSize: theme.typography.body1.fontSize,
        boxShadow: '0 2px 2px 3px rgba(0, 0, 0, 0.2)',
        marginTop: 'auto',
        ...scrollStyle,
      },
      item: {
        padding: '0.5px 10px',
        textTransform: 'capitalize',
        '&focused': {
          backgroundColor: theme.palette.customColor.messageBackground,
        },
      },
    },
    '&singleLine': {
      display: 'inline-block',
      width: 180,
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },

      input: {
        padding: 1,
        outline: 'none',
        width: '100%',
        border: 'none',
      },
    },
  };
};

export const PaperStyled = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'all 0.1s',
  width: '100%',
  margin: '0 auto',
  boxShadow: 'none',
  backgroundColor: theme.palette.customColor.cardBackground,
  boxSizing: 'border-box',
  zIndex: config.zIndex.NIVEAU_1,
  position: 'relative',
}));

export const StyledMentionsInput = styled(MentionsInput)(({ theme }) => ({
  width: '100%',
  margin: 0,
  maxHeight: 200,
  'textarea::-webkit-scrollbar': {
    width: '8px',
    height: '4px',
  },
  'textarea::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '1.5px',
  },
  'textarea::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.customColor.scrollColor,
    borderRadius: '1.5px',
  },
  'textarea::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.customColor.scrollColor,
  },
}));
