// src/ScrollContent.tsx
import React, { FC, useEffect } from 'react';
import CustomScrollBar from './MessageContainerScroll';
import { StyledContentMessage } from './MessageContainerStyle';
import useScroolButton from '../FloatingScroolButton/useScroolButton';
import FloatingScroolButton from '../FloatingScroolButton/FloatingScroolButton';

const MemorizedCustomScrollBar = React.memo(CustomScrollBar);
const MemorizedStyledContentMessage = React.memo(StyledContentMessage);

interface Props {
  children: React.ReactNode;
  scrollToBottomRef: React.RefObject<HTMLDivElement>;
  messages: any[];
}

const MessageContainer: FC<Props> = ({
  scrollToBottomRef,
  messages,
  children,
}) => {
  const { handleScrollBottom, isDisplayScrollButton, onScroll } =
    useScroolButton({
      ref: scrollToBottomRef,
    });

  useEffect(() => {
    if (!isDisplayScrollButton) {
      handleScrollBottom();
    }
  }, [messages, scrollToBottomRef]);

  return (
    <>
      <MemorizedCustomScrollBar contentRef={scrollToBottomRef} />
      <MemorizedStyledContentMessage
        id="content-inner"
        ref={scrollToBottomRef}
        onScroll={onScroll}
      >
        {children}
      </MemorizedStyledContentMessage>
      {isDisplayScrollButton ? (
        <FloatingScroolButton onClick={handleScrollBottom} />
      ) : null}
    </>
  );
};

export default React.memo(MessageContainer);
