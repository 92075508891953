import AddIcon from '@mui/icons-material/Add';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useCreateLabel from '../../hooks/useCreateLabel';
import Loading from '../../../common/Loading/Loading';
import { Todo } from '../../../../gql/graphql';
import AutoCompleteLabel from './AutoCompleteLabel/AutoCompleteLabel';
import useCheckLabelToTask from '../../hooks/useCheckLabelToTask';
import { ButtonBase, styled, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import TodoLabelTag from './AutoCompleteLabel/TodoLabelTag/TodoLabelTag';
const AutoCompleteButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.text.primary,
  fontWeight: 600,
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));
export default function TodoLabel({ task }: { task: Todo }) {
  const projectId = task.projectId || 0;
  const todoId = task.id || 0;
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { handelCheckLabelToTask } = useCheckLabelToTask();
  const { createLabel, loading: createLoad } = useCreateLabel({
    projectId,
    todoId,
  });
  const handleClickOption = (label: any) => {
    handelCheckLabelToTask(label.id, projectId, task.id);
  };

  const handleKeyPress = (e: any) => {
    if (e.which === 13) {
      e.preventDefault();
      const { textContent } = e.target;
      if (textContent.trim()) {
        createLabel(textContent);
      }
    }
  };
  return (
    <CustomBox sx={{ paddingTop: (theme) => theme.spacing(1) }}>
      <AutoCompleteLabel
        projectId={projectId}
        isChecked={(label) =>
          task?.labels.some((newlabel: any) => newlabel?.id === label?.id)
        }
        isShowCheckBox={true}
        handleClickOption={handleClickOption}
        renderAutoCompleteButton={
          <AutoCompleteButton
            sx={{ width: '100%' }}
            disableRipple
            aria-describedby={'swiftak-todo-label'}
          >
            <span>
              <FormattedMessage id="button.label" />
            </span>
            <AddIcon />
          </AutoCompleteButton>
        }
        childrenAction={(value, isLabelNameEqualOfSearchQuery) =>
          value.trim() && !isLabelNameEqualOfSearchQuery ? (
            <CustomBox display="flex" width="100%">
              <AutoCompleteButton
                disableRipple
                disabled={createLoad}
                onClick={() => createLabel(value)}
                sx={{ mt: 1.5, mx: 'auto' }}
              >
                {createLoad ? (
                  <Loading color="inherit" size={10} type="circular" />
                ) : (
                  <AddIcon />
                )}
                <span>
                  <FormattedMessage id="button.create.label" /> {`"${value}"`}
                </span>
              </AutoCompleteButton>
            </CustomBox>
          ) : null
        }
        onKeyPress={handleKeyPress}
      />
      <CustomBox display="flex" flexWrap={'wrap'}>
        {task?.labels.map((label: any) => (
          <TodoLabelTag
            labelId={label.id}
            labelColor={label.color}
            showLabel={!isSm}
            isWithAction={true}
            actionbgColor={label.bgColor}
            sx={{ backgroundColor: label.bgColor }}
            label={label?.name}
            onDelete={() => handleClickOption(label)}
            key={label.id}
          />
        ))}
      </CustomBox>
    </CustomBox>
  );
}
