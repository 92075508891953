import { useQuery } from '@apollo/client';
import { graphql } from '../gql/gql';
import { SyncStatus } from '../gql/graphql';

export const GET_DATASOURCE_WORKSPACE = graphql(`
  query GetWorkSpaceDataSources($syncStatus: SyncStatus) {
    getWorkSpaceDataSources(syncStatus: $syncStatus) {
      id
      name
      url
      createdAt
      updatedAt
      description
      syncToVectorStoreError
      syncStatus
      dataSourceCategory {
        id
        name
        slug
        description
      }
    }
  }
`);

interface IProps {
  syncStatus?: SyncStatus;
}

const useGetWorkspaceDataSources = (props?: IProps) => {
  const { data, loading } = useQuery(GET_DATASOURCE_WORKSPACE, {
    variables: {
      syncStatus: props?.syncStatus,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    dataSources: data?.getWorkSpaceDataSources || [],
  };
};

export default useGetWorkspaceDataSources;
