import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material';

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  zIndex: 99999,
}));

const MenuListStyled = styled(MenuList)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '4px 0px 4px 0px',
}));
interface props {
  anchorEl: null | HTMLElement;
  handleClose(): void;
  open: boolean;
  children: React.ReactNode;
}
export default function CustomMenu({
  anchorEl,
  handleClose,
  children,
  open,
}: props) {
  return (
    <MenuStyled
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={(event: any) => {
        event.stopPropagation();
        handleClose();
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuListStyled>{children}</MenuListStyled>
    </MenuStyled>
  );
}
