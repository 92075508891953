import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { Todo } from '../../../../gql/graphql';
import Moment from 'react-moment';
import UserProfil from './UserProfil/UserProfil';

interface IProps {
  task: Todo;
}

const TodoCreated: React.FC<IProps> = ({ task }) => {
  const dateToFormat = new Date(task.createdAt);
  return (
    <CustomBox
      sx={{
        marginTop: 1,
      }}
    >
      <CustomBox marginBottom={2}>
        <CustomTypography variant="h6" fontWeight="700">
          <FormattedMessage id="todo.created" />
        </CustomTypography>
      </CustomBox>
      <CustomBox
        display={'flex'}
        flexWrap={'wrap'}
        width="100%"
        alignItems={'center'}
      >
        <CustomBox display={'flex'} mr={2}>
          <CustomTypography variant="h6">
            <Moment format="D MMM YYYY" date={dateToFormat} />
          </CustomTypography>
        </CustomBox>
        <UserProfil
          userName={task.createdBy?.firstName || ''}
          userProfil={task.createdBy?.profilePicture || ''}
        />
      </CustomBox>
    </CustomBox>
  );
};

export default TodoCreated;
