import { IconButton, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import { OpenInNew, Refresh, Share } from '@mui/icons-material';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';
import useSaveAndShareHtml from './useSaveAndShareHtml';

interface IProps {
  htmlContent?: string;
  messageId: number;
}
const useHtmlPreview = ({ htmlContent, messageId }: IProps) => {
  const { sharePreview, loading, error } = useSaveAndShareHtml();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [iframeSrc, setIframeSrc] = useState(htmlContent);
  const { displayMessage } = useDisplayMessage();

  const handleRefresh = () => {
    const timestamp = new Date().getTime();
    setIframeSrc(`${htmlContent}?t=${timestamp}`);
  };

  const handleCopy = () => {
    displayMessage({ message: 'code.copied', type: 'success' });
  };
  const getPreviewUrl = async () => {
    if (htmlContent) {
      try {
        const share = await sharePreview({
          variables: {
            htmlContent,
            messageId,
          },
        });

        return share.data?.saveAndShareArtifact;
      } catch (error) {
        displayMessage({ message: 'htmlPreview.failedToShare', type: 'error' });
      }
    }
    return;
  };
  const handleOpenInNewTab = async () => {
    const url = await getPreviewUrl();
    if (url) {
      window && window.open(url, '_blank');
    }
  };
  const handleShare = async () => {
    if (htmlContent) {
      const url = await getPreviewUrl();
      if (url) {
        navigator.clipboard.writeText(url);
        displayMessage({ message: 'htmlPreview.shared', type: 'success' });
      }
    }
  };
  return {
    iframeRef,
    refreshKey,
    iframeSrc,
    handleRefresh,
    handleCopy,
    handleShare,
    handleOpenInNewTab,
    loadingShare: loading,
  };
};

export default useHtmlPreview;
