import { useLazyQuery, useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import { useCallback } from 'react';
import { debounce } from 'lodash';
export const GET_ALL_LABEL = graphql(`
  query GetTodoLabel($projectId: Float!) {
    getTodoLabel(projectId: $projectId) {
      id
      name
      color
      bgColor
      description
    }
  }
`);
export const GET_SEARCh_LABEL = graphql(`
query GetSearchLabel($projectId: Float!, $searchQuery: String!) {
    searchLabel(projectId: $projectId, searchQuery: $searchQuery) {
      id
      name
      color
      bgColor
      description
    }
  }
`);


const useGetTodoLabel = ({projectId}:{projectId:number}) => {
  const { data:allLabels } = useQuery(GET_ALL_LABEL, {
    variables: {
      projectId,
    },
  });
  const [getMatchingLabels, { data:dataSearched, loading:searchload }] = useLazyQuery(
    GET_SEARCh_LABEL
  );
  const debouncedGetMatchingLabels = useCallback(
    debounce((searchQuery: string) => {
      getMatchingLabels({ variables: { projectId, searchQuery: searchQuery } });
    }, 500),
    []
  );
  return {
    allLabels,
    dataSearched,
    searchload,
    debouncedGetMatchingLabels,
  };
};

export default useGetTodoLabel;
