import * as React from 'react';
import Task from './Task/Task';
import { Todo } from '../../../gql/graphql';
import useDisplayTodoDescriptionSidebar from '../../../Hooks/useDisplayTodoDescriptionSidebar';

interface IProps {
  tasks: Todo[];
  showCompleteCheckbox?: boolean;
  taskRoutePrefix?: string;
  isShowLabel?: boolean;
}

const ListTasks: React.FC<IProps> = ({
  tasks,
  showCompleteCheckbox,
  taskRoutePrefix,
  isShowLabel,
}) => {
  const { displayDescriptionSidbar } = useDisplayTodoDescriptionSidebar();
  const onTaskClick = (taskId: number) => {
    displayDescriptionSidbar(taskId);
  };
  return (
    <>
      {tasks?.map((task: Todo) => (
        <Task
          isShowLabel={isShowLabel}
          key={task.id}
          task={task}
          showCompleteCheckbox={showCompleteCheckbox}
          taskRoutePrefix={taskRoutePrefix}
          onClick={onTaskClick}
        />
      ))}
    </>
  );
};

export default ListTasks;
