
interface DownloadProps {
  fileUrl: string;
}

const useDownLoadFile = () => {
  const downloadFile = ({ fileUrl }: DownloadProps) => {
    // Open file in new tab
    window.open(fileUrl, '_blank');
  };

  return { downloadFile };
};

export default useDownLoadFile;
