// Custom Paper component using material-ui Paper component

import { FC } from 'react';
import { Paper, PaperProps } from '@mui/material';

interface IProps extends PaperProps {}

const STPaper: FC<IProps> = ({ children, ...rest }) => {
  return <Paper {...rest}>{children}</Paper>;
};

export default STPaper;
