import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

// GraphQL query to get the display history chat state
const GET_STATE_MESSAGE = gql`
  query getDisplayHistoryChat {
    displayHistoryChat @client {
      isDisplay
      position
      __typename
    }
  }
`;

// TypeScript interface for the structure of the state
interface DisplayHistoryChat {
  isDisplay: boolean;
  position: 'relative' | 'absolute';
  __typename: string;
}

// Hook to manage the state
const useDisplayHistoryChat = () => {
  const client = useApolloClient();
  const { data } = useQuery(GET_STATE_MESSAGE);

  // Function to display or update the state
  const handleToogleDisplayHistoryChat = useCallback(() => {
    client.writeQuery({
      query: GET_STATE_MESSAGE,
      data: {
        displayHistoryChat: {
          isDisplay: !data?.displayHistoryChat.isDisplay,
          position: 'absolute',
          __typename: 'DisplayHistoryChat',
        },
      },
    });
  }, [data?.displayHistoryChat.isDisplay]);

  const handlePositionHistoryChat = useCallback(() => {
    client.writeQuery({
      query: GET_STATE_MESSAGE,
      data: {
        displayHistoryChat: {
          isDisplay: true,
          position:
            data?.displayHistoryChat.position === 'relative'
              ? 'absolute'
              : 'relative',
          __typename: 'DisplayHistoryChat',
        },
      },
    });
  }, [data?.displayHistoryChat.position]);

  const makeRelativePosition = useCallback(() => {
    client.writeQuery({
      query: GET_STATE_MESSAGE,
      data: {
        displayHistoryChat: {
          isDisplay: true,
          position: 'relative',
          __typename: 'DisplayHistoryChat',
        },
      },
    });
  }, [data?.displayHistoryChat.position]);

  // Function to clear (reset) the state
  const clearDisplayHistoryChatState = () => {
    client.writeQuery({
      query: GET_STATE_MESSAGE,
      data: {
        displayHistoryChat: {
          isDisplay: false,
          position: 'absolute',
          __typename: 'DisplayHistoryChat',
        },
      },
    });
  };

  // Function to get the state
  const getDisplayHistoryChatState = (): DisplayHistoryChat => {
    const data = client.readQuery({
      query: GET_STATE_MESSAGE,
    });
    return data?.displayHistoryChat;
  };

  const isDisplayHistoryChat = data?.displayHistoryChat.isDisplay;
  const displayPositionHistoryChat = data?.displayHistoryChat.position;

  return {
    handleToogleDisplayHistoryChat,
    clearDisplayHistoryChatState,
    getDisplayHistoryChatState,
    handlePositionHistoryChat,
    isDisplayHistoryChat,
    displayPositionHistoryChat,
    makeRelativePosition,
  };
};

export default useDisplayHistoryChat;
