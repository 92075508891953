import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

interface props {
  users: any;
}
export default function ListUserByAvatar({ users }: props) {
  return (
    <AvatarGroup
      max={3}
      spacing="medium"
      sx={{
        mr: 1,
        display: { xs: 'none', sm: 'flex' },
        marginTop: '-2px',
        '& .MuiAvatar-root': {
          width: 25,
          height: 25,
          fontSize: 12,
        },
      }}
    >
      {users?.map((user: any) => {
        return (
          <Avatar
            alt={user?.user?.email}
            src={user?.user?.profilePicture}
            key={user?.user?.id}
          />
        );
      })}
    </AvatarGroup>
  );
}
