import { FC, useEffect, useState } from 'react';
import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import STDynamicForms from '../../../../../../common/STDynamicForm/STDynamicForm';
import { FormattedMessage } from 'react-intl';
import { Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
import CustomIconButton from '../../../../../../common/CustomIconButton/CustomIconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
interface IProps {
  formFields: any[];
  value: any;
  onChange: (name: string, value: any) => void;
}

const FormUI: FC<IProps> = ({ value, formFields, onChange }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  // Function to set default value data
  const setDefaultValueData = () => {
    formFields.forEach((formField) => {
      // Reset only formName in formFields
      if (formField.defaultValue !== undefined) {
        onChange(formField.formName, formField.defaultValue);
      }
    });
  };
  // Function to handle close
  const handleToggle = () => {
    setOpen((lasted) => !lasted);
  };

  useEffect(() => {
    setDefaultValueData();
  }, [formFields.length]);

  return (
    <Box
      sx={{
        position: 'absolute',
        transition: '500ms',
        width: '100%', // You can adjust this width as needed
        backgroundColor: theme.palette.background.paper, // Background color
        boxShadow: theme.shadows[2], // Optional shadow for elevation
        p: 2, // Padding
        left: 0,
        bottom: 0,
      }}
    >
      <CustomBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTypography fontWeight={'bold'}>
          <FormattedMessage id="botui.formUi.title" />
        </CustomTypography>
        <CustomIconButton
          intlId={open ? 'botui.formUi.close' : 'botui.formUi.open'}
          onClick={handleToggle}
        >
          {open ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowUpIcon fontSize="small" />
          )}
        </CustomIconButton>
      </CustomBox>

      {open ? (
        <CustomBox
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '600px',
            paddingRight: (theme) => theme.spacing(1),
          }}
        >
          <Divider />
          <CustomBox>
            <STDynamicForms
              fields={formFields}
              currentValues={value}
              onChange={onChange}
            />
          </CustomBox>
        </CustomBox>
      ) : null}
    </Box>
  );
};

export default FormUI;
