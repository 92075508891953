import { useTheme } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { styleDefObj } from './FileIconStyle';

type Props = {
  filename: string;
  size?: 'small' | 'medium' | 'large';
};

const FileIconComponent = ({ filename, size = 'small' }: Props) => {
  const iconSize = size === 'small' ? 16 : size === 'medium' ? 26 : 36;
  const extension = filename.split('.').pop()?.toLowerCase() || '';
  const theme = useTheme();
  const defaultLabelColor = styleDefObj[extension]
    ? styleDefObj[extension]['labelColor'] ?? theme.palette.primary.light
    : theme.palette.primary.light;
  const defaultColor = styleDefObj[extension]
    ? styleDefObj[extension]['color'] ?? theme.palette.primary.light
    : theme.palette.primary.light;

  const defaultGlyphColor =
    defaultStyles[extension as keyof typeof defaultStyles] &&
    defaultStyles[extension as keyof typeof defaultStyles]['labelColor'];

  return (
    <div style={{ width: iconSize, height: iconSize }}>
      <FileIcon
        extension={extension}
        {...defaultStyles[extension as keyof typeof defaultStyles]}
        glyphColor={defaultGlyphColor ?? defaultLabelColor}
        labelColor={defaultLabelColor}
        color={defaultColor}
        {...styleDefObj[extension]}
      />
    </div>
  );
};
export default FileIconComponent;
