import { FC } from 'react';
import useGetBotSuggestion from '../../../../../Hooks/botSuggestions/useGetBotSuggestion';
import { isEmptyArray } from '../../../../../utils/arrayUtils';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import Bot from '../../../Description/Bots/Bot/Bot';
import useBotSuggestions from './useBotSuggestions';

interface IProps {
  messageId: number;
}

const BotSuggestions: FC<IProps> = ({ messageId }) => {
  const { botSuggestions, handleBotClick } = useBotSuggestions({ messageId });
  if (isEmptyArray(botSuggestions)) {
    return null;
  }

  return (
    <CustomBox pt={2}>
      {botSuggestions.map((botSuggestion: any) => {
        const bot = botSuggestion.bot;
        return (
          <CustomBox
            sx={{
              paddingLeft: (theme) => theme.spacing(2),
              '&:hover': {
                backgroundColor: (theme) => theme.palette.background.paper,
                cursor: 'pointer',
                borderRadius: (theme) => theme.spacing(1),
              },
            }}
          >
            <Bot
              botSlug={bot.slug}
              botname={bot.name}
              botWorkspaceId={bot.workspaceId}
              isForDescription={true}
              avatarSize="large"
              avatar={bot.user?.profilePicture}
              botDescription={bot.description}
              isDisplayDescription={true}
              onClick={() => handleBotClick(bot)}
              isBot={false}
              botId={bot.id}
              key={bot.id}
              isOpenDocumentationOnClicked={true}
            />
          </CustomBox>
        );
      })}
    </CustomBox>
  );
};

export default BotSuggestions;
