import { GET_CHAT } from '../hooks/useGetChatTodo';

export const onAfterRemoveMessage = ({
  cache,
  currentMessage,
  sessionId,
  todoId,
}: {
  cache: any;
  currentMessage: any;
  sessionId: number;
  todoId?: number;
}) => {
  // Update cache for GE_CHAT query

  const existingSessionMessages = cache.readQuery({
    query: GET_CHAT,
    variables: {
      sessionId,
    },
  });

  if (existingSessionMessages) {
    cache.writeQuery({
      query: GET_CHAT,
      variables: {
        sessionId,
      },
      data: {
        sessionMessages: existingSessionMessages.sessionMessages.filter(
          (item: any) => item.id !== currentMessage.id
        ),
      },
    });
  }

  // Update cache of Todo allFiles if chat related on todo
  if (todoId && currentMessage?.files && currentMessage?.files?.length) {
    const fileRefs = currentMessage?.files?.map(
      (file: any) => `File:${file?.id}`
    );
    cache.modify({
      id: cache.identify({
        __typename: 'Todo',
        id: todoId,
      }),
      fields: {
        allFiles(existingFiles = []) {
          const newFiles = existingFiles?.filter(
            (item: any) => !fileRefs.includes(item.__ref)
          );
          return newFiles;
        },
      },
    });
  }
};
