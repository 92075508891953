import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import useGetOnePrompt from '../../../../Hooks/useGetOnePrompt';
import { FormFieldType } from '../../../../gql/graphql';
import { useIntl } from 'react-intl';

interface FormValues {
  title: string;
  description: string;
  categoryPromptsId: number;
  workspaceId: number;
}

const useUpdatePromptForm = (
  idCategorie: number,
  idPrompts: number,
  onUpdatePrompt: (
    description: string,
    idPrompts: number,
    title: string
  ) => void
) => {
  const { workspaceId } = useParams();
  const { data } = useGetOnePrompt();
  const intl = useIntl();
  const prompt = data?.findFirstPromptsOrThrow;
  const defaultDescription = prompt?.description ?? '';
  const defaultTitle = prompt?.title ?? '';

  const commonField = {
    createdAt: new Date(),
    updatedAt: new Date(),
    options: [],
    removed: false,
  };

  const fields = [
    {
      formName: 'title',
      label: intl.formatMessage({
        id: 'text.field.prompt.name',
      }),
      placeholder: intl.formatMessage({
        id: 'text.field.prompt.title.placeholder',
      }),
      required: true,
      id: 1,
      type: FormFieldType.Text,
      ...commonField,
    },
    {
      formName: 'description',
      label: intl.formatMessage({
        id: 'text.field.prompt.description',
      }),
      placeholder: intl.formatMessage({
        id: 'text.field.prompt.description.placeholder',
      }),
      required: false,
      id: 2,
      type: FormFieldType.Textarea,
      ...commonField,
    },
  ];

  const formik = useFormik<FormValues>({
    initialValues: {
      title: defaultTitle,
      description: defaultDescription,
      categoryPromptsId: idCategorie,
      workspaceId: Number(workspaceId || 0),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (values.categoryPromptsId && values.description && values.title) {
        onUpdatePrompt(values.description, idPrompts, values.title);
      }
    },
  });

  useEffect(() => {
    if (defaultTitle && defaultDescription) {
      formik.setValues({
        title: defaultTitle,
        description: defaultDescription,
        categoryPromptsId: idCategorie,
        workspaceId: Number(workspaceId || 0),
      });
    }
  }, [defaultTitle, defaultDescription, idCategorie, workspaceId]);

  return { formik, fields, handleChange: formik.handleChange };
};

export default useUpdatePromptForm;
