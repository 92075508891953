import { FC } from 'react';
import STFileForm from '../../../common/STFileForm/STFileForm';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useAddDataSourcesToChatSession from '../../hooks/useAddDataSourcesToChatSession';
import { graphql } from '../../../../gql/gql';
import { useSubscription } from '@apollo/client';
import React from 'react';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';
import AlertText from '../../../common/AlertText/AlertText';

interface IProps {
  defaultBot: any;

  sessionId: number;
}
const BOT_ANALYZING_DATASOURCES = graphql(`
  subscription botAnalyzingDatasources($sessionId: Float!) {
    botAnalyzingDatasources(sessionId: $sessionId) {
      id
      firstName
      lastName
      profilePicture
      __typename
    }
  }
`);

const ChatDataSourceAdd: FC<IProps> = ({ defaultBot, sessionId }) => {
  const [botAnalyzingDatasources, setBotAnalyzingDatasources] =
    React.useState<any>(null);
  const { displayMessage } = useDisplayMessage();

  const {
    data: dataBotAnalyzingDatasources,
    loading: loadingBotAnalyzingDatasources,
    error: errorBotAnalyzingDatasources,
  } = useSubscription(BOT_ANALYZING_DATASOURCES, {
    variables: {
      sessionId: sessionId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      setBotAnalyzingDatasources(
        subscriptionData?.data?.botAnalyzingDatasources
      );
    },
  });
  React.useEffect(() => {
    if (dataBotAnalyzingDatasources) {
      setBotAnalyzingDatasources(null);
    }
  }, [sessionId]);

  // Add datasource to session
  const { addDataSourcesToAssistant, loading, error } =
    useAddDataSourcesToChatSession();

  const onSendFiles = (files: any) => {
    addDataSourcesToAssistant({
      variables: {
        data: {
          sessionId: sessionId,
          files: files.map((file: any) => {
            return {
              name: file.name,
              fileId: file.id,
            };
          }),
        },
      },
      onError: (error) => {
        setBotAnalyzingDatasources(null);
        displayMessage({
          message: 'chat.add.datasource.error',
          type: 'error',
        });
      },
    });
  };
  return (
    <CustomBox>
      <STFileForm
        size={'large'}
        multiple={true}
        onAddFiles={onSendFiles}
        fileFormPlaceholder={defaultBot.fileFormOnFirstSessionPlaceholder}
        isDisplayLoader={true}
        loading={loading || Boolean(botAnalyzingDatasources)}
      />
      {error && defaultBot.slug === 'chatpdf' && (
        <CustomBox mt={2}>
          <AlertText
            severity="error"
            message={error?.message}
            id="chaptpdf.error.message"
          />
        </CustomBox>
      )}
    </CustomBox>
  );
};

export default ChatDataSourceAdd;
