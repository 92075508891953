// React FC component containing a material ui dialog with a input field for creating a new project

import * as React from 'react';

import Dialog, { DialogProps } from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';

import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';

import { IconButton, styled, useTheme } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import { FormattedMessage } from 'react-intl';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';
import { Close } from '@mui/icons-material';

const DialogStyled = styled(Dialog)(({ theme }) => ({
  zIndex: 100000,
  '& .MuiDialog-paper': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
    borderRadius: 10,
    width: '100%',
    height: 'auto',
  },
  '& .MuiDialogTitle-root': {
    borderBottom: theme.palette.mode === 'light' ? '1px solid #e0e0e0' : 'none',
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .MuiDialogActions-root': {
    padding: '8px 25px',
    marginBottom: '16px',
  },
  '& .MuiButton-root': {
    textTransform: 'capitalize',
    fontFamily: 'Lato',
    fontSize: '13px',
  },
  [theme.breakpoints.down('xs')]: {
    height: '100%',
  },
}));

// Interface for the props of the component dialog to handle dialog open and close

interface IDialogProps extends DialogProps {
  open: boolean;
  handleClose: () => void;
  contentChildren: React.ReactNode;
  actionChildren?: React.ReactNode;
  leftItem?: React.ReactNode;
  rigthItem?: React.ReactNode;
  title?: any;
  intlId?: string;
  fullScreen?: boolean;
  actions?: any[];
  disableCloseButton?: boolean;
}

const CustomDialog: React.FC<IDialogProps> = ({
  open,
  handleClose,
  contentChildren,
  actionChildren,
  fullScreen,
  leftItem,
  rigthItem,
  title,
  intlId,
  disableCloseButton,
  actions,
  ...rest
}) => {
  const onClose = () => {
    handleClose();
  };

  const theme = useTheme();
  const maxWidth = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DialogStyled
      fullScreen={fullScreen ? true : maxWidth}
      open={open}
      onClose={onClose}
      scroll="body"
      color="inherit"
      {...rest}
    >
      {actions?.length || title ? (
        <DialogTitle variant="body1">
          <CustomBox
            display="flex"
            width={'100%'}
            alignItems="center"
            justifyContent="center"
            height={20}
          >
            {leftItem && leftItem}
            <CustomTypography fontWeight={'bold'}>
              {intlId ? (
                <FormattedMessage
                  id={intlId}
                  defaultMessage={title as string}
                />
              ) : (
                title
              )}
            </CustomTypography>

            <CustomBox
              ml={'auto'}
              sx={{
                display: 'flex',
              }}
            >
              {actions?.map((action: any) => (
                <IconButton
                  onClick={() => {
                    action.onClick();
                  }}
                >
                  <action.icon
                    color={action.iconColor || 'action'}
                    fontSize={action.iconSize || 'medium'}
                  />
                </IconButton>
              ))}
              <CustomBox
                sx={{
                  display: 'flex',
                  gap: '16px',
                }}
              >
                {rigthItem && rigthItem}
                <IconButton onClick={onClose} disabled={disableCloseButton}>
                  <Close />
                </IconButton>
              </CustomBox>
            </CustomBox>
          </CustomBox>
        </DialogTitle>
      ) : null}
      <DialogContent>{contentChildren}</DialogContent>
      {actionChildren && <DialogActions>{actionChildren}</DialogActions>}
    </DialogStyled>
  );
};

export default CustomDialog;
