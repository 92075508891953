import { gql, useApolloClient, useQuery } from '@apollo/client';

const GET_EXTERNAL_MESSAGE = gql`
  query externalMessageInput {
    externalMessageInput @client {
      message
      mentionedBotId
    }
  }
`;

const useExternalMessageInput = () => {
  const client = useApolloClient();
  const { data, refetch } = useQuery(GET_EXTERNAL_MESSAGE, {
    fetchPolicy: 'cache-only',
  });

  const setMessageInput = ({
    message,
    mentionedBotId,
  }: {
    message: string;
    mentionedBotId?: number;
  }) => {
    client.writeQuery({
      query: GET_EXTERNAL_MESSAGE,
      data: {
        externalMessageInput: {
          message,
          mentionedBotId,
          __typename: 'ExternalMessageInput',
        },
      },
    });
    refetch();
  };
  const externalMessageInput = data?.externalMessageInput;

  return { externalMessageInput, setMessageInput };
};

export default useExternalMessageInput;
