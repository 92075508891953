interface ICallBack {
  callBack?: (file: FileList) => void;
  callBackBlob?: (file: any) => void;
}

export const usePaste = (props: ICallBack) => {
  const onPasteFile = (e: ClipboardEvent | any) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const blob = items[i].getAsFile();
      if (blob) {
        const file = new File([blob], blob.name, {
          type: blob.type,
          lastModified: Date.now(),
        });

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileList = dataTransfer.files;

        props.callBack && props.callBack(fileList);
      }
    }
  };

  const onPasteReturnBlob = (e: ClipboardEvent | any) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const blob = items[i].getAsFile();
      if (blob) {
        const file = new File([blob], blob.name, {
          type: blob.type,
          lastModified: Date.now(),
        });
        props.callBackBlob && props.callBackBlob(file);
      }
    }
  };

  return {
    onPasteFile,
    onPasteReturnBlob,
  };
};
