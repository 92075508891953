// Custom IconButton component using material-ui IconButton component

import { IconButton, IconButtonProps } from '@mui/material';
import CustomTooltip from '../CustomToolTip/CustomToolTip';

interface CustomIconButtonProps extends IconButtonProps {
  intlId?: string;
  onClick?: (e: any) => void;
}

const CustomIconButton = ({
  intlId,
  onClick,

  ...rest
}: CustomIconButtonProps) => {
  return (
    <CustomTooltip arrow intlId={intlId} title={''}>
      <IconButton onClick={onClick} {...rest}>
        {rest.children}
      </IconButton>
    </CustomTooltip>
  );
};

export default CustomIconButton;
