import { gql, useApolloClient, useQuery } from '@apollo/client';

const GET_PDF_VIEWER_PAGE = gql`
  query getPdfViewerPage {
    pdfViewerPage @client {
      page
      url
      clickHash
    }
  }
`;

const useChangePDFPage = () => {
  const client = useApolloClient();
  const { data, refetch } = useQuery(GET_PDF_VIEWER_PAGE, {
    fetchPolicy: 'cache-only',
  });

  const setPdfViewerPage = ({ page, url }: { page: number; url: string }) => {
    // Write null to the cache to trigger a refetch
    const randomString = Math.random().toString(36).substring(7);

    client.writeQuery({
      query: GET_PDF_VIEWER_PAGE,
      data: {
        pdfViewerPage: {
          page,
          clickHash: randomString,
          url,
          __typename: 'PDFViewerPage',
        },
      },
    });
    refetch();
  };
  const pdfViewerPage = data?.pdfViewerPage?.page;
  const pdfViewerUrl = data?.pdfViewerPage?.url;
  const clickHash = data?.pdfViewerPage?.clickHash;

  return { pdfViewerPage, pdfViewerUrl, setPdfViewerPage, clickHash };
};

export default useChangePDFPage;
