import { FormattedMessage, useIntl } from 'react-intl';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { ButtonBase, styled } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import TextInput from '../../../common/TextInput/TextInput';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useCreateTodoSuTask from '../../hooks/useCreateTodoSubTask';
import useGetTodoSubTask from '../../hooks/useGetTodoSubTask';
import STList from '../../../common/STList/STList';
import React from 'react';
import useDeleteTodoSuTask from '../../hooks/useDeleteTodoSubTask';
import useRenameTodoSuTask from '../../hooks/useRenameTodoSubtask';
import useCompleteTodoSuTask from '../../hooks/useCompleteTodoSubTask';
const StyleButton = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.text.primary,
  fontWeight: 600,
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));
const BoxStyled = styled(CustomBox)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: '14px',
    padding: '5px 8px',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    border: '1px solid gray',
    width: '100%',
  },

  '& .MuiOutlinedInput-root': {
    padding: '10px 0',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));
const TodoSubTask = ({ todoId }: { todoId: number }) => {
  const intl = useIntl();
  const { data } = useGetTodoSubTask({ todoId });
  const [isAddSubTaskFocused, setIsAddSubTaskFocused] = useState(false);
  const [value, setValue] = useState('');
  const [subtaskSelectedId, setSubtaskSelectedId] = useState(0);
  const [isContentEditableDisabled, setIsContentEditableDisabled] =
    React.useState(true);
  const [isPrimaryTextFocused, setIsPrimaryTextFocused] = React.useState(false);
  const [isItemTextCheckedToDelete, setIsItemTextCheckedToDelete] =
    React.useState(false);
  const debounceTimeoutRef = React.useRef(null as any);
  const { deleteSubTask } = useDeleteTodoSuTask();
  const { createSubTask } = useCreateTodoSuTask();
  const { renameSubTask } = useRenameTodoSuTask();
  const { completeSubTask } = useCompleteTodoSuTask();
  const onChange = (e: any) => {
    setValue(e.target.value);
  };
  const onPrimaryTextChange = (e: any) => {
    clearTimeout(debounceTimeoutRef?.current);
    debounceTimeoutRef.current = setTimeout(() => {
      if (Boolean(e?.target?.value?.trim().length)) {
        renameSubTask(e.target.value, subtaskSelectedId);
      }
    }, 500);
  };
  const onCreateSubTask = (e: any) => {
    if (e.key === 'Enter') {
      createSubTask(value, todoId);
      setValue('');
    }
  };
  const actions = [
    {
      confirmationActions: [
        {
          tooltipIntlId: 'subtask.confirm',
          icon: CheckIcon,
          onClick: () => {
            setIsPrimaryTextFocused(false);
            setIsContentEditableDisabled(true);
            if (isItemTextCheckedToDelete) {
              deleteSubTask(todoId, subtaskSelectedId);
            }
          },
        },
        {
          tooltipIntlId: 'subtask.cancel',
          icon: CloseIcon,
          onClick: () => {
            setIsPrimaryTextFocused(false);
            setIsContentEditableDisabled(true);
            setIsItemTextCheckedToDelete(false);
          },
        },
      ],
      actions: [
        {
          tooltipIntlId: 'subtask.rename',
          icon: DriveFileRenameOutlineIcon,
          onClick: () => {
            setIsPrimaryTextFocused(true);
            setIsContentEditableDisabled(false);
          },
        },
        {
          tooltipIntlId: 'subtask.delete',
          icon: DeleteOutlineIcon,
          onClick: () => {
            setIsItemTextCheckedToDelete(true);
          },
        },
      ],
    },
  ];
  const onItemClick = (item: any) => {
    if (isContentEditableDisabled) {
      setIsPrimaryTextFocused(false);
      setIsContentEditableDisabled(true);
      setIsItemTextCheckedToDelete(false);
    }
    setSubtaskSelectedId(item.id);
  };
  let items = data?.getTodoSubTask || [];
  let itemList: any[] = [];
  itemList = [
    ...itemList,
    ...items.map((item: any) => {
      return {
        id: item.id,
        primaryText: item.title,
        actions: actions,
        onItemClick: () => onItemClick(item),
        onChangeCheckbox: (id: number) => completeSubTask(id),
        checkValue: item.isCompleted,
      };
    }),
  ];
  const iconStyle = {
    transition: 'transform 0.3s ease',
    transform: isAddSubTaskFocused ? 'rotate(0deg)' : 'rotate(45deg)',
  };

  return (
    <BoxStyled width={'100%'}>
      <StyleButton sx={{ width: '100%', pt: 2 }} disableRipple>
        <span>
          <FormattedMessage id="todo.subtask" defaultMessage="SubTask" />
        </span>
        <CloseIcon
          style={iconStyle}
          onClick={() => setIsAddSubTaskFocused(!isAddSubTaskFocused)}
        />
      </StyleButton>
      {isAddSubTaskFocused && (
        <CustomBox mb={-2} mt={-3} px={1}>
          <TextInput
            value={value}
            multiline={false}
            rows={1}
            onKeyPress={(e: any) => onCreateSubTask(e)}
            name="Subtaskname"
            placeholder={intl.formatMessage({
              id: 'todo.create.subtask',
            })}
            onChange={(event: any) => onChange(event)}
          />
        </CustomBox>
      )}
      <CustomBox
        sx={{
          maxHeight: '20vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <STList
          items={itemList}
          isViewChecBox={true}
          displayAction={'ONSELECT'}
          isContentEditableDisabled={isContentEditableDisabled}
          isPrimaryEditable={true}
          isPrimaryTextFocused={isPrimaryTextFocused}
          isItemTextCheckedToDelete={isItemTextCheckedToDelete}
          onPrimaryTextEdit={onPrimaryTextChange}
          selectedItemId={subtaskSelectedId}
          isPrimaryTextDisplayInMultiline={true}
        />
      </CustomBox>
    </BoxStyled>
  );
};
export default TodoSubTask;
