import * as React from 'react';
import Typography from '@mui/material/Typography/Typography';
import FileIcon from '../SvgIcon/FileIcon/FileIcons';
import {
  DropZoneContainer,
  ImageContainer,
  Image,
  Aside,
  BoxIcon,
  ImgAction,
} from './style';
import { useTheme } from '@mui/material';
import CancelIcon from '../../../../../common/CustomDialog/CancelIcon/CancelIcon';

export interface propsDropZone {
  files: any;
  bgColor?: string;
  onDelete(index: number): void;
}
const Dropzone = ({ files, bgColor, onDelete }: propsDropZone) => {
  let re = /\.(jpg|jpeg|png|webp|gif)/i;
  const theme = useTheme();
  const bgCustom =
    theme.palette.mode === 'light' ? bgColor : theme.palette.background.paper;
  const bg = bgColor ? bgCustom : theme.palette.background.paper;
  const bgBorder =
    theme.palette.mode === 'light' ? bgColor : theme.palette.divider;
  const bordercolor = bgColor ? bgBorder : theme.palette.divider;
  const file = files.map((file: any, index: number) => (
    <DropZoneContainer
      key={file.name}
      bgcolor={theme.palette.background.default}
      border={`1px solid ${bordercolor}`}
    >
      <ImgAction bgcolor={bg}>
        <CancelIcon onClose={() => onDelete(index)} />
      </ImgAction>
      <ImageContainer>
        {re.test(file.name) ? (
          <Image
            src={file.preview}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        ) : (
          <BoxIcon>
            <FileIcon
              extension={file.name.slice(
                ((file.name.lastIndexOf('.') - 1) >>> 0) + 2
              )}
            />
          </BoxIcon>
        )}
      </ImageContainer>
      <br />
      {file.name.length > 10 ? (
        <Typography variant="body2">{file.name.slice(0, 12)}...</Typography>
      ) : (
        <Typography variant="body2">{file.name}</Typography>
      )}
    </DropZoneContainer>
  ));
  return (
    <Aside bgcolor={bg} border={`1px solid ${bordercolor}`}>
      <ul>{file}</ul>
    </Aside>
  );
};
export default Dropzone;
