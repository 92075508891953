import * as React from 'react';
import SessionGrouped from './SessionGrouped/SessionGrouped';
import CustomBox from '../../common/CustomBox/CustomBox';
import Loader from './Component/Loader/Loader';
import Header from './Header/Header';
import useChatSessions from './useChatSession';
import LoadMoreSession from './LoadMoreSession/LoadMoreSession';
interface IProps {
  todoId: number;
}

const ChatSession: React.FC<IProps> = ({ todoId }) => {
  const {
    loading,
    error,
    sessions,
    sessionId,
    isLoadingMore,
    hasMoreSessions,
    onItemClick,
    handleUpdateSessionName,
    onLoadMore,
    handleDeleteSession,
  } = useChatSessions(todoId);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  return (
    <CustomBox pt={1}>
      <Header todoId={todoId} />
      <CustomBox
        sx={{
          overflowY: 'auto',
          width: '100%',
          maxHeight: '90vh',
          padding: (theme) => theme.spacing(0.5),
        }}
      >
        <SessionGrouped
          sessions={sessions}
          onClickSession={onItemClick}
          selectedItemId={sessionId}
          handleUpdateSessionName={handleUpdateSessionName}
          groupBy={'createdAt'}
          handleDeleteSessions={handleDeleteSession}
        />
        {hasMoreSessions && (
          <LoadMoreSession isLoading={isLoadingMore} onLoadMore={onLoadMore} />
        )}
      </CustomBox>
    </CustomBox>
  );
};

export default ChatSession;
