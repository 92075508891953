import * as React from 'react';
import Box from '@mui/material/Box/Box';
import CheckedItem from '../../../../../Project/ProjectDashboard/ProjectDashboardHeader/ProjectCollaborators/CollaboratorList/SeclectRole/MenuItemList/CheckedItem/CheckedItem';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';

interface props {
  role: string;
  roleToTest: string;
  isLeft?: boolean;
}
export default function ProjectMenuItem({ role, roleToTest, isLeft }: props) {
  return (
    <Box display="flex" width="100%">
      {role === roleToTest && isLeft ? <CheckedItem /> : null}
      <CustomTypography
        variant="body2"
        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
      >
        {role.toLowerCase()}
      </CustomTypography>
      <Box ml="auto">
        {role === roleToTest && !isLeft ? <CheckedItem /> : null}
      </Box>
    </Box>
  );
}
