import { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
interface IProps {
  onChange: (value: string, isValid: boolean) => void;
  value: any;
  label: string;
  placeholder?: string;
  placeholderIntl?: string;
}

const STJsonEditor: FC<IProps> = ({
  onChange,
  value,
  label,
  placeholder,
  placeholderIntl,
}) => {
  const [jsonText, setJsonText] = useState(value || '');
  const [isValidJson, setIsValidJson] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();

  useEffect(() => {
    setJsonText(value || '');
  }, [value]);

  const handleJsonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setJsonText(text);

    try {
      JSON.parse(text);
      setIsValidJson(true);
      setErrorMessage('');
      if (onChange) {
        onChange(text, true);
      }
    } catch (error: any) {
      setIsValidJson(false);
      setErrorMessage(error?.message as string);
      if (onChange) {
        onChange(text, false);
      }
    }
  };

  return (
    <TextField
      multiline
      rows={10}
      variant="outlined"
      placeholder={
        placeholderIntl
          ? intl.formatMessage({
              id: placeholderIntl || '',
            })
          : placeholder
      }
      fullWidth
      value={jsonText}
      onChange={handleJsonChange}
      error={!isValidJson}
      helperText={!isValidJson ? `Error: ${errorMessage}` : ''}
    />
  );
};

export default STJsonEditor;
