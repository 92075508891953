import React, { useEffect, useRef, useState, MouseEvent } from 'react';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';

interface STAudioProps {
  src: string;
}

const StyledPlayButton = styled(IconButton)({
  width: '40px',
  border: 'none',
});

const STAudioProps: React.FC<STAudioProps> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState(false);
  const handlePlay = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play();
      setPlaying(true);
    } else if (audioRef.current) {
      audioRef.current.pause();
      setPlaying(false);
    }
  };

  useEffect(() => {
    setPlaying(false);
  }, [src]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const onEnd = () => {
        setPlaying(false);
        audio.pause();
      };
      audio.addEventListener('ended', onEnd);
      return () => audio.removeEventListener('ended', onEnd);
    }
  }, []);

  return (
    <>
      <audio
        ref={audioRef}
        src={src}
        onError={(e) => {
          console.log('Error on load audio ', e);
        }}
      />
      <StyledPlayButton onClick={handlePlay}>
        {playing ? <PauseIcon /> : <PlayArrowIcon />}
      </StyledPlayButton>
    </>
  );
};

export default STAudioProps;
