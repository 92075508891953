import React, { FC, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import useImprovePrompt from '../../../Hooks/useImprovePrompt';
import { useIntl } from 'react-intl';

interface Props {
  prompt: string;
  textPrompt: string;
  onRegenerate: (value: string) => void;
}

const STMagicPrompt: FC<Props> = ({ prompt, onRegenerate, textPrompt }) => {
  const { newPrompt, reGeneratePrompt, loading } = useImprovePrompt(
    prompt,
    textPrompt
  );

  const intl = useIntl();

  useEffect(() => {
    if (newPrompt) {
      onRegenerate(newPrompt);
    }
  }, [newPrompt]);

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'tootips.button.magic.prompt',
      })}
      arrow
    >
      <span>
        <IconButton onClick={reGeneratePrompt} disabled={loading}>
          {loading ? (
            <CircularProgress
              sx={{ color: (theme) => theme.palette.grey[200] }}
              size={24}
            />
          ) : (
            <AutoFixHighIcon />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default STMagicPrompt;
