import { Avatar } from '@mui/material';
import { stringToColor } from '../../../utils/backgroundProfil';

interface PropsProfil {
  userProfil: string | null | undefined;
  lastName?: string;
  email?: string;
  alt?: string;
  sx?: any;
}

const UserAvatar = ({ userProfil, lastName, email, alt, sx }: PropsProfil) => {
  const initials = lastName ? lastName.charAt(0).toUpperCase() : '';
  const backgroundColor = userProfil
    ? 'transparent'
    : stringToColor(email || lastName || '');

  const avatarStyles = Object.assign(
    {
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      backgroundColor: backgroundColor,
      color: userProfil ? 'inherit' : '#fff',
    },
    sx
  );

  return (
    <Avatar
      variant="rounded"
      src={userProfil || ''}
      alt={alt}
      sx={avatarStyles}
    >
      {!userProfil && initials}
    </Avatar>
  );
};

export default UserAvatar;
