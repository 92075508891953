import { Box } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import ChatAutoReply from './ChatAutoReply/ChatAutoReply';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CustomIconButton from '../../../common/CustomIconButton/CustomIconButton';
import ProjectBreadCumb from './ProjectBreadCumb/ProjectBreadCumb';
import ResetChatButton from './ResetChatButton/ResetChatButton';
import useCustomBotConfig from '../../../../Hooks/useCustomBotConfig';
import useGetChatContext from '../ChatBodyContainer/useGetChatContext';
import STStartChatBotButton from '../../../common/STStartChatBotButton/STStartChatBotButton';
import { useContextFormChat } from '../../hooks/useContextFormChat';
import Settings from '@mui/icons-material/Settings';
import AgentToolCallDisplay from '../RealtimeUpdate/AgentToolCallDisplay';

const HeadMessage = () => {
  const { isInifiniteChat, handleOpenDrawerConfigWhenMobile } =
    useContextFormChat();
  const { sessionId, botId } = useParams();
  const botID = +(botId || 0);
  const { isBotConfigMode, defaultBot } = useGetChatContext();
  const { customBotConfig } = useCustomBotConfig({ botId: botID });
  const mainChatSessionId = customBotConfig?.botConfigMainChatSessionId;
  const hasExtraConfig =
    defaultBot?.formFields && defaultBot.formFields.length > 0;
  const theSessionId = +(
    (!isBotConfigMode ? sessionId : mainChatSessionId) || 0
  );
  return (
    <CustomBox id="chat-controls-box" display={'flex'} alignItems={'center'}>
      {!isInifiniteChat && <ProjectBreadCumb />}

      <Box flexGrow={1} />
      <AgentToolCallDisplay sessionId={theSessionId} />
      <ResetChatButton
        chatSessionId={
          +((!isBotConfigMode ? sessionId : mainChatSessionId) || 0)
        }
      />
      {isBotConfigMode && <STStartChatBotButton botId={botID} />}

      {!isBotConfigMode && (
        <>
          <CustomBox sx={{ display: ['none', 'block'] }}>
            <Link
              href={`https://graphql.swiftask.ai/chat/export/${sessionId}`}
              download="text.md"
            >
              <CustomIconButton
                sx={{ mr: (theme) => theme.spacing(1) }}
                intlId={'export.chat'}
                size="small"
              >
                <SaveAltIcon fontSize="small" />
              </CustomIconButton>
            </Link>
          </CustomBox>
          {!isInifiniteChat && (
            <CustomBox
              sx={{
                display: ['none', 'block'],
                width: '25px',
                position: 'relative',
                '& span': {
                  fontSize: '12px',
                },
              }}
            >
              <ChatAutoReply />
            </CustomBox>
          )}
        </>
      )}

      {hasExtraConfig ? (
        <CustomIconButton
          intlId="button.open.config.chat.mobile"
          size="small"
          onClick={handleOpenDrawerConfigWhenMobile}
          sx={(theme) => ({
            mt: (theme) => theme.spacing(0.75),
            display: 'none',
            [theme.breakpoints.down(1300)]: {
              display: 'block',
            },
          })}
        >
          <Settings fontSize="small" />
        </CustomIconButton>
      ) : null}
    </CustomBox>
  );
};
export default HeadMessage;
