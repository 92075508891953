import Divider from '@mui/material/Divider';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useGetBot from '../../../../../Hooks/useGetBot';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import ReferralCreditsPopup from '../../../../Setting/Account/ReferralContent/ReferralCreditsPopup/ReferralCreditsPopup';
import PurchaseCreditButton from '../../../../Setting/BotsCredit/PurchaseCreditButton/PurchaseCreditButton';
import UpgradeWorkspace from '../../../../Workspace/UpgradeWorkspace/UpgradeWorkspace';
import { formatNumber } from '../../../../../utils/numberUtil';
import useGetSubscriptionContext from '../../../../Context/SubscriptionContext/useGetSubscriptionContext';
import { SUBSCRIPTION_LEVEL } from '../../../../../constants/subscription';

interface BotLimitReachedCTAProps {
  botId: number;
  isDisplayDescription?: boolean;
}

const BotLimitReachedCTA: FC<BotLimitReachedCTAProps> = ({
  botId,
  isDisplayDescription = true,
}) => {
  const { data, loading } = useGetBot({ botId });
  const { planCode } = useGetSubscriptionContext();
  const remainingExtraCredit = useMemo(() => {
    if (data?.bot?.currentUserCredit?.remainingExtraCredit) {
      return data?.bot?.currentUserCredit.remainingExtraCredit;
    }
    return 0;
  }, [data?.bot?.currentUserCredit?.remainingExtraCredit]);
  if (loading || !data) return null;
  if (!data?.bot) return null;
  if (remainingExtraCredit > 0) return null;

  const isBotPartOfSubscription = data.bot?.isBotPartOfSubscription;
  const descriptionMessageId = isBotPartOfSubscription
    ? 'limit.reached.purchasecreditcta'
    : 'limit.reached.notpartofsubscription';

  return (
    <CustomBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <CustomBox>
        {
          <CustomBox>
            {isDisplayDescription && (
              <CustomBox>
                <CustomTypography variant="body1">
                  <FormattedMessage
                    id={descriptionMessageId}
                    values={{
                      botPrice: data.bot?.creditUnitPrice,
                      botName: data.bot?.name,
                      priceQuantity: formatNumber(
                        data.bot?.creditAmountPerUnitPrice || 0
                      ),
                      quantityLabel: data.bot?.quantityLabelFR,
                    }}
                  />
                </CustomTypography>
              </CustomBox>
            )}

            <CustomBox
              sx={{
                // Align button to the center
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: (theme) => theme.spacing(1),
              }}
            >
              <PurchaseCreditButton
                //stripeLink={data?.bot?.stripePaymentLink}
                //currentUserEmail={dataMe?.me?.email}
                buttonHeight={40}
                buttonWidth={300}
                // botName={data.bot?.name}
                //creditPrice={data.bot?.creditUnitPrice || 0}
              />
            </CustomBox>
          </CustomBox>
        }
        {planCode === SUBSCRIPTION_LEVEL.FREE && (
          <CustomBox
            sx={{
              mt: (theme) => theme.spacing(1),
            }}
          >
            <CustomBox
              sx={{
                mb: (theme) => theme.spacing(1),
              }}
            >
              <CustomTypography variant="body2">
                <FormattedMessage
                  id="limit.reached.upgradeplancta"
                  values={{
                    botPrice: data.bot?.creditUnitPrice,
                    botName: data.bot?.name,
                    priceQuantity: formatNumber(
                      data.bot?.creditAmountPerUnitPrice || 0
                    ),
                    quantityLabel: data.bot?.quantityLabel,
                  }}
                />
              </CustomTypography>
            </CustomBox>

            <CustomBox
              sx={{
                // Align button to the center
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UpgradeWorkspace
                alwaysDisplayUpgradeButton={true}
                buttonHeight={40}
                buttonWidth={300}
              />
            </CustomBox>
          </CustomBox>
        )}
      </CustomBox>
      <Divider sx={{ mt: 2, color: (theme) => theme.palette.primary.main }}>
        <CustomTypography variant="h5">
          <FormattedMessage id="limit.reached.referralcta" />
        </CustomTypography>
      </Divider>
      <CustomBox>
        <ReferralCreditsPopup />
      </CustomBox>
    </CustomBox>
  );
};

export default BotLimitReachedCTA;
