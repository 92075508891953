import { FC } from 'react';
import { graphql } from '../../../../../gql';
import { useMutation } from '@apollo/client';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';

// Mutation saveAndShareArtifact
const SAVE_SHARE_HTML = graphql(
  `
    mutation saveAndShareArtifact($htmlContent: String!, $messageId: Float!) {
      saveAndShareArtifact(htmlContent: $htmlContent, messageId: $messageId)
    }
  `
);

const useSaveAndShareHtml = () => {
  const { displayMessage } = useDisplayMessage();
  const [sharePreview, { loading, error }] = useMutation(SAVE_SHARE_HTML, {
    onError: (error) => {
      displayMessage({ message: 'htmlPreview.failedToShare', type: 'error' });
    },
  });

  return {
    sharePreview,
    loading,
    error,
  };
};

export default useSaveAndShareHtml;
