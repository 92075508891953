import { FC, useEffect, useState } from 'react';
import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../../../common/CustomButton/CustomButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import STPopoverList from '../../../../../../common/STPopoverList/STPopoverList';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
import {
  setLastedConfigSelected,
  getLastedConfigSelected,
} from '../../../../../utils/lastedConfigSelected';

interface IProps {
  botUIData: any[];
  onSelect: (name: string, value: any) => void;
  selected?: string;
  slug: string;
  keyField: string;
}

const ListeSelector: FC<IProps> = ({
  botUIData,
  onSelect,
  selected,
  slug,
  keyField,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onSelect(keyField, getLastedConfigSelected(slug)[0] || botUIData[0].name);
  }, [botUIData.length]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const onClose = () => {
    setOpen((prev) => !prev);
    setAnchorEl(null);
  };

  const onChoose = (voice: string) => () => {
    onSelect(keyField, voice);
    setLastedConfigSelected(slug, voice);
    onClose();
  };

  const id = open ? 'simple-popper' : undefined;

  const configSelector = botUIData.find((voice) => voice.name === selected);

  const listOption = botUIData.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const listRecentlyUsed = (getLastedConfigSelected(slug) || []).map(
    (selected: string) => ({
      label: selected,
      value: selected,
    })
  );

  return (
    <CustomBox>
      <CustomButton
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        variant="outlined"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderColor: (theme) => theme.palette.grey[200],
        }}
      >
        {configSelector ? (
          <CustomBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <CustomTypography
              sx={{
                minWidth: (theme) => theme.spacing(15),
                minHeight: (theme) => theme.spacing(4),
                paddingLeft: (theme) => theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                textAlign: 'start',
              }}
            >
              {configSelector.name}
            </CustomTypography>
            <UnfoldMoreIcon
              fontSize="small"
              sx={{ color: (theme) => theme.palette.grey[200] }}
            />
          </CustomBox>
        ) : null}
      </CustomButton>

      <STPopoverList
        open={open}
        anchorEl={anchorEl}
        list={listOption}
        currentValue={selected || ''}
        listRecentlyUsed={listRecentlyUsed}
        onChange={onChoose}
        onClose={onClose}
        option={{
          intlId: {
            recentlyUsed: 'poper.voice.selector.recently.used',
            placeholder: 'poper.voice.selector.list.placeholder',
            list: 'poper.voice.selector.list',
          },
        }}
      />
    </CustomBox>
  );
};

export default ListeSelector;
