import { useMutation } from '@apollo/client';
import { graphql } from '../gql/gql';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';

const GET_PURCHASE_CREDIT_URL = graphql(`
  mutation GetPurchaseCreditUrl($swiftaskTokenPriceId: Float!) {
    getPurchaseCreditUrl(swiftaskTokenPriceId: $swiftaskTokenPriceId)
  }
`);

const useGetPurchaseCreditUrl = () => {
  const [getPurchaseCreditUrl, { loading }] = useMutation(
    GET_PURCHASE_CREDIT_URL,
    {
      update(cache, { data }) {
        if (data?.getPurchaseCreditUrl) {
          // Handle the returned Stripe checkout URL
          window.open(data.getPurchaseCreditUrl, '_blank');
        }
      },
      onError(error) {
        // Handle any errors that occur during the mutation
        displayMessage({ message: error.message, type: 'error' });
      },
    }
  );

  const { displayMessage } = useDisplayMessage();

  return {
    getPurchaseCreditUrl,
    loading,
  };
};

export default useGetPurchaseCreditUrl;
