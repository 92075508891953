/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import { FileProps } from '../Attachments';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

function srcset(video: string, size: number, rows = 1, cols = 1) {
  return {
    url: `${video}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${video}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

interface props {
  item: FileProps;
}

export default function VideoItem({ item }: props) {
  return (
    <>
      <ImageListItem cols={item.cols || 1} rows={item.rows || 1}>
        <StyledReactPlayer
          width={'100%'}
          height={'95%'}
          controls={true}
          preload="metadata"
          {...srcset(item.url, 121, item.rows, item.cols)}
        />
      </ImageListItem>
    </>
  );
}
