import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

const COMPLETE_TODO_SUBTASK = graphql(`
mutation CompleteTodoSubtask($todoSubTaskId: Float!) {
    completeTodoSubtask(todoSubTaskId: $todoSubTaskId) {
      id
      isCompleted
      title
    }
  }
`);

const useCompleteTodoSuTask = () => {
  const [completeTodoSubTask, { loading }] = useMutation(COMPLETE_TODO_SUBTASK);
  const completeSubTask = (todoSubTaskId: number) => {
    completeTodoSubTask({
      variables: {
        todoSubTaskId,
      },

    });
  };

  return {
    completeSubTask,
    loading,
  };
};

export default useCompleteTodoSuTask;
