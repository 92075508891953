import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';

import CustomBox from '../../../../common/CustomBox/CustomBox';
import STMenu from '../../../../common/STMenu/STMenu';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

import { getAllCategoriesLabel } from './utils/constants';
import { formatText } from './utils/formatText';

interface IProps {
  categories?: string[];
  handleChangeCategory?: (categoryName: string) => void;
  latestCategoryFilter?: string;
}

const DatasourceCategoryMenulist = ({
  categories,
  handleChangeCategory,
  latestCategoryFilter,
}: IProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ALL_CATEGORIES = getAllCategoriesLabel(intl);

  const [currentCategory, setCurrentCategory] =
    useState<string>(ALL_CATEGORIES);

  useEffect(() => {
    if (latestCategoryFilter) {
      const defaultCategory = categories?.find(
        (category) => category === latestCategoryFilter
      );
      if (defaultCategory) {
        setCurrentCategory(defaultCategory);
      } else {
        setCurrentCategory(ALL_CATEGORIES);
      }
    }
  }, [categories, latestCategoryFilter, ALL_CATEGORIES]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChooseCategory = (category: string) => {
    setCurrentCategory(category);
    handleChangeCategory?.(category);
    handleClose();
  };

  const menuItems = [
    {
      component: (
        <CustomBox key="ALL">
          <CustomTypography>{ALL_CATEGORIES}</CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        onChooseCategory(ALL_CATEGORIES);
      },
    },
    ...(categories?.map((category, index) => ({
      component: (
        <CustomBox key={`${category}-${index}`}>
          <CustomTypography>{formatText(category)}</CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        onChooseCategory(category);
      },
    })) || []),
  ];

  return (
    <StyledBoxWrapper sx={{ display: 'flex', columnGap: 1, width: '100%' }}>
      <StyledCustomBox onClick={handleClick}>
        <CustomTypography>{formatText(currentCategory)}</CustomTypography>
      </StyledCustomBox>
      {Boolean(anchorEl) && (
        <STMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          menuItems={menuItems}
          disablePortal
        />
      )}
    </StyledBoxWrapper>
  );
};

export default DatasourceCategoryMenulist;

const StyledCustomBox = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  padding: theme.spacing(0, 1),
  background: theme.palette.background.default,
  borderRadius: 12,
  width: '100%',
  '&:hover': {
    opacity: 1,
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
}));

const StyledBoxWrapper = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
  width: '100%',
}));
