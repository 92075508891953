import * as React from 'react';
import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
export interface propsTitle {
    TitleValue: string;
}

const StyledTypography = styled(CustomTypography)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: theme.typography.h3.fontSize
}));

const Title = ({ TitleValue }: propsTitle) => {
    return (
        <StyledTypography
            className={"TitleValue"}
        >
            <FormattedMessage id={"Authentication.Title"} defaultMessage={TitleValue} />
        </StyledTypography>
    );
}
export default Title