import { FC, useEffect, useState } from 'react';
import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import STAudio from '../../../../../../common/STAudio/STAudio';
import CustomButton from '../../../../../../common/CustomButton/CustomButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import STPopoverList from '../../../../../../common/STPopoverList/STPopoverList';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
import {
  setLastedVoiceSelected,
  getLastedVoiceSelected,
} from '../../../../../utils/lastedVoiceSelected';

interface IProps {
  botUIData: any[];
  onVoiceSelect: (name: string, value: any) => void;
  voiceSelected?: string;
}

const VoiceSelector: FC<IProps> = ({
  botUIData,
  onVoiceSelect,
  voiceSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const listOption = botUIData.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const listRecentlyUsed = (getLastedVoiceSelected() || [])
    .filter((voice: string) => listOption.find((v) => v.label === voice))
    .map((voiceSelected: string) => ({
      label: voiceSelected,
      value: voiceSelected,
    }));

  useEffect(() => {
    onVoiceSelect('voice', listRecentlyUsed[0]?.value || botUIData[0].name);
  }, [botUIData.length]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const onClose = () => {
    setOpen((prev) => !prev);
    setAnchorEl(null);
  };

  const onChooseVoice = (voice: string) => () => {
    onVoiceSelect('voice', voice);
    setLastedVoiceSelected(voice);
    onClose();
  };

  const id = open ? 'simple-popper' : undefined;

  const voiceSelectedObject = botUIData.find(
    (voice) => voice.name === voiceSelected
  );

  return (
    <CustomBox>
      <CustomButton
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        variant="outlined"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          borderColor: (theme) => theme.palette.grey[200],
        }}
      >
        {voiceSelectedObject ? (
          <CustomBox sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <STAudio src={voiceSelectedObject?.preview_url || ''} />
            <CustomTypography sx={{ width: '80px', textAlign: 'start' }}>
              {voiceSelectedObject.name}
            </CustomTypography>
            <UnfoldMoreIcon
              fontSize="small"
              sx={{ color: (theme) => theme.palette.grey[200] }}
            />
          </CustomBox>
        ) : null}
      </CustomButton>

      <STPopoverList
        open={open}
        anchorEl={anchorEl}
        list={listOption}
        currentValue={voiceSelected || ''}
        listRecentlyUsed={listRecentlyUsed}
        onChange={onChooseVoice}
        onClose={onClose}
        option={{
          intlId: {
            recentlyUsed: 'poper.voice.selector.recently.used',
            placeholder: 'chat.extraConfig.filter.voice.placeholder',
            list: 'poper.voice.selector.list.voice',
          },
        }}
      />
    </CustomBox>
  );
};

export default VoiceSelector;
