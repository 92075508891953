// import CustomSearchInput from '../../common/CustomSearchInput/CustomSearchInput';
import { Box, styled } from '@mui/material';
import PromptsStepOne from './PrompsStepOne/PromptsStepOne';

interface props {
  handleValidePrompts(prompts: string): void;
  handleSearchChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  isMobile: boolean;
  searchValue: string;
}

const CustomBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  // flexDirection: 'column',
  height: theme.spacing(80),
}));

export default function BodyPrompts({
  handleValidePrompts,
  // handleSearchChange,
  searchValue,
  isMobile,
}: props) {
  return (
    <CustomBoxContainer>
      {/* {isMobile && (
        <Box
          sx={{
            position: 'relative',
            justifyContent: 'flex-end',
            mx: 2,
            py: 2,
            display: 'flex',
            flexGrow: 0,
          }}
        >
          <CustomSearchInput
            searchInputPlaceholder="Rechercher un prompt..."
            searchValue={searchValue}
            onChangeInputSearch={handleSearchChange}
          />
        </Box>
      )} */}
      <PromptsStepOne
        callback={handleValidePrompts}
        searchValue={searchValue}
      />
    </CustomBoxContainer>
  );
}
