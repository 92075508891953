/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import ImageViewer from '../ImageViewer/ImageViewer';
import { FileProps } from '../Attachments';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

interface props {
  item: FileProps;
}

export default function ImageItem({ item }: props) {
  const [open, setOpen] = React.useState(false);
  const [src, setSrc] = React.useState('');

  const handleViewImage = (src: string) => {
    setSrc(src);
    setOpen(true);
  };

  const handleClose = () => {
    setSrc('');
    setOpen(false);
  };

  return (
    <>
      <ImageViewer handleClose={handleClose} open={open} src={src} />
      <ImageListItem
        key={item.url}
        onClick={() => handleViewImage(item.url)}
        sx={{ cursor: 'pointer'}}
        cols={item.cols || 1}
        rows={item.rows || 1}
      >
        <img
          style={{ borderRadius: '10px' }}
          {...srcset(item.url, 121, item.rows, item.cols)}
          alt={item.title}
        />
      </ImageListItem>
    </>
  );
}
