import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';

import CustomBox from '../../../../common/CustomBox/CustomBox';
import STMenu from '../../../../common/STMenu/STMenu';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

import { SyncStatus } from '../../../../../gql/graphql';

import { getAllStatusLabel } from './utils/constants';
import { formatText } from './utils/formatText';

interface IProps {
  dataSourcesSyncStatus?: SyncStatus[];
  handleChangeStatus?: (status: SyncStatus) => void;
  latestFilter?: SyncStatus;
}

const DatasourceStatusMenulist = ({
  dataSourcesSyncStatus = [],
  handleChangeStatus,
  latestFilter,
}: IProps) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ALL_STATUS = getAllStatusLabel(intl);

  const [currentStatus, setCurrentStatus] = useState<string>(ALL_STATUS);

  useEffect(() => {
    if (latestFilter) {
      const defaultStatus = dataSourcesSyncStatus?.find(
        (status) => status === latestFilter
      );
      if (defaultStatus) {
        setCurrentStatus(defaultStatus);
      } else {
        setCurrentStatus(ALL_STATUS);
      }
    }
  }, [dataSourcesSyncStatus, latestFilter, ALL_STATUS]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChooseCategory = (status: SyncStatus) => {
    setCurrentStatus(status);
    handleChangeStatus?.(status);
    handleClose();
  };

  const filteredMenuItems = [
    {
      component: (
        <CustomBox key="ALL">
          <CustomTypography>{ALL_STATUS}</CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        onChooseCategory(ALL_STATUS as SyncStatus);
      },
    },
    ...dataSourcesSyncStatus.map((syncStatusValue, index) => ({
      component: (
        <CustomBox key={`${syncStatusValue}-${index}`}>
          <CustomTypography>{formatText(syncStatusValue)}</CustomTypography>
        </CustomBox>
      ),
      onClick: () => {
        onChooseCategory(syncStatusValue);
      },
    })),
  ];

  return (
    <StyledBoxWrapper sx={{ display: 'flex', columnGap: 1, width: '100%' }}>
      <StyledCustomBox onClick={handleClick}>
        <CustomTypography>{formatText(currentStatus)}</CustomTypography>
      </StyledCustomBox>
      {Boolean(anchorEl) && (
        <STMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          menuItems={filteredMenuItems}
          disablePortal
        />
      )}
    </StyledBoxWrapper>
  );
};

export default DatasourceStatusMenulist;

const StyledCustomBox = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  padding: theme.spacing(0, 1),
  background: theme.palette.background.default,
  borderRadius: 12,
  width: '100%',
  '&:hover': {
    opacity: 1,
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
}));

const StyledBoxWrapper = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(1),
  width: '100%',
}));
