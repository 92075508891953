import { Facebook } from '@mui/icons-material';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from 'react-share';
import CustomBox from '../../../../common/CustomBox/CustomBox';

interface ShareLinkToSocialMediaProps {
  link: string;
}

const ShareLinkToSocialMedia: React.FC<ShareLinkToSocialMediaProps> = ({
  link,
}) => {
  const style = {
    marginRight: '8px',
  };
  return (
    <CustomBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <FacebookShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <EmailShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
      <RedditShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <RedditIcon size={32} round />
      </RedditShareButton>
      <TelegramShareButton
        style={{
          ...style,
        }}
        url={link}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
    </CustomBox>
  );
};

export default ShareLinkToSocialMedia;
