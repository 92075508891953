import * as React from 'react';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/system/Box';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import CustomLink from '../../../../common/CustomLink/CustomLink';

const StyledTypography = styled(CustomTypography)(({ theme }) => ({}));

const CardLinkSignup = () => {
  const theme = useTheme();
  const styleSignupText = {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    marginLeft: '5px',
  };
  return (
    <Box role="box" sx={{ textAlign: 'center' }}>
      <StyledTypography>
        <FormattedMessage
          id={'signup.alreadyAccount'}
          defaultMessage={'Already have an account?'}
        />
        <CustomLink
          shouldInclueRedirect={true}
          shouldIncludeInvite={true}
          to={'/signin'}
          sx={styleSignupText}
        >
          <FormattedMessage id="signin.button" defaultMessage="Sign In" />{' '}
        </CustomLink>
      </StyledTypography>
    </Box>
  );
};
export default CardLinkSignup;
