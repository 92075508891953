import { ChangeEvent, FC, useEffect, useState } from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { ClickAwayListener, InputAdornment } from '@mui/material';
import CustomBox from '../CustomBox/CustomBox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import STTextField from '../STTextField/STTextField';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useIntl, FormattedMessage } from 'react-intl';

interface ISTPopoverList {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  list: { label: string; value: string }[];
  listRecentlyUsed?: { label: string; value: string }[];
  currentValue: string;
  onChange: (voiceSelected: string) => () => void;
  onClose: () => void;
  option: {
    intlId: {
      list: string;
      recentlyUsed: string;
      placeholder: string;
    };
  };
}

const STPopoverList: FC<ISTPopoverList> = ({
  open,
  anchorEl,
  list,
  onChange,
  onClose,
  currentValue,
  listRecentlyUsed,
  option,
}) => {
  const [filter, setFilter] = useState('');
  const handleFilter = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter(e.target.value);
  const intl = useIntl();

  useEffect(() => {
    if (!open) {
      setFilter('');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        sx={{ zIndex: 1200, paddingBottom: '16px' }}
        open={open}
        placement="top"
        transition={true}
        anchorEl={anchorEl}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <STTextField
                name="filter"
                onChange={handleFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder={intl.formatMessage({
                  id: option.intlId.placeholder,
                })}
                sx={{
                  border: 'none',
                  '& .MuiInputBase-root': {
                    padding: '8px', // Padding for input area
                  },
                }}
              />
              <CustomBox
                sx={{
                  maxHeight: '240px',
                  overflow: 'auto',
                }}
              >
                {listRecentlyUsed?.length ? (
                  <>
                    <Divider />
                    <Typography variant="body2" color="text.secondary" pl={2}>
                      <FormattedMessage id={option.intlId.recentlyUsed} />
                    </Typography>
                    {listRecentlyUsed
                      .filter((item) =>
                        item.label
                          .toLocaleLowerCase()
                          .includes(filter.toLocaleLowerCase())
                      )
                      .map((item, index: number) => (
                        <MenuItem key={index} onClick={onChange(item.value)}>
                          <Typography variant="body2" color="text.secondary">
                            {item?.value && currentValue === item?.value ? (
                              <>
                                <ListItemIcon>
                                  <CheckIcon
                                    fontSize="small"
                                    sx={{ fontSize: '15px' }}
                                  />
                                </ListItemIcon>
                                {item?.label}
                              </>
                            ) : (
                              <ListItemText inset={true}>
                                {item.label}
                              </ListItemText>
                            )}
                          </Typography>
                        </MenuItem>
                      ))}
                  </>
                ) : null}

                <Divider />
                <Typography variant="body2" color="text.secondary" pl={2}>
                  <FormattedMessage id={option.intlId.list} />
                </Typography>

                {list
                  .filter((item) =>
                    item.label
                      .toLocaleLowerCase()
                      .includes(filter.toLocaleLowerCase())
                  )
                  .map((item, index: number) => (
                    <MenuItem key={index} onClick={onChange(item.value)}>
                      <Typography variant="body2" color="text.secondary">
                        {item?.value && currentValue === item?.value ? (
                          <>
                            <ListItemIcon>
                              <CheckIcon
                                fontSize="small"
                                sx={{ fontSize: '15px' }}
                              />
                            </ListItemIcon>
                            {item?.label}
                          </>
                        ) : (
                          <ListItemText inset={true}>{item.label}</ListItemText>
                        )}
                      </Typography>
                    </MenuItem>
                  ))}
              </CustomBox>
            </Paper>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default STPopoverList;
