// MessageTime component to diplsay the time of the message in the chat using the moment library
import * as React from 'react';
import Moment from 'react-moment';
import { styled } from '@mui/material/styles';

// Styled MessageTime typography
const StyledTime = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 2,
  marginLeft: '4px',
}));

interface IProps {
  createdAt: string;
}

const MessageTime: React.FC<IProps> = ({ createdAt }) => {
  return (
    <StyledTime>
      <Moment fromNow>{createdAt}</Moment>
    </StyledTime>
  );
};

export default MessageTime;
