// A custom NavLink component that allows us to use the "to" prop instead of "href"

import { styled } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getUrlParam } from '../../../utils/url';

// Styled NavLink
const StyledLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
}));

interface IProps {
  to: string;
  children: React.ReactNode;
  shouldInclueRedirect?: boolean;
  shouldIncludeInvite?: boolean;
  sx?: any;
}

const CustomLink: React.FC<IProps> = ({
  to,
  children,
  shouldInclueRedirect,
  shouldIncludeInvite,
  sx,
  ...rest
}) => {
  const redirectUrl = getUrlParam('redirect');
  // Invite code
  const inviteCode = getUrlParam('invite');

  if (shouldInclueRedirect && redirectUrl && !to.includes('?redirect')) {
    to = to + '?redirect=' + redirectUrl;
    if (inviteCode && shouldIncludeInvite) {
      to = to + '&invite=' + inviteCode;
    }
  } else if (inviteCode && shouldIncludeInvite && !to.includes('?invite')) {
    to = to + '?invite=' + inviteCode;
  }

  return (
    <StyledLink to={to} sx={sx} {...rest}>
      {children}
    </StyledLink>
  );
};

export default CustomLink;
