// create a function verify NotNull to check if a value is not null and length is greater than 0

const verifyNotNull = (value: any) => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value.length === 0
  ) {
    return false;
  }
  return true;
};

export default verifyNotNull;
