import { DividerProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import { FC } from 'react';

interface IProps extends DividerProps {}

const STDivider: FC<IProps> = ({ ...rest }) => {
  return <Divider {...rest} />;
};

export default STDivider;
