import React, { useMemo } from 'react';
import { List, ListProps } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import useGroupSTList from '../../../common/STList/useGroupSTList';
import SessionItem from './SessionItem/SessionItem';

interface Props extends ListProps {
  sessions: any[];
  groupBy: 'createdAt' | 'updatedAt';
  selectedItemId: number;
  onClickSession: (session: any) => void;
  handleDeleteSessions: () => void;
  handleUpdateSessionName: (newSessionName: string) => void;
}

const SessionGrouped: React.FC<Props> = ({
  groupBy,
  sessions,
  onClickSession,
  selectedItemId,
  handleDeleteSessions,
  handleUpdateSessionName,
  ...rest
}) => {
  const { todoMainChatSessions, otherSessions } = useMemo(() => {
    return sessions.reduce(
      (acc, session) => {
        if (session.isTodoMainChat) {
          acc.todoMainChatSessions.push(session);
        } else {
          acc.otherSessions.push(session);
        }
        return acc;
      },
      { todoMainChatSessions: [] as any[], otherSessions: [] as any[] }
    );
  }, [sessions]);

  const groupedOtherSessions = useGroupSTList(otherSessions, groupBy);

  const renderSessionItems = (sessions: any[], isDisabledToDelete: boolean) =>
    sessions.map((session) => (
      <SessionItem
        key={session.id}
        session={session}
        selectedItemId={selectedItemId}
        isDisabledToDelete={isDisabledToDelete}
        handleDeleteSessions={handleDeleteSessions}
        onClickSession={() => onClickSession(session)}
        handleUpdateSessionName={handleUpdateSessionName}
      />
    ));

  return (
    <List {...rest}>
      {todoMainChatSessions.length > 0 && (
        <CustomBox>
          {renderSessionItems(
            todoMainChatSessions,
            todoMainChatSessions.length === 1 && otherSessions.length === 0
          )}
        </CustomBox>
      )}
      {groupedOtherSessions?.map((data) => (
        <CustomBox key={`group-${data.name}`}>
          {data.items.length > 0 && (
            <CustomTypography
              fontWeight={600}
              my={1}
              sx={{
                color: (theme) => theme.palette.grey[200],
                marginLeft: (theme) => theme.spacing(2),
                textTransform: 'capitalize',
              }}
            >
              {data.name}
            </CustomTypography>
          )}
          {renderSessionItems(
            data.items,
            data.items.length === 1 && todoMainChatSessions.length === 0
          )}
        </CustomBox>
      ))}
    </List>
  );
};

export default SessionGrouped;
