import * as React from 'react';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Icon from './Svg/Icon';
import CheckedIcon from './Svg/CheckedIcon';
import CustomTooltip from '../../../../common/CustomToolTip/CustomToolTip';

interface propsCheckBox {
  Check?: boolean;
  onChange(): void;
}

const CheckBox = ({ Check, onChange }: propsCheckBox) => {
 
  return (
    <CustomTooltip intlId="task.complete.checkbox.title" title="">
      <Checkbox
        inputProps={{ 'aria-label': 'Checkbox task' }}
        checked={Check || false}
        onChange={onChange}
        icon={<Icon />}
        checkedIcon={<CheckedIcon />}
      />
    </CustomTooltip>
  );
};

export default CheckBox;
