import React from 'react';
import STChip from '../../../../../common/STChip/STChip';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import _uniqBy from 'lodash/uniqBy';
import _groupBy from 'lodash/groupBy';

interface IProps {
  metaSources?: {
    id: number;
    source: string;
    type: string;
    name?: string;
    url?: string;
  }[];
  onSourceClick: (source: any) => void;
}
const groupByNames = (metaSources: any) => {
  // Using lodash
  // Filter out source that has no name
  metaSources = metaSources.filter((metaSource: any) => metaSource.name);
  const grouped = _groupBy(metaSources, 'name');
  return grouped;
};

const MessageMetaSources: React.FC<IProps> = ({
  metaSources,
  onSourceClick,
}) => {
  const metaSourcesGrouped = React.useMemo(
    () => groupByNames(metaSources),
    [metaSources]
  );

  if (!metaSources) {
    return null;
  }
  const sources = Object.keys(metaSourcesGrouped);
  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <CustomBox>
      <CustomBox>
        <CustomBox>
          <CustomTypography sx={{ fontSize: '12px', mr: 1 }}>
            SOURCES:
          </CustomTypography>
        </CustomBox>
        <ul>
          {Object.keys(metaSourcesGrouped).map((name, index) => {
            return (
              <li>
                <CustomTypography
                  variant="body2"
                  display="inline"
                  mb={1}
                  mr={1}
                >
                  {name}
                </CustomTypography>
              </li>
            );
          })}
        </ul>
      </CustomBox>
    </CustomBox>
  );
};

export default React.memo(MessageMetaSources);
