import { FC, memo } from 'react';
import ChatSession from './ChatSession';
import useDisplayHistoryChat from '../ChatSession/Hooks/useDisplayHistoryChat';
import { StyledChatHistory, OnClickOutside } from './ChatSession.style';

interface IProps {
  todoId: number;
}
const ChatSessionLayout: FC<IProps> = ({ todoId }) => {
  const {
    isDisplayHistoryChat,
    displayPositionHistoryChat,
    handleToogleDisplayHistoryChat,
  } = useDisplayHistoryChat();

  if (!isDisplayHistoryChat) {
    return <div />;
  }

  return (
    <>
      <StyledChatHistory position={displayPositionHistoryChat}>
        <ChatSession todoId={todoId} />
      </StyledChatHistory>

      {displayPositionHistoryChat === 'absolute' ? (
        <OnClickOutside onClick={handleToogleDisplayHistoryChat} />
      ) : null}
    </>
  );
};

export default memo(ChatSessionLayout);
