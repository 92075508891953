import { FC } from 'react';
import STChip from '../../common/STChip/STChip';
import { useIntl } from 'react-intl';
import CustomBox from '../../common/CustomBox/CustomBox';
import { Alert } from '@mui/material';
import { ChatDataSourceSyncStatus } from '../../../gql/graphql';
import Loading from '../../common/Loading/Loading';

interface IProps {
  syncToVectorStoreError?: string | null;
  syncStatus?: string | null;
  displayErrorMessages?: boolean | null;
}

const DataSourceIndexStatus: FC<IProps> = ({
  syncToVectorStoreError,
  syncStatus,
  displayErrorMessages,
}) => {
  const intl = useIntl();
  const intlMessage: any = {
    [ChatDataSourceSyncStatus.LoadDocuments]: {
      id: 'dataSources.index.loadDocuments',
      color: 'info',
      displayLoading: true,
    },
    [ChatDataSourceSyncStatus.Embedding]: {
      id: 'dataSources.index.embedding',
      color: 'info',
      displayLoading: true,
    },
    [ChatDataSourceSyncStatus.Indexing]: {
      id: 'dataSources.index.indexing',
      color: 'info',
      displayLoading: true,
    },
    [ChatDataSourceSyncStatus.Failed]: {
      id: 'dataSources.index.syncToVectorStoreError',
      color: 'error',
      displayLoading: false,
    },
    [ChatDataSourceSyncStatus.InProgress]: {
      id: 'dataSources.index.syncToVectorStoreInProgress',
      color: 'info',
      displayLoading: true,
    },
    [ChatDataSourceSyncStatus.Success]: {
      id: 'dataSources.index.syncToVectorStoreSuccess',
      color: 'success',
      displayLoading: false,
    },
  };
  if (syncStatus === 'FAILED') {
    return (
      <CustomBox>
        <STChip
          label={intl.formatMessage({
            id: 'dataSources.index.syncToVectorStoreError',
          })}
          color="error"
        />
        {displayErrorMessages && (
          <CustomBox mt={1}>
            <Alert severity="error">{syncToVectorStoreError}</Alert>
          </CustomBox>
        )}
      </CustomBox>
    );
  }
  const theStatus = syncStatus
    ? syncStatus
    : ChatDataSourceSyncStatus.LoadDocuments;
  return (
    <>
      <CustomBox
        sx={{
          display: 'flex',
        }}
      >
        {intlMessage[theStatus as string].displayLoading && (
          <Loading size={25} type="circular" />
        )}
        <STChip
          sx={{
            ml: 1,
          }}
          label={intl.formatMessage({
            id: intlMessage[theStatus as string].id,
          })}
          color={intlMessage[theStatus as string].color}
        />
      </CustomBox>
    </>
  );
};

export default DataSourceIndexStatus;
