// src/CustomScrollbar.tsx
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import useDebounce from './useDebounce'; // Adjust the path as necessary
import {
  StyledScrollBar,
  StyledScrollIndicator,
} from './MessageContainerStyle';

// Minimum height for the scrollbar indicator
const MIN_INDICATOR_HEIGHT = 0;

interface Props {
  contentRef: React.RefObject<HTMLDivElement>;
}

// Styled Components with React.memo to prevent unnecessary re-renders
const MemoizedStyledScrollBar = React.memo(StyledScrollBar);
const MemoizedStyledScrollIndicator = React.memo(StyledScrollIndicator);

const MessageContainerScroll: React.FC<Props> = ({ contentRef }) => {
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const scrollIndicatorRef = useRef<HTMLDivElement>(null);

  // State variables
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [indicatorTop, setIndicatorTop] = useState<number>(0);
  const [indicatorHeight, setIndicatorHeight] =
    useState<number>(MIN_INDICATOR_HEIGHT);
  const [startY, setStartY] = useState<number>(0);
  const [startTop, setStartTop] = useState<number>(0);
  const [clientHeight, setClientHeight] = useState<number>(0);
  const [clientTop, setClientTop] = useState<number>(0);

  /**
   * Updates the scrollbar indicator's position and size based on the content's scroll state.
   */
  const updateScrollBar = useCallback((): void => {
    const contentDiv = contentRef.current;
    const scrollBar = scrollBarRef.current;

    if (!contentDiv || !scrollBar) return;

    const contentHeight = contentDiv.scrollHeight;
    const visibleHeight = contentDiv.clientHeight;
    const scrollTop = contentDiv.scrollTop;

    const scrollRatio = visibleHeight / contentHeight;
    const calculatedIndicatorHeight = Math.max(
      scrollRatio * scrollBar.clientHeight,
      MIN_INDICATOR_HEIGHT
    );

    const maxIndicatorTop = scrollBar.clientHeight - calculatedIndicatorHeight;
    const calculatedIndicatorTop =
      contentHeight === visibleHeight
        ? 0
        : (scrollTop / (contentHeight - visibleHeight)) * maxIndicatorTop;

    // Batch state updates
    setIndicatorHeight(calculatedIndicatorHeight);
    setIndicatorTop(calculatedIndicatorTop);
    setClientHeight(contentDiv.clientHeight);
    setClientTop(contentDiv.getBoundingClientRect().top);
  }, [contentRef]);

  /**
   * Debounced version of updateScrollBar to optimize performance during rapid events.
   */
  const debouncedUpdateScrollBar = useDebounce(updateScrollBar, 1);

  /**
   * Initiates the dragging state when the user starts dragging the scrollbar indicator.
   */
  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>): void => {
      setIsDragging(true);
      setStartY(event.clientY);
      setStartTop(indicatorTop);
      event.preventDefault(); // Prevents text selection

      // Capture the pointer to continue receiving events even if it leaves the element
      scrollIndicatorRef.current?.setPointerCapture(event.pointerId);
    },
    [indicatorTop]
  );

  /**
   * Handles the movement of the pointer during dragging.
   */
  const handlePointerMove = useCallback(
    (event: PointerEvent): void => {
      if (!isDragging) return;

      const scrollBar = scrollBarRef.current;
      if (!scrollBar) return;

      const deltaY = event.clientY - startY;
      let newTop = startTop + deltaY;

      const maxIndicatorTop = scrollBar.clientHeight - indicatorHeight;
      newTop = Math.max(0, Math.min(newTop, maxIndicatorTop));

      setIndicatorTop(newTop);

      const scrollRatio = maxIndicatorTop > 0 ? newTop / maxIndicatorTop : 0;
      const contentDiv = contentRef.current;
      if (contentDiv) {
        contentDiv.scrollTop =
          scrollRatio * (contentDiv.scrollHeight - contentDiv.clientHeight);
      }
    },
    [isDragging, startY, startTop, indicatorHeight, contentRef]
  );

  /**
   * Ends the dragging state when the pointer is released.
   */
  const handlePointerUp = useCallback((): void => {
    setIsDragging(false);
  }, []);

  /**
   * Sets up global event listeners for pointer movements and releases during dragging.
   */
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);
      document.body.style.userSelect = 'none'; // Disable text selection
    } else {
      document.body.style.userSelect = 'auto'; // Enable text selection
    }

    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.body.style.userSelect = 'auto'; // Ensure it's re-enabled
    };
  }, [isDragging, handlePointerMove, handlePointerUp]);

  /**
   * Attaches scroll and resize event listeners to update the scrollbar.
   */
  useEffect(() => {
    debouncedUpdateScrollBar(); // Initial update

    const contentDiv = contentRef.current;
    if (contentDiv) {
      contentDiv.addEventListener('scroll', debouncedUpdateScrollBar);
    }
    window.addEventListener('resize', debouncedUpdateScrollBar);

    return () => {
      if (contentDiv) {
        contentDiv.removeEventListener('scroll', debouncedUpdateScrollBar);
      }
      window.removeEventListener('resize', debouncedUpdateScrollBar);
      // No need to cancel debouncedUpdateScrollBar as the hook handles cleanup
    };
  }, [debouncedUpdateScrollBar, contentRef]);

  /**
   * Ensures the scrollbar is updated before the browser paints.
   */
  useLayoutEffect(() => {
    updateScrollBar();
  }, [updateScrollBar]);

  /**
   * Handles keyboard interactions for accessibility.
   */
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const contentDiv = contentRef.current;
      if (!contentDiv) return;

      const scrollAmount = 40; // Pixels per key press
      switch (event.key) {
        case 'ArrowUp':
          contentDiv.scrollTop -= scrollAmount;
          event.preventDefault();
          break;
        case 'ArrowDown':
          contentDiv.scrollTop += scrollAmount;
          event.preventDefault();
          break;
        case 'PageUp':
          contentDiv.scrollTop -= contentDiv.clientHeight;
          event.preventDefault();
          break;
        case 'PageDown':
          contentDiv.scrollTop += contentDiv.clientHeight;
          event.preventDefault();
          break;
        case 'Home':
          contentDiv.scrollTop = 0;
          event.preventDefault();
          break;
        case 'End':
          contentDiv.scrollTop = contentDiv.scrollHeight;
          event.preventDefault();
          break;
        default:
          break;
      }
    },
    [contentRef]
  );

  /**
   * Calculates the maximum top position for the scrollbar indicator.
   */
  const ariaValueMax = useMemo(() => {
    if (scrollBarRef.current) {
      return scrollBarRef.current.clientHeight - indicatorHeight;
    }
    return 0;
  }, [indicatorHeight]);

  return (
    <MemoizedStyledScrollBar
      ref={scrollBarRef}
      clientHeight={clientHeight}
      clientTop={clientTop}
      role="scrollbar"
      aria-controls={contentRef.current?.id || undefined} // Dynamically set based on contentRef
    >
      <MemoizedStyledScrollIndicator
        ref={scrollIndicatorRef}
        indicatorHeight={indicatorHeight}
        indicatorTop={indicatorTop}
        isDragging={isDragging}
        role="slider"
        tabIndex={0}
        aria-valuenow={indicatorTop}
        aria-valuemin={0}
        aria-valuemax={ariaValueMax}
        aria-orientation="vertical"
        onPointerDown={handlePointerDown}
        onKeyDown={handleKeyDown}
        // Add focus styles in StyledScrollIndicator for better visibility
      />
    </MemoizedStyledScrollBar>
  );
};

export default MessageContainerScroll;
