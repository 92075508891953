/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { graphql } from '../../../gql';
import { setAuthToken } from '../../../utils/authToken';
import { getUrlParam } from '../../../utils/url';

// Mutation query to login to google, code as argument
const GOOGLE_LOGIN = graphql(`
  mutation GoogleLogin($referralCode: String!, $code: String!) {
    googleLogin(referralCode: $referralCode, code: $code) {
      token
      user {
        id
      }
    }
  }
`);

const GoogleAuth = () => {
  const code = getUrlParam('code');
  const referralCode = getUrlParam('invite') as string;
  // Mutation with appollo client
  const [googleLogin] = useMutation(GOOGLE_LOGIN, {
    update(cache, { data }) {
      // Redirect to home page if login success
      if (data?.googleLogin?.token) {
        setAuthToken(data.googleLogin.token);
        //window.location.href = '/';
      }
    },
    onError(err) {
      // Redirect to sigin page if login failed
      //window.location.href = '/signin';
    },
  });

  useEffect(() => {
    // If code is not null, call mutation
    if (code) {
      googleLogin({ variables: { referralCode: referralCode, code } });
    } else {
      // Redirect to sigin page if code is null
      //window.location.href = '/signin';
    }
  }, [code]);

  return <Box />;
};

export default GoogleAuth;
