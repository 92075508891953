import * as React from 'react';
import { useTheme } from '@emotion/react';

const StartIcon = () => {
    const theme :any=useTheme()
  return (
    <>
     
    <svg width="41" style={{color: theme.palette.text.secondary,marginTop:-7,marginLeft:5,opacity:0.5}} height="35" viewBox="0 0 41 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="2.00648" y1="0.996712" x2="2.00648" y2="91.0067" stroke={theme.palette.text.secondary} stroke-width="3"/>
        <line x1="1" y1="44.5" x2="41" y2="44.5" stroke={theme.palette.text.secondary} stroke-width="3"/>
        <line x1="2.00648" y1="0.996712" x2="2.00648" y2="91.0067" stroke={theme.palette.text.secondary} stroke-width="3"/>
        <line x1="1" y1="44.5" x2="41" y2="44.5" stroke={theme.palette.text.secondary} stroke-width="3"/>
        <line x1="2.00648" y1="0.996712" x2="2.00648" y2="91.0067" stroke={theme.palette.text.secondary} stroke-width="3"/>
        <line x1="1" y1="44.5" x2="41" y2="44.5" stroke={theme.palette.text.secondary} stroke-width="3"/>
    </svg>
    </>
  );
};

export default StartIcon;
