import { createContext } from 'react';
import { SubscriptionPlan } from '../../../gql/graphql';

interface ISubscriptionContext {
  currentUserSubscription: SubscriptionPlan | null;
}

export const SubscriptionContext = createContext<ISubscriptionContext>({
  currentUserSubscription: null,
});
