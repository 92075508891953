import { useIntl } from 'react-intl';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import STChip from '../../../../../common/STChip/STChip';
import useBotTagsForInputMessage from '../../../../hooks/useBotTagsForInputMessage';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../../../../common/Skeleton/Skeleton';
import React from 'react';
import STPopper from '../../../../../common/STPopper/STPopper';
import STList from '../../../../../common/STList/STList';
import { Avatar, Paper, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { AllAiIcon } from '../SvgIcon/AllAiIcon';
import useBotTags from './useBotTags';

const BotTags = () => {
  const intl = useIntl();
  const theme = useTheme();

  const { list, loading } = useBotTagsForInputMessage();
  const {
    nbrTags,
    handleTagClick,
    handleClick,
    handleClose,
    anchorEl,
    open,
    id,
  } = useBotTags();

  if (loading) {
    return (
      <ProviderSkeleton>
        <CustomBox
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={1}
          mt={1}
          mb={1}
        >
          <SkeletonContent height={32} width={67} />
          <SkeletonContent height={32} width={70} />
          <SkeletonContent height={32} width={60} />
          <SkeletonContent height={32} width={80} />
          <SkeletonContent height={32} width={50} />
        </CustomBox>
      </ProviderSkeleton>
    );
  }

  const firstThreeTags = list.slice(0, nbrTags());

  const remainingTags = list.slice(nbrTags());

  return (
    <CustomBox
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="flex-start"
      gap={1}
      mt={1}
      mb={1}
      boxSizing="border-box"
    >
      {firstThreeTags.map((tag, index) => (
        <STChip
          label={tag.title}
          variant={theme.palette.mode === 'light' ? 'filled' : 'outlined'}
          key={index}
          onClick={handleTagClick(tag?.slug || '')}
          avatar={
            tag.urlIcon ? (
              <Avatar alt={tag.title} src={tag.urlIcon} />
            ) : undefined
          }
          sx={{ borderRadius: (theme) => theme.spacing(1) }}
        />
      ))}

      <STPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition={true}
        placement="top"
        onClose={handleClose}
      >
        <STList
          items={remainingTags.map((tag) => ({
            id: tag.id,
            primaryText: tag.title,
            onItemClick: handleTagClick(tag?.slug || ''),
            avatarImg: tag.urlIcon ? tag.urlIcon : undefined,
          }))}
        />
      </STPopper>

      <STChip
        icon={<AllAiIcon />}
        label={intl.formatMessage({ id: 'bot.tag.other' })}
        variant={theme.palette.mode === 'light' ? 'filled' : 'outlined'}
        onClick={handleClick}
        sx={{ borderRadius: (theme) => theme.spacing(1) }}
      />
    </CustomBox>
  );
};

export default React.memo(BotTags);
