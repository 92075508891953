// A FC component that displays todo project name and add icon at the right side to open a popover to select other project

import { FC } from 'react';
import React from 'react';
import { graphql } from '../../../../../gql';
import { useQuery } from '@apollo/client';
import Loading from '../../../../common/Loading/Loading';
import STMenu from '../../../../common/STMenu/STMenu';
import ProjectMenuItem from './ProjectMenuItem/ProjectMenuItem';

const GET_MY_PROJECTS = graphql(`
  query getProjects {
    myProjects {
      id
      name
    }
  }
`);

interface IProps {
  anchorElProject: any;
  selectedProject?: string;
  handleSelectProject: (project: any) => void;
  handleCloseProject(): void;
}

const ProjectMenuList: FC<IProps> = ({
  anchorElProject,
  selectedProject,
  handleSelectProject,
  handleCloseProject,
}) => {
  const { data, loading } = useQuery(GET_MY_PROJECTS);

  if (loading) {
    return <Loading />;
  }
  const projectData = data?.myProjects || [];
  const menuItems = [
    ...projectData.map((project: any) => ({
      component: (
        <ProjectMenuItem
          key={project.id}
          roleToTest={selectedProject as string}
          role={project.name}
        />
      ),
      onClick: () => {
        handleSelectProject(project);
        handleCloseProject();
      },
    })),
  ];
  // Return list of projects in a Material ui Popover component
  if (!data) return null;
  return (
    <>
      {Boolean(anchorElProject) && (
        <STMenu
          anchorEl={anchorElProject}
          open={Boolean(anchorElProject)}
          onClose={handleCloseProject}
          menuItems={menuItems}
        />
      )}
    </>
  );
};

export default ProjectMenuList;
