import { Divider, useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import PricingPlanItem from './PricingPlanItem/PricingPlanItem';
import { SubscriptionPlan } from '../../../../gql/graphql';
import STToggleButton from '../../../common/STToggleButton/STToggleButton';
import usePricingPlansContent from './usePricingPlansContent';
import SwiftaskCreditPurshase from '../../../SwiftaskToken/SwiftaskCreditPurshase/SwiftaskCreditPurshase';
import ShareCreditButton from './ShareCreditButton/ShareCreditButton';

const PricingPlansContent = () => {
  const {
    isAnnual,
    filteredPlans,
    handleChange,
    TOGGLE_DATA,
    currentPlanCode,
  } = usePricingPlansContent();
  const theme = useTheme();

  return (
    <CustomBox display={'flex'} flexDirection={'column'} width={'100%'}>
      <CustomBox marginInline={'auto'} py={theme.spacing(2)}>
        <STToggleButton
          value={isAnnual}
          exclusive
          onChange={handleChange}
          color="secondary"
          size="small"
          toggleData={TOGGLE_DATA as any}
        />
      </CustomBox>
      <CustomBox display={'flex'} width={'100%'}>
        {filteredPlans.length > 0
          ? (filteredPlans || [])?.map((plan: any, index: number) => (
              <PricingPlanItem
                key={plan?.id}
                currentPlanCode={currentPlanCode as string}
                plan={plan as SubscriptionPlan}
                width={`${100 / filteredPlans?.length}%`}
                padding={theme.spacing(1)}
                borderRight={
                  index < filteredPlans?.length - 1
                    ? `1px solid ${theme.palette.divider}`
                    : 'none'
                }
              />
            ))
          : null}
      </CustomBox>
      <Divider />
      <CustomBox mt={3}>
        <SwiftaskCreditPurshase />
      </CustomBox>
      <Divider />
      <CustomBox mt={2} display={'flex'} justifyContent={'flex-end'}>
        <ShareCreditButton />
      </CustomBox>
    </CustomBox>
  );
};

export default PricingPlansContent;
