import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomDialog from '../../../common/CustomDialog/CustomDialog';
import ListBotByTags from './ListBotByTags/ListBotByTags';

interface IProps {
  todoId: number;
  openDialog: boolean;
  handleCloseDialog(): void;
  shouldCreateSession?: boolean;
  botTagSlug?: string;
}

const SearchBotDialog = ({
  todoId,
  openDialog,
  handleCloseDialog,
  shouldCreateSession = false,
  botTagSlug,
}: IProps) => {
  return (
    <CustomDialog
      open={openDialog}
      handleClose={handleCloseDialog}
      scroll="paper"
      maxWidth="md"
      contentChildren={
        <CustomBox
          sx={(theme) => ({
            height: '80vh',
            [theme.breakpoints.down(450)]: {
              height: '85vh',
            },
          })}
        >
          <ListBotByTags
            todoId={todoId}
            isDisplayBotsTag={true}
            onBotClicked={handleCloseDialog}
            shouldCreateSession={shouldCreateSession}
            handleCloseSearchDialog={handleCloseDialog}
            botTagSlug={botTagSlug}
          />
        </CustomBox>
      }
    />
  );
};
export default SearchBotDialog;
