import * as React from 'react';
import { Mention } from 'react-mentions';
import Bot from '../../../Description/Bots/Bot/Bot';
import PropsActions from './PropsActions/PropsActions';
import SpeechRecognition from 'react-speech-recognition';
import BotUI from './BotUI/BotUI';
import {
  MessageInputStyle,
  PaperStyled,
  StyledMentionsInput,
} from './MessageInput.style';
import { useTheme } from '@mui/material';
import BotTags from './BotTags/BotTags';
import useGetChatContext from '../../ChatBodyContainer/useGetChatContext';
import { isSwiftaskBot } from '../../../../../utils/botUtils';

interface IProps {
  onChangeForm(e: any): void;
  onSendForm(e: any): void;
  handelFileRecordingChange(file: any): void;
  files: any;
  messageValue: string;
  isSendMessageLoading: boolean;
  onKeyDown(
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ): void;
  bots: Array<{
    __typename?: 'Bot';
    id: number;
    name: string;
    slug: string;
    user?: {
      __typename?: 'User';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      profilePicture?: string | null;
    } | null;
  }>;
  involvedUsers: Array<{
    __typename?: 'User';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    profilePicture?: string | null;
    bot?: {
      __typename?: 'Bot';
      id: number;
      name: string;
      slug: string;
    } | null;
  }>;
  onAddMentionUser(id: number | string, display: string): void;
  onPaste: any;
  onMouseEnterDropzone(): void;
  onMouseLeaveDropzone(): void;
  placeholder?: string;
  acceptFileInput?: boolean;
  handleValidePrompts(prompts: string): void;
  isBotConfigMode?: boolean;
}
const MessageInput: React.FC<IProps> = ({
  messageValue,
  handelFileRecordingChange,
  isSendMessageLoading,
  onMouseEnterDropzone,
  onMouseLeaveDropzone,
  onChangeForm,
  onSendForm,
  onPaste,
  files,
  acceptFileInput,
  onKeyDown,
  placeholder,
  involvedUsers,
  onAddMentionUser,
  handleValidePrompts,
  isBotConfigMode = false,
}) => {
  const [isRecording, setRecording] = React.useState(false);
  const { defaultBot } = useGetChatContext();

  const [mediaRecorder, setMediaRecorder] =
    React.useState<MediaRecorder | null>(null);

  const theme = useTheme();

  const botsToMention = involvedUsers.map((user: any) => ({
    id: user.id,
    display: `${user.firstName}`,
    profil: user.profilePicture,
    isBot: user.bot,
    botId: user.bot?.id,
  }));
  //starting video screencast

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      if (stream === null) {
        return;
      } else if ('getVideoTracks' in stream) {
        const chunks: Blob[] = [];
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'video/mp4' });
          const file = new File([blob], 'recorded-video.mp4', {
            type: 'video/mp4',
          });
          handelFileRecordingChange(file);
        };

        recorder.start();
        setMediaRecorder(recorder);
        setRecording(true);
      }
    } catch {
      setRecording(false);
    }
  };
  //stoping video screencast
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      const tracks = mediaRecorder.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setRecording(false);
  };

  const [elevation, setElevation] = React.useState(1);

  const botsSuggestion: any = (bot: any) => {
    return (
      <Bot
        avatar={bot.profil}
        botname={`@${bot.display}`}
        isBot={false}
        botId={0}
      />
    );
  };
  React.useEffect(() => {
    if (files.length) {
      setRecording(false);
    }
  }, [files]);

  const handleTransciptAudio = (word: string) => {
    if (word) {
      if (word) {
        const e = {
          target: {
            value: messageValue + ' ' + word,
          },
        };
        onChangeForm(e);
      }
    }
  };
  return (
    <>
      <BotUI />
      {!isBotConfigMode && isSwiftaskBot(defaultBot?.slug as string) && (
        <BotTags />
      )}
      <PaperStyled elevation={elevation}>
        <StyledMentionsInput
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          contentEditable={true}
          value={messageValue}
          onFocus={() => {
            setElevation(4);
            SpeechRecognition.stopListening();
          }}
          onBlur={() => setElevation(1)}
          onChange={onChangeForm}
          placeholder={placeholder}
          style={MessageInputStyle({ theme })}
          allowSuggestionsAboveCursor={true}
        >
          <Mention
            trigger="@"
            data={botsToMention}
            markup={'@[__display__](__slug__)'}
            onAdd={onAddMentionUser}
            renderSuggestion={botsSuggestion}
          />
        </StyledMentionsInput>

        <PropsActions
          handleValidePrompts={handleValidePrompts}
          isRecording={isRecording}
          stopRecording={stopRecording}
          startRecording={startRecording}
          acceptFileInput={acceptFileInput}
          onMouseEnterDropzone={onMouseEnterDropzone}
          onMouseLeaveDropzone={onMouseLeaveDropzone}
          isSendMessageLoading={isSendMessageLoading}
          onSendForm={onSendForm}
          files={files}
          messageValue={messageValue}
          handleTransciptAudio={handleTransciptAudio}
          isBotConfigMode={isBotConfigMode}
        />
      </PaperStyled>
    </>
  );
};

export default MessageInput;
