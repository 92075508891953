import * as React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

export const LinkStyle = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

const CardLinkForgotPassword = () => {
  return (
    <Box textAlign={'center'} role={'box'}>
      <CustomTypography>
        <LinkStyle to={'/signin'}>
          <FormattedMessage id={'link.Goback'} defaultMessage={'Go back'} />
        </LinkStyle>
      </CustomTypography>
    </Box>
  );
};
export default CardLinkForgotPassword;
