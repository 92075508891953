import { InputAdornmentProps, useTheme } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { StyledTextField } from '../../Auth/AccountForm/EmailInput/EmailInput';

export interface TextInputProps {
  value: string | number;
  placeholder?: string | undefined;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (e?: React.FormEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string;
  autocomplete?: string;
  color?: 'primary' | 'secondary';
  name?: string;
  onKeyPress?: any;
  style?: any;
  multiline?: boolean;
  helperTextIntl?: string;
  rows?: number;
  type?: 'text' | 'password' | 'email' | 'number';
  minRows?: number;
  maxRows?: number;
  disabled?: boolean;
  endAdornment?: React.ReactElement<InputAdornmentProps>;
  required?: boolean;
  readOnly?: boolean;
}
const TextInput: React.FC<TextInputProps> = ({
  onChange,
  onBlur,
  onFocus,
  multiline,
  onKeyPress,
  placeholder,
  value,
  style,
  id,
  autocomplete,
  label,
  color,
  name,
  helperTextIntl,
  rows,
  type,
  minRows,
  maxRows,
  disabled,
  endAdornment,
  required,
  readOnly,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <StyledTextField
      margin="normal"
      onKeyPress={onKeyPress}
      minRows={minRows}
      maxRows={maxRows}
      required={required}
      value={value || ''}
      type={type || 'text'}
      fullWidth
      onChange={(event: any) => onChange && onChange(event)}
      onBlur={(event: any) => onBlur && onBlur(event)}
      onFocus={(event: any) => onFocus && onFocus(event)}
      // color={color || "secondary"}
      id={id}
      label={label}
      placeholder={placeholder}
      name={name}
      autoComplete={autocomplete}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      helperText={helperTextIntl && intl.formatMessage({ id: helperTextIntl })}
      inputProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          ...style,
        },
      }}
      InputProps={{
        readOnly: readOnly,
        endAdornment,
      }}
    />
  );
};

export default TextInput;
