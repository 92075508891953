import * as React from 'react';
import { FileProps } from '../Attachments';
import prettyBytes from 'pretty-bytes';
import FileIcon from '../../../BodyChat/FormAddMessage/MessageInput/SvgIcon/FileIcon/FileIcons';
import Box from '@mui/system/Box/Box';
import { styled, useTheme } from '@mui/material';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

const BoxIcon = styled(Box)(({ theme }) => ({
  maxWidth: '42px',
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  //backgroundColor: theme.palette.background.default,
}));

const BoxLabel = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  //marginTop: theme.spacing(1),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export interface props {
  item: FileProps;
  extension: string;
  type: string | any;
}
const FileItem = ({ item, extension, type }: props) => {
  return (
    <BoxStyled>
      <BoxIcon>
        <FileIcon extension={extension} />
      </BoxIcon>
      <BoxLabel>
        <>
          <CustomTypography variant="body1" fontWeight={'bold'} addTooltip>
            {item.title}
          </CustomTypography>
          {item.size ? (
            <CustomTypography variant="body1" mt={1}>
              {prettyBytes(item.size)}
            </CustomTypography>
          ) : null}
        </>
      </BoxLabel>
    </BoxStyled>
  );
};
export default FileItem;
