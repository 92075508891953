import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LogoIcon from '../LogoIcon/LogoIcon';
import Title from '../Title/Title';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import AlertText from '../../common/AlertText/AlertText';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { StyledContainer } from '../Signup/Signup';

// Reset mutation
const resetMutation = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;

export default function ResetPassword() {
  const [error, SetError] = React.useState(false);
  const [inputValue, setInput] = React.useState<{
    password: string;
    confirmPassword: string;
  }>({ password: '', confirmPassword: '' });

  const [isSubmit, setSubmit] = React.useState(false);

  const { token } = useParams();

  //resetPassword appollo mutation
  const [resetPassword, { loading }] = useMutation(resetMutation, {
    update(cache, { data: { forgotPassword } }) {
      Mixpanel.track('Reset password ', {});
    },
  });

  const navigate = useNavigate();
  // Handle input change
  const onChangeInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput({ ...inputValue, [e.currentTarget.name]: e.currentTarget.value });
    setSubmit(false);
  };

  const onSubmit = () => {
    setSubmit(true);
    if (inputValue.password) {
      SetError(false);
    }
    if (
      inputValue.confirmPassword &&
      inputValue.password !== inputValue.confirmPassword
    ) {
      setSubmit(true);
    }
    if (
      inputValue.confirmPassword &&
      inputValue.password === inputValue.confirmPassword
    ) {
      resetPassword({
        variables: { newPassword: inputValue.password, token: token },
        update: () => {
          navigate(`/signin`);
        },
        onError: () => {
          SetError(true);
          setSubmit(false);
          setInput({ password: '', confirmPassword: '' });
        },
      });
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box>
          <LogoIcon />
        </Box>
        <Box>
          <Title TitleValue={'New Password'} />
        </Box>
        {isSubmit && !inputValue.password ? (
          <Box sx={{ width: 310 }}>
            <AlertText
              message="Please fill in the required field and 
                try again."
              severity="warning"
            />
          </Box>
        ) : error ? (
          <Box sx={{ width: 310 }}>
            <AlertText
              message="Token has expired. Please request another password reset and try again"
              severity="warning"
            />
          </Box>
        ) : (
          isSubmit &&
          inputValue.confirmPassword &&
          inputValue.password !== inputValue.confirmPassword && (
            <Box width={310}>
              <AlertText
                message="Passwords do not match. Please try again."
                severity="warning"
              />
            </Box>
          )
        )}
        <Box>
          <ResetPasswordForm
            inputValue={inputValue}
            onChange={onChangeInputChange}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </StyledContainer>
  );
}
