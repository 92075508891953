import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import CustomTooltip from '../../../../common/CustomToolTip/CustomToolTip';
import CustomPromptsField from '../CustomPromptsField/CustomPromptsField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material';
const HeaderTagContainer = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  marginTop:10
}));
const BoxStyled = styled(CustomBox)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop:4,
  borderRadius:2,
  border:`1px solid ${theme.palette.divider}`,
  height: '50vh',
}));

interface formPromps {
  [key: string]: string;
}
interface props {
  fields: string[];
  colors: string[];
  formPromps: formPromps;
  handleChange(tagName: string, value: string, color: string): void;
}
const EditTagContent = ({
  fields,
  handleChange,
  formPromps,
  colors,
}: props) => {
  return (
    <>
      <CustomBox>
        <HeaderTagContainer>
          <CustomTypography variant="h3">
            <FormattedMessage id="libraries.prompts.tag.label" />
          </CustomTypography>
          <CustomTooltip
            arrow
            title=""
            children={<HelpOutlineIcon sx={{ mx: 1 }} fontSize="small" />}
            intlId="libraries.prompts.info"
          />
        </HeaderTagContainer>
      </CustomBox>
      <CustomBox maxHeight={'51vh'} overflow={'auto'} pr={1}>
        {fields?.length <1 ? (
          <BoxStyled>
          <CustomTypography variant="h3">
            <FormattedMessage id="libraries.prompts.tag.not.found" />
          </CustomTypography>
          </BoxStyled>
        ) : (
          <>
            {fields?.map((tagName, i) => {
              const color = colors[i];
              return (
                <CustomBox my={1}>
                  <CustomPromptsField
                    label={tagName}
                    color={color}
                    value={formPromps[tagName] || ''}
                    onChange={(e) =>
                      handleChange(tagName, e.target.value, color)
                    }
                  />
                </CustomBox>
              );
            })}
          </>
        )}
      </CustomBox>
    </>
  );
};
export default EditTagContent;
