import * as React from 'react';
import { Box, IconButton, styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ChatContext } from '../../Chat';
import { ContentCopy, Delete } from '@mui/icons-material';
import useRemoveTodo from '../../hooks/useRemoveTodo';
import useDisplayTodoDescriptionSidebar from '../../../../Hooks/useDisplayTodoDescriptionSidebar';
import STMenu from '../../../common/STMenu/STMenu';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

interface props {
  todoId: number;
  onDeleteTask(e: any, todoId: number): void;
}

const Header = ({ todoId, onDeleteTask }: props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyLink = () => {
    // const currentUrl = window.location.href;
    // navigator.clipboard.writeText(currentUrl).then(() => {
    //   handleClose();
    // });
  };
  const menuItems = [
    // {
    //   component: <ContentCopy fontSize="small" />,
    //   onClick: handleCopyLink,
    // },
    {
      component: (
        <CustomBox
          sx={{
            diplay: 'flex',
          }}
        >
          <CustomTypography>
            <FormattedMessage id="chat.taskMenu.delete" />
          </CustomTypography>
        </CustomBox>
      ),
      onClick: (e: any) => onDeleteTask(e, todoId),
    },
  ];
  return (
    <CustomBox>
      <IconButton
        aria-label="more"
        id="more-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      {Boolean(anchorEl) && (
        <STMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          menuItems={menuItems}
        />
      )}
    </CustomBox>
  );
};

export default Header;
