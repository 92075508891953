import { useTheme } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { FC } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';

import { styleDefObj } from './FileIconStyle';
interface Props {
  extension: string;
}

const FileIcons: FC<Props> = ({ extension }) => {
  const theme = useTheme();
  const defaultLabelColor = styleDefObj[extension]
    ? styleDefObj[extension]['labelColor'] ?? theme.palette.primary.light
    : theme.palette.primary.light;
  const defaultColor = styleDefObj[extension]
    ? styleDefObj[extension]['color'] ?? theme.palette.primary.light
    : theme.palette.primary.light;

  const defaultGlyphColor =
    defaultStyles[extension as keyof typeof defaultStyles] &&
    defaultStyles[extension as keyof typeof defaultStyles]['labelColor'];

  return (
    <Box>
      <FileIcon
        extension={extension}
        {...defaultStyles[extension as keyof typeof defaultStyles]}
        glyphColor={defaultGlyphColor ?? defaultLabelColor}
        labelColor={defaultLabelColor}
        color={defaultColor}
        {...styleDefObj[extension]}
      />
    </Box>
  );
};

export default FileIcons;
