import { ApolloCache } from '@apollo/client';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { GET_CHAT } from '../hooks/useGetChatTodo';

export const onAfterSendMessage = ({
  cache,
  newMessage,
  botSlug,
  todoId,
  sessionId,
  trackMixpanel,
}: {
  cache: ApolloCache<any>;
  newMessage: any;
  sessionId: number;
  botSlug?: string;
  todoId?: number;
  trackMixpanel?: boolean;
}) => {
  // Update cache for GE_CHAT query
  const existingSessionMessages = cache.readQuery({
    query: GET_CHAT,
    variables: {
      sessionId,
    },
  });

  if (existingSessionMessages) {
    cache.writeQuery({
      query: GET_CHAT,
      variables: {
        sessionId,
      },
      data: {
        sessionMessages: [
          ...existingSessionMessages.sessionMessages,
          newMessage,
        ],
      },
    });
  }
  if (trackMixpanel) {
    // Mixpanel.track('Send Todo Chat Message', {
    //   todoId,
    //   botSlug,
    // });
  }
  if (newMessage?.isSubscriptionUsageLimitReachedMessage) {
    Mixpanel.track('Credit Limit Reached', {
      botSlug,
    });
  }
};
