import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import useGetTodoDescription from '../../hooks/useGetCrudTodoDescription';
import STEditor from '../../../common/STEditor/STEditor';
import CustomBox from '../../../common/CustomBox/CustomBox';

interface props {
  todoID: number;
  displayDescriptionLabel?: boolean;
}

const ToDoDescription = ({ todoID, displayDescriptionLabel }: props) => {
  const { data, handleUpdateTodoDescriptionLite, loading } =
    useGetTodoDescription({
      todoID,
    });
  const intl = useIntl();
  if (loading) return <CustomBox>Loading...</CustomBox>;

  let parsedDescription;
  try {
    parsedDescription = JSON.parse(data?.todo?.description || '');
  } catch (error) {
    const placeholder = intl.formatMessage({
      id: 'todo.description.placeholder',
      defaultMessage: 'Add more detailed description...',
    });
    const text = data?.todo?.description || placeholder;
    parsedDescription = {
      blocks: [
        {
          id: 'mhTl6ghSkV',
          type: 'paragraph',
          data: { text },
        },
      ],
    };
  }

  return (
    <CustomBox
      sx={{
        height: '100%',
      }}
    >
      <STEditor
        defaultValue={parsedDescription}
        save={handleUpdateTodoDescriptionLite}
      />
    </CustomBox>
  );
};
export default React.memo(ToDoDescription);
