import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const GET_BOT_NAME = graphql(`
  query GetBotName($botId: Float!) {
    bot(id: $botId) {
      id
      name
      isChatLMM
      baseBotId
    }
  }
`);

const useGetBotName = ({ botId }: { botId: number }) => {
  const { data, loading } = useQuery(GET_BOT_NAME, { variables: { botId } });
  const botData=data?.bot
  return {
    botData,
    loading,
  };
};

export default useGetBotName;
