import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../CustomTypography/CustomTypography';

interface IProps {
  label: string;
  defaultMessage: string;
  children?: React.ReactNode;
  handleAction(e: any): void;
}

const ItemContext: React.FC<IProps> = ({
  label,
  children,
  defaultMessage,
  handleAction,
}) => {
  return (
    <MenuItem onClick={handleAction}>
      {children && <ListItemIcon>{children}</ListItemIcon>}
      <ListItemText>
        <CustomTypography variant="body2">
          <FormattedMessage id={label} defaultMessage={defaultMessage} />
        </CustomTypography>
      </ListItemText>
    </MenuItem>
  );
};

export default ItemContext;
