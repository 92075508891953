import { FC } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { Variant } from '@mui/material/styles/createTypography';

interface IProps {
  intlId: string;
  defaultMessage: string;
  textVariant?: Variant;
}

const STInfoHelp: FC<IProps> = ({ intlId, defaultMessage, textVariant }) => {
  return (
    <CustomBox>
      <CustomTypography variant={textVariant || 'body2'}>
        <FormattedMessage id={intlId} defaultMessage={defaultMessage} />
      </CustomTypography>
    </CustomBox>
  );
};

export default STInfoHelp;
