
import { useTheme } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
interface props{
    descriptionPreview:string
}
const DescriptionPreview = ({descriptionPreview}:props) => {
    const theme=useTheme()
  return (
    <CustomBox>
      <CustomTypography variant="body2">
        <span
          dangerouslySetInnerHTML={{ __html: descriptionPreview }}
          style={{ lineHeight: theme.spacing(3.5) }}
        />
      </CustomTypography>
    </CustomBox>
  );
};
export default DescriptionPreview;
