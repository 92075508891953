import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTheme } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';


interface SearchLoadingProps {
  todoLoadLength: number;
  botsLoadLength: number;
  dataSourceLoadLength: number;
}

const SearchLoading: React.FC<SearchLoadingProps> = ({ todoLoadLength, botsLoadLength, dataSourceLoadLength }) => {
  const theme = useTheme();
  const skeletonBaseColor = theme.palette.mode === 'dark' ? theme.palette.background.default : '';
  const skeletonHighlightColor = theme.palette.mode === 'dark' ? theme.palette.grey['400'] : '';

  return (
    <CustomBox width="100%">
      <SkeletonTheme baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor}>
        <CustomBox mb={1} width="100%">
          <Skeleton width="20%" height={10} />
          {Array.from({ length: todoLoadLength }).map((_, index) => (
            <Skeleton key={index} width="100%" height={40} />
          ))}
        </CustomBox>

        <CustomBox mb={1} width="100%">
          <Skeleton width="20%" height={10} />
          {Array.from({ length: botsLoadLength }).map((_, index) => (
            <CustomBox key={index} display="flex" gap={1}>
              <Skeleton width={40} height={35} />
              <CustomBox width="80%">
                <Skeleton width="50%" height={10} />
                <Skeleton width="100%" height={10} />
              </CustomBox>
            </CustomBox>
          ))}
        </CustomBox>

        <CustomBox mb={1} width="100%">
          <Skeleton width="20%" height={10} />
          {Array.from({ length: dataSourceLoadLength }).map((_, index) => (
            <Skeleton key={index} width="100%" height={20} />
          ))}
        </CustomBox>
      </SkeletonTheme>
    </CustomBox>
  );
};

export default SearchLoading;
