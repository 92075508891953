// Custom @mui/material Box component with custom props

import * as React from 'react';
import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';
import theme from '../../../theme';

interface CustomBagde extends BoxProps {}
const StyledBox = styled(Box)(({ theme }) => ({
  clipPath: `polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%)`,
  background: theme.palette.secondary.main, // Change to secondary background color
  textAlign: 'center',
  padding: '8px 16px', // Set padding to 8px on top and bottom, 16px on left and right
  color: theme.palette.secondary.contrastText, // Assuming you want text color based on secondary palette

  fontWeight: 'bold',
}));

const CustomBagde: React.FC<CustomBagde> = ({ children, ...rest }) => {
  return <StyledBox {...rest}>{children}</StyledBox>;
};

export default CustomBagde;
