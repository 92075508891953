import * as React from 'react';
import CardLinkSignin from '../../AccountForm/CardLink/CardLinkSignin/CardLinkSignin';
import EmailInput from '../../AccountForm/EmailInput/EmailInput';
import SubmitButton, {
  propsSubmit,
} from '../../AccountForm/SubmitButton/SubmitButton';
import SubmitWithGoogleBottom from '../../AccountForm/SubmitWithGoogle/SubmitWithGoogle';
import Box from '@mui/material/Box';
import PasswordInput from '../../../common/PasswordInput/PasswordInput';
import AlertText from '../../../common/AlertText/AlertText';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import { BoxStyled } from '../../ForgotPassword/ForgotPasswordForm/ForgotPasswordForm';
import DividerChoice from '../DividerChoice/DividerChoice';
import Agreement from '../../AccountForm/Agreement/Agreement';
import CustomBox from '../../../common/CustomBox/CustomBox';
import Auth0Login from '../../Auth0Login/Auth0Login';

const GoogleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  marginBottom: '8px',
}));

export interface Formprops {
  inputValue: { email: string; password: string };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: propsSubmit['onSubmit'];
  hasError?: boolean;
  isEmptyInput?: boolean;
  isLoading?: boolean;
}
const SigninForm = ({
  inputValue,
  onChange,
  onSubmit,

  hasError,
  isEmptyInput,
  isLoading,
}: Formprops) => {
  const intl = useIntl();

  return (
    <Box component="form" noValidate sx={{ mt: 1, width: 302 }}>
      <BoxStyled className="EmailInput">
        <Agreement />
        <EmailInput
          email={inputValue.email}
          placeholder={intl.formatMessage({
            id: 'input.email',
            defaultMessage: 'Email address',
          })}
          onChange={onChange}
        />
        <Box sx={{ width: 325 }}>
          <PasswordInput
            password={inputValue.password}
            onChange={onChange}
            name={'password'}
            placeholder={intl.formatMessage({
              id: 'input.password',
              defaultMessage: 'Password',
            })}
            required={true}
          />
        </Box>
        {hasError && (
          <AlertText
            message={intl.formatMessage({
              id: 'signin.error',
              defaultMessage: 'Invalid email or password',
            })}
            severity="error"
          />
        )}
        {isEmptyInput && (
          <AlertText
            message={intl.formatMessage({
              id: 'signin.emptyInput',
              defaultMessage: 'Please fill in all the fields',
            })}
            severity="warning"
          />
        )}
        <SubmitButton
          onSubmit={onSubmit}
          buttonValue={'Continue'}
          disable={isLoading}
          isLoading={isLoading}
        />
        <CardLinkSignin />
        <DividerChoice />
        <GoogleBox>
          <SubmitWithGoogleBottom from="signin" />
        </GoogleBox>
        <GoogleBox>
          <Auth0Login />
        </GoogleBox>
      </BoxStyled>
    </Box>
  );
};
export default SigninForm;
