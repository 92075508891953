//@ts-ignore
import Embed from '@editorjs/embed';
//@ts-ignore
import Table from '@editorjs/table';
//@ts-ignore
import List from '@editorjs/list';
//@ts-ignore
import Warning from '@editorjs/warning';
//@ts-ignore
import Code from '@editorjs/code';
//@ts-ignore
import LinkTool from '@editorjs/link';
//@ts-ignore
import Image from '@editorjs/image';
//@ts-ignore
import Raw from '@editorjs/raw';
//@ts-ignore
import Header from '@editorjs/header';
//@ts-ignore
import Quote from '@editorjs/quote';
//@ts-ignore
import Marker from '@editorjs/marker';
//@ts-ignore
import CheckList from '@editorjs/checklist';
//@ts-ignore
import Delimiter from '@editorjs/delimiter';
//@ts-ignore
import InlineCode from '@editorjs/inline-code';
//@ts-ignore
import SimpleImage from '@editorjs/simple-image';
import { styled } from '@mui/material';
import CustomBox from '../../CustomBox/CustomBox';

export const EDITOR_TOOLS = {
  embed: Embed,
  table: Table,
  marker: Marker,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: Image,
  raw: Raw,
  header: Header,
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};
export const FormeNoteBox = styled(CustomBox)(({ theme }) => ({
  // Overflow x
  overflowX: 'auto',
  height: '100%',

  // Scrollbar
  scrollbarWidth: 'thin',

  paddingRight: theme.spacing(1),
  '& .ce-inline-tool': {
    color: theme.palette.getContrastText,
  },
  '& .cdx-block': {
    minWidth: 150,
    padding: theme.spacing(2),
  },
  '& .ce-inline-tool:hover': {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
  },
  '& .ce-inline-tool--active': {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
  },

  '& .ce-inline-toolbar': {
    backgroundColor: theme.palette.background.paper,
  },

  '& .ce-toolbar__plus': {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.customColor.scrollColor,
  },
  '& .ce-toolbar__settings-btn': {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.customColor.scrollColor,
  },
  '& .ce-inline-toolbar__dropdown': {
    color: theme.palette.getContrastText,
    backgroundColor: theme.palette.background.paper,
  },

  '& .ce-conversion-tool:hover': {
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .ce-conversion-tool__icon': {
    backgroundColor: theme.palette.customColor.scrollColor,
  },

  '& .ce-conversion-tool--focused': {
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .ce-popover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .tc-popover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .tc-popover__item:hover': {
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .tc-popover__item': {
    color: theme.palette.action.active,
  },
  '& .tc-popover__item-icon': {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.customColor.scrollColor,
  },
  '& .ce-popover-item__title': {
    color: theme.palette.action.active,
  },
  '& .tc-cell--selected': {
    backgroundColor: theme.palette.customColor.scrollColor,
  },
  '& .ce-code__textarea': {
    backgroundColor: theme.palette.customColor.scrollColor,
    color: theme.palette.action.active,
  },
  '& .cdx-button': {
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .cdx-search-field ce-popover__search': {
    backgroundColor: '#fff !important',
    color: '#fff !important',
  },
  '& .tc-add-column:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .tc-add-row:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .ce-popover-item:hover': {
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .ce-popover-item--active': {
    fontWeight: 600,
    borderRadius: 4,
    color: theme.palette.action.active,
    background: `${theme.palette.secondary.main} !important`,
  },
  '& .ce-popover-item__icon': {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.customColor.scrollColor,
  },
  '& .ce-conversion-toolbar': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  '& .ce-conversion-tool': {
    color: theme.palette.getContrastText,
  },
  '& .ce-header': {
    backgroundColor: theme.palette.customColor.chatDescriptionBackground,
  },
}));
