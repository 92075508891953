import CustomIconButton from '../../../../common/CustomIconButton/CustomIconButton';
import PushPinIcon from '@mui/icons-material/PushPin';
import useDisplayHistoryChat from '../../Hooks/useDisplayHistoryChat';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

const CreateSession = () => {
  const {
    handlePositionHistoryChat,
    handleToogleDisplayHistoryChat,
    displayPositionHistoryChat,
  } = useDisplayHistoryChat();

  const handleClick = () => {
    if (displayPositionHistoryChat === 'relative') {
      handleToogleDisplayHistoryChat();
      return;
    }

    handlePositionHistoryChat();
  };
  return (
    <CustomIconButton
      intlId={
        displayPositionHistoryChat === 'absolute'
          ? 'chat.change.postion.chat'
          : 'chat.close.session'
      }
      onClick={handleClick}
      sx={{
        padding: 0,
        width: 40,
        height: 40,
        display: {
          xs: 'none',
          sm: 'none',
          md: 'block',
        },
      }}
    >
      {displayPositionHistoryChat === 'relative' ? (
        <KeyboardDoubleArrowLeftIcon
          sx={{ fontSize: (theme) => theme.spacing(2) }}
          fontSize="small"
        />
      ) : (
        <KeyboardDoubleArrowRight
          fontSize="small"
          sx={{ fontSize: (theme) => theme.spacing(2) }}
        />
      )}
    </CustomIconButton>
  );
};

export default CreateSession;
