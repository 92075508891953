import { FC, ReactNode, useState } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import { FileRejection, useDropzone } from 'react-dropzone';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';
import { UploadFileOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../CustomTypography/CustomTypography';
import config from '../../../config/config';

interface IProps {
  onDropFile: (acceptedFiles: File[]) => void;
  multiple?: boolean;
  maxFileSize?: number;
  disabled?: boolean;
  children: ReactNode;
  option?: {
    cannotClick?: boolean;
  };
  sx?: any;
}

const CustomDropZone: FC<IProps> = ({
  onDropFile,
  multiple,
  maxFileSize,
  disabled,
  children,
  option,
  sx,
}) => {
  const [isHoverDraggable, setIsHoverDraggable] = useState(false);
  const { displayMessage } = useDisplayMessage();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    multiple,
    disabled: disabled || (option?.cannotClick ? !isHoverDraggable : false),
    maxSize: maxFileSize || config.maxFileSize,
    maxFiles: undefined,
    onDropRejected: (fileRejections: FileRejection[]) => {
      if (fileRejections.length > 1 && !multiple) {
        displayMessage({
          message: 'file.too.cannot.upload.more.file',
          type: 'error',
        });
        return;
      }
      displayMessage({
        message: 'file.too.large',
        type: 'error',
      });
    },
  });

  // Suggestion
  const isHOverDrag = () => {
    if (!isHoverDraggable && !disabled) {
      setIsHoverDraggable(true);
    }
  };

  // Suggestion
  const notHOverDrag = () => {
    if (isHoverDraggable && !disabled) {
      setIsHoverDraggable(false);
    }
  };

  return (
    <CustomBox
      onDragOver={isHOverDrag}
      onDrop={notHOverDrag}
      onDragLeave={notHOverDrag}
    >
      <CustomBox
        {...getRootProps()}
        sx={{
          ...sx,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <input {...getInputProps()} />
        {isHoverDraggable ? (
          <CustomBox
            disable={true}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              zIndex: 10,
            }}
          >
            <CustomBox>
              <UploadFileOutlined />
            </CustomBox>
            <CustomTypography variant="caption">
              <FormattedMessage id={'fileForm.dropfileIndication'} />
            </CustomTypography>
          </CustomBox>
        ) : null}
        <CustomBox>{children}</CustomBox>
      </CustomBox>
    </CustomBox>
  );
};

export default CustomDropZone;
