/* eslint-disable jsx-a11y/alt-text */
import BotTitleContent from './BotTitleContent/BotTitleContent';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import { styled } from '@mui/material';
import BotAction from '../../../BotAction/BotAction';

const StyledCustomBox = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxHeight: '112px',
  width: '100%',
  padding: theme.spacing(1),
  cursor: 'pointer',
  borderRadius: theme.spacing(1),
  // On hover

  '& .MuiChip-root': {
    height: '18px',
    marginLeft: theme.spacing(1),
    opacity: 0,
    width: '25px',
  },
  '&:hover': {
    backgroundColor: theme.palette.customColor.messageBackground,
    '& .help-icon , .MuiChip-root': {
      opacity: 1,
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
}));

export interface IProps {
  botname: string;
  botDescription?: string;
  avatar: string;
  isBot?: boolean;
  botWorkspaceId?: number;
  botId: number;
  isForDescription?: boolean;
  botSlug?: string;
  isDisplayDescription?: boolean;
  onClick?: () => void;
  maxlineDescription?: number;
  flexDirection?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  isOpenDocumentationOnClicked?: boolean;
  openEditDialog?(botId: number): void;
  isNew?: boolean;
  avatarSize?: 'large' | 'small';
}
export default function Bot({
  botname,
  avatar,
  isBot,
  botWorkspaceId,
  botId,
  isDisplayDescription,
  botDescription,
  onClick,
  flexDirection,
  avatarSize,
  maxlineDescription,
  openEditDialog,
  isNew,
}: IProps) {
  const src = isBot ? avatar || '/default-bot.png' : avatar;

  const handleUseBot = () => {
    onClick?.();
  };

  return (
    <StyledCustomBox>
      <BotTitleContent
        isDisplayDescription={isDisplayDescription}
        userProfil={src}
        direction={flexDirection}
        handleClickBot={handleUseBot}
        maxlineDescription={maxlineDescription}
        handleAutoMention={handleUseBot}
        botDescription={botDescription}
        botname={botname}
        avatarSize={avatarSize}
        isBot={isBot}
        isNew={isNew}
      />
      {botWorkspaceId && (
        <BotAction botId={botId} openEditDialog={openEditDialog} />
      )}
    </StyledCustomBox>
  );
}
