import React from 'react';

function MicrosoftIcon() {
  return (
    <img
      src="/microsoft.png"
      alt="Microsoft"
      style={{ width: 24, height: 24 }}
    />
  );
}

export default MicrosoftIcon;
