import React, { useState } from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import 'pdfjs-dist/build/pdf.worker.entry';
import { styled } from '@mui/material';
import { Box } from '@mui/material';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { useClickToSelect, Selection } from './useClickToSelect';
import CustomBox from '../../../common/CustomBox/CustomBox';
import Loading from '../../../common/Loading/Loading';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useSentNewMessage from '../../hooks/useSendNewMessage';
import PDFToolBar from './PDFToolBar/PDFToolBar';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';

const PdfContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

interface PDFViewerProps {
  url: string;
  todoId: number;
  initialPage?: number;
  clickHash?: string;
  defaultBotId: number;
}

const PDFViewer = ({
  url,
  initialPage,
  todoId,
  clickHash,
  defaultBotId,
}: PDFViewerProps) => {
  const [isSelect, setIsSelect] = useState(false);
  const { displayMessage } = useDisplayMessage();
  const { sessionId } = useParams();

  const { addNewMessage, loadSendMessage } = useSentNewMessage({
    taskID: todoId,
    onMessageSent(data: any) {
      setIsSelect(false);
      resetCoordinates();
    },
  });

  const onSendFiles = (files: any) => {
    addNewMessage({
      variables: {
        newMessageData: {
          mentionedBotId: defaultBotId,
          fileIds: files.map((file: any) => file.id),
          sessionId: +(sessionId || 0),
        },
      },

      onError() {
        setIsSelect(false);
        resetCoordinates();
        displayMessage({
          message: 'send.image.error',
          type: 'error',
        });
      },
    });
  };
  const {
    ref,
    dx,
    dy,
    startX,
    startY,
    selection,
    offsetWidth,
    offsetHeight,
    captureRef,
    selectionRef,
    loading,
    resetCoordinates,
  } = useClickToSelect({ onAddFiles: onSendFiles });

  const toolbarPluginInstance = toolbarPlugin();

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const rotatePluginInstance = rotatePlugin();

  const { jumpToPage } = pageNavigationPluginInstance;
  const { Toolbar } = toolbarPluginInstance;

  const onSelect = () => {
    setIsSelect(!isSelect);
  };

  React.useEffect(() => {
    if (initialPage) {
      jumpToPage(initialPage - 1);
    }
  }, [initialPage, clickHash]);

  const y = dy ? (dy as number) : 0;
  const isDisplayBlackBackground =
    (loading || loadSendMessage || isSelect) && selection !== Selection.None;
  const isReadyToDraw =
    loading ||
    loadSendMessage ||
    (selection === Selection.Dragging && isSelect);

  return (
    <>
      <PDFToolBar Toolbar={Toolbar} onSelect={onSelect} isSelect={isSelect} />
      <PdfContainer
        ref={ref as any}
        sx={{
          cursor: isSelect ? 'crosshair' : 'default',
          position: 'relative',
          width: '100%',
        }}
      >
        {isDisplayBlackBackground && (
          <div
            style={{
              width: '100%',
              height: '100%',
              cursor: isSelect ? 'crosshair' : 'default',
              zIndex: 999999999,
              position: 'absolute',
              borderLeft: `${startX}px solid rgba(0,0,0,0.5)`,
              borderTop: `${startY}px solid rgba(0,0,0,0.5)`,
              borderBottom: `${
                offsetHeight - startY - y
              }px solid rgba(0,0,0,0.5)`,
              borderRight: `${
                offsetWidth - startX - dx
              }px solid rgba(0,0,0,0.5)`,
            }}
          />
        )}
        {isReadyToDraw && (
          <>
            <div
              ref={selectionRef}
              style={{
                transform: `translate(${startX}px, ${startY}px)`,
                width: `${dx}px`,
                height: `${y}px`,
                cursor: isSelect ? 'crosshair' : 'default',
                border: '3px dashed grey',
                zIndex: 999999999,
                position: 'absolute',
              }}
            >
              {(loading || loadSendMessage) && (
                <CustomBox
                  sx={{
                    backgroundColor: '#fff',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.7,
                  }}
                >
                  <CustomBox>
                    <Loading
                      sx={{ color: (theme) => theme.palette.grey[200] }}
                      type="circular"
                      size={30}
                    />
                    <CustomTypography sx={{ color: '#000' }}>
                      <FormattedMessage
                        id="fileForm.analyzing"
                        defaultMessage="Analyzing"
                      />
                    </CustomTypography>
                  </CustomBox>
                </CustomBox>
              )}
            </div>
          </>
        )}
        <PdfContainer
          ref={captureRef as any}
          sx={{
            cursor: isSelect ? 'crosshair' : 'default',
            border: `5px solid ${isSelect ? '#ff6e72' : 'transparent'}`,
          }}
        >
          <Viewer
            fileUrl={url}
            plugins={[
              toolbarPluginInstance,
              pageNavigationPluginInstance,
              rotatePluginInstance,
            ]}
            defaultScale={SpecialZoomLevel.PageFit}
            initialPage={initialPage}
          />
        </PdfContainer>
      </PdfContainer>
    </>
  );
};

export default PDFViewer;
