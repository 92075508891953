import { setAuthToken } from './authToken';
import { getUrlParam } from './url';

export const handleAfterAuth = (token: string | null | undefined) => {
  if (!token) {
    return;
  }
  const redirectTo = getUrlParam('redirect');
  setAuthToken(token);
  if (redirectTo) {
    window.location.href = redirectTo;
  } else {
    window.location.href = '/';
    window.location.href = '/';
  }
};
