import React from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';

interface PasswordStrengthIndicatorProps {
  password: string;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  password,
}) => {
  const weakRegex = /^(?=.*[a-z]).{8,}$/;
  const mediumRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  const passwordStrength = () => {
    if (mediumRegex.test(password)) {
      return 'strong';
    }

    if (weakRegex.test(password)) {
      return 'medium';
    }

    return 'weak';
  };

  const passwordScore = passwordStrength();
  const barColor =
    passwordScore === 'strong'
      ? 'primary'
      : passwordScore === 'medium'
      ? 'secondary'
      : 'error';
  if (!password) return null;
  return (
    <Box width="100%">
      <Typography>
        <FormattedMessage
          id="password.strength.indicator.title"
          defaultMessage="Password strength"
        />
        : {passwordScore}
      </Typography>
      <LinearProgress
        color={barColor}
        variant="determinate"
        value={
          passwordScore === 'strong'
            ? 100
            : passwordScore === 'medium'
            ? 50
            : 25
        }
      />
    </Box>
  );
};

export default PasswordStrengthIndicator;
