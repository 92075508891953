import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { Mixpanel } from '../mixpanel/mixpanel';

const GET_SUBSCRIPTION_URL = graphql(
  `
    query GetSubscriptionUpdateUrl($newPlanCode: String!) {
      getSubscriptionUpdateUrl(newPlanCode: $newPlanCode)
    }
  `
);

const useGetSubsciptionUrl = () => {
  const { displayMessage } = useDisplayMessage();
  const [getSubscriptionUpdateUrl, { loading: loadingUpdateUrl }] =
    useLazyQuery(GET_SUBSCRIPTION_URL);

  const startUpgrade = (newPlanCode: string) => {
    Mixpanel.track('Click on upgrade workspace button', {});
    getSubscriptionUpdateUrl({ variables: { newPlanCode } })
      .then((result) => {
        const subscriptionUrl = result.data?.getSubscriptionUpdateUrl;
        if (!subscriptionUrl) {
          displayMessage({
            message: 'subscription.upgrade.geturl.error',
            type: 'error',
          });
          return;
        }
        Mixpanel.track('Redirect to stripe subscription page', {});
        window.location.assign(subscriptionUrl);
      })
      .catch((err) => {
        displayMessage({
          message: 'subscription.upgrade.geturl.error',
          type: 'error',
        });
      });
  };

  return {
    startUpgrade,
    loadingUpdateUrl,
  };
};

export default useGetSubsciptionUrl;
