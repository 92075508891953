import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql/gql';

const GET_DATASOURCE_SESSION = graphql(`
  query GetOneTodoChatSessionDefaultBot($sessionId: Float!) {
    getOneTodoChatSession(sessionId: $sessionId) {
      id
      autoReplyLMM
      allDataSources {
        id
      }

      todoChat {
        todoId
      }
      defaultBot {
        id
        isDisplayFileFormOnFirstSession
        fileFormOnFirstSessionPlaceholder
        name
        slug
        description
        acceptFileInput
        acceptTextInput
        messageInputPlaceHolder
        greetingMessage
        currentUserCredit {
          remainingExtraCredit
          remainingMonthlyCredit
          monthlyCredit
          spendedMonthlyCredit
          totalRemainingCredit
          totalMaxCredit
        }
        supportedContentTypes {
          id
          extension
          type
        }
        user {
          id
          firstName
          lastName
          profilePicture
        }
        illustrationUrl
        stripePaymentLink
        purchasePageOrder
        creditUnitPrice
        creditAmountPerUnitPrice
        quantityLabel
        quantityLabelFR
        baseBotId
        isChatLMM
        enableUserCustomization
        isBotPartOfSubscription
        baseBot {
          id
          name
          user {
            id
            profilePicture
          }
        }
        createdBy {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  }
`);

const useGetChatSessionDefaultBot = () => {
  const params = useParams();
  const { data, loading } = useQuery(GET_DATASOURCE_SESSION, {
    variables: {
      sessionId: +(params?.sessionId || 0),
    },
    fetchPolicy: 'cache-first',
  });

  return {
    data,
    loading,
  };
};

export default useGetChatSessionDefaultBot;
