import * as React from 'react';
import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import scrollStyle from '../../../Chat/scrollStyle';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import CustomButton from '../../../common/CustomButton/CustomButton';
import Loading from '../../../common/Loading/Loading';
import DescriptionPreview from './DescriptionPreview/DescriptionPreview';
import useGetOnePrompt from '../../../../Hooks/useGetOnePrompt';
import {
  arrayToObject,
  getRandomColor,
  onChangeDescriptionFromTag,
  removeTags,
  replaceTagName,
} from '../../../../utils/promptUtil';
import { StepContext } from '../../Prompts';
import EditTagContent from './EditTagContent/EditTagContent';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';
import { Mixpanel } from '../../../../mixpanel/mixpanel';

interface Props {
  callback(prompts: string): void;
  onEdit(): void;
}

const CustomGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(54),
  overflow: 'auto',
}));

const CustomGridListField = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
}));

const CustomContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  height: '10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const PromptsStepTwo = ({ callback, onEdit }: Props) => {
  const { data } = useGetOnePrompt();
  const { idCategorie, isCustomCategory } = React.useContext(StepContext);
  const { onDeletePrompt } = useGetCategoryPrompts(idCategorie);
  const prompts = data?.findFirstPromptsOrThrow;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fields = prompts?.fieldsPrompts || [];
  const defaultDescription = prompts?.description as string;
  const [description, setDescription] = React.useState('');
  const [colors, setColors] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formPromps, setFormPromps] = React.useState({
    ...arrayToObject(fields),
  });
  const [isDeleteConfirm, setIsDeleteConfirm] = React.useState(false);

  const handleSumbitPrompt = (e: React.FormEvent) => {
    e.preventDefault();
    const newDescription = removeTags(description);
    Mixpanel.track('Use prompt', {
      title: prompts?.title,
    });
    callback(newDescription);
  };

  const handleDeletePrompt = () => {
    if (prompts && prompts.id) {
      onDeletePrompt(prompts.id);
      setIsDeleteConfirm(false);
    }
  };

  const generetedColors = () => {
    if (fields.length) {
      let tmpColors: string[] = [];
      fields.forEach((field) => {
        const color = getRandomColor();
        tmpColors.push(color);
        setDescription((prevDescription) => {
          let str = prevDescription;
          str = replaceTagName(str, field, field, color, true);
          return str;
        });
      });
      setColors(tmpColors);
    }
  };

  const handleChange = (tagName: string, value: string, color: string) => {
    setDescription((prevDescription) => {
      const newDescription = onChangeDescriptionFromTag(
        prevDescription,
        value,
        tagName,
        color,
        formPromps
      );
      return newDescription;
    });

    setFormPromps((prevFormPromps) => ({
      ...prevFormPromps,
      [tagName]: value,
    }));
  };

  React.useEffect(() => {
    setDescription(defaultDescription);
    generetedColors();
    if (defaultDescription) {
      setIsLoading(false);
    }
  }, [defaultDescription]);

  return (
    <form
      onSubmit={handleSumbitPrompt}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Grid container sx={{ flexGrow: 1 }}>
        <CustomGrid
          item
          pt={2.5}
          xs={12}
          px={3}
          sx={{
            ...scrollStyle,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: isMobile ? '90%' : '560px',
          }}
        >
          {isLoading ? (
            <CustomContainer mt={18}>
              <Loading color="primary" size={20} type="circular" />
            </CustomContainer>
          ) : (
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Box mb={2}>
                <CustomTypography variant="h3">
                  {prompts?.title}
                </CustomTypography>
              </Box>
              <DescriptionPreview descriptionPreview={description} />
              {fields.length > 0 && (
                <CustomGridListField item my={1} xs={isMobile ? 12 : 8} py={1}>
                  {!isLoading && (
                    <EditTagContent
                      fields={fields}
                      colors={colors}
                      formPromps={formPromps}
                      handleChange={handleChange}
                    />
                  )}
                </CustomGridListField>
              )}
            </Box>
          )}
        </CustomGrid>
        <FooterGrid item xs={12}>
          <Box display="flex" alignItems="center" gap={1}>
            {isCustomCategory && (
              <>
                {isDeleteConfirm ? (
                  <>
                    <CustomButton
                      size="small"
                      sx={{ height: 40 }}
                      variant="contained"
                      color="secondary"
                      intlId="libraries.prompts.confirm.delete"
                      onClick={handleDeletePrompt}
                    />
                    <CustomButton
                      size="small"
                      sx={{ height: 40 }}
                      variant="contained"
                      color="inherit"
                      intlId="libraries.prompts.cancel"
                      onClick={() => setIsDeleteConfirm(false)}
                    />
                  </>
                ) : (
                  <CustomButton
                    size="small"
                    sx={{ height: 40 }}
                    variant="contained"
                    color="primary"
                    intlId="libraries.prompts.delete"
                    onClick={() => setIsDeleteConfirm(true)}
                  />
                )}
                <CustomButton
                  size="small"
                  sx={{ height: 40 }}
                  variant="contained"
                  color="primary"
                  intlId="libraries.prompts.edit"
                  onClick={onEdit}
                />
              </>
            )}
          </Box>

          <CustomButton
            size="small"
            sx={{ height: 40, marginLeft: 1 }}
            variant="contained"
            type="submit"
            color="primary"
            intlId="libraries.prompts.use"
          />
        </FooterGrid>
      </Grid>
    </form>
  );
};

export default PromptsStepTwo;
