import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import CustomButton from '../../common/CustomButton/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledButton = styled(CustomButton)(({ theme }) => ({
  height: theme.spacing(3.5),
  marginLeft: theme.spacing(0.5),
  marginTop: theme.spacing(1.7),
  backgroundColor: theme.palette.customColor.cardBackground,
  color: theme.palette.customColor.blackColor,
  position: 'relative',
  border: 'none',
  fontSize: '1em',
  '&:hover': {
    border: 'none',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.customColor.cardBackgroundHover,
  },
}));
interface props {
  handleClick: () => void;
}

const ButtonPrompts = ({ handleClick }: props) => {
  const intl = useIntl();
  return (
    <StyledButton
      endIcon={<KeyboardArrowDownIcon />}
      color="primary"
      variant="outlined"
      onClick={handleClick}
      label={intl.formatMessage({
        id: 'button.prompts',
        defaultMessage: 'Browse Prompts',
      })}
    />
  );
};

export default ButtonPrompts;
