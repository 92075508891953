import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { graphql } from '../gql/gql';
import useGetPutSignedUrl from './useGetPutSignedUrl';
import axios from 'axios';

const CREATE_FILE = graphql(`
  mutation CreateNewFile($newFileData: NewFileInput!) {
    createFile(newFileData: $newFileData) {
      id
      url
      updatedAt
      type
      size
      removed
      name
      createdAt
    }
  }
`);
export interface FileType {
  id: number;
  url: string;
  updatedAt: string;
  type: string;
  size: number;
  removed: boolean;
  name: string;
  createdAt: string;
}

const useCreateFile = ({
  onComplete,
  isDirectlyToUpload = false,
  supportedFiletypes,
}: {
  onComplete: (files: FileType[]) => void;
  isDirectlyToUpload?: boolean;
  supportedFiletypes?: string;
}) => {
  const { getFilePutSignedUrl } = useGetPutSignedUrl();
  const [addNewFile]: any = useMutation(CREATE_FILE);
  const [createdFiles, setCreatedFiles] = useState<FileType[]>([]);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isInsupported, setIsInsupported] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const getSupportedFiletypesArray = () => {
    if (supportedFiletypes) {
      return supportedFiletypes.split(',').length
        ? supportedFiletypes.split(',').map((item) => item.trim())
        : [supportedFiletypes];
    }

    return '';
  };

  const supportedFiletypesArray = getSupportedFiletypesArray();

  const createFile = useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        const createdFiles = await Promise.all(
          data.map(async (file: any) => {
            let s3key = '';
            const result = await getFilePutSignedUrl({
              variables: {
                fileName: file.name,
              },
            });

            // Check if result.data and result.data.getFilePutSignedUrl are valid
            if (!result.data || !result.data.getFilePutSignedUrl) {
              throw new Error('Failed to get signed URL');
            }

            const { key, url } = result.data.getFilePutSignedUrl;
            s3key = key;

            await axios.put(url, file, {
              headers: {
                'Content-Type': file.type,
              },
              onUploadProgress: (progressEvent: any) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(progress);
              },
            });

            return addNewFile({
              variables: {
                newFileData: {
                  name: file.name,
                  type: file.type,
                  size: file.size,
                  s3key: s3key,
                },
              },
            });
          })
        );

        setLoading(false);
        setUploadProgress(0);
        setCreatedFiles(createdFiles);
        const createdFile = createdFiles.map((file) => file.data.createFile);
        setFiles(createdFile);
        onComplete(createdFile);
      } catch (err: any) {
        setError(err);
        setLoading(false);
        setUploadProgress(0);
        console.log('Error:', err);
      }
    },
    [
      setLoading,
      setUploadProgress,
      setCreatedFiles,
      onComplete,
      setError,
      addNewFile,
      getFilePutSignedUrl,
    ]
  );

  const onDropFile = useCallback(
    (acceptedFiles: File[]) => {
      if (supportedFiletypes) {
        const hasUnsupportedFile = acceptedFiles.some(
          (file) => !supportedFiletypesArray.includes(file.type)
        );

        setIsInsupported(hasUnsupportedFile);

        if (!hasUnsupportedFile) {
          if (isDirectlyToUpload) {
            createFile(acceptedFiles);
          }
        }
      } else {
        if (isDirectlyToUpload) {
          createFile(acceptedFiles);
        }
      }
    },
    [supportedFiletypes, isDirectlyToUpload, setFiles, createFile]
  );

  const onUploadFile = useCallback(
    (acceptedFiles: FileList) => {
      if (supportedFiletypes) {
        const newFiles = Array.from(acceptedFiles).map((file) => file);

        const hasUnsupportedFile = newFiles.some(
          (file) => !supportedFiletypesArray.includes(file.type)
        );
        setIsInsupported(hasUnsupportedFile);

        if (!hasUnsupportedFile) {
          createFile(newFiles);
        }
      }
    },
    [createFile]
  );

  const handleCreateFile = () => {
    createFile(files);
  };

  return {
    error,
    uploadProgress,
    handleCreateFile,
    createdFiles,
    loading,
    isInsupported,
    files,
    onDropFile,
    onUploadFile,
    setFiles,
  };
};

export default useCreateFile;
