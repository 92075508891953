import { useTheme } from '@mui/material';
import * as React from 'react';

interface props {
  callBack?(): void;
}
const PlusIcon = ({ callBack }: props) => {
  const theme = useTheme();
  const color = theme.palette.mode === 'light' ? 'black' : 'white';
  return (
    <>
      <svg
        onClick={callBack}
        role="img"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 3.33334V16.6667"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 10H3.33337"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default PlusIcon;
