import { BoxProps } from '@mui/system';
import * as React from 'react';
import useChangeLabelColor from '../../../../hooks/useChangelabelColor';
import CustomDialogColorPicker from '../../../../../common/CustomDialogColorPicker/CustomDialogColorPicker';

interface ColorProps extends BoxProps {
  labelId: number;
  defaultColor?: string;
  openColor: boolean;
  handleCloseColor(): void;
}


export default function TodoLabelColor({
  handleCloseColor,
  openColor,
  defaultColor,
  labelId,
}: ColorProps) {
  const { onChangeColor } = useChangeLabelColor();
  const [color, setColor] = React.useState(defaultColor || '#fff');
  const onColorPickerInfoChange = (color: any) => {
    setColor(color.hex);
    onChangeColor(color.hex, labelId);
  };
  return (
    <>
      <CustomDialogColorPicker
        open={openColor}
        value={color}
        handleClose={handleCloseColor}
        onColorPickerInfoChange={onColorPickerInfoChange}
      />
    </>
  );
}
