import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import CheckedItem from './CheckedItem/CheckedItem';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

interface props {
  handleAction(e?: any): void;
  role: string;
  roleToTest: string;
  isLeft?: boolean;
}
export default function MenuItemList({
  handleAction,
  role,
  roleToTest,
  isLeft,
}: props) {
  return (
    <MenuItem onClick={handleAction}>
      {role === roleToTest && isLeft ? <CheckedItem /> : null}
      <CustomTypography
        variant="body2"
        sx={{ textTransform: 'capitalize', fontSize: '12px' }}
      >
        {role.toLowerCase()}
      </CustomTypography>
      {role === roleToTest && !isLeft ? <CheckedItem /> : null}
    </MenuItem>
  );
}
