import React from 'react';
import { Container } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

const EmptyResult: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        width:'100%',
        justifyContent: 'center',
      }}
    >
      <CustomBox textAlign="center" display={'flex'} flexDirection={'column'}>
        <CustomBox
          sx={{
            width: 56,
            height: 56,
            bgcolor: 'primary.main',
            color: 'common.white',
            borderRadius: '50%',
            m:'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <InfoIcon />
        </CustomBox>
        <CustomTypography variant="h3" fontWeight={700}>
          <FormattedMessage id='no.search.result.title'/>
        </CustomTypography>
        {/* <CustomTypography variant="h6" >
        <FormattedMessage id='no.search.result.description'/>
        </CustomTypography> */}
      </CustomBox>
    </Container>
  );
};

export default EmptyResult;
