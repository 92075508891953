import React, { useRef, useEffect, useState } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import { CircularProgress } from '@mui/material';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ReplayIcon from '@mui/icons-material/Replay';

interface Props {
  onUploadImage: (fileList: FileList) => void; // Updated to include base64
  onExitWebCam: () => void;
  onResetImage: () => void;
  loadingUpload: boolean;
  uploadProgress: number;
  value: string;
}

const WebcamCapture: React.FC<Props> = ({
  onUploadImage,
  onExitWebCam,
  loadingUpload,
  uploadProgress,
  onResetImage,
  value,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageCapturedDisplay, setImageCapturedDisplay] = useState('');

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current = null; // Clear the reference
    }
  };

  useEffect(() => {
    if (imageCapturedDisplay === '') {
      startCamera();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera(); // Ensure camera stops when component unmounts
    };
  }, [imageCapturedDisplay]);

  const captureImage = () => {
    if (imageCapturedDisplay !== '') {
      onResetImage();
      setImageCapturedDisplay('');
      return;
    }
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );

        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'captured-image.png', {
              type: 'image/png',
            });
            const base64Image = canvasRef.current
              ? canvasRef.current.toDataURL('image/png')
              : '';
            setImageCapturedDisplay(base64Image);

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            const fileList = dataTransfer.files;

            onUploadImage(fileList); // Send both the FileList and Base64 to the parent component
          }
        }, 'image/png');
      }
    }
  };

  return (
    <>
      <CustomBox
        sx={{
          background: (theme) => theme.palette.customColor.grayColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <CustomIconButton onClick={onExitWebCam}>
          <CloseIcon />
        </CustomIconButton>
      </CustomBox>
      <CustomBox
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        {imageCapturedDisplay !== '' && videoRef.current === null ? (
          <img
            src={imageCapturedDisplay}
            alt="web-cam"
            style={{ maxHeight: '400px' }}
          />
        ) : (
          <video ref={videoRef} autoPlay={true} />
        )}
      </CustomBox>

      {loadingUpload ? (
        <CustomBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: (theme) => theme.palette.grey[200],
          }}
        >
          <CustomBox>
            <b>{uploadProgress} %</b>
          </CustomBox>
          <CircularProgress size={10} />
        </CustomBox>
      ) : (
        <CustomBox
          sx={{
            background: (theme) => theme.palette.customColor.grayColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomIconButton onClick={captureImage} disabled={loadingUpload}>
            {imageCapturedDisplay ? <ReplayIcon /> : <CameraAltIcon />}
          </CustomIconButton>
        </CustomBox>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
};

export default WebcamCapture;
