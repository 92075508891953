import { useTheme } from '@mui/material';
import * as React from 'react';

const LogoIcon = () => {
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark' ? true : false
  const color = isDark ? '#fff' : '#34324A'
  return (
    <>
      <svg
        width="100"
        height="30"
        style={{ marginBottom: 10 }}
        viewBox="0 0 134 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.3975 11.4812L61.1546 14.0624C59.7795 13.2637 58.0553 12.7083 56.9975 12.7083C56.146 12.7083 55.5695 13.0257 55.5695 13.6552C55.5695 15.9032 62.4398 14.6231 62.4398 19.3148C62.4398 21.912 60.1391 23.2872 57.2408 23.2872C55.067 23.2872 52.8404 22.4726 51.3013 21.2085L52.5812 18.6642C53.9193 19.8332 55.9238 20.616 57.2778 20.616C58.3197 20.616 58.965 20.2246 58.965 19.4841C58.965 17.1832 52.0946 18.5585 52.0946 13.9514C52.0946 11.5552 54.1573 10.0319 57.2725 10.0319C59.1448 10.0319 61.0594 10.6084 62.3975 11.4812Z"
          fill={color}
        />
        <path
          d="M73.625 23.1762L71.6575 16.8289L69.69 23.1762H66.4955L62.8408 13.1686H66.1094L68.1721 20.0924L70.1766 13.1686H73.2389L75.3016 20.0924L77.3273 13.1686H80.4636L76.809 23.1762H73.6356H73.625Z"
          fill={color}
        />
        <path
          d="M84.9193 10.2912C84.9193 11.2962 84.2158 11.9997 83.2321 11.9997C82.2484 11.9997 81.5449 11.2962 81.5449 10.2912C81.5449 9.28619 82.2484 8.60385 83.2321 8.60385C84.2158 8.60385 84.9193 9.30735 84.9193 10.2912ZM81.6137 23.1762V13.1686H84.8082V23.1762H81.6137Z"
          fill={color}
        />
        <path
          d="M91.1543 12.8883V13.613H93.418V15.7288H91.1543V23.171H87.9809V15.7288H86.627V13.613H87.9809V12.6873C87.9809 10.4763 89.6522 9.25449 91.8048 9.25449C92.7886 9.25449 93.8464 9.51367 94.608 9.95798L93.4762 12.206C93.1218 11.9468 92.6775 11.7987 92.3073 11.7987C91.6568 11.7775 91.1543 12.1531 91.1543 12.8936V12.8883Z"
          fill={color}
        />
        <path
          d="M101.273 22.6367C100.548 23.0652 99.5647 23.3244 98.6021 23.3244C96.6716 23.3244 95.2806 22.2295 95.2806 20.1507V15.734H93.8896V13.6341H95.2806V10.8519H98.4752V13.6183H101.093V15.734H98.4752V19.6535C98.4752 20.431 98.8084 20.7696 99.4007 20.7484C99.7339 20.7484 100.162 20.6162 100.644 20.4152L101.273 22.642V22.6367Z"
          fill={color}
        />
        <path
          d="M111.741 16.6384V23.1709H108.604V22.0178C107.954 22.8694 106.896 23.319 105.542 23.319C103.405 23.319 102.109 22.0178 102.109 20.1823C102.109 18.3469 103.463 17.1726 105.949 17.1568H108.583V17.0087C108.583 16.0248 107.933 15.4324 106.637 15.4324C105.801 15.4324 104.706 15.7286 103.648 16.2681L102.739 14.1524C104.299 13.4277 105.711 13.0204 107.377 13.0204C110.122 13.0204 111.719 14.3745 111.741 16.6384ZM108.583 19.775V18.8864H106.557C105.595 18.8864 105.108 19.2196 105.108 19.9655C105.108 20.7113 105.627 21.1556 106.52 21.1556C107.525 21.1556 108.377 20.6002 108.583 19.7803V19.775Z"
          fill={color}
        />
        <path
          d="M121.723 14.1526L120.628 16.2313C119.422 15.5807 118.306 15.2263 117.471 15.2263C116.894 15.2263 116.487 15.4485 116.487 15.914C116.487 17.2892 121.76 16.6175 121.739 20.1085C121.739 22.1714 119.941 23.3033 117.524 23.3033C115.852 23.3033 114.239 22.822 113.033 21.891L114.054 19.8493C115.186 20.648 116.487 21.0923 117.598 21.0923C118.227 21.0923 118.692 20.8702 118.692 20.4047C118.692 18.9395 113.514 19.7012 113.53 16.189C113.53 14.1261 115.276 13.0154 117.598 13.0154C119.047 13.0154 120.491 13.4226 121.718 14.1473L121.723 14.1526Z"
          fill={color}
        />
        <path
          d="M126.848 20.4469V23.1763H123.637V9.40257H126.848V16.6808L130.153 13.1739H133.792L130.116 17.0194L133.903 23.1816H130.1L127.927 19.3203L126.848 20.4522V20.4469Z"
          fill={color}
        />
        <path
          d="M31.5152 17.5576H36.4498C37.497 17.5576 38.4384 17.1345 39.126 16.4469C39.8083 15.7645 40.2367 14.8177 40.2367 13.7757C40.2367 11.6864 38.5442 9.99375 36.4551 9.99375H20.9161C20.4507 9.99375 20.0064 9.7187 19.8689 9.27439C19.6415 8.54974 20.1809 7.87798 20.8738 7.87798H28.4264C29.4684 7.87798 30.4151 7.45482 31.1026 6.7672C31.7902 6.08486 32.2133 5.13805 32.2133 4.09603C32.2133 2.00671 30.5209 0.314087 28.4317 0.314087H9.16405C8.12212 0.314087 7.1754 0.737242 6.48783 1.42487C5.67862 2.22357 5.23964 3.39254 5.41946 4.65142C5.68391 6.52917 7.37638 7.87798 9.27511 7.87798H12.787C13.2524 7.87798 13.6967 8.15303 13.8342 8.59734C14.0616 9.32199 13.5221 9.99375 12.8293 9.99375H3.84335C2.79614 9.99375 1.85471 10.4169 1.16714 11.1045C0.36322 11.9032 -0.0810528 13.0722 0.0987718 14.3311C0.368509 16.2088 2.06098 17.5576 3.95971 17.5576H24.0578C24.6395 17.5576 25.1631 17.9967 25.1843 18.5785C25.2055 19.1603 24.7242 19.6787 24.1265 19.6787H17.0076C15.9656 19.6787 15.0189 20.1019 14.3366 20.7895C13.5274 21.5935 13.0885 22.7572 13.2683 24.016C13.5327 25.8938 15.2252 27.2479 17.1239 27.2479H25.8348C26.8821 27.2479 27.8817 27.6922 28.5798 28.475L30.9916 31.1779C31.5152 31.7598 32.4778 31.3948 32.4778 30.612V28.7871C32.4778 27.9408 33.1653 27.2532 34.0116 27.2532H34.4241C35.466 27.2532 36.4128 26.83 37.1003 26.1477C37.8461 25.4019 38.2745 24.3651 38.2004 23.2173C38.0682 21.2073 36.3176 19.6893 34.3025 19.6893H31.4464C30.8488 19.6893 30.3675 19.1974 30.3886 18.5891C30.4098 17.9808 30.9334 17.5682 31.5152 17.5682V17.5576Z"
          fill="#FF6E72"
        />
      </svg>
    </>
  );
};

export default LogoIcon;
