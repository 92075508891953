import { useTheme } from "@mui/material";

const CheckedIcon = () => {
    const theme = useTheme()
    const color = theme.palette.mode === "dark" ? "#F5F5F5" : "#34324A"
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 7L10 14L5 9.99999M6 21L16 21C18.7614 21 21 18.7614 21 16L21 5.99998C21 3.23856 18.7614 0.99998 16 0.99998L6 0.99998C3.23857 0.99998 0.999997 3.23856 0.999997 5.99998L0.999998 16C0.999998 18.7614 3.23857 21 6 21Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    );
}

export default CheckedIcon 