import useGetChatContext from '../ChatBodyContainer/useGetChatContext';

const useChatForm = () => {
  const context = useGetChatContext();

  const hasDataSources = context?.dataSources && context.dataSources.length > 0;
  const defaultBot = context?.defaultBot;
  const isBotConfigMode = context?.isBotConfigMode;

  return {
    hasDataSources,
    defaultBot,
    isBotConfigMode,
  };
};

export default useChatForm;
