import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

// ChatSessionId as mutation argument
const SETUP_CHAT_SESSION_DATA_INDEXATION = graphql(`
  mutation SetupChatSessionDataIndexation(
    $sessionId: Float!
    $messageId: Float!
  ) {
    setupChatSessionDatasource(sessionId: $sessionId, messageId: $messageId) {
      id
      allDataSources {
        id
        name
        url
      }
    }
  }
`);

const useSetupChatSessionDataIndexation = () => {
  // Mutation to setup chat session data indexation
  const [setup, { loading: loadingSetupChatSessionDataIndexation }] =
    useMutation(SETUP_CHAT_SESSION_DATA_INDEXATION);

  const setupChatSessionDataIndexation = (args: {
    sessionId: number;
    messageId: number;
  }) => {
    const { sessionId } = args;
    setup({
      variables: {
        sessionId,
        messageId: args.messageId,
      },
    });
  };

  return {
    setupChatSessionDataIndexation,
    loadingSetupChatSessionDataIndexation,
    setup,
  };
};

export default useSetupChatSessionDataIndexation;
