import * as React from 'react';
import { graphql } from '../../../../gql';
import { GoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import AlertText from '../../../common/AlertText/AlertText';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { handleAfterAuth } from '../../../../utils/authUtils';
import { getUrlParam } from '../../../../utils/url';

const GOOGLE_LOGIN = graphql(`
  mutation GoogleLoginAuth($referralCode: String, $code: String!) {
    googleLogin(referralCode: $referralCode, code: $code) {
      token
      user {
        id
        email
        firstName
        lastName
      }
      isNewSignup
    }
  }
`);

interface IProps {
  from: 'signin' | 'signup';
}

const SubmitWithGoogle: React.FC<IProps> = ({ from }) => {
  // Show error state
  const [error, setError] = React.useState(false);
  const intl = useIntl();
  const referralCode = getUrlParam('invite') as string;
  const [googleLogin] = useMutation(GOOGLE_LOGIN, {
    update(cache, { data }) {
      // Redirect to home page if login success
      if (data?.googleLogin?.token) {
        Mixpanel.identify(data?.googleLogin.user.id);
        const isNewSignup = data?.googleLogin.isNewSignup;
        if (!isNewSignup) {
          Mixpanel.track('Signin', {
            email: data?.googleLogin.user.email,
            firstName: data?.googleLogin.user.firstName,
            lastName: data?.googleLogin.user.lastName,
            method: 'google',
          });
        }
        if (isNewSignup && isNewSignup === true) {
          Mixpanel.track('Signup', {
            email: data?.googleLogin.user.email,
            firstName: data?.googleLogin.user.firstName,
            lastName: data?.googleLogin.user.lastName,
            method: 'google',
          });
        }

        Mixpanel.people.set({
          $email: data?.googleLogin.user.email,
          $first_name: data?.googleLogin.user.firstName,
          $last_name: data?.googleLogin.user.lastName,
        });
        handleAfterAuth(data?.googleLogin.token);
      }
    },
    onError() {
      setError(true);
    },
  });

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            if (credentialResponse.credential) {
              googleLogin({
                variables: {
                  referralCode: referralCode,
                  code: credentialResponse.credential,
                },
              });
            }
          }}
          onError={() => {
            setError(true);
          }}
          useOneTap
          size="large"
          cancel_on_tap_outside={false}
          context={from}
        />
      </Box>
      <Box marginTop={1}>
        {error && (
          <AlertText
            message={intl.formatMessage({
              id: 'signin.google.error',
              defaultMessage: 'Something went wrong. Please try again.',
            })}
            severity="error"
          />
        )}
      </Box>
    </Box>
  );
};

export default SubmitWithGoogle;
