// A component to display parent message of a reply message, use material ui box component to display parent message

import * as React from 'react';
import { Avatar, Box, styled, useTheme } from '@mui/material';
import { gql, useApolloClient } from '@apollo/client';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import ContentMessage from '../ContentMessage/ContentMessage';
import CustomTooltip from '../../../../common/CustomToolTip/CustomToolTip';
import { useIntl } from 'react-intl';

interface IProps {
  messageId: number;
}

// Query to get parent message using message id, query todoChatMessage

const MessageReplyFromContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  textAlign: 'start',
  alignItems:'center',
  height: theme.spacing(3),
  '&:hover': {
    cursor: 'pointer',
  },
}));

const MessageReplyFromContent = styled(CustomTypography)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  overflow: 'hidden',
  position: 'relative',
  textOverflow: 'ellipsis',
  width: '80%',
  height: theme.spacing(3.5),
}));
const MessageReplyFromUser = styled(CustomTypography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '11px',
}));
// Create a arrow svg pointing to the parent message
const MessageReplyFromArrow = styled('svg')(({ theme }) => ({
  width: theme.spacing(4.5),
  height: theme.spacing(2),
  fill: 'none',
  stroke: theme.palette.grey[500],
  strokeWidth: '1.5px',
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(0.5),
}));

const MessageReplyFrom: React.FC<IProps> = ({ messageId }) => {
  // Read fragment from cache
  const intl = useIntl();
  const theme = useTheme();
  const client = useApolloClient();
  const parentMessage = client.readFragment({
    id: `TodoChatMessage:${messageId}`,
    fragment: gql`
      fragment parentMessage on TodoChatMessage {
        id
        createdAt
        content
        sentByUserId
        todoChatId
        updatedAt
        parentMessage {
          id
          content
          sentBy {
            id
            firstName
            lastName
            profilePicture
            isBot
          }
        }
      }
    `,
  });
  if (!parentMessage) return null;
  if (!parentMessage.parentMessage) return null;
  if (!parentMessage.parentMessage.sentBy) return null;
  // If content null
  if (!parentMessage.parentMessage.content) return null;
  // Truncate parent message content if it's too long
  let messageToDisplay = parentMessage.parentMessage.content;
  if (messageToDisplay.length > 75) {
    messageToDisplay = `${messageToDisplay.substring(0, 75)}...`;
  }
  const handleClick = (id: any) => {
    const messageEl = document.getElementById(`message-${id}`);
    if (messageEl) {
      messageEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      messageEl.style.backgroundColor = theme.palette.background.default;
      setTimeout(() => (messageEl.style.backgroundColor = 'transparent'), 2000);
    }
  };
  return (
    <CustomTooltip
      title={intl.formatMessage({ id: 'reply.message.tooltip' })}
      arrow
      placement="top"
    >
      <MessageReplyFromContainer
        onClick={() => handleClick(parentMessage?.parentMessage?.id)}
      >
        <MessageReplyFromArrow viewBox="0 0 25 40">
          <path d="M1 44V32.4C1 21.7646 1 16.4469 2.9709 12.3425C4.93884 8.24432 8.24432 4.93884 12.3425 2.9709C16.4469 1 21.7646 1 32.4 1H102" />
        </MessageReplyFromArrow>
        <Avatar
          sx={{ width: '16px', height: '16px', mr: 0.5 }}
          src={parentMessage?.parentMessage?.sentBy?.profilePicture}
          variant="rounded"
        />
        <MessageReplyFromUser variant="body2">
          {parentMessage.parentMessage?.sentBy?.firstName}{' '}
        </MessageReplyFromUser>
        <MessageReplyFromContent noWrap>
          <ContentMessage message={messageToDisplay} />
        </MessageReplyFromContent>
      </MessageReplyFromContainer>
    </CustomTooltip>
  );
};

export default MessageReplyFrom;
