import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useState } from 'react';

export const GET_CONFIRM_DIALOG = gql`
  query getConfirmDialog {
    confirmDialog @client {
      message
      isConfirm
    }
  }
`;
interface ConfirmDialog {
  message: string;
  isConfirm?: boolean;
  onConfirm?: () => void;
}

const useConfirmDialog = () => {
  // State to store confirm callback function
  const [confirmCallback, setConfirmCallback] = useState<() => void>();

  const { data } = useQuery(GET_CONFIRM_DIALOG, {
    fetchPolicy: 'cache-only',
    onCompleted: (data) => {
      if (data.confirmDialog.isConfirm) {
        confirmCallback && confirmCallback();
      }
    },
  });

  const client = useApolloClient();

  const displayConfirmDialog = ({ message, onConfirm }: ConfirmDialog) => {
    client.writeQuery({
      query: GET_CONFIRM_DIALOG,
      data: {
        confirmDialog: {
          message,
          isConfirm: false,

          __typename: 'ConfirmDialog',
        },
      },
    });
    // Set but don't call the callback
    setConfirmCallback(() => onConfirm);
  };
  const closeDialog = () => {
    client.writeQuery({
      query: GET_CONFIRM_DIALOG,
      data: {
        confirmDialog: {
          message: null,
          isConfirm: null,
          __typename: 'ConfirmDialog',
        },
      },
    });
    confirmCallback && confirmCallback();
  };
  // on confirm, close dialog and call the callback
  const onConfirm = () => {
    // If type is function, call it

    // Propagate that the user has confirmed
    client.writeQuery({
      query: GET_CONFIRM_DIALOG,
      data: {
        confirmDialog: {
          message: null,
          isConfirm: true,
          __typename: 'ConfirmDialog',
        },
      },
    });
    setTimeout(() => {
      closeDialog();
    }, 1000);
  };

  return { displayConfirmDialog, closeDialog, onConfirm };
};

export default useConfirmDialog;
