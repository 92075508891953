import Link from '@mui/material/Link';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';

const StyledLink = styled(Link)`
  color: blue;
`;
const Agreement = () => {
  return (
    <CustomTypography textAlign={'center'}>
      <FormattedMessage id={'introd.agree'}></FormattedMessage>
      <StyledLink
        href="https://www.swiftask.ai/tos.html"
      >
        <FormattedMessage id={'term.service'}></FormattedMessage>
      </StyledLink>
      <FormattedMessage id={'agree.and'}></FormattedMessage>
      <StyledLink href="https://www.swiftask.ai/privacy.html">
        <FormattedMessage id={'privacy.notice'}></FormattedMessage>
      </StyledLink>
      .
    </CustomTypography>
  );
};
export default Agreement;
