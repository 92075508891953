import { FC } from 'react';
import { Todo } from '../../../../../gql/graphql';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import Task from '../../../../MyDay/ListTasks/Task/Task';
import useGetTodoDetails from '../../../hooks/useGetTodoDetails';
import TodoSchedule from './TodoSchedule/TodoSchedule';
import STDivider from '../../../../common/STDivider/STDivider';
import ToDoProject from '../../ToDoProject/ToDoProject';
import TodoLabel from '../../TodoLabel/TodoLabel';
import TodoCreated from '../../TodoCreated/TodoCreated';
import ToDoAsignees from '../../ToDoAsignees/ToDoAsignees';
import TodoSubTask from '../../TodoSubTask/TodoSubTask';
import TodoListFiles from '../../ToDoFiles/TodoListFiles';
import TodoActivity from '../../TodoActivity/TodoActivity';
import React from 'react';
import TaskMenu from '../../../BodyChat/TaskMenu/TaskMenu';
import useDisplayTodoDescriptionSidebar from '../../../../../Hooks/useDisplayTodoDescriptionSidebar';
import useRemoveTodo from '../../../hooks/useRemoveTodo';
import { useTheme } from '@mui/material';
import { borderRadius } from '@mui/system';
interface IProps {
  todoId: number;
  isForChat: boolean;
}

const TodoInfo: FC<IProps> = ({ todoId, isForChat }) => {
  const theme = useTheme();
  const { data } = useGetTodoDetails({ todoId: todoId });
  const { hiddeDescriptionSidbar } = useDisplayTodoDescriptionSidebar();
  const projectId = data?.todo?.projectId || 0;
  const { handleDeleteTask } = useRemoveTodo({
    projectId: projectId || 0,
    isInChatPage: isForChat,
    onDeleteComplete: () => {
      hiddeDescriptionSidbar();
    },
  });

  const handleRemoveTask = (e: any, todoId: number) => {
    handleDeleteTask(e, todoId);
  };

  const todo = (data?.todo || {}) as Todo;
  return (
    <CustomBox>
      <CustomBox
        sx={{
          position: 'relative',
          display: 'flex',
          backgroundColor:
            theme.palette.customColor.projectSectionKabanBackground,
          borderRadius: theme.spacing(1),
        }}
      >
        <CustomBox width={'100%'}>
          <Task
            isEditable={true}
            task={todo}
            isForChat={isForChat}
            showCompleteCheckbox={true}
          />
        </CustomBox>
        <CustomBox
          sx={{
            alignItems: 'center',
            paddingRight: theme.spacing(1),
            display: 'flex',
            margin: 'auto',
          }}
        >
          <TaskMenu todoId={todoId} onDeleteTask={handleRemoveTask} />
        </CustomBox>
      </CustomBox>
      <CustomBox>
        <TodoSchedule todo={todo} />
      </CustomBox>
      <CustomBox
        sx={{
          paddingTop: 2,
        }}
      >
        <STDivider />
        <ToDoProject taskId={todoId} />
      </CustomBox>
      {todo.projectId && (
        <CustomBox
          sx={{
            marginTop: 1,
          }}
        >
          <STDivider />
          <TodoLabel task={todo} />
        </CustomBox>
      )}
      <CustomBox
        sx={{
          marginTop: 1,
        }}
      >
        <STDivider />
        <TodoCreated task={todo} />
      </CustomBox>
      <CustomBox
        sx={{
          marginTop: 1,
        }}
      >
        <STDivider />
        <ToDoAsignees todoId={todoId} />
      </CustomBox>
      <CustomBox
        sx={{
          marginTop: 1,
        }}
      >
        <STDivider />
        <TodoSubTask todoId={todoId} />
      </CustomBox>
      <CustomBox>
        <TodoListFiles todoID={todoId} />
      </CustomBox>

      <CustomBox>
        <STDivider />

        <TodoActivity todoId={todoId} />
      </CustomBox>
    </CustomBox>
  );
};

export default React.memo(TodoInfo);
