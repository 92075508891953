import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Box from '@mui/system/Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import { FC } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: theme.spacing(3),
}));

interface ProviderSkeletonProps {
  children: React.ReactNode;
}

export const ProviderSkeleton: FC<ProviderSkeletonProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <SkeletonTheme
      baseColor={theme.palette.background.default}
      highlightColor={theme.palette.grey['400']}
    >
      <Container>{children}</Container>
    </SkeletonTheme>
  );
};

export const SkeletonContent = Skeleton;
