import { FC, useContext } from 'react';
import useGetChatContext from '../../../ChatBodyContainer/useGetChatContext';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import ListeSelector from './ListeSelector/ListeSelector';
import VoiceSelector from './VoiceSelector/VoiceSelector';
import FormUI from './FormUI/FormUI';
import { ChatContext } from '../../../../Chat';
import { UiLayout } from '../../../../../../gql/graphql';

const BotUI: FC = () => {
  const { onChangeExtraConfig, extraConfig } = useContext(ChatContext);

  const { defaultBot } = useGetChatContext();
  const botUIs = defaultBot?.botUIs;
  return (
    <>
      <CustomBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '8px',
          paddingBottom: '8px',
          position: 'relative',
          background: 'transparent',
        }}
      >
        <CustomBox>
          {botUIs ? (
            <CustomBox
              sx={{
                display: 'flex',
              }}
            >
              {/* displaying select voice elevenlabs  */}
              {botUIs.map((botUI) => {
                if (
                  botUI.slug === 'bot-formui' &&
                  defaultBot.uiLayout === UiLayout.Chat
                ) {
                  return (
                    <FormUI
                      value={extraConfig}
                      onChange={onChangeExtraConfig}
                      formFields={botUI.config.formFields || []}
                    />
                  );
                }
                if (botUI.slug === 'voice-selector-elevenlabs')
                  return (
                    <VoiceSelector
                      botUIData={botUI?.config.voices}
                      voiceSelected={extraConfig.voice}
                      onVoiceSelect={onChangeExtraConfig}
                    />
                  );

                if (botUI.slug === 'style-selector-face-to-many') {
                  return (
                    <ListeSelector
                      slug={botUI.slug}
                      botUIData={botUI?.config.style}
                      selected={extraConfig.style}
                      onSelect={onChangeExtraConfig}
                      keyField="style"
                    />
                  );
                }

                return;
              })}
            </CustomBox>
          ) : null}
        </CustomBox>
      </CustomBox>
    </>
  );
};

export default BotUI;
