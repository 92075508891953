import { Divider, useTheme } from '@mui/material';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import DataSourceIcon from '../../../../SideBar/SvgIcons/DataSourceIcon';
import EastIcon from '@mui/icons-material/East';
import { ChatDataSource } from '../../../../../../gql/graphql';
import { Link, useParams } from 'react-router-dom';

interface SearchDatasourcesListProps {
  dataSource: ChatDataSource[];
}
const SearchDatasourcesList = ({ dataSource }: SearchDatasourcesListProps) => {
  const theme = useTheme();
  const { workspaceId } = useParams();
  return (
    <CustomBox>
      {dataSource?.map((data: any, i) => (
        <Link
          key={i}
          style={{ color: 'inherit', textDecoration: 'none' }}
          to={`../../../${workspaceId}/datasources/detail/${data.id}`}
        >
          <CustomBox
            display={'flex'}
            width={'100%'}
            p={theme.spacing(1)}
            gap={1}
            sx={{
              '&:hover': {
                cursor: 'pointer',
                fontWeight: 'bold',
                '& .MuiTypography-root': {
                  fontWeight: 'bold',
                },
              },
            }}
          >
            <DataSourceIcon
              color={
                theme.palette.mode === 'dark'
                  ? 'white'
                  : theme.palette.text.disabled
              }
            />
            <CustomTypography
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              fontWeight={'inherit'}
              color={'inherit'}
            >
              {data.name}
            </CustomTypography>
            <EastIcon sx={{ ml: 'auto', width: '18px', fontWeight: 'bold' }} />
          </CustomBox>
          <Divider />
        </Link>
      ))}
    </CustomBox>
  );
};
export default SearchDatasourcesList;
