import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '../../../gql';

const UPDATE_TODO_DESCRIPTION = graphql(`
  mutation UpdateTodoDescription($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      description
    }
  }
`);

const UPDATE_TODO_DESCRIPTION_LITE = graphql(`
  mutation UpdateTodoDescriptionLite($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
    }
  }
`);

export const GET_TODO_DESCRIPTION = graphql(`
  query TodoDesc($todoId: Float!) {
    todo(id: $todoId) {
      id
      description
    }
  }
`);

function useGetTodoDescription({ todoID }: { todoID: number }) {
  const [updateTodoDescription] = useMutation(UPDATE_TODO_DESCRIPTION);
  const [updateTodoDescriptionLite] = useMutation(UPDATE_TODO_DESCRIPTION_LITE);
  const { data, refetch, loading } = useQuery(GET_TODO_DESCRIPTION, {
    variables: {
      todoId: todoID,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleUpdateTodoDescription = (description: string) => {
    updateTodoDescription({
      variables: {
        updateTodoInput: {
          id: todoID,
          description,
        },
      },
    });
  };
  const handleUpdateTodoDescriptionLite = (description: string) => {
    updateTodoDescriptionLite({
      variables: {
        updateTodoInput: {
          id: todoID,
          description,
        },
      },
    });
  };
  const refetchTodoDescription = () => {
    refetch();
  };

  return {
    handleUpdateTodoDescription,
    data,
    handleUpdateTodoDescriptionLite,
    refetchTodoDescription,
    loading
  };
}

export default useGetTodoDescription;
