import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

const UPDATE_CHAT_SESSION = graphql(`
  mutation UpdateChatSessionData($data: TodoChatSessionInput!, $id: Float!) {
    updateTodoChatSession(data: $data, id: $id) {
      id
      title
      autoReplyLMM
      defaultBot {
        id
        name
        slug
        description
        supportedContentTypes {
          id
          extension
          type
        }
        user {
          id
          firstName
          lastName
          profilePicture
        }
        illustrationUrl
      }
    }
  }
`);

const useChatSession = () => {
  const [updateChatSession, { loading }] = useMutation(UPDATE_CHAT_SESSION);

  return { updateChatSession, loading };
};

export default useChatSession;
