import React from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useSubscribeAgentToolCall from './useSubscribeAgentToolCall';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

interface AgentToolCallDisplayProps {
  sessionId: number;
}

const AgentToolCallDisplay: React.FC<AgentToolCallDisplayProps> = ({
  sessionId,
}) => {
  const { agentToolCall } = useSubscribeAgentToolCall({ sessionId });

  if (!agentToolCall) {
    return null;
  }
  if (agentToolCall.status !== 'START') {
    return null;
  }

  return (
    <CustomBox sx={{}}>
      <CustomTypography variant="h6">
        <FormattedMessage
          id="agent.skill.call.indication"
          values={{ skillName: agentToolCall.tool }}
        />
      </CustomTypography>
    </CustomBox>
  );
};

export default React.memo(AgentToolCallDisplay);
