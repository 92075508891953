export const formatText = (text: string | undefined | null): string => {
  if (typeof text !== 'string' || !text.trim()) {
    return '';
  }

  const words = text.split(' ');

  return words
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()
    )
    .join(' ');
};
