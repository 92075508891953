import { FC } from 'react';
import { graphql } from '../../gql';
import { useQuery } from '@apollo/client';
const GET_ALL_EXTERNAL_APP = graphql(
  `
    query GetAllExternalApps {
      externalApps {
        id
        name
        slug
        description
        icon
      }
    }
  `
);

const useGetAllExternalApps = () => {
  const { data, loading } = useQuery(GET_ALL_EXTERNAL_APP, {
    fetchPolicy: 'network-only',
  });
  const externalApps = data?.externalApps || [];
  return {
    externalApps,
    loading,
  };
};

export default useGetAllExternalApps;
