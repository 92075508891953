import { graphql } from '../gql';

export const UserPublicInfoFragment = graphql(`
  fragment UserPublicInfoFragment on User {
    id
    firstName
    lastName
    email
    profilePicture
    isBot
  }
`);
