// FC component

import React from 'react';
import Box from '@mui/material/Box';
import { graphql } from '../../../../gql';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import ProjectMenuList from './ProjectMenuList/ProjectMenuList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Divider, useTheme, styled, Stack } from '@mui/material';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import { Link, useNavigate } from 'react-router-dom';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import ProjectSectionMenuList from './ProjectSectionMenuList/ProjectSectionMenuList';

// Get task id as props

const TypographyStyled = styled(CustomTypography)(({ theme }) => ({
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
}));
interface IProps {
  taskId: number;
}

// Query to get todo project using todo query

const GET_TODO_PROJECT = graphql(`
  query getTodoProject($taskId: Float!) {
    todo(id: $taskId) {
      id
      project {
        id
        name
      }
      projectSection {
        id
        name
      }
    }
  }
`);

const UPDATE_TODO_PROJECT = graphql(`
  mutation updateTodoProject($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      project {
        id
        name
      }
      projectSection {
        id
        name
      }
    }
  }
`);

const ToDoProject: React.FC<IProps> = ({ taskId }) => {
  // Get todo project using todo query
  const [anchorElProject, setAnchorElProject] =
    React.useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const [anchorElSection, setAnchorElSection] =
    React.useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [openProject, setOpenProject] = React.useState(false);
  const [openSection, setOpenSection] = React.useState(false);

  const { data, loading } = useQuery(GET_TODO_PROJECT, {
    variables: {
      taskId,
    },
  });

  const [updateTodoProject, { loading: isLoadingEdit }] = useMutation(
    UPDATE_TODO_PROJECT,
    {
      update: (cache, { data }) => {
        // Mixpanel track
        Mixpanel.track('Update todo project', {
          taskId,
          projectId: data?.updateTodo?.project?.id,
        });
      },
    }
  );
  React.useEffect(() => {
    if (data?.todo?.project) {
      const handlePopstate = () => {
        navigate(`../../project/${data?.todo?.project?.id}`);
      };

      window.addEventListener('popstate', handlePopstate);

      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }
  }, [data?.todo?.project]);
  // Return null if loading
  if (loading) {
    return null;
  }

  // Handle open popover to select other project

  const handleOpenPopoverProject = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElProject(event.currentTarget);
    setOpenProject(true);
  };
  const handleOpenPopoverSection = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElSection(event.currentTarget);
    setOpenSection(true);
  };
  const handleCloseProject = () => {
    setAnchorElProject(null);
    setOpenProject(false);
  };
  const handleCloseSection = () => {
    setAnchorElSection(null);
    setOpenSection(false);
  };
  const handleSelectProject = (project: any) => {
    updateTodoProject({
      variables: {
        updateTodoInput: {
          id: taskId,
          projectId: project.id,
        },
      },
    });
  };

  const handleSelectProjectSection = (section: any) => {
    updateTodoProject({
      variables: {
        updateTodoInput: {
          id: taskId,
          projectSectionId: section.id,
        },
      },
    });
  };
  // Return component to display todo project name and add icon at the right side to open a popover to select other project
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      display="flex"
      flexWrap="wrap"
      mt={1}
      sx={{ px: (theme) => theme.spacing(2) }}
      id="chat-project-task"
    >
      <Box width={'40%'}>
        <CustomTypography variant="h6" color="#34324A" fontWeight="700">
          <FormattedMessage
            id="chat.description.todoProject.title"
            defaultMessage="Project"
          />
        </CustomTypography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TypographyStyled
            variant="body2"
            sx={{ overflow: 'hidden', flex: 1, textOverflow: 'ellipsis' }}
            textTransform="capitalize"
            fontSize={12}
          >
            {data?.todo?.project ? (
              <Link
                to={`../../project/${data?.todo?.project?.id}`}
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                {data.todo.project.name.toLowerCase()}
              </Link>
            ) : (
              <FormattedMessage
                id="chat.description.todoProject.noProject"
                defaultMessage="No project"
              />
            )}
          </TypographyStyled>

          <IconButton
            id="demo-customized-button"
            aria-controls={openProject ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openProject ? 'true' : undefined}
            style={{ backgroundColor: 'transparent' }}
            onClick={handleOpenPopoverProject}
          >
            {openProject ? (
              <KeyboardArrowUpIcon color="action" />
            ) : (
              <KeyboardArrowDownIcon color="action" />
            )}
          </IconButton>
          <ProjectMenuList
            selectedProject={data?.todo?.project?.name}
            anchorElProject={anchorElProject}
            handleSelectProject={handleSelectProject}
            handleCloseProject={handleCloseProject}
          />
        </Box>
      </Box>
      {data?.todo?.project && (
        <>
          <Divider
            orientation="vertical"
            sx={{
              ml: 2,
              height: 25,
              mt: 0.3,
              borderLeft: `1px ridge ${theme.palette.grey[200]}`,
            }}
            flexItem
          />
          <Box ml={2} width="40%">
            <CustomTypography variant="h6" color="#34324A" fontWeight="700">
              <FormattedMessage
                id="chat.description.todoProject.section"
                defaultMessage="Section"
              />
            </CustomTypography>
            <Box
              sx={{
                display: 'flex',

                alignItems: 'center',
              }}
            >
              {' '}
              {data?.todo?.projectSection ? (
                <TypographyStyled
                  variant="body2"
                  sx={{ overflow: 'hidden', flex: 1, textOverflow: 'ellipsis' }}
                  textTransform="capitalize"
                  fontSize={12}
                >
                  {data?.todo?.projectSection?.name}
                </TypographyStyled>
              ) : (
                <CustomTypography
                  variant="body2"
                  textTransform="capitalize"
                  fontSize={12}
                >
                  <FormattedMessage
                    id="chat.description.todoProject.no.section"
                    defaultMessage=" No Section"
                  />
                </CustomTypography>
              )}
              <IconButton
                id="demo-customized-button"
                aria-controls={openSection ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSection ? 'true' : undefined}
                style={{ backgroundColor: 'transparent' }}
                onClick={handleOpenPopoverSection}
              >
                {openSection ? (
                  <KeyboardArrowUpIcon color="action" />
                ) : (
                  <KeyboardArrowDownIcon color="action" />
                )}
              </IconButton>
              {
                <ProjectSectionMenuList
                  anchorElSection={anchorElSection}
                  selectedProjectSection={data?.todo?.projectSection?.name}
                  projectId={data?.todo?.project?.id}
                  handleSelectProjectSection={handleSelectProjectSection}
                  handleCloseSection={handleCloseSection}
                />
              }
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ToDoProject;
