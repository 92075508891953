import { useIntl } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import BotTagsList from '../BotTagsList/BotTagsList';
import { BoxProps, useTheme } from '@mui/material';
import CustomSearchInput from '../../../../common/CustomSearchInput/CustomSearchInput';
import useGetSearchBots from '../../../../../Hooks/useGetSearchBots';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';
import useChatSession from '../../../hooks/useChatSession';
import useGetChatSessionDefaultBot from '../../../hooks/useGetChatSessionDefaultBot';
import CustomIconButton from '../../../../common/CustomIconButton/CustomIconButton';
import Close from '@mui/icons-material/Close';
import { useContextFormChat } from '../../../hooks/useContextFormChat';
import ListBotsSearch from '../../ListBotsSearch/ListBotsSearch';
import { Bot } from '../../../../../gql/graphql';
import React, { useEffect } from 'react';

interface props extends BoxProps {
  isDisplayBotsTag?: boolean;
  todoId?: number;
  isDisplaySearch?: boolean;
  onBotClicked?(bot: any): void;
  shouldCreateSession?: boolean;
  handleCloseSearchDialog?(): void;
  botTagSlug?: string;
}
const ListBotByTags = ({
  isDisplayBotsTag,
  onBotClicked,
  todoId,
  handleCloseSearchDialog,
  isDisplaySearch = true,
  shouldCreateSession = false,
  botTagSlug,
  ...rest
}: props) => {
  const theme = useTheme();
  const intl = useIntl();
  const { updateChatSession } = useChatSession();
  const { resetExtraConfig } = useContextFormChat();
  const { data: dataSourceSession } = useGetChatSessionDefaultBot();
  const {
    data,
    loading,
    handleBotClick: createChatSessionAndGo,
    onTagClick,
    onChangeInput,
    inputValue,
    tagSlug,
  } = useGetSearchBots({
    todoId,
    onBotClick: (bot: any) => {
      onBotClicked?.(bot);
    },
  });

  useEffect(() => {
    if (botTagSlug) {
      onTagClick({ slug: botTagSlug });
    }
    return;
  }, [botTagSlug]);

  if (loading && !isDisplaySearch) {
    return null;
  }

  const onBotClick = (bot: any) => {
    updateChatSession({
      variables: {
        id: dataSourceSession?.getOneTodoChatSession?.id || 0,
        data: {
          defaultBotId: bot.id,
        },
      },
    });
    onBotClicked?.(bot);
    Mixpanel.track('Select Default LMM Chat', {
      botSlug: bot.slug,
    });
    resetExtraConfig();
  };
  const handleBotClick = (bot: any) => {
    if (!shouldCreateSession) {
      onBotClick(bot);
    } else {
      createChatSessionAndGo(bot);
    }
    resetExtraConfig();
  };

  return (
    <CustomBox>
      {isDisplaySearch && (
        <CustomBox
          id="search-bot-input"
          display={'flex'}
          gap={1}
          width={'100%'}
        >
          <CustomBox sx={{ width: '100%' }}>
            <CustomSearchInput
              loading={loading}
              autoFocus={true}
              searchValue={inputValue || ''}
              searchInputPlaceholder={intl.formatMessage({
                id: 'search.bot.placeholder',
              })}
              onChangeInputSearch={onChangeInput}
            />
          </CustomBox>
          {handleCloseSearchDialog && (
            <CustomIconButton
              onClick={handleCloseSearchDialog}
              size="small"
              sx={{
                padding: 0.2,
                cursor: 'pointer',
                width: '40px',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '4px',
              }}
            >
              <Close fontSize="small" />
            </CustomIconButton>
          )}
        </CustomBox>
      )}

      {isDisplayBotsTag && (
        <CustomBox mt={2} mb={2}>
          <BotTagsList
            onItemClick={(id: number) => onTagClick?.(id)}
            tagSlug={tagSlug || ''}
          />
        </CustomBox>
      )}

      <ListBotsSearch
        loading={loading}
        bots={data?.getSearchBots as Bot[]}
        handleBotClick={handleBotClick}
      />
    </CustomBox>
  );
};
export default React.memo(ListBotByTags);
