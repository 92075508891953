import { FC } from 'react';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
const STOP_AI_RESPONOSE = graphql(
  `
    mutation stopAIResponse($sessionId: Float!, $botId: Float!) {
      stopAIResponse(sessionId: $sessionId, botId: $botId)
    }
  `
);

const useStopAIResponse = () => {
  const [stopAIResponse, { loading }] = useMutation(STOP_AI_RESPONOSE);
  const stop = async (sessionId: number, botId: number) => {
    return stopAIResponse({
      variables: {
        sessionId,
        botId,
      },
    });
  };

  return {
    stop,
    loading,
  };
};

export default useStopAIResponse;
