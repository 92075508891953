import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LogoIcon from '../LogoIcon/LogoIcon';
import Title from '../Title/Title';
import { gql, useMutation } from '@apollo/client';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import AlertText from '../../common/AlertText/AlertText';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { StyledContainer } from '../Signup/Signup';

export const TitleValueForgotPassword: string = 'Forgot Password';

// Forgot mutation

const forgot_Password = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export default function ForgotPassword() {
  const [inputValue, setInput] = React.useState<{ email: string }>({
    email: '',
  }) as any;
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [isSubmit, setSubmit] = React.useState(false);

  // Mix panel page view event

  React.useEffect(() => {
    Mixpanel.track('Forgot password page view', { page: 'Forgot password' });
  }, []);

  // forgot appollo mutation

  const [forgotPassword, { loading, error }] = useMutation(forgot_Password, {
    update(cache, { data: { forgotPassword } }) {
      Mixpanel.track('Forgot password request', {});
    },
  });
  // Handle input change
  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const onChangeInputEmail: any = (e: React.FormEvent<HTMLInputElement>) => {
    setInput({ ...inputValue, [e.currentTarget.name]: e.currentTarget.value });
    setSubmit(false);
  };
  const handleSubmit = () => {
    setSubmit(true);
    if (emailRegex.test(inputValue.email)) {
      forgotPassword({
        variables: { email: inputValue.email },
        update: () => {
          setSubmit(true);
          setAlertSuccess(true);
        },
      });
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box>
          <LogoIcon />
        </Box>
        <Box>
          <Title TitleValue={'Forgot Password'} />
        </Box>
        <Box style={{ width: 310 }}>
          {isSubmit && !inputValue.email && (
            <AlertText
              severity={'warning'}
              message={'Enter a valid email address to reset your password.'}
            />
          )}
          {isSubmit &&
            inputValue.email &&
            !emailRegex.test(inputValue.email) && (
              <AlertText
                severity={'warning'}
                message={'Please enter a valid email address.'}
              />
            )}
          {isSubmit && alertSuccess && (
            <AlertText
              severity={'success'}
              message={
                'We have sent you an email with a link to reset your password. Please check your email.'
              }
            />
          )}
          {isSubmit && error && (
            <AlertText
              severity={'error'}
              message="An error occurred while requesting a password reset. Please try again. If the problem persists, please contact support."
            />
          )}
        </Box>
        <Box>
          <ForgotPasswordForm
            onSubmit={handleSubmit}
            inputValue={inputValue}
            onChange={onChangeInputEmail}
            buttonValue={
              alertSuccess ? 'Resend email' : loading ? 'Reseting...' : 'Reset'
            }
          />
        </Box>
      </Box>
    </StyledContainer>
  );
}
