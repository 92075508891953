import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useState } from 'react';

import {
  getAllCategoriesLabel,
  getAllStatusLabel,
} from '../DatasourceHeader/DatasourceSearchBarWithFilter/Menus/utils/constants';

interface useBotDatasourceFilterProps {
  rows: any[];
}

const useDataSourcesPageFilter = ({ rows }: useBotDatasourceFilterProps) => {
  const intl = useIntl();
  const ALL_CATEGORIES = getAllCategoriesLabel(intl);
  const ALL_STATUS = getAllStatusLabel(intl);

  const [queryString, setQueryString] = useState<string>('');
  const [currentCategory, setCurrentCategory] =
    useState<string>(ALL_CATEGORIES);
  const [currentStatus, setCurrentStatus] = useState<string>(ALL_STATUS);

  const filteredRows = rows.filter((dataSource) => {
    const matchesQuery =
      queryString === '' ||
      dataSource.name.toLowerCase().includes(queryString.toLowerCase());

    const matchesCategory =
      currentCategory === ALL_CATEGORIES ||
      (dataSource.category &&
        dataSource.category.trim().toLowerCase() ===
          currentCategory.trim().toLowerCase());

    const matchesStatus =
      currentStatus === ALL_STATUS ||
      !currentStatus ||
      dataSource.syncStatus === currentStatus;

    return matchesQuery && matchesCategory && matchesStatus;
  });

  const categories = _.uniq(_.map(rows, 'category'));
  const syncStatusList = _.uniq(_.map(rows, 'syncStatus'));

  return {
    queryString,
    categories,
    syncStatusList,
    filteredRows,
    setCurrentCategory,
    setQueryString,
    setCurrentStatus,
  };
};

export default useDataSourcesPageFilter;
