import { Menu, styled, Box, useTheme } from '@mui/material';
import CustomTypography from '../CustomTypography/CustomTypography';

export interface props {
  anchorEl: any;
  open: boolean;
  title?: string;
  handleClick?(): void;
  titleAction?: string;
  handleClose(): void;
  isDisable?: boolean;
  childrenContent: React.ReactNode;
  childrenAction?: React.ReactNode;
}
const MenuStyled = styled(Menu)(({ theme }) => ({
  borderRadius: '10px',
  marginTop: '10px',
  boxThadow: '0 3px 5px rgba(0, 0, 0, 0.3)',
  zIndex: theme.zIndex.drawer + 1,
}));

const MenuContainer = styled('div')(({ theme }) => ({
  marginTop: '-10px',
  marginBottom: '-8px',
  backgroundColor: theme.palette.background.default,
  padding: '8px',
  top: 0,
}));

const Title = styled(CustomTypography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const TitleAction = styled(CustomTypography)(({ theme }) => ({
  marginLeft: 'auto',
}));

const CustomPopover = ({
  anchorEl,
  handleClose,
  title,
  isDisable,
  open,
  childrenAction,
  childrenContent,
  handleClick,
  titleAction,
}: props) => {
  const theme = useTheme();
  return (
    <MenuStyled
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      disablePortal={true}
    >
      <MenuContainer>
        {(title?.trim() || titleAction?.trim()) && (
          <Box display={'flex'} width={'100%'} pr={theme.spacing(4)}>
            <Title>{title}</Title>
            <TitleAction
              sx={{
                color: isDisable
                  ? theme.palette.grey[200]
                  : theme.palette.primary.light,
                cursor: 'pointer',
              }}
              onClick={() => handleClick?.()}
            >
              {titleAction}
            </TitleAction>
          </Box>
        )}
        {childrenContent}
        <Box>{childrenAction}</Box>
      </MenuContainer>
    </MenuStyled>
  );
};

export default CustomPopover;
