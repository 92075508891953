// src/hooks/useDebounce.ts
import { useRef, useEffect, useCallback } from 'react';

const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<number | undefined>(undefined);

  const debouncedFunction = useCallback(
    (...args: any[]) => {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return debouncedFunction;
};

export default useDebounce;
