import { useTheme } from "@mui/material"

const TodayIcon = () => {
    const theme = useTheme()
    const color = theme.palette.mode === "dark" ? "#fff" : '#34324A'
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_236_230)">
                    <path d="M9.782 14H4C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H12C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V9.782" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 9.782L2 4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2L12 2C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4L14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14L6.218 14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 5.33333H2" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.49946 7.63334C4.49526 7.63325 4.49108 7.63399 4.48717 7.63552C4.48325 7.63704 4.47967 7.63933 4.47664 7.64224C4.47361 7.64515 4.47117 7.64863 4.46949 7.65248C4.4678 7.65632 4.46688 7.66047 4.4668 7.66467V7.66667C4.4668 7.67313 4.46871 7.67945 4.4723 7.68482C4.47589 7.69019 4.48099 7.69438 4.48696 7.69685C4.49293 7.69932 4.4995 7.69997 4.50584 7.69871C4.51217 7.69745 4.51799 7.69434 4.52256 7.68977C4.52713 7.6852 4.53024 7.67938 4.5315 7.67304C4.53276 7.66671 4.53212 7.66014 4.52964 7.65417C4.52717 7.6482 4.52298 7.6431 4.51761 7.63951C4.51224 7.63592 4.50592 7.63401 4.49946 7.63401" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.53346 7.66666C4.53346 7.67326 4.53151 7.6797 4.52785 7.68518C4.52418 7.69067 4.51898 7.69494 4.51289 7.69746C4.5068 7.69998 4.50009 7.70064 4.49363 7.69936C4.48716 7.69807 4.48122 7.6949 4.47656 7.69024C4.4719 7.68557 4.46872 7.67963 4.46744 7.67317C4.46615 7.6667 4.46681 7.66 4.46933 7.65391C4.47186 7.64782 4.47613 7.64261 4.48161 7.63895C4.48709 7.63529 4.49354 7.63333 4.50013 7.63333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.5 7.63333C4.50884 7.63333 4.51732 7.63684 4.52357 7.64309C4.52982 7.64935 4.53333 7.65782 4.53333 7.66666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.86745 7.66666C6.86745 7.67326 6.86549 7.6797 6.86183 7.68518C6.85817 7.69067 6.85296 7.69494 6.84687 7.69746C6.84078 7.69998 6.83408 7.70064 6.82761 7.69936C6.82115 7.69807 6.81521 7.6949 6.81054 7.69024C6.80588 7.68557 6.80271 7.67963 6.80142 7.67317C6.80014 7.6667 6.8008 7.66 6.80332 7.65391C6.80584 7.64782 6.81011 7.64261 6.8156 7.63895C6.82108 7.63529 6.82752 7.63333 6.83411 7.63333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.83398 7.63333C6.84282 7.63333 6.8513 7.63684 6.85755 7.64309C6.86381 7.64935 6.86732 7.65782 6.86732 7.66666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.49946 9.63334C4.49526 9.63325 4.49108 9.63399 4.48717 9.63552C4.48325 9.63704 4.47967 9.63933 4.47664 9.64224C4.47361 9.64515 4.47117 9.64863 4.46949 9.65248C4.4678 9.65632 4.46688 9.66047 4.4668 9.66467V9.66667C4.4668 9.67313 4.46871 9.67945 4.4723 9.68482C4.47589 9.69019 4.48099 9.69438 4.48696 9.69685C4.49293 9.69932 4.4995 9.69997 4.50584 9.69871C4.51217 9.69745 4.51799 9.69434 4.52256 9.68977C4.52713 9.6852 4.53024 9.67938 4.5315 9.67304C4.53276 9.66671 4.53212 9.66014 4.52964 9.65417C4.52717 9.6482 4.52298 9.6431 4.51761 9.63951C4.51224 9.63592 4.50592 9.63401 4.49946 9.63401" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.53346 9.66666C4.53346 9.67326 4.53151 9.6797 4.52785 9.68518C4.52418 9.69067 4.51898 9.69494 4.51289 9.69746C4.5068 9.69998 4.50009 9.70064 4.49363 9.69936C4.48716 9.69807 4.48122 9.6949 4.47656 9.69024C4.4719 9.68557 4.46872 9.67963 4.46744 9.67317C4.46615 9.6667 4.46681 9.66 4.46933 9.65391C4.47186 9.64782 4.47613 9.64261 4.48161 9.63895C4.48709 9.63529 4.49354 9.63333 4.50013 9.63333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.5 9.63333C4.50884 9.63333 4.51732 9.63684 4.52357 9.64309C4.52982 9.64935 4.53333 9.65782 4.53333 9.66666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.86745 9.66666C6.86745 9.67326 6.86549 9.6797 6.86183 9.68518C6.85817 9.69067 6.85296 9.69494 6.84687 9.69746C6.84078 9.69998 6.83408 9.70064 6.82761 9.69936C6.82115 9.69807 6.81521 9.6949 6.81054 9.69024C6.80588 9.68557 6.80271 9.67963 6.80142 9.67317C6.80014 9.6667 6.8008 9.66 6.80332 9.65391C6.80584 9.64782 6.81011 9.64261 6.8156 9.63895C6.82108 9.63529 6.82752 9.63333 6.83411 9.63333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.83203 9.6333C6.84087 9.6333 6.84935 9.63681 6.8556 9.64306C6.86185 9.64932 6.86536 9.65779 6.86536 9.66663" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5 9.6333C10.5088 9.6333 10.5173 9.63681 10.5236 9.64306C10.5298 9.64932 10.5333 9.65779 10.5333 9.66663" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.49946 11.6333C4.49098 11.6332 4.48278 11.6364 4.47665 11.6422C4.47053 11.6481 4.46698 11.6562 4.4668 11.6646V11.6667C4.4668 11.6731 4.46871 11.6794 4.4723 11.6848C4.47589 11.6902 4.48099 11.6944 4.48696 11.6969C4.49293 11.6993 4.4995 11.7 4.50584 11.6987C4.51217 11.6974 4.51799 11.6943 4.52256 11.6898C4.52713 11.6852 4.53024 11.6794 4.5315 11.673C4.53276 11.6667 4.53212 11.6601 4.52964 11.6542C4.52717 11.6482 4.52298 11.6431 4.51761 11.6395C4.51224 11.6359 4.50592 11.6333 4.49946 11.6333ZM4.49946 11.6333L4.4988 11.634" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.53346 11.6667C4.53346 11.6733 4.53151 11.6797 4.52785 11.6852C4.52418 11.6907 4.51898 11.6949 4.51289 11.6975C4.5068 11.7 4.50009 11.7006 4.49363 11.6994C4.48716 11.6981 4.48122 11.6949 4.47656 11.6902C4.4719 11.6856 4.46872 11.6796 4.46744 11.6732C4.46615 11.6667 4.46681 11.66 4.46933 11.6539C4.47186 11.6478 4.47613 11.6426 4.48161 11.6389C4.48709 11.6353 4.49354 11.6333 4.50013 11.6333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.5 11.6333C4.50884 11.6333 4.51732 11.6368 4.52357 11.6431C4.52982 11.6493 4.53333 11.6578 4.53333 11.6667" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.86745 11.6667C6.86745 11.6733 6.86549 11.6797 6.86183 11.6852C6.85817 11.6907 6.85296 11.6949 6.84687 11.6975C6.84078 11.7 6.83408 11.7006 6.82761 11.6994C6.82115 11.6981 6.81521 11.6949 6.81054 11.6902C6.80588 11.6856 6.80271 11.6796 6.80142 11.6732C6.80014 11.6667 6.8008 11.66 6.80332 11.6539C6.80584 11.6478 6.81011 11.6426 6.8156 11.6389C6.82108 11.6353 6.82752 11.6333 6.83411 11.6333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.83203 11.6333C6.84087 11.6333 6.84935 11.6368 6.8556 11.6431C6.86185 11.6493 6.86536 11.6578 6.86536 11.6666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.5 11.6333C11.5088 11.6333 11.5173 11.6368 11.5236 11.6431C11.5298 11.6493 11.5333 11.6578 11.5333 11.6666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.19948 7.66666C9.19948 7.67326 9.19752 7.6797 9.19386 7.68518C9.1902 7.69067 9.18499 7.69494 9.1789 7.69746C9.17281 7.69998 9.16611 7.70064 9.15964 7.69936C9.15318 7.69807 9.14724 7.6949 9.14258 7.69024C9.13791 7.68557 9.13474 7.67963 9.13345 7.67317C9.13217 7.6667 9.13283 7.66 9.13535 7.65391C9.13787 7.64782 9.14215 7.64261 9.14763 7.63895C9.15311 7.63529 9.15955 7.63333 9.16615 7.63333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.16602 7.63333C9.17486 7.63333 9.18333 7.63684 9.18959 7.64309C9.19584 7.64935 9.19935 7.65782 9.19935 7.66666" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_236_230">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default TodayIcon