import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { FormattedMessage } from 'react-intl';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


interface props {
    label: any
    callback(): void
    open: boolean
    variant: 'error' | 'info' | 'success' | 'warning'
}
export default function SnackBar({ label, open, callback, variant }: props) {
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        callback();
    };
    return (
        <Snackbar open={open}  autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
                {label}
            </Alert>
        </Snackbar>
    );
}