import { IconButton, styled } from '@mui/material';
import Box from '@mui/system/Box';
import config from '../../../config/config';

// Styled box for right container, scrollable and fixed height based on viewport
export const LayoutChatContainer = styled(Box)<{ hasExtraConfig: boolean }>(
  ({ theme, hasExtraConfig }) => ({
    position: 'relative',
    width: '100%',
    display: 'grid',
    transition: 'all 0.5s',
    overflow: 'hidden',
    top: '0',
    gridTemplateColumns: hasExtraConfig
      ? 'auto 770px minmax(0, 30%)'
      : 'auto 770px minmax(0, auto)',
    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: hasExtraConfig
        ? 'auto 770px 35%'
        : 'auto 770px auto', // Adjust the grid template columns based on the hasExtraConfig prop
    },
    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: '10% 80% 10%',
    },
    [theme.breakpoints.down(750)]: {
      height: 'calc(100vh - 44px)',

      display: 'block',
    },
  })
);

// Styled box for right container, scrollable and fixed height based on viewport
export const LayoutChatAndFormContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100vh',
  width: '100%',
  margin: '0 auto', // Center the grid
  gap: 1, // spacing between items
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.5s',
  [theme.breakpoints.down(1300)]: {
    display: 'block',
  },
  [theme.breakpoints.down(750)]: {
    height: 'calc(100vh - 44px)',
    display: 'block',
  },
}));

export const ChatAndFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  transition: 'all 0.3s',
  width: '100%',
  [theme.breakpoints.down(1300)]: {
    position: 'absolute',
    top: 0,
  },
}));

export const ChatAndFormConfigContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  [theme.breakpoints.down(1300)]: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: config.zIndex.NIVEAU_5,
  },
}));

// Styled box for right container, scrollable and fixed height based on viewport
export const ConfigContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  zIndex: config.zIndex.NIVEAU_5,
  [theme.breakpoints.down(1300)]: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down(750)]: {
    display: 'block',
  },
}));

export const ChatContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  transition: 'all 0.3s',
  [theme.breakpoints.down(1300)]: {
    paddingLeft: theme.spacing(1),
  },
  [theme.breakpoints.down(750)]: {
    width: '100%',
  },
}));

export const IConButtonClose = styled(IconButton)(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  zIndex: 100,
  top: theme.spacing(1),
  right: theme.spacing(2),
  [theme.breakpoints.down(1300)]: {
    display: 'block',
  },
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(0.5),
    right: theme.spacing(1),
  },
}));
