import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../gql';

const GET_PROJECT = graphql(`
  query GetProjectBreadCumb($todoChatId: Float!) {
    todoChat(id: $todoChatId) {
      todo {
        project {
          name
          id
        }
      }
    }
  }
`);

const useGetProjectBreadCumb = () => {
  const { taskID } = useParams();
  const { data, error, loading } = useQuery(GET_PROJECT, {
    variables: {
      todoChatId: +(taskID||0)
    },
    fetchPolicy: 'cache-first',
  });
  return { data, error, loading };
};

export default useGetProjectBreadCumb;
