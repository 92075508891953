import React from 'react';
import useSwiftaskTokenPrices from '../../Chat/hooks/useSwiftaskTokenPrices';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import CustomBox from '../../common/CustomBox/CustomBox';
import CustomBagde from '../../common/CustomBagde/CustomBagde';
import CustomButton from '../../common/CustomButton/CustomButton';
import Loading from '../../common/Loading/Loading';
import { formatNumber } from '../../../utils/numberUtil';
import { FormattedMessage } from 'react-intl';

const SwiftaskCreditPurshase = () => {
  const { loading, swiftaskTokenPrices, onClickPurchase } =
    useSwiftaskTokenPrices();

  if (loading) {
    return (
      <CustomBox>
        <Loading />
      </CustomBox>
    );
  }

  return (
    <CustomBox>
      <CustomBox mb={2}>
        <CustomTypography variant="h6" fontWeight="700">
          <FormattedMessage id="credit.purchase.title" />
        </CustomTypography>
      </CustomBox>
      <Grid container spacing={4}>
        {swiftaskTokenPrices.map((pack: (typeof swiftaskTokenPrices)[0]) => (
          <Grid item xs={12} sm={6} md={4} key={pack.id}>
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  paddingTop: '30px',
                }}
              >
                {pack.percentageGain ? (
                  <CustomBox
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: -5,
                    }}
                  >
                    <CustomBagde>+{pack.percentageGain} %</CustomBagde>
                  </CustomBox>
                ) : null}

                <CustomBox mt={2}>
                  <CustomTypography
                    variant="h1"
                    component="div"
                    sx={{
                      fontSize: 36,
                    }}
                  >
                    {formatNumber(pack.quantity)}
                  </CustomTypography>
                </CustomBox>
                <CustomBox mt={1}>
                  <CustomTypography
                    variant="h4"
                    component="div"
                    sx={{
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    <FormattedMessage id="credit.purchase.price" />
                  </CustomTypography>
                </CustomBox>
                <CustomBox mt={1}>
                  <CustomTypography variant="h2" component="div">
                    €{pack.price}
                  </CustomTypography>
                </CustomBox>

                <CustomBox mt={2}>
                  <CustomButton
                    variant="contained"
                    sx={{
                      width: '100%',
                      bgcolor: pack.btnColor,
                    }}
                    onClick={() => onClickPurchase(pack.id)}
                    isLoading={loading}
                    disabled={loading}
                  >
                    {pack.buttonText}
                  </CustomButton>
                </CustomBox>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </CustomBox>
  );
};

export default SwiftaskCreditPurshase;
