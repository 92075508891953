/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Grid from '@mui/material/Grid';
import InputForm from '../../../MyDay/FormAddTask/InputForm/InputForm';
import BodyCalendar from './BodyCalendar/BodyCalendar';
import { Divider } from '@mui/material';
import CalendarPicker from './CalendarPicker/CalendarPicker';
import customDateEvent from '../../../../utils/customDateEvent';
import moment from 'moment';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import { dueDateProps } from '../../../../types/DueDates';

interface props {
  handleSetDueDate(duedate: dueDateProps): void;
  dueDate: dueDateProps;
}

export default function CalendarDueDate({ handleSetDueDate, dueDate }: props) {
  const parseDate = (date: string | number | Date) => {
    if (date) {
      if (moment(date).isValid()) {
        return moment(customDateEvent(date)).format('D MMM');
      }
    }
    return moment(customDateEvent(new Date())).format('D MMM');
  };

  const [inputDate, setInputDate] = React.useState(parseDate(dueDate.value));

  const resetDueDate = () => {
    // Mixpanel trac
    handleSetDueDate({ label: 'Due Date', value: '0' });
  };
  const handleSetDueDateAction = (data: dueDateProps) => {
    // Mixpanel track
    Mixpanel.track('Set Due Date', {});
    handleSetDueDate(data);
  };
  return (
    <Grid container>
      <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
        <InputForm
          multiline={false}
          name="dueDate"
          style={{ padding: '0px 10px 5px 10px', fontSize: '12px' }}
          placeholder="Type a due date"
          onChange={(e) => {
            if (e.target.value) {
              /* Don't do that logic yet */
            }
          }}
          value={inputDate}
          onKeyPress={() => {}}
        />
      </Grid>
      <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
        <BodyCalendar
          handleSetDueDate={handleSetDueDateAction}
          resetDueDate={resetDueDate}
        />
      </Grid>
      <Grid item lg={12} xl={12} md={12} xs={12} sm={12} width="100%">
        <Divider />
      </Grid>
      <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
        <CalendarPicker
          dueDate={dueDate}
          handleSetDueDate={handleSetDueDateAction}
        />
      </Grid>
      <Grid item lg={12} xl={12} md={12} xs={12} sm={12}>
        <Divider />
      </Grid>
      {/* <Grid item lg={12} xl={12} md={12} xs={12} sm={12} mt={1} ml={1}>
                <Button variant="text" color="primary">
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        textTransform="none"
                        color="primary">
                        +  Add time
                    </Typography>
                </Button>
            </Grid> */}
    </Grid>
  );
}
