import moment from "moment";
const messagesByDate = (messages:any) =>{
    return messages.reduce((acc:any, message:any) => {
        let date = moment(message.createdAt).format('L')
        if (!acc[date]) {
          acc[date] = [];
        }
      
        acc[date].push(message);
      
        return acc;
    }, {});
}

export default messagesByDate