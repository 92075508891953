import { Divider, useTheme } from '@mui/material';
import Moment from 'react-moment';
interface props {
  date: string | Date | number;
}
const MessageDivider = ({ date }: props) => {
  const displayDate = new Date(date);
  const now = new Date();
  const fromat =
    displayDate.getFullYear() === now.getFullYear()
      ? 'MMMM DD'
      : 'MMMM DD YYYY';
  const theme = useTheme();
  const defaultCommonStyle = {
    color: theme.palette.grey[600],
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '400',
    width: '98%',
    fontFamily: 'Lato',
  };
  return (
    <Divider
      textAlign="center"
      variant="middle"
      sx={{
        '& 	.MuiDivider-wrapper': {
          marginTop: '-6px',
          pt: 0,
        },
      }}
    >
      <Moment format={fromat} style={defaultCommonStyle} date={displayDate} />
    </Divider>
  );
};

export default MessageDivider;
