import { useEffect, useState } from 'react';
import useGetGlobalSearch from '../../../../Hooks/useGetGlobalSearch';
import { useLocation } from 'react-router-dom';

export enum sectionSlug {
  todos,
  bots,
  dataSources,
  project
}
const useSearch = () => {
  const [isDisplaySearchDialog, setIsDisplaySearchDialog] = useState(false);
  const { data, loading, searchValue, onChangeInputSearch } =
    useGetGlobalSearch();
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState<sectionSlug>(
    sectionSlug.bots
  );

  useEffect(() => {
    handleCloseSearchDialog();
    setSelectedSection(sectionSlug.bots);
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'k') {
      event.preventDefault();
        handleOpenSearchDialog();
      }
    };
    if (!isDisplaySearchDialog) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDisplaySearchDialog]);

  const handleOpenSearchDialog = () => {
    setIsDisplaySearchDialog(true);
  };

  const handleCloseSearchDialog = () => {
    setIsDisplaySearchDialog(false);
    onChangeInputSearch({ target: { value: '' } });
  };

  const handleSelectSection = (section: sectionSlug) => {
    setSelectedSection(section);
  };

  return {
    data,
    loading,
    searchValue,
    selectedSection,
    onChangeInputSearch,
    handleSelectSection,
    isDisplaySearchDialog,
    handleOpenSearchDialog,
    handleCloseSearchDialog,
  };
};
export default useSearch;
