import * as React from 'react';
import { Box, Divider, Grid, IconButton, Paper, useTheme } from '@mui/material';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import useWindowDimensions from '../../../../../Hooks/useWindowDimensions';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MobileMenuSetting from '../../../SettingLayout/SettingSidebar/MobileMenuSetting/MobileMenuSetting';
import DrawerSetting from '../../../SettingLayout/SettingSidebar/DrawerSetting/DrawerSetting';
interface props {
  title: any;
  userEmail?: string;
  isSubHeader: boolean;
}
const Header = ({ title, isSubHeader, userEmail }: props) => {
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const handelMenu = () => {
    setOpenMenu(true);
  };
  const handelClose = () => {
    setOpenMenu(false);
  };
  React.useEffect(() => {
    if (Number(width) < 600) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [width]);
  return (
    <>
      <Box>
        <Box p={0.5} display={'flex'}>
          {open && !isSubHeader ? (
            <IconButton size="small" sx={{ mt: -0.5 }} onClick={handelMenu}>
              <KeyboardBackspaceIcon fontSize="small" />
            </IconButton>
          ) : null}

          <CustomTypography variant="h5" fontWeight={'bold'}>
            {title} {userEmail}
          </CustomTypography>
        </Box>

        <Divider
          sx={{ border: '1px solid', borderColor: theme.palette.divider }}
        />
      </Box>

      {!isSubHeader ? (
        <MobileMenuSetting
          open={openMenu}
          children={<DrawerSetting onClose={handelClose} />}
        />
      ) : null}
    </>
  );
};

export default Header;
