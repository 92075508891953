import { FC, useState } from 'react';
import useSTGDrive from './useSTGdrive';
import { FileType } from '../../../Hooks/useCreateFile';
import CustomBox from '../CustomBox/CustomBox';
import CustomButton from '../CustomButton/CustomButton';
import FileList from '../STFileForm/FileList';
import { Icon } from './icon';
import { ProviderSkeleton, SkeletonContent } from '../Skeleton/Skeleton';

interface IProps {
  onUpload?(files: FileType[]): void;
}

const STGoogleDrive: FC<IProps> = ({ onUpload }) => {
  const [files, setFiles] = useState<FileType[]>([]);

  const { handleOpenPicker, loading } = useSTGDrive({
    onUpload: (files) => {
      setFiles(files);
      if (onUpload) {
        onUpload(files);
      }
    },
  });

  const onDelete = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    if (onUpload) {
      onUpload(newFiles);
    }
  };
  return (
    <CustomBox>
      <CustomBox>
        <CustomButton
          variant="contained"
          onClick={handleOpenPicker}
          startIcon={<Icon />}
        >
          Google drive
        </CustomButton>
      </CustomBox>
      {loading ? (
        <ProviderSkeleton>
          <CustomBox mb={1} width={300}>
            <SkeletonContent height={30} />
          </CustomBox>
          <CustomBox mb={1} width={300}>
            <SkeletonContent height={30} />
          </CustomBox>
          <CustomBox width={300}>
            <SkeletonContent height={30} />
          </CustomBox>
        </ProviderSkeleton>
      ) : (
        <CustomBox>
          <FileList files={files} onDelete={onDelete} />
        </CustomBox>
      )}
    </CustomBox>
  );
};

export default STGoogleDrive;
