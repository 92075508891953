import Avatar from '@mui/material/Avatar/Avatar';
import UserAvatar from '../../../../../../common/UserAvatar/UserAvatar';
interface PropsBotAvatar {
  handleAutoMention?(): void;
  userProfil?: string;
  avatarSize?: 'large' | 'small';
}
const BotAvatar = ({
  userProfil,
  avatarSize,
}: PropsBotAvatar) => {
  return (
    <Avatar
      sx={
        avatarSize === 'large'
          ? { width: 40, height: 40,backgroundColor:'transparent' }
          : { width: 24, height: 24,backgroundColor:'transparent' }
      }
      variant="rounded"
    >
      <UserAvatar
        sx={avatarSize === 'large' && { width: 40, height: 40 }}
        userProfil={userProfil}
      />
    </Avatar>
  );
};
export default BotAvatar;
