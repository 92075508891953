import * as React from 'react';
import { Box, TextField } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { useIntl } from 'react-intl';
import { spanTagName } from '../../../../../utils/promptUtil';

interface props {
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label: string;
  color: string;
}
const CustomPromptsField = ({ value, onChange, label, color }: props) => {
  const intl = useIntl();
  return (
    <CustomBox>
      <Box style={{ maxWidth: '100%' }}>
        <CustomTypography>
          <span
            dangerouslySetInnerHTML={{
              __html: spanTagName(label, color),
            }}
          />
        </CustomTypography>
      </Box>
      <Box mt={0.5}>
        <TextField
          fullWidth
          required
          variant="outlined"
          name={label}
          value={value}
          onChange={onChange}
          size="small"
          type="text"
          multiline
          placeholder={intl.formatMessage({
            id: 'libraries.prompts.field.placeholder',
          })}
        />
      </Box>
    </CustomBox>
  );
};

export default CustomPromptsField;
