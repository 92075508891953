import { useContext, useState } from 'react';
import { CATEGORY_PROMPTS_FRAGMENT } from '../../../../../../../fragements/categoryPrompts';
import { FragmentType, useFragment } from '../../../../../../../gql';
import STList from '../../../../../../common/STList/STList';
import { StepContext } from '../../../../../Prompts';
import { find, filter } from 'lodash';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContainerPrompts from '../../../ContainerPrompts/ContainerPrompts';

interface props {
  categories?: FragmentType<typeof CATEGORY_PROMPTS_FRAGMENT>[] | null;
  isMobile: boolean;
  handleSelectedPrompt(id: number): void;
  prompts: any[];
  defaultSelectedId: number;
  loading: boolean;
}

const ListCaterogiePrompts = ({
  categories,
  isMobile,
  handleSelectedPrompt,
  prompts,
  defaultSelectedId,
  loading,
}: props) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const categoriesDataFragment = useFragment(
    CATEGORY_PROMPTS_FRAGMENT,
    categories
  );

  const {
    updateStep,
    handleNextStep,
    lastStep,
    handlePreviousStep,
    idCategorie,
  } = useContext(StepContext);

  const handleChange = (
    categoryName: string,
    idCategorie: number,
    isCustomCategory: boolean | null | undefined
  ) => {
    updateStep({
      activeStep: 0,
      lastStep,
      idPrompts: 0,
      categoryName,
      idCategorie,
      isCustomCategory,
      updateStep,
      handleNextStep,
      handlePreviousStep,
    });
  };

  const filteredItems = filter(
    categoriesDataFragment,
    (item) => !item.isCustomWorkspace
  );
  const customItem = find(categoriesDataFragment, { isCustomWorkspace: true });
  const itemsWithCustom = customItem
    ? [customItem, ...filteredItems]
    : filteredItems;

  const items =
    itemsWithCustom?.map((element) => ({
      id: element.id,
      primaryText: element.title,
      isCustomWorkspace: element.isCustomWorkspace,
      onItemClick: (item: { primaryText: string; id: number }) =>
        handleChange(item.primaryText, item.id, element.isCustomWorkspace),
    })) || [];

  const handleAccordionChange =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean, item: any) => {
      setExpanded(isExpanded ? panel : false);
      handleChange(item.title, item.id, item.isCustomWorkspace);
    };

  return (
    <>
      {isMobile ? (
        items.map((item: any) => (
          <Accordion
            key={item.id}
            expanded={expanded === `panel-${item.id}`}
            onChange={(event, isExpanded) =>
              handleAccordionChange(`panel-${item.id}`)(event, isExpanded, item)
            }
            disableGutters
            sx={{ boxShadow: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${item.id}-content`}
              id={`panel-${item.id}-header`}
            >
              <Typography>{item.primaryText}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '8px 16px' }}>
              <Typography>
                <ContainerPrompts
                  handleSelectedPrompt={handleSelectedPrompt}
                  prompts={prompts}
                  defaultSelectedId={defaultSelectedId}
                  loading={loading}
                  isMobile={isMobile}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <STList selectedItemId={idCategorie} items={items} />
      )}
    </>
  );
};

export default ListCaterogiePrompts;
