const scrollStyle = {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '3px',
        height: '3px',
    },
    '&::-webkit-scrollbar-track': {
        background: "rgba(217, 217, 217, 1)",
        borderRadius: '1.5px'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(156, 157, 172, 1)',
        borderRadius: '1.5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(156, 157, 172, 1)'
    }
}

export default scrollStyle