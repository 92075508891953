import { useMutation, useQuery } from '@apollo/client';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { graphql } from '../gql/gql';
import { Mixpanel } from '../mixpanel/mixpanel';

const GET_PROJECT_MEMBERS = graphql(`
  query GetProjectMembers($id: Float!) {
    project(id: $id) {
      id
      members {
        id
        role
        email
        invitationStatus
        user {
          id
          profilePicture
          firstName
          lastName
          email
        }
      }
    }
  }
`);

const REMOVE_COLLABORATOR = graphql(`
  mutation RemoveMemberFromOneProject($memberId: Float!) {
    removeMemberFromProject(memberId: $memberId) {
      id
      members {
        id
        email
        role
        user {
          id
          firstName
          lastName
          email
          profilePicture
        }
      }
    }
  }
`);

const UPDATE_COLLABORATOR_ROLE = graphql(`
  mutation UpdateMemberRoleInOneProject($role: String!, $memberId: Float!) {
    updateMemberRoleInProject(role: $role, memberId: $memberId) {
      id
      members {
        id
        email
        role
        user {
          id
          firstName
          lastName
          email
          profilePicture
        }
      }
    }
  }
`);

const ADD_PROJECT_COLLABORATOR = graphql(`
  mutation AddMemberToProject(
    $newProjectCollaboratorData: NewProjectMemberInput!
  ) {
    addMemberToProject(newProjectMemberData: $newProjectCollaboratorData) {
      id
      members {
        id
        email
        role
        user {
          id
          firstName
          lastName
          email
          profilePicture
        }
      }
    }
  }
`);

interface IProps {
  projectId: number;
}

const useGetProjectMembers = ({ projectId }: IProps) => {
  const { displayMessage } = useDisplayMessage();
  const { data, loading } = useQuery(GET_PROJECT_MEMBERS, {
    variables: {
      id: projectId,
    },
  });

  const [
    addProjectCollaborator,
    { loading: isInviteInProgress, error: isInviteError },
  ] = useMutation(ADD_PROJECT_COLLABORATOR, {
    update: (cache, { data }) => {
      // Modify project cache to add new project collaborator
      cache.modify({
        id: cache.identify({
          __typename: 'Project',
          id: projectId,
        }),
        fields: {
          members: (existingMembers = []) => {
            return [...existingMembers, data?.addMemberToProject];
          },
        },
      });
      Mixpanel.track('Invite Collaborator', {
        projectId: projectId,
      });

      displayMessage({
        message: 'Collaborator invited successfully',
        type: 'success',
      });
    },
    onError: () => {
      displayMessage({
        message: 'Error inviting collaborator',
        type: 'error',
      });
    },
  });

  const [removeProjectCollaborator] = useMutation(REMOVE_COLLABORATOR, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Project',
          id: projectId,
        }),
        fields: {
          members: (existingMembers = []) => {
            return existingMembers.filter(
              (member: any) => member.id !== data?.removeMemberFromProject.id
            );
          },
        },
      });
      displayMessage({
        message: 'Collaborator removed successfully',
        type: 'success',
      });
    },
    onError: (error) => {
      displayMessage({
        message: 'Error removing collaborator',
        type: 'error',
      });
    },
  });

  const [updateCollaboratorRole] = useMutation(UPDATE_COLLABORATOR_ROLE, {
    update: (cache, { data }) => {
      displayMessage({
        message: 'Collaborator role updated successfully',
        type: 'success',
      });
    },
    onError: (error) => {
      displayMessage({
        message: 'Error updating collaborator role',
        type: 'error',
      });
    },
  });

  return {
    data,
    loading,
    addProjectCollaborator,
    removeProjectCollaborator,
    updateCollaboratorRole,
    isInviteInProgress,
    isInviteError,
  };
};

export default useGetProjectMembers;
