import * as React from 'react';
import { Box, Divider, styled, useMediaQuery, useTheme } from '@mui/material';
import 'moment-timezone';
import { Todo } from '../../../../gql/graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../../gql';
import CheckBox from './CheckBox/CheckBox';
import Label from './Label/Label';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import LastMessage from './Label/LastMessage/LastMessage';
import CustomUnreadnotification from '../../../common/CustomUnreadnotification/CustomUnreadnotification';
import IconBox from './IconBox/IconBox';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useCheckLabelToTask from '../../../Chat/hooks/useCheckLabelToTask';
import TodoLabelTag from '../../../Chat/Description/TodoLabel/AutoCompleteLabel/TodoLabelTag/TodoLabelTag';
import useDisplayTodoDescriptionSidebar from '../../../../Hooks/useDisplayTodoDescriptionSidebar';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';

const Container = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  maxWidth: '100%',
}));

const ContainerMain = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  maxWidth: '100%',
  borderRadius: '5px',
  '&:hover': {
    cursor: 'pointer',
    '.chat-icon': {
      cursor: 'pointer',
      transition: 'opacity 0.3s ease',
      opacity: 1,
    },
  },
}));

const COMPLETE_TODO = graphql(`
  mutation completeTodo($todoId: Int!) {
    completeTodo(todoId: $todoId) {
      id
      isCompleted
    }
  }
`);

interface IProps {
  task?: Todo;
  isEditable?: boolean;
  bgColor?: string;
  showCompleteCheckbox?: boolean;
  disPlayLastMessage?: boolean;
  onClick?: (taskId: number) => void;
  taskRoutePrefix?: string;
  isForChat?: boolean;
  isKanban?: boolean;
  isLabelCompact?: boolean;
  isShowLabel?: boolean;
}

const InnerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '12px 16px',
}));

const Task: React.FC<IProps> = ({
  task,
  isEditable,
  bgColor,
  isForChat,
  showCompleteCheckbox,
  disPlayLastMessage,
  isShowLabel,
  isLabelCompact,
  taskRoutePrefix,
  isKanban = false,
  onClick,
}) => {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const { displayMessage } = useDisplayMessage();
  const theme = useTheme();
  const route = useLocation();
  const navigate = useNavigate();
  const { handelCheckLabelToTask } = useCheckLabelToTask();
  let isOverdue = false;
  const path = route.pathname;
  const dueDate = moment(task?.dueDate);
  const dateNow = moment(Date.now());

  if (dueDate.isBefore(dateNow, 'day')) isOverdue = true;

  const [completeTodo] = useMutation(COMPLETE_TODO, {
    update: () => {
      displayMessage({
        message: 'todo.complete.task.success',
        type: 'success',
      });
    },
    onError: () => {
      displayMessage({
        message: 'An error has occurred, please try again!',
        type: 'error',
      });
    },
  });

  const handleChecktask = () => {
    completeTodo({
      variables: { todoId: task?.id as number },
    });
  };
  const isProjectRoute = path.indexOf('project') > -1 ? true : false;

  const handleClickTask = (e: any) => {
    e.preventDefault();
    if (isSm) {
      handleNavigateToChat(e);
      return;
    }

    //displayDescriptionSidbar(task?.id as number);
    if (onClick) onClick(task?.id as number);
  };
  const handleNavigateToChat = (e: any) => {
    e.preventDefault();
    if (task?.id && !isEditable) {
      if (taskRoutePrefix) navigate(`${taskRoutePrefix}/chat/${task?.id}`);
      else navigate(`chat/${task?.id}`);
    }
  };

  const bgCustom = isKanban
    ? theme.palette.customColor.taskKanbanBackgroundColor
    : theme.palette.customColor.taskBackgroundColor;
  const bg = bgColor
    ? bgCustom
    : theme.palette.customColor.projectSectionKabanBackground;
  const handleRemovelabel = (label: any) => {
    handelCheckLabelToTask(label.id, task?.projectId || 0, task?.id || 0);
  };

  return (
    <ContainerMain bgcolor={bg} flexDirection="column" mt={1} p={1.3}>
      {isShowLabel && (
        <CustomBox display="flex" flexWrap={'wrap'}>
          {task?.labels?.map((label: any) => (
            <TodoLabelTag
              showLabel={isLabelCompact ? !isLabelCompact : !isSm}
              labelId={label.id}
              isWithAction={true}
              labelColor={label.color}
              actionbgColor={label.bgColor}
              sx={{ backgroundColor: label.bgColor }}
              label={label?.name}
              onDelete={() => handleRemovelabel(label)}
            />
          ))}
        </CustomBox>
      )}

      <Container flexDirection="row">
        <Box
          sx={{
            marginLeft: (theme) => theme.spacing(-0.8),
            marginTop: (theme) => theme.spacing(1.8),
            width: (theme) => theme.spacing(1.1),
          }}
        >
          {task?.todoUnreadNotificationsCount ? (
            <CustomUnreadnotification />
          ) : null}
        </Box>

        {showCompleteCheckbox && (
          <Box>
            <CheckBox
              Check={task?.isCompleted || false}
              onChange={handleChecktask}
            />
          </Box>
        )}
        <Box
          flexGrow={1}
          sx={{
            maxWidth: '100%',
            mt: (theme) => theme.spacing(1),
            ...(!showCompleteCheckbox && {
              ml: (theme) => theme.spacing(1),
            }),
          }}
        >
          <Label
            isOverdue={isOverdue}
            todo={task}
            isEditable={isEditable}
            onClick={handleClickTask}
          />
        </Box>
        {!isForChat ? (
          <IconBox handleClickTask={handleNavigateToChat} todo={task} />
        ) : null}
      </Container>

      {Boolean(task?.chat?.messages?.length) && disPlayLastMessage && (
        <Box onClick={handleClickTask}>
          <Box>
            <Divider />
          </Box>
          <InnerContainer>
            <LastMessage todo={task} isProjectRoute={isProjectRoute} />
          </InnerContainer>
        </Box>
      )}
    </ContainerMain>
  );
};

export default Task;
