import { useContext } from 'react';
import ChatContext from './ChatContext';

const useGetChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

export default useGetChatContext;
