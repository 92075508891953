import { graphql } from '../gql';

export const BOT_PROMPT_FRAGMENT = graphql(`
  fragment BotPromptFragment on BotPrompt {
    id
    prompt
    actionsOnClick
    bot {
      id
      slug
      isNeedsOAuth
      isAlreadyAuthenticated
      user {
        ...UserPublicInfoFragment
      }
    }
  }
`);

export const MESSAGE_MINIMAL_FRAGMENT = graphql(`
  fragment MessageMinimalFragment on TodoChatMessage {
    id
    createdAt
    content
    sentByUserId
    todoChatId
    updatedAt
    isBotError
  }
`);

export const CHAT_MESSAGE_FRAGMENT = graphql(`
  fragment ChatMessageFragment on TodoChatMessage {
    id
    createdAt
    content
    htmlPreviewUrl
    htmlContent
    sentByUserId
    todoChatId
    updatedAt
    isBotError
    isSubscriptionUsageLimitReachedMessage
    isForTaskRunningInBackGround
    botPrompts {
      ...BotPromptFragment
    }
    parentMessage {
      id
      content
      sentBy {
        id
        firstName
        lastName
        email
        profilePicture
        isBot
      }
    }

    files {
      ...FileFragment
    }
    messageMetaSources {
      id
      source
      type
      url
      name
      page
    }
    sentBy {
      id
      firstName
      lastName
      email
      profilePicture
      isBot
      bot {
        id
        supportRegeneration
      }
    }
    __typename
  }
`);
