import { FC } from 'react';
import { graphql } from '../gql/gql';
import { useMutation } from '@apollo/client';
const REGENERATE_BOT_RESPONSE = graphql(
  `
    mutation RegenerateBotResponse($messageId: Float!) {
      regenerateResponse(messageId: $messageId) {
        id
        content
      }
    }
  `
);

const useRegenerateBotResponse = () => {
  const [regenerateBotResponse, { loading }] = useMutation(
    REGENERATE_BOT_RESPONSE
  );
  return {
    regenerateBotResponse,
    loading,
  };
};

export default useRegenerateBotResponse;
