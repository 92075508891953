export const getLastedConfigSelected = (slug: string) => {
  return JSON.parse(`${localStorage.getItem(`lasted:config:${slug}`)}`) || [];
};

export const setLastedConfigSelected = (slug: string, value: string) => {
  let lastedConfigSelected: string[] = getLastedConfigSelected(slug) || [];

  if (!lastedConfigSelected.includes(value)) {
    lastedConfigSelected = [value, ...lastedConfigSelected];
  }
  localStorage.setItem(
    `lasted:config:${slug}`,
    JSON.stringify(lastedConfigSelected)
  );
};
