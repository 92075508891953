import {  useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
export const GET_TODO_SUB_TASK = graphql(`
query GetTodoSubTask($todoId: Float!) {
    getTodoSubTask(todoId: $todoId) {
      id
      title
      isCompleted
      todoId
    }
  }
`);

const useGetTodoSubTask = ({todoId}:{todoId:number}) => {
  const { data ,loading,error} = useQuery(GET_TODO_SUB_TASK, {
    variables: {
        todoId,
    },
  });
  return {
    data,
    loading,
    error,
  };
};

export default useGetTodoSubTask;
