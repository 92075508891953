import useGetChatSessionAutoReply from '../../../../../Hooks/useGetChatSessionAutoReply';
import STSwitch from '../../../../common/STSwitch/STSwitch';
import useChatSession from '../../../hooks/useChatSession';

const ChatAutoReply = () => {
  const { data: chatSession, loading } = useGetChatSessionAutoReply();

  const { updateChatSession } = useChatSession();

  if (loading) return null;

  const autoReplyLMM =
    chatSession?.getOneTodoChatSession?.autoReplyLMM || false;

  const handleToggle = (e: any) => {
    // if checked, set default bot to gpt-4
    updateChatSession({
      variables: {
        id: chatSession?.getOneTodoChatSession?.id || 0,
        data: {
          autoReplyLMM: !autoReplyLMM,
        },
      },
    });
  };

  // check if defaultbot slug is gpt-4

  return (
    <STSwitch
      size="small"
      sx={{ position: 'absolute' }}
      checked={autoReplyLMM}
      onClick={handleToggle}
      tooltip="chat.autoreply.switch.tooltip"
    />
  );
};

export default ChatAutoReply;
