import mixpanel from 'mixpanel-browser';
import config from '../config/config';

mixpanel.init(config.REACT_APP_MIXPANEL_TOKEN);

let actions = {
  identify: (id: any) => {
    mixpanel.identify(id);
  },
  alias: (id: any) => {
    mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    },
    union: (props: any) => {
      mixpanel.people.union(props);
    },
  },
};

export let Mixpanel = actions;
