import * as React from 'react';
import { Box } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './ThreeDotsSkeleton.css';


const Loader = () => {
  const count: number = 3;
  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      }}
    >
      <SkeletonTheme
        baseColor={'#222327'}
        highlightColor={'#2A2B2F'}
      >
        {Array.from({ length: count }, (_, index) => (
          <Skeleton
            key={index}
            circle
            className={`dot dot${index}`}
            duration={1}
            height={16}
            width={16}
            style={{ marginRight: '10px' }}
          />
        ))}
      </SkeletonTheme>
    </Box>
  );
};

export default Loader;
