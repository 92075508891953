import { FC } from 'react';
import useLogout from '../../../Hooks/useLogout';
import CustomButton from '../CustomButton/CustomButton';
import { FormattedMessage } from 'react-intl';

interface IProps {}

const LogoutButton: FC<IProps> = ({}) => {
  const { logout } = useLogout();

  return (
    <CustomButton
      onClick={logout}
      size="small"
      variant="contained"
      color="primary"
    >
      <FormattedMessage id="logout.button" defaultMessage={'Logout'} />
    </CustomButton>
  );
};

export default LogoutButton;
