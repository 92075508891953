import { useLazyQuery } from '@apollo/client';
import { FC } from 'react';
import { graphql } from '../../gql/gql';
const GET_EXTERNAL_APP = graphql(
  `
    query GetExternalApp($appSlug: String!) {
      externalAppConnection(appSlug: $appSlug) {
        id
        accessToken
      }
    }
  `
);

const useExternalAppConnection = () => {
  // Lazy query
  const [
    getExternalAppConnection,
    { data, loading: loadingGetExternalAppConnection },
  ] = useLazyQuery(GET_EXTERNAL_APP, {
    fetchPolicy: 'network-only',
  });
  return {
    getExternalAppConnection,
  };
};

export default useExternalAppConnection;
