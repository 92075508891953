import { FC } from 'react';
import { graphql } from '../../../gql/gql';
import { useMutation } from '@apollo/client';
import useDisplayMessage from '../../common/GlobalMessage/useDisplayMessage';

const ADD_DATASOURCE = graphql(
  `
    mutation addDataSourcesToChatSession($data: AssistantDatasourcesInput!) {
      addDataSourcesToChatSession(data: $data) {
        id
        allDataSources {
          id
          name
          url
          fileType
          type
        }
      }
    }
  `
);

const useAddDataSourcesToChatSession = () => {
  const { displayMessage } = useDisplayMessage();
  const [addDataSourcesToAssistant, { loading, error }] = useMutation(
    ADD_DATASOURCE,
    {
      onError: (error) => {
        displayMessage({
          message: 'chat.add.datasource.error',
          type: 'error',
        });
      },
    }
  );
  return {
    addDataSourcesToAssistant,
    loading,
    error,
  };
};

export default useAddDataSourcesToChatSession;
