import CustomBox from '../../CustomBox/CustomBox';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomIconButton from '../../CustomIconButton/CustomIconButton';
import { useState } from 'react';
import UserBotCustomConfig from './UserBotCustumConfig';

const CustomTemperature = ({ botId }: { botId: number }) => {
  const [isOpenDialogConfig, setIsOpenDialogConfig] = useState(false);

  const handleOpen = () => {
    setIsOpenDialogConfig(true);
  };

  const handleClose = () => {
    setIsOpenDialogConfig(false);
  };

  return (
    <CustomBox
      sx={{
        ml: 'auto',
      }}
    >
      <CustomIconButton size="small" onClick={handleOpen}>
        <SettingsIcon fontSize="small" />
      </CustomIconButton>
      {isOpenDialogConfig ? (
        <UserBotCustomConfig
          handleClose={handleClose}
          botId={botId}
          open={isOpenDialogConfig}
        />
      ) : null}
    </CustomBox>
  );
};
export default CustomTemperature;
