import { Chip, Stack, styled, useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';
import DoneIcon from '@mui/icons-material/Done';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import BotAvatar from './BotAvatar/BotAvatar';

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: '3px',
  borderRadius: '4px',
  height: '14px',
  width: '34px',
  '& .MuiChip-icon': {
    margin: 0,
  },
  '& 	.MuiChip-label': {
    fontWeight: '400',
    fontSize: '8px',
    paddingRight: '0px',
    paddingLeft: '2px',
  },
  '& .MuiChip-root': {
    padding: '0px',
    fontSize: '8px',
  },
}));

interface props {
  handleAutoMention?(): void;
  userProfil?: string;
  botname: string;
  isDisplayDescription?: boolean;
  isBot?: boolean;
  isNew?: boolean;
  maxlineDescription?: number;
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  botDescription?: string;
  handleClickBot?(): void;
  avatarSize?: 'large' | 'small';
}
const BotTitleContent = ({
  handleAutoMention,
  handleClickBot,
  botDescription,
  userProfil,
  isDisplayDescription,
  botname,
  isBot,
  avatarSize,
  maxlineDescription,
  direction,
  isNew,
}: props) => {
  const theme = useTheme();
  return (
    <Stack
      direction={direction ? direction : 'row'}
      display="flex"
      alignItems="center"
      spacing={theme.spacing(1)}
      my={theme.spacing(1)}
      sx={{
        cursor: 'pointer',
        width: '100%',
      }}
      onClick={handleClickBot}
    >
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            border: '1px solid',
            borderRadius: '4px',
          },
        }}
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        variant="dot"
        onClick={handleAutoMention}
      >
        <BotAvatar avatarSize={avatarSize} userProfil={userProfil} />
      </Badge>
      {direction === ('column' || 'row-reverse') ? (
        <CustomTypography
          sx={{
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '99%',
            outline: 'none',
          }}
          variant="h6"
        >
          {botname}
        </CustomTypography>
      ) : null}
      <CustomBox>
        {direction !== ('column' || 'row-reverse') ? (
          <CustomTypography variant="h6">{botname}</CustomTypography>
        ) : null}
        {isDisplayDescription && (
          <CustomTypography
            sx={{
              textAlign: 'justify',
              fontSize: '12px',
              color: (theme) => theme.palette.grey[200],
            }}
            maxCharacters={180}
            maxLines={maxlineDescription ? maxlineDescription : 3}
          >
            {botDescription}
          </CustomTypography>
        )}
      </CustomBox>
      {isBot && (
        <StyledChip
          label="BOT"
          size="small"
          color="info"
          icon={<DoneIcon style={{ fontSize: '8px' }} />}
        />
      )}
      {isNew && (
        <StyledChip
          label="New"
          size="small"
          color="info"
          icon={<DoneIcon style={{ fontSize: '8px' }} />}
        />
      )}
    </Stack>
  );
};
export default BotTitleContent;
