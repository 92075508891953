// Import react
import { useLazyQuery, useMutation } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import { BOT_PROMPT_FRAGMENT } from '../../../../../fragements/message';
import { UserPublicInfoFragment } from '../../../../../fragements/user';
import { FragmentType, graphql, useFragment } from '../../../../../gql';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import Loading from '../../../../common/Loading/Loading';
import STAvatar from '../../../../common/STAvatar/STAvatar';
import STChip from '../../../../common/STChip/STChip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { onAfterSendMessage } from '../../../utils/onAfterSendMessage';
import { ADD_NEW_MESSAGE } from '../../FormAddMessage/FormAddMessage';
import { BotPromptAction } from '../../../../../gql/graphql';
import useCreateChatSession from '../../../hooks/useCreateChatSession';
import useNavigateToChatSession from '../../../hooks/useNavigateToChatSession';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';
import useCheckBotAuth from '../../../hooks/useCheckBotAuth';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';
import useSetupChatSessionDataIndexation from '../../../hooks/setupChatSessionDataIndexation';

// getAuthUrl graphql query
const GET_AUTH_URL = graphql(`
  query GetAuthUrl($botId: Float!) {
    getAuthUrl(botId: $botId)
  }
`);

interface IProps {
  messageId: number;
  botPrompts?: FragmentType<typeof BOT_PROMPT_FRAGMENT>[] | null;
  onBotPromptClick?: (messageId: number, prompt: string, botId: number) => void;
  todoId?: number;
}

interface IBotClick {
  messageId: number;
  prompt: string;
  botUserId: number;
  botPromptId: number;
  isAlreadyAuthenticated?: boolean | null;
  isNeedsOAuth?: boolean | null;
  botId: number;
  actionsOnClick?: BotPromptAction[];
  botSlug?: string;
}

const PromptItem = ({
  item: itemData,
  index,
  onClick,
  messageId,
  loading,
}: {
  item: FragmentType<typeof BOT_PROMPT_FRAGMENT>;
  index: number;
  onClick: (event: IBotClick) => void;
  messageId: number;
  botPromptId: number;
  loading?: boolean;
}) => {
  const theme = useTheme();
  const item = useFragment(BOT_PROMPT_FRAGMENT, itemData);
  const isDark = theme.palette.mode === 'dark';
  const userInfo = useFragment(UserPublicInfoFragment, item.bot.user);
  if (!userInfo) return null;
  if (!item) return null;

  return (
    <>
      <STChip
        avatar={
          <STAvatar
            userInfo={item?.bot?.user}
            sx={{
              height: (theme) => theme.spacing(2),
              '& img': {
                height: (theme) => theme.spacing(2),
                width: 'auto',
              },

              borderRadius: (theme) => theme.spacing(0.5),
            }}
          />
        }
        label={item?.prompt}
        onDelete={() =>{}}
        deleteIcon={<ArrowForwardIcon fontSize="small" />}
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          '& .MuiChip-icon': {
            marginLeft: 'auto',
          },
          '& .MuiChip-deleteIcon': {
            marginLeft: 'auto',
            width: '15px',
            color: isDark ? '#fff' : '#1D1C1D',
          },
          height: (theme) => theme.spacing(3),

          borderRadius: (theme) => theme.spacing(0.5),
        }}
        onClick={() =>
          onClick({
            messageId,
            prompt: item?.prompt,
            botUserId: userInfo.id,
            botPromptId: item.id,
            isAlreadyAuthenticated: item?.bot?.isAlreadyAuthenticated,
            isNeedsOAuth: item?.bot?.isNeedsOAuth,
            botId: item?.bot?.id,
            botSlug: item?.bot?.slug,
            actionsOnClick: item?.actionsOnClick,
          })
        }
      />
      {loading ? <Loading type="circle" size={20} /> : null}
    </>
  );
};

const MessageBotPrompts: React.FC<IProps> = ({
  messageId,
  botPrompts,

  todoId,
}) => {
  const goToChatSession = useNavigateToChatSession();
  const { displayMessage } = useDisplayMessage();
  const { checkBotAuth } = useCheckBotAuth();
  const { createChatSession } = useCreateChatSession({
    todoId: todoId as number,
  });
  const { setup } = useSetupChatSessionDataIndexation();
  // Get todo id from params
  const params = useParams();
  const [addNewMessage, { error: errorAddMessage, loading }] = useMutation(
    ADD_NEW_MESSAGE,
    {
      update: (cache, { data }) => {
        // Common action after send message
        onAfterSendMessage({
          cache,
          newMessage: data?.sendNewMessage,

          todoId,
          sessionId: (params?.sessionId ? +params.sessionId : 0) as number,
        });
      },
    }
  );
  // Lazy query getAuthUrl

  const [getAuthUrl, { data: dataGetAuthUrl, loading: loadingGetAuthUrl }] =
    useLazyQuery(GET_AUTH_URL, {
      fetchPolicy: 'network-only',
    });

  // Clicked bot state
  const [clickedBotPromptId, setClickedPromptId] = React.useState<
    number | null
  >(null);

  // On both auth error

  const onBotAuthError = () => {
    displayMessage({
      message: 'bot.auth.error',
      type: 'error',
    });
  };

  const onBotPromptClick = ({
    messageId,
    prompt,
    botUserId,
    botPromptId,
    isAlreadyAuthenticated,
    isNeedsOAuth,
    botId,
    actionsOnClick,
    botSlug,
  }: IBotClick) => {
    setClickedPromptId(botPromptId);
    Mixpanel.track('Click on bot prompt', {
      prompt,
      botId,
      botUserId,
      messageId,
      botSlug,
      botPromptId,
    });
    if (loading) return;
    if (actionsOnClick?.length) {
      // Create a new chat session and attach message datasources
      if (
        actionsOnClick.includes(
          BotPromptAction.CreateNewChatSessionAndAttachMessageDatasources
        )
      ) {
        createChatSession({
          variables: {
            data: {
              todoId: todoId as number,
              //createFromChatAsDataSourceId: messageId,
              defaultBotId: botId,
              fromBotPromptId: botPromptId,
            },
          },
        })
          .then((res) => {
            // Redirect to new chat session
            if (res?.data?.createTodoChatSession) {
              const sessionId = res?.data?.createTodoChatSession.id;
              // Send initial message to start conversation
              goToChatSession({
                todoId: todoId as number,
                chatSessionId: sessionId as number,
                dataSources: res?.data?.createTodoChatSession?.dataSources,
              });

              setTimeout(() => {
                // Setup chat session data indexation
                setup({
                  variables: {
                    messageId,
                    sessionId,
                  },
                });
              }, 2000);
            } else {
              onBotAuthError();
            }
          })
          .catch((err) => {
            onBotAuthError();
          });
        return;
      }
    }
    if (!isAlreadyAuthenticated && isNeedsOAuth) {
      getAuthUrl({
        variables: {
          botId,
        },
      }).then((res) => {
        if (res?.data?.getAuthUrl) {
          // window.location.href = res?.data?.getAuthUrl;
          // Open window popup and get callback after close
          const popup = window.open(
            res?.data?.getAuthUrl,
            'popup',
            'width=600,height=600'
          );

          const timer = setInterval(() => {
            if (popup?.closed) {
              clearInterval(timer);
              checkBotAuth({
                variables: {
                  botId,
                },
              }).then((res) => {
                if (res?.data?.bot?.isAlreadyAuthenticated) {
                  Mixpanel.track('Bot authenticated', {
                    botId,
                  });
                  // Handle after close popup

                  addNewMessage({
                    variables: {
                      newMessageData: {
                        parentMessageId: messageId,
                        mentionedUserId: botUserId,

                        message: prompt,
                        botPromptId,
                        sessionId: params?.sessionId ? +params.sessionId : null,
                      },
                    },
                  });
                }
              });
            }
          }, 1000);
        }
      });
      return;
    }

    addNewMessage({
      variables: {
        newMessageData: {
          parentMessageId: messageId,
          mentionedUserId: botUserId,

          message: prompt,
          botPromptId,
          sessionId: params?.sessionId ? +params.sessionId : null,
        },
      },
    });
  };
  const prompts = useFragment(BOT_PROMPT_FRAGMENT, botPrompts);

  if (!prompts) return null;
  if (prompts.length === 0) return null;

  return (
    <CustomBox
      sx={{
        // Horizontally scrollable
        overflowX: 'auto',
        width: '100%',
      }}
    >
      <Stack direction="row" flexWrap={'wrap'} gap={1}>
        {prompts?.map((item, index: number) => (
          <PromptItem
            key={index}
            // @ts-ignore
            item={item}
            index={index}
            messageId={messageId}
            onClick={onBotPromptClick}
            loading={loading && clickedBotPromptId === item.id}
          />
        ))}
      </Stack>
    </CustomBox>
  );
};

export default MessageBotPrompts;
