import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import React from 'react';

const GE_TODO_DETAILS = graphql(`
  query TodoDetails($id: Float!, $take: Int) {
    todo(id: $id) {
      id
      published
      title
      createdAt
      description
      updatedAt
      isCompleted
      dueDate
      startDate
      removed
      labels {
        id
        name
        description
        color
        bgColor
      }
      projectId
      createdBy {
        id
        firstName
        lastName
        profilePicture
      }
      asignees(take: $take) {
        id
        assignedTo {
          id
          firstName
          profilePicture
        }
      }
    }
  }
`);

const useGetTodoDetails = ({ todoId }: { todoId: number }) => {
  const { data, error, loading } = useQuery(GE_TODO_DETAILS, {
    variables: {
      id: todoId,
      take: 1,
    },
  });

  return {
    data,
    error,
    loading,
  };
};

export default useGetTodoDetails;
