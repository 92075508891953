import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import CustomBox from '../../../common/CustomBox/CustomBox';
import TextInput from '../../../common/TextInput/TextInput';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { useIntl } from 'react-intl';
import React from 'react';
interface Formprops {
  value: {
    projectName: string;
    projectDescription: string;
    shouldGenerateTasks:boolean;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  isForAddProject: boolean;
}

const ProjectForm = ({
  value,
  onChange,
  isForAddProject,
}: Formprops) => {
  const intl = useIntl();
  return (
    <>
      <CustomBox>
        <TextInput
          value={value.projectName}
          multiline={true}
          rows={1}
          name="projectName"
          placeholder={intl.formatMessage({
            id: 'project.create.name',
            defaultMessage: 'Project name',
          })}
          onChange={(event: any) => onChange(event)}
        />
      </CustomBox>
      {isForAddProject ? (
        <CustomBox
          sx={{
            ml: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                name="shouldGenerateTasks"
                value={value.shouldGenerateTasks}
                onChange={(event: any) => onChange(event)}
              />
            }
            label={intl.formatMessage({
              id: 'project.create.generate.tasks',
              defaultMessage: 'Generate tasks for this project',
            })}
          />
        </CustomBox>
      ) : null}
      {value.shouldGenerateTasks && (
        <CustomBox
          mb={1}
          sx={{
            width: '100%',
          }}
        >
          <TextInput
            value={value.projectDescription}
            multiline={true}
            name="projectDescription"
            rows={4}
            placeholder={intl.formatMessage({
              id: 'project.create.description',
              defaultMessage: 'Describe your project',
            })}
            onChange={(event: any) => onChange(event)}
            helperTextIntl="project.create.description.helper"
          />
        </CustomBox>
      )}
    </>
  );
};
export default ProjectForm;
