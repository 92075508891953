
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useChatSession from '../hooks/useChatSession';
import useDeleteSession from '../hooks/useDeleteSession';
import useGetAllChatSessions from '../hooks/useGetAllChatSessions';
import useNavigateToChatSession from '../hooks/useNavigateToChatSession';

const useChatSessions = (todoId: number) => {
  const [offset, setOffset] = React.useState(0);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const { data, loading, error, fetchMore } = useGetAllChatSessions({
    todoId,
  });
  const { handleDeleteSession } = useDeleteSession();
  const params = useParams();
  const goToChatSession = useNavigateToChatSession();
  const { updateChatSession } = useChatSession();

  const sessionId = params?.sessionId ? +params?.sessionId : 0;

  const sessions = data?.getTodoChatSessions?.sessions || [];
  const totalCount = data?.getTodoChatSessions?.totalCount || 0;

  const onItemClick = (item: any) => {
    goToChatSession({
      todoId,
      chatSessionId: item.id,
      dataSources: item.dataSources,
    });
  };

  const handleUpdateSessionName = React.useCallback(
    (value: string) => {
      updateChatSession({
        variables: {
          id: sessionId,
          data: {
            title: value,
          },
        },
      });
    },
    [sessionId, updateChatSession]
  );

  const onLoadMore = async () => {
    setIsLoadingMore(true);
    const newOffset = offset + 15;
    setOffset(newOffset);
    try {
      await fetchMore({
        variables: {
          todoId,
          limit: 15,
          offset: newOffset,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            getTodoChatSessions: {
              ...fetchMoreResult.getTodoChatSessions,
              sessions: [
                ...prev.getTodoChatSessions.sessions,
                ...fetchMoreResult.getTodoChatSessions.sessions,
              ],
            },
          };
        },
      });
    } catch (error) {
      console.error('Error loading more sessions:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const hasMoreSessions = totalCount > sessions.length;

  return {
    loading,
    error,
    sessions,
    sessionId,
    isLoadingMore,
    hasMoreSessions,
  
    onItemClick,
    handleUpdateSessionName,
    onLoadMore,
    handleDeleteSession,
  };
};

export default useChatSessions;