import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import CustomDialog from '../../../../common/CustomDialog/CustomDialog';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import ReferralContent from '../ReferralContent';

const ReferralCreditsPopup: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomBox>
      <CustomButton onClick={handleClickOpen}>
        <CustomTypography>
          <FormattedMessage id="referral.invite.user.link.title" />
        </CustomTypography>
      </CustomButton>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        maxWidth="md"
        contentChildren={
          <CustomBox>
            <ReferralContent />
          </CustomBox>
        }
        title={intl.formatMessage({
          id: 'referral.popup.title',
          defaultMessage: 'Invite your friends',
        })}
      />
    </CustomBox>
  );
};

export default ReferralCreditsPopup;
