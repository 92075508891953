const getExtension = (mimeType: string) => {
  const mimeMap: { [key: string]: string } = {
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'pptx',
    'text/html': 'html',
    'text/csv': 'csv',
  };
  return mimeMap[mimeType] || 'unknown';
};

export default getExtension;
