// Display alert message when user is redirected to the login page, redirect message is based on the query params
import React from 'react';
import styled from '@mui/material/styles/styled';
import CustomBox from '../../common/CustomBox/CustomBox';
import { getUrlParam } from '../../../utils/url';
import AlertText from '../../common/AlertText/AlertText';
import { useIntl } from 'react-intl';

const StyledBox = styled(CustomBox)(({ theme }) => ({}));

const RedirectionInfo = () => {
  const currentRedirectUrl = getUrlParam('redirect');
  const intl = useIntl();
  // If redirect url contains 'confirm-email' then display the message

  if (currentRedirectUrl?.includes('confirm-email')) {
    return (
      <StyledBox>
        <AlertText
          message={intl.formatMessage({
            id: 'signin.info.confirmEmail',
            defaultMessage: 'Please login before confirming your email address',
          })}
          severity="info"
        />
      </StyledBox>
    );
  }
  return null;
};

export default RedirectionInfo;
