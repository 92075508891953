import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import { styled } from '@mui/system';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
const CustomTypographyStyled = styled(CustomTypography)(({ theme }) => ({
    fontWeight: 'normal',
    marginLeft: theme.spacing(0.5)
  }));
interface Iprops {
  label: string;
  children?: React.ReactNode;
}
const MenuItem: React.FC<Iprops> = ({  label, children }) => {
  const theme = useTheme();
  return (
    <Box display='flex'>
      {children}
      <CustomTypographyStyled>
        {label}
      </CustomTypographyStyled>
    </Box>
  );
};

export default MenuItem;