import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const GET_CUSTOM_BOT = graphql(
  `
    query GetCustomBotConfig($botId: Float!) {
      bot(id: $botId) {
        id
        customBotConfig {
          id
          botConfigTodoId
          botConfigMainChatSessionId
        }
      }
    }
  `
);
interface GetBotArg {
  botId: number;
}

const useCustomBotConfig = ({ botId }: GetBotArg) => {
  const { loading, data, error } = useQuery(GET_CUSTOM_BOT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      botId,
    },
  });

  const customBotConfig = data?.bot?.customBotConfig;

  return { loading, customBotConfig, error };
};

export default useCustomBotConfig;
