import * as React from 'react';
import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

interface CustomBoxProps extends BoxProps {
  disable?: boolean;
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disable',
})<CustomBoxProps>(({ theme, disable }) => ({
  scrollbarWidth: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '4px',
  },
  '&:hover': {
    '&::-webkit-scrollbar': {
      width: '8px',
      display: 'block',
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      borderRadius: '1.5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.customColor.scrollColor,
      borderRadius: '1.5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.customColor.scrollColor,
    },
  },
  ...(disable && {
    opacity: 0.5,
    filter: 'grayscale(100%)',
    pointerEvents: 'none',
  }),
}));

const CustomBox: React.FC<CustomBoxProps> = ({ children, ...rest }) => {
  return <StyledBox {...rest}>{children}</StyledBox>;
};

export default CustomBox;
