import React, { useState, useEffect, useRef } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from '@mui/material/Slider';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PauseIcon from '@mui/icons-material/Pause';
import {
  Wrapper,
  PlayButton,
  RangeWrapper,
  TimeDisplay,
} from './stAudioControl.style';

// Définition des types pour les props et les états
interface STAudioContorlProps {
  src: string;
}

const STAudioControl: React.FC<STAudioContorlProps> = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (audio.paused) {
        audio.play();
        setIsPlaying(true);
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio) {
      setCurrentTime(audio.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    const audio = audioRef.current;
    if (audio) {
      setDuration(audio.duration);
    }
  };

  const handleSeekChange = (event: Event, value: number | number[]) => {
    const audio = audioRef.current;
    if (audio) {
      const newTime = Array.isArray(value) ? value[0] : value;
      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleDownload = () => {
    window.open(src, '_blank');
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const onEnd = () => {
        setIsPlaying(false);
        audio.pause();
      };
      audio.addEventListener('ended', onEnd);
      return () => audio.removeEventListener('ended', onEnd);
    }
  }, []);

  return (
    <Wrapper>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <PlayButton onClick={togglePlayPause} disabled={!src}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </PlayButton>
      <RangeWrapper>
        <Slider
          min={0}
          max={duration}
          value={currentTime}
          step={0.01}
          onChange={handleSeekChange}
          disabled={!src}
          sx={{ width: '100%', color: (theme) => theme.palette.grey[200] }}
        />
      </RangeWrapper>
      <TimeDisplay>
        <span>
          {Math.floor(currentTime / 60)}:
          {Math.floor(currentTime % 60)
            .toString()
            .padStart(2, '0')}
        </span>{' '}
        /{' '}
        <span>
          {Math.floor(duration / 60)}:
          {Math.floor(duration % 60)
            .toString()
            .padStart(2, '0')}
        </span>
      </TimeDisplay>
      <CustomIconButton
        onClick={handleDownload}
        sx={{ color: (theme) => theme.palette.grey[200] }}
      >
        <FileDownloadIcon />
      </CustomIconButton>
    </Wrapper>
  );
};

export default STAudioControl;
