import { Avatar, ListItemAvatar } from '@mui/material';
import FileIconComponent from '../../../../common/FileIcon/FileIcon';

interface props {
  fileName: string;
}

const ListItemLeft = ({ fileName }: props) => {
  return (
    <ListItemAvatar>
      <Avatar
        sx={{
          background: 'transparent',
          paddingBottom: 1,
        }}
      >
        <FileIconComponent filename={fileName} size="medium" />
      </Avatar>
    </ListItemAvatar>
  );
};
export default ListItemLeft;
