import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChatContext } from '../Chat';
import { isInfiniteChat } from '../../../utils/isInfiniteChat';

const useNavigateToChatSession = () => {
  const navigate = useNavigate();
  const params = useParams();
  const route = useLocation();
  const isInfinite = isInfiniteChat(route.pathname);
  const goToChatSession = ({
    chatSessionId,
    todoId,
    dataSources,
  }: {
    chatSessionId: number;
    todoId?: number;
    dataSources?: any;
  }) => {
    // Use path because if we are outside the chat page, we need to redirect to the chat page
    const path = isInfinite
      ? `/${params.workspaceId}/talk/${chatSessionId}`
      : `/${params.workspaceId}/chat/${todoId}/${chatSessionId}`;
    navigate(path);
  };
  return goToChatSession;
};

export default useNavigateToChatSession;
