import * as React from 'react';
import { Box, TextField, useTheme } from '@mui/material';

interface props {
    multiline: boolean
    onChange(e: any): void
    value: string
    placeholder: string
    name: string
    onKeyPress(e: any): void,
    style?: Object
}
const InputForm = ({ multiline, onChange, value, placeholder, name, onKeyPress, style }: props) => {
    const theme = useTheme()
    const color = theme.palette.mode === "dark" ? "#fff" : theme.palette.primary.main
    const inputProps = {
        "style": {
            fontSize: theme.typography.h6.fontSize,
            color: color,
            ...style
        }
    }
    return (
        <Box mt={1}>
            <TextField
                onKeyPress={onKeyPress}
                autoFocus={!multiline}
                name={name}
                multiline={multiline}
                onChange={onChange}
                value={value}
                inputProps={inputProps}
                placeholder={placeholder}
                fullWidth
                size='small'
                label="" variant="standard"
            />
        </Box>
    );
}

export default InputForm 