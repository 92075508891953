import { useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';

const drawerWidth = "100%";

interface Props {
    window?: () => Window;
    open: boolean
    toggleDrawer?(): void
    children: React.ReactElement
    toggleDrawerSetting?():void
}

const MobileMenuSetting = (props: Props) => {
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const theme = useTheme()
    return (
        <Drawer
            container={container}
            variant="temporary"
            open={props.open}
            onClose={props?.toggleDrawer||props?.toggleDrawerSetting}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                mt:4,
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    borderRadius:"10px",
                    whiteSpace: 'nowrap',
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    marginTop: '44px',
                    background: theme.palette.background.default
                },
            }}
        >
            {props.children}
        </Drawer>
    )
}

export default MobileMenuSetting 