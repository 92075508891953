import { FC } from 'react';
import MicrosoftLogin from 'react-microsoft-login';

import CustomButton from '../../common/CustomButton/CustomButton';
import MicrosoftIcon from './MicrosoftIcon';
import { FormattedMessage } from 'react-intl';
import useMicrosoftLogin from './useMicrosoftLogin';
import config from '../../../config/config';

interface IProps {}

const Auth0Login: FC<IProps> = ({}) => {
  const { loading, error, authHandler } = useMicrosoftLogin();

  return (
    <div>
      <MicrosoftLogin
        clientId={config.microsoft.clientId}
        authCallback={authHandler}
        redirectUri={config.auth0.redirectUri}
      >
        <CustomButton
          startIcon={<MicrosoftIcon />}
          color="primary"
          isLoading={loading}
          sx={{
            width: '235px',
            height: '40px',
            backgroundColor: '#F3F3F3',
            borderRadius: '4px',
            // On hover
            '&:hover': {
              backgroundColor: '#F3F3F3',
            },
          }}
        >
          <FormattedMessage
            id="signin.microsoft"
            defaultMessage="Sign in with Microsoft"
          />
        </CustomButton>
      </MicrosoftLogin>
    </div>
  );
};

export default Auth0Login;
