import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { GET_TODO_SUB_TASK } from './useGetTodoSubTask';

const DELETE_TODO_SUBTASK = graphql(`
  mutation DeleteTodoSubTask($todoSubTaskId: Float!) {
    deleteTodoSubTask(todoSubTaskId: $todoSubTaskId) {
      id
      title
    }
  }
`);

const useDeleteTodoSuTask = () => {
  const [deleteTodoSubTask, { loading }] = useMutation(DELETE_TODO_SUBTASK);
  const deleteSubTask = (todoId: number, todoSubTaskId: number) => {
    deleteTodoSubTask({
      variables: {
        todoSubTaskId,
      },

      update(cache, { data }) {
        if (!data) {
          return;
        }
        const existingSubTask = cache.readQuery({
          query: GET_TODO_SUB_TASK,
          variables: {
            todoId,
          },
        });
        const filteredSubTask: any = existingSubTask?.getTodoSubTask.filter(
          (subtask) => subtask.id != data.deleteTodoSubTask.id
        );
        cache.writeQuery({
          query: GET_TODO_SUB_TASK,
          variables: {
            todoId,
          },
          data: {
            getTodoSubTask: [...filteredSubTask],
          },
        });
      },
    });
  };

  return {
    deleteSubTask,
    loading,
  };
};

export default useDeleteTodoSuTask;
