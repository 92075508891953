import { useMutation } from '@apollo/client';
import { graphql } from '../gql';
import useConfirmDialog from './useConfirmDialog';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { GET_MY_BOTS_LIST } from './usGetMyBotsList';
import { Bot } from '../gql/graphql';

const DELETE_CUSTOM_BOT = graphql(`
  mutation DeleteCustomBot($botId: Float!) {
    deleteCustomBot(botId: $botId) {
      baseBot {
        id
        name
      }
      id
      name
      removed
      systemPrompt
      user {
        id
        profilePicture
      }
      description
      greetingMessage
    }
  }
`);

const useDeleteCustomBot = () => {
  const { displayConfirmDialog } = useConfirmDialog();
  const [deleteCustomBot, { loading }] = useMutation(DELETE_CUSTOM_BOT);
  const { displayMessage } = useDisplayMessage();
  const handleDeleteCustomBot = (botId: number) => {
    displayConfirmDialog({
      message: 'view.delete.title.dialog',
      onConfirm: () => {
        deleteCustomBot({
          variables: {
            botId,
          },
          update(cache, { data }) {
            if (!data) {
              return;
            }

            const existingBots: any = cache.readQuery({
              query: GET_MY_BOTS_LIST,
              variables: { botType: 'PERSONAL' },
            });

            const filteredBots: any = existingBots?.getMyBotsList.filter(
              (bot: Bot) => bot.id !== data.deleteCustomBot.id
            );

            cache.writeQuery({
              query: GET_MY_BOTS_LIST,
              data: {
                getMyBotsList: filteredBots,
              },
              variables: { botType: 'PERSONAL' },
            });
            displayMessage({
              message: 'bot.delete.success',
              type: 'success',
            });
          },
          onError: (error) => {
            console.log('error', error);
            displayMessage({
              message: 'bot.delete.error',
              type: 'error',
            });
          },
        });
      },
    });
  };
  return { handleDeleteCustomBot, loading };
};
export default useDeleteCustomBot;
