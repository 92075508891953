import { useEffect } from 'react';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
const MARK_ALL_NOTIFICATION_IN_SESSION_AS_READ = graphql(`
  mutation MarkAllNotificationsInSessionAsRead($sessionId: Float!) {
    markAllNotificationsInSessionAsRead(sessionId: $sessionId) {
      id
      todoChat {
        id
        todo {
          id
          todoUnreadNotificationsCount
          project {
            id
            projectUnreadNotificationsCount
          }
        }
      }
      sessionUnreadNotificationsCount
    }
  }
`);

const useNotificationReader = () => {
  const params = useParams();
  const [markAllNotificationsInSessionAsRead] = useMutation(
    MARK_ALL_NOTIFICATION_IN_SESSION_AS_READ
  );
  useEffect(() => {
    if (params?.sessionId && +params?.sessionId > 0) {
      markAllNotificationsInSessionAsRead({
        variables: {
          sessionId: +params?.sessionId,
        },
      });
    }
  }, [params?.sessionId]);
  return {};
};
export default useNotificationReader;
