import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlertText from '../AlertText/AlertText';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';

interface Props {
  message?: string;
  messageIntl?: string;
}

const ErrorMessageIndication: React.FC<Props> = ({ message, messageIntl }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <CustomBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      {messageIntl ? (
        <AlertText severity="error" message={messageIntl} />
      ) : (
        <CustomTypography variant="h6" align="center" gutterBottom>
          <FormattedMessage id={message} />
        </CustomTypography>
      )}

      <Button variant="contained" color="secondary" onClick={handleReload}>
        <FormattedMessage id="common.reload" defaultMessage="Reload" />
      </Button>
    </CustomBox>
  );
};

export default ErrorMessageIndication;
