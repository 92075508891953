import { Box, ClickAwayListener, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomContentEditable from '../../../../../common/CustomContentEditable/CustomContentEditable';
import useRenameLabel from '../../../../hooks/useRenameLabel';
import LabelTagContext from './LabelTagContext/LabelTagContext';

interface TagProps {
  label: string;
  onDelete?(): void;
  handleRenameLabel?(): void;
  labelColor?: string;
  labelId: number;
  showLabel:boolean
  isWithAction?: boolean;
  actionbgColor?: string;
}
function Tag(props: TagProps) {
  const {
    label,
    actionbgColor,
    handleRenameLabel,
    labelColor,
    onDelete,
    isWithAction,
    showLabel,
    labelId,
    ...other
  } = props;
  const { renameLabel } = useRenameLabel();

  const [isEdit, setIsEdit] = React.useState(false);
  const [newName, setNewName] = React.useState(label);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const intl = useIntl();

  const onChange = (e: any) => {
    const { textContent } = e.currentTarget;
    setNewName(textContent);
  };
  const handleSaveRenameLabel = () => {
    if (newName.trim()) {
      renameLabel(newName, labelId);
    }
    setIsEdit(false);
  };
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSaveRenameLabel();
    }
  };
  const contentEditabelStyle = {
    padding: ' 0 2px 0 2px',
    lineHeight: '16px',
    height: '22px',
    outline: 'none',
    margin: '2px',
    color: isDark ? actionbgColor : labelColor,
    transition: 'all 0.1s',
    borderRadius: '6px',
    maxWidth: '150px',
    border: `2px solid ${isDark ? actionbgColor : labelColor}`,
    cursor: 'pointer',
  };
  return (
    <>
      {!isEdit ? (
        <div
          {...other}
          style={{
            color: labelColor,
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          {showLabel && (
            <>
              <SellOutlinedIcon fontSize="small" />
              <span>{label}</span>
              {isWithAction && (
                <div className="icon-container">
                  <LabelTagContext
                    labelId={labelId}
                    defaultColor={actionbgColor}
                    handleOpenRenameLabel={() => setIsEdit(true)}
                  />
                  <CloseIcon className="icon-button" onClick={onDelete} />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <ClickAwayListener onClickAway={handleSaveRenameLabel}>
          <Box>
            <CustomContentEditable
              labelContent={newName}
              isDisable={false}
              style={contentEditabelStyle}
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
}

const TodoLabelTag = styled(Tag)<TagProps>(
  ({ theme, actionbgColor }) => `
      display: flex;
      align-items: center;
      min-height: 4px;
      max-height: 18px;
      min-width:10px;
      margin: 2px;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 0 4px 0 2px;
      max-width: 150px;
      overflow: hidden;
      & span {
        overflow: hidden;
        white-space: nowrap;
        opacity: 1;
        font-size:11px;
        margin-right: 2px;
        margin-top: -2px;
        text-overflow: ellipsis;
        flex: 1
      }
      .icon-container {
        display: flex;
        position: absolute;
        right: 0;
        background-color: ${actionbgColor};
       
      } 
      .icon-button {
        display: none;
      }
      &:hover .icon-button {
        display: block;
      }
      & svg {
        font-size: 12px;
        cursor: pointer;
        padding: 4px;
        border-radius: 4px;
        &:hover{
          background: ${theme.palette.customColor.scrollColor};
      }
      }
    `
);
export default TodoLabelTag;
