import { useTheme } from '@mui/material';
import * as React from 'react';

const GanttIcon = () => {
  const theme = useTheme();
  const color = theme.palette.mode === 'light' ? 'grey' : 'white';
  return (
    <>
      <svg
        fill={color}
        width="15"
        height="15"
        viewBox="0 -16 544 544"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M146.67 32h378.66C535.6 32 544 40.4 544 50.67v74.66c0 10.27-8.4 18.67-18.67 18.67H146.67c-10.27 0-18.67-8.4-18.67-18.67V50.67C128 40.4 136.4 32 146.67 32zm-32.01 336h250.68c10.26 0 18.66 8.4 18.66 18.66v74.68c0 10.26-8.4 18.66-18.66 18.66H114.66C104.4 480 96 471.6 96 461.34v-74.68c0-10.26 8.4-18.66 18.66-18.66zM18.67 224h250.66c10.27 0 18.67 8.4 18.67 18.67v74.66c0 10.27-8.4 18.67-18.67 18.67H18.67C8.4 336 0 327.6 0 317.33v-74.66C0 232.4 8.4 224 18.67 224z" />
      </svg>
    </>
  );
};

export default GanttIcon;
