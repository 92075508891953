// Component to display list of project members in the project using material ui list component
// Project id is passed as props

import * as React from 'react';
import { useQuery } from '@apollo/client';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
//import { graphql } from '../../../../../../gql';
import ListUserByAvatar from './ListUserByAvatar/ListUserByAvatar';
//import SeclectRole from './SeclectRole/SeclectRole';
import useWindowDimensions from '../../../../Hooks/useWindowDimensions';
import { Member, MemberRole } from '../type';
import Loading from '../../Loading/Loading';
import SeclectRole from '../SeclectRole/SeclectRole';
import CustomTypography from '../../CustomTypography/CustomTypography';
import UserAvatar from '../../UserAvatar/UserAvatar';

interface IProps {
  isOnlyAvatar?: boolean;
  members: Member[];
  isLoadingMembers?: boolean;
  roles: MemberRole[];
  handleRemoveMember?(memberId: number): void;
  handleUpdateMember?(role: string, memberId: number): void;
}

const MemberList: React.FC<IProps> = ({
  isOnlyAvatar,
  members,
  isLoadingMembers,
  roles,
  handleRemoveMember,
  handleUpdateMember,
}) => {
  const { width } = useWindowDimensions();

  const hasAction = (role: string) => {
    if (role && role !== 'OWNER') {
      return true;
    } else {
      return false;
    }
  };

  const reduceUserName = (name: string) => {
    if ((width as number) < 442 && name.length > 10) {
      return name.slice(0, 7) + '...';
    } else {
      return name;
    }
  };

  if (isLoadingMembers) return <Loading />;

  return (
    <>
      {isOnlyAvatar ? (
        <ListUserByAvatar users={members} />
      ) : (
        <List sx={{ width: '100%', alignItems: 'flex-start', mt: 1 }}>
          {members.map((member: Member) => (
            <ListItem
              secondaryAction={
                <>
                  {hasAction(member.role) ? (
                    <SeclectRole
                      style={{ marginTop: '13px' }}
                      isUpdate={true}
                      initRole={member.role}
                      memberId={member.id}
                      user={{
                        id: member.user?.id,
                      }}
                      roles={roles}
                      handleRemove={handleRemoveMember}
                      handleUpdate={handleUpdateMember}
                    />
                  ) : (
                    <Typography
                      style={{ marginTop: '13px' }}
                      variant="body2"
                      textTransform="capitalize"
                    >
                      {member.role.toLowerCase()}
                    </Typography>
                  )}
                </>
              }
              key={member.id}
              alignItems="flex-start"
              sx={{
                width: '100%',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemAvatar>
                <UserAvatar
                  sx={{ width: 29, height: 29 }}
                  alt={member.user?.email}
                  userProfil={member?.user?.profilePicture}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ fontSize: 13 }}
                primary={
                  <>
                    <Typography variant="h5" fontSize={13}>
                      {member.user
                        ? reduceUserName(
                            member.user?.firstName + ' ' + member.user?.lastName
                          )
                        : member.email}
                    </Typography>
                    <CustomTypography variant="body2" fontSize={13}>
                      {member.user ? (
                        member.email
                      ) : (
                        <span style={{ textTransform: 'capitalize' }}>
                          ({member.invitationStatus.toLowerCase()})
                        </span>
                      )}
                    </CustomTypography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default MemberList;
