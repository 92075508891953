import React from 'react';
import { graphql } from '../../../../../gql';
import { useQuery } from '@apollo/client';
import Loading from '../../../../common/Loading/Loading';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { Todo } from '../../../../../gql/graphql';
import UserAvatar from '../../../../common/UserAvatar/UserAvatar';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    textTransform: 'capitalize',
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const USER_ASIGNEE_TO_TODO = graphql(`
    query GetUserAsigneeTodo($todoId: Float!, $take: Int) {
        todo(id: $todoId) {
            id
            asignees(take: $take) {
                id
                assignedTo {
                    id
                    firstName
                    profilePicture
                }
            }
        }
    }
`);

// FC component

interface IProps {
    todo?: Todo;
}

const UserAsignee: React.FC<IProps> = ({ todo }) => {
    // Query to get user asign to todo
    const { data, loading, error } = useQuery(USER_ASIGNEE_TO_TODO, {
        variables: {
            todoId: todo?.id as number,
            take: 1
        },
        fetchPolicy: 'cache-only'
    });

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return null
    }

    const task = todo ? todo : data?.todo

    return (
        <>
            {task?.asignees && task?.asignees?.length ?
                <StyledTooltip title={task?.asignees[0].assignedTo?.firstName}>
                    <span>
                        <UserAvatar
                            alt="user"
                            sx={{width: 21, height: 21}}
                            userProfil={task?.asignees[0].assignedTo?.profilePicture as string}
                        />
                    </span>
                </StyledTooltip>
                : null}
        </>
    );
};

export default UserAsignee;
