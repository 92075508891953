import { Mixpanel } from '../../../mixpanel/mixpanel';
import useCreateChatSession from './useCreateChatSession';
import useNavigateToChatSession from './useNavigateToChatSession';

const useCreateAndGoChatSession = ({ todoId }: { todoId?: number }) => {
  const goToChatSession = useNavigateToChatSession();
  const { createChatSession } = useCreateChatSession({
    todoId,
  });
  const onCreateAndGoToChatSession = (bot: any) => {
    Mixpanel.track('Start Conversation with Bot', {
      botUserId: bot.user.id,
      botSlug: bot.slug,
    });
    // Create a new chat session
    createChatSession({
      variables: {
        data: {
          todoId,
          defaultBotId: bot.id,
          title: bot.name,
        },
      },
    }).then((res) => {
      if (res?.data?.createTodoChatSession)
        goToChatSession({
          todoId,
          chatSessionId: res?.data?.createTodoChatSession?.id,
          dataSources: res?.data?.createTodoChatSession?.dataSources,
        });
    });
  };
  return{onCreateAndGoToChatSession}
};
export default useCreateAndGoChatSession;
