import { useState } from 'react';
import useBotAttributes from '../../../../Hooks/useBotAttributes';

const useBotAttributesUtils = ({ botId }: { botId: number }) => {
  const { data } = useBotAttributes({ botId });
  const [openBotDemo, setOpenBotDemo] = useState(false);
  const attributes = data?.bot?.attribute;
  const bot = data?.bot;

  const handleCloseBotDemo = () => {
    setOpenBotDemo(false);
  };
  const handleOpenBotDemo = () => {
    setOpenBotDemo(true);
  };
  return {
    openBotDemo,
    handleCloseBotDemo,
    handleOpenBotDemo,
    attributes,
    bot,
  };
};
export default useBotAttributesUtils;
