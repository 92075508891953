import CustomButton from '../../../../../common/CustomButton/CustomButton';
import Avatar from '@mui/material/Avatar';
import { Bot } from '../../../../../../gql/graphql';
import { useContextFormChat } from '../../../../hooks/useContextFormChat';
import CustomIconButton from '../../../../../common/CustomIconButton/CustomIconButton';
import { useMediaQuery } from '@mui/system';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface props {
  defaultBot: Bot;
}
const SwitchBotAction = ({ defaultBot }: props) => {
  const { handleToogleDialogSwitchBot } = useContextFormChat();

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      {defaultBot?.name ? (
        <>
          {isMobile ? (
            <CustomIconButton onClick={handleToogleDialogSwitchBot}>
              <Avatar
                alt="Remy Sharp"
                src={defaultBot?.user?.profilePicture || ''}
                sx={{ width: 25, height: 25 }}
              />
            </CustomIconButton>
          ) : (
            <CustomButton
              variant="outlined"
              startIcon={
                <Avatar
                  alt="Remy Sharp"
                  src={defaultBot?.user?.profilePicture || ''}
                  sx={{ width: 20, height: 20 }}
                />
              }
              endIcon={<KeyboardArrowUpIcon />}
              sx={{
                fontSize: '1rem',
                border: 'none',
                backgroundColor: (theme) =>
                  theme.palette.customColor.whiteColor,
                height: (theme) => theme.spacing(3.5),
                marginLeft: (theme) => theme.spacing(0.5),
                marginTop: (theme) => theme.spacing(1.7),
                color: (theme) => theme.palette.common.black,
                '&:hover': {
                  backgroundColor: (theme) =>
                    theme.palette.customColor.messageBackground,
                  border: 'none',
                },
              }}
              onClick={handleToogleDialogSwitchBot}
            >
              {defaultBot?.name}
            </CustomButton>
          )}
        </>
      ) : null}
    </>
  );
};
export default SwitchBotAction;
