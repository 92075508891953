import * as React from 'react';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, Menu, Paper, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItemList from './MenuItemList/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { graphql } from '../../../../gql';
import { useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { User } from '../../../../gql/graphql';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { MemberRole } from '../type';
const USER_INFO = graphql(/* GraphQL */ `
  query UserInfo {
    me {
      id
    }
  }
`);
interface IProps {
  initRole: string;
  isUpdate?: boolean;
  style?: Object;
  memberId?: number;
  user?: {
    id: number;
  };
  getRole?(role: string): void;
  handleRemove?: (memberId: number) => void;
  handleUpdate?: (role: string, memberId: number) => void;
  roles: MemberRole[];
}

export default function SeclectRole({
  initRole,
  user,
  isUpdate,
  style,
  memberId,
  getRole,
  handleRemove,
  handleUpdate,
  roles,
}: IProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [role, setRole] = React.useState(initRole);

  const { data } = useQuery(USER_INFO, {
    nextFetchPolicy: 'cache-first',
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (newRole: string) => {
    if (!isUpdate && getRole) {
      getRole(newRole);
    } else {
      handleUpdate && handleUpdate(newRole, memberId as number);
    }

    setRole(newRole);
    handleClose();
  };

  const handleDelete = () => {
    handleRemove && handleRemove(memberId as number);
  };
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setRole(role);
  };

  const isUserConnected = user?.id === data?.me.id;

  return (
    <div style={{ height: '100%', ...style }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        size="large"
        disableElevation
        style={{ backgroundColor: 'transparent' }}
        onClick={handleClickListItem}
        endIcon={
          open ? (
            <KeyboardArrowUpIcon color="action" />
          ) : (
            <KeyboardArrowDownIcon color="action" />
          )
        }
      >
        <CustomTypography variant="body2">
          {role.toLocaleLowerCase()}
        </CustomTypography>
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: 125,
          maxWidth: '100%',
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          zIndex: 999999999999,
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <Paper>
          <MenuList sx={{ background: theme.palette.background.default }}>
            {roles.map((roleItem) => (
              <MenuItemList
                handleAction={() => handleEdit(roleItem.value)}
                roleToTest={role}
                role={roleItem.value}
              />
            ))}

            {isUpdate && !isUserConnected && (
              <>
                <Divider sx={{ border: '1px solid #34324A' }} />
                <MenuItem onClick={handleDelete}>
                  <CustomTypography variant="body2">Remove</CustomTypography>
                  <ListItemIcon>
                    <DeleteIcon
                      color="error"
                      sx={{ fontSize: 18, mb: 0.3, ml: 0.5 }}
                    />
                  </ListItemIcon>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Paper>
      </Menu>
    </div>
  );
}
