export const formatNumber = (number: number | undefined) => {
  if (number === undefined) {
    return '0';
  }
  const options = {
    useGrouping: true,
    minimumFractionDigits: 0,
  };

  const formattedNumber = number
    .toLocaleString(undefined, options)
    .replace(/,/g, ' ');
  return formattedNumber;
};

export const isZeroNotNullOrUndefined = (value: number | undefined | null) => {
  return value !== undefined && value !== null && value === 0;
};

export const zeroIfZero = (value: number | undefined) => {
  return value === 0 ? 0 : value;
};

export const zeroOrValueOrDefaultIfUndefined = (
  value: number | undefined | null,
  defaultValue: number
) => {
  const isZero = isZeroNotNullOrUndefined(value);
  return isZero ? 0 : value || defaultValue;
};

export const toFloor = (value: number | undefined | null) => {
  if (!value) {
    return 0;
  }
  return value === undefined ? 0 : Math.floor(value);
};

export const stringToNumber = (value: string | undefined | null) => {
  if (!value) {
    return 0;
  }
  try {
    return parseFloat(value);
  } catch (e) {
    return 0;
  }
};
