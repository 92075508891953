import { useContextFormChat } from '../../../../hooks/useContextFormChat';
import { useState, MouseEvent } from 'react';
import useWindowDimensions from '../../../../../../Hooks/useWindowDimensions';
import { Mixpanel } from '../../../../../../mixpanel/mixpanel';

const useBotTags = () => {
  const { width } = useWindowDimensions();

  const { handleToogleDialogSwitchBot, setBotTagSlug } = useContextFormChat();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transition-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTagClick = (slug: string) => () => {
    Mixpanel.track('Form Chat - Bot Tag Clicked', {
      slug,
    });
    setBotTagSlug(slug);
    handleToogleDialogSwitchBot();
    handleClose();
  };

  const nbrTags = () => {
    if (Number(width) < 450) {
      return 2;
    }

    if (Number(width) < 900) {
      return 3;
    }

    return 4;
  };

  return {
    nbrTags,
    handleTagClick,
    handleClick,
    handleClose,
    id,
    open,
    anchorEl,
  };
};

export default useBotTags;
