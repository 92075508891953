import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import Loading from '../Loading/Loading';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';

const ButtonStyle = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontFamily: 'Lato',
  boxShadow: 'none',
  fontSize: theme.typography.body2.fontSize,
  borderRadius: '8px',
  padding: '8px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    boxShadow: 'none',
  },
}));

export interface CustomButtonProps extends ButtonProps {
  label?: React.ReactNode;
  isLoading?: boolean;
  intlId?: string;
  intlValues?: Record<string, any>;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  isLoading = false,
  onClick,
  intlId,
  children,
  intlValues,
  ...rest
}) => {
  const renderContent = () => {
    if (children) return children;
    if (intlId)
      return (
        <FormattedMessage
          id={intlId}
          defaultMessage={label as string}
          values={intlValues}
        />
      );
    return label;
  };

  return (
    <ButtonStyle onClick={onClick} {...rest}>
      {renderContent()}
      {isLoading && (
        <CustomBox display="flex" gap={1} ml={1}>
          <Loading type="circular" color="inherit" size={15} />
        </CustomBox>
      )}
    </ButtonStyle>
  );
};

export default React.memo(CustomButton);
