import { Box } from '@mui/material';
import CustomDialog from '../../common/CustomDialog/CustomDialog';
import CustomSearchInput from '../../common/CustomSearchInput/CustomSearchInput';
import BodyPrompts from '../BodyPrompts/BodyPrompts';
import { FormattedMessage } from 'react-intl';
import ActionButton from '../BodyPrompts/ActionButton/ActionButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePrompts from './usePrompt';

interface props {
  open: boolean;
  handleClose: () => void;
  handleValidePrompts(prompts: string): void;
}

const ModalPrompts = ({ open, handleClose, handleValidePrompts }: props) => {
  const {
    searchValue,
    isFocused,
    activeStep,
    isMobile,
    intl,
    handlePreviousStep,
    handleSearchChange,
    handleFocus,
    handleBlur,
  } = usePrompts();

  return (
    <CustomDialog
      open={open}
      leftItem={
        activeStep > 0 && isMobile ? (
          <ActionButton
            isDisabled={false}
            handleClick={() => handlePreviousStep()}
            style={{
              justifyContent: 'flex-start',
              minWidth: '35px',
              margin: '0px 5px 0px -15px',
            }}
          >
            <ArrowBackIcon />
          </ActionButton>
        ) : null
      }
      title={<FormattedMessage id="libraries.prompts.modal.title" />}
      scroll="paper"
      maxWidth="lg"
      sx={{
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
      }}
      handleClose={handleClose}
      contentChildren={
        <>
          {!isMobile && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 'calc(17% + 8px)',
                width: isFocused ? '60%' : 'calc(24% + 8px)',
                transition: 'width 0.2s ease-in-out',
                mt: '8px',
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >
              <CustomSearchInput
                searchInputPlaceholder={intl.formatMessage({
                  id: 'search.prompts.placeholder',
                })}
                searchValue={searchValue}
                onChangeInputSearch={handleSearchChange}
              />
            </Box>
          )}
          <BodyPrompts
            handleValidePrompts={(prompts) => {
              handleValidePrompts(prompts);
              handleClose();
            }}
            searchValue={searchValue}
            handleSearchChange={handleSearchChange}
            isMobile={isMobile}
          />
        </>
      }
    />
  );
};

export default ModalPrompts;
