import React, { useState, FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomDialog from '../common/CustomDialog/CustomDialog';
import CustomButton from '../common/CustomButton/CustomButton';
import STDynamiqueForm from '../common/STDynamicForm/STDynamicForm';
import { FormFieldType } from '../../gql/graphql';
import { Menu, MenuItem } from '@mui/material';
import CustomTypography from '../common/CustomTypography/CustomTypography';
import useCanceledSubsriptionPlan from '../../Hooks/useCanceledSubsriptionPlan';
import AlertText from '../common/AlertText/AlertText';

const CanceledPlans: FC = () => {
  const intl = useIntl();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [radioSelection, setRadioSelection] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState<string>('');
  const [isCanceledPlansOpen, setIsCanceledPlansOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (isCanceledPlansOpen) {
      setIsConfirmed(false);
      setRadioSelection(null);
      setOtherReason('');
    }
  }, [isCanceledPlansOpen]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFormChange = (field: string, value: any) => {
    if (field === 'otherReason') {
      setOtherReason(value);
      return;
    }
    setRadioSelection(value);
  };
  const handleToggleCanceledPlans = () => {
    setIsCanceledPlansOpen((lasteState) => !lasteState);
  };

  const { handleCanceledSubscriptionPlan, loading } =
    useCanceledSubsriptionPlan(handleToggleCanceledPlans);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    if (isConfirmed) {
      handleCanceledSubscriptionPlan(otherReason || radioSelection || '');
      return;
    }
    setIsConfirmed(true);
  };

  return (
    <>
      <CustomButton
        variant="outlined"
        onClick={handleOpenMenu}
        fullWidth={true}
      >
        <CustomTypography>
          <FormattedMessage id="subscription.handle" />
        </CustomTypography>
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleToggleCanceledPlans}>
          <FormattedMessage id="subscription.cancel" />
        </MenuItem>
      </Menu>

      <CustomDialog
        open={isCanceledPlansOpen}
        handleClose={handleToggleCanceledPlans}
        title={intl.formatMessage({ id: 'subscription.cancel' })}
        contentChildren={
          isConfirmed ? (
            <>
              <STDynamiqueForm
                fields={[
                  {
                    formName: 'radioSelection',
                    label: intl.formatMessage({
                      id: 'subscription.cancel.title.reason',
                    }),
                    type: FormFieldType.Radio,
                    required: true,
                    options: [
                      {
                        fieldId: 0,
                        id: 0,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.1',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.1',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 1,
                        id: 1,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.2',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.2',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 2,
                        id: 2,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.3',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.3',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 3,
                        id: 3,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.4',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.4',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 4,
                        id: 4,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.5',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.5',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 5,
                        id: 5,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.6',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.6',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 6,
                        id: 6,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.7',
                        }),
                        value: intl.formatMessage({
                          id: 'subscription.cancel.reason.7',
                        }),
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                      {
                        fieldId: 7,
                        id: 7,
                        label: intl.formatMessage({
                          id: 'subscription.cancel.reason.8',
                        }),
                        value: 'other',
                        createdAt: undefined,
                        removed: false,
                        updatedAt: undefined,
                      },
                    ],
                    createdAt: new Date(),
                    id: 0,
                    removed: false,
                    updatedAt: new Date(),
                  },
                  ...(radioSelection === 'other'
                    ? [
                        {
                          formName: 'otherReason',
                          label: intl.formatMessage({
                            id: 'subscription.cancel.reason',
                          }),
                          type: FormFieldType.Textarea,
                          required: true,
                          createdAt: new Date(),
                          id: 1,
                          options: [],
                          removed: false,
                          updatedAt: new Date(),
                        },
                      ]
                    : []),
                ]}
                currentValues={{ radioSelection, otherReason }}
                onChange={handleFormChange}
              />
            </>
          ) : (
            <CustomTypography mt={2} color="error">
              <AlertText
                id="subscription.cancel.title.warning"
                severity="warning"
              />
            </CustomTypography>
          )
        }
        actionChildren={
          <>
            <CustomButton
              onClick={handleToggleCanceledPlans}
              color="secondary"
              variant="contained"
              disabled={loading}
            >
              <FormattedMessage id="subscription.cancel.button.cancel" />
            </CustomButton>
            <CustomButton
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disabled={(!radioSelection && isConfirmed) || loading}
              isLoading={loading}
            >
              <FormattedMessage
                id={
                  isConfirmed
                    ? 'subscription.cancel.confirm'
                    : 'subscription.cancel.button.next'
                }
              />
            </CustomButton>
          </>
        }
      />
    </>
  );
};

export default CanceledPlans;
