import { FC } from 'react';
import { Todo } from '../../../../../../gql/graphql';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import Stack from '@mui/material/Stack';
import { Divider, useTheme } from '@mui/material';
import parseDate from '../../../../../../utils/parseDate';
import ButtonTaskAction from '../../../../../MyDay/FormAddTask/ButtonTaskAction/ButtonTaskAction';
import DueDate from '../../../DueDate/DueDate';
import TodayIcon from '../../../SvgIcon/TodayIcon';
interface IProps {
  todo: Todo;
}

const TodoSchedule: FC<IProps> = ({ todo }) => {
  const theme = useTheme();
  return (
    <CustomBox>
      <Stack
        mt={3}
        direction="row"
        justifyContent="flex-start"
        display="flex"
        flexWrap="wrap"
        id="chat-due-date"
      >
        <CustomBox width={'40%'}>
          <DueDate
            todoID={todo.id}
            dueDateInput={todo.startDate}
            isEditMode={true}
            isForStartDate={true}
          >
            <ButtonTaskAction
              bgColor={'white'}
              label={parseDate(todo.startDate, true)}
              style={{
                fontSize: theme.typography.caption.fontSize,
                mt: 0,
              }}
              labelTooltip="Set start date"
              icon={<TodayIcon />}
            />
          </DueDate>
        </CustomBox>
        <Divider
          orientation="vertical"
          sx={{
            ml: 2,
            height: 25,
            mt: 0.3,
            borderLeft: `1px ridge ${theme.palette.grey[200]}`,
          }}
          flexItem
        />
        <CustomBox ml={2} width={'40%'}>
          <DueDate
            todoID={todo.id}
            dueDateInput={todo.dueDate}
            isEditMode={true}
          >
            <ButtonTaskAction
              bgColor={'white'}
              label={parseDate(todo.dueDate)}
              style={{
                fontSize: theme.typography.caption.fontSize,
                mt: 0,
              }}
              labelTooltip="Set due date"
              icon={<TodayIcon />}
            />
          </DueDate>
        </CustomBox>
      </Stack>
    </CustomBox>
  );
};

export default TodoSchedule;
