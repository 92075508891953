import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';

// GraphQL query to get the modal open/close state
const GET_MODAL_STATE = gql`
  query getDisplayUpgradePlan {
    displayUpgradePlan @client {
      isOpen
      __typename
    }
  }
`;

// TypeScript interface for the structure of the modal state
interface DisplayUpgradePlanState {
  isOpen: boolean;
  __typename: string;
}

// Hook to manage the modal state
const useModalUpgradePlan = () => {
  const client = useApolloClient();
  const { data } = useQuery(GET_MODAL_STATE);

  // Function to open the modal
  const openUpgradePlanModal = useCallback(() => {
    client.writeQuery({
      query: GET_MODAL_STATE,
      data: {
        displayUpgradePlan: {
          isOpen: true,
          __typename: 'DisplayUpgradePlanState',
        },
      },
    });
  }, [client]);

  // Function to close the modal
  const closeUpgradePlanModal = useCallback(() => {
    client.writeQuery({
      query: GET_MODAL_STATE,
      data: {
        displayUpgradePlan: {
          isOpen: false,
          __typename: 'DisplayUpgradePlanState',
        },
      },
    });
  }, [client]);

  // Function to toggle modal open/close state
  const toggleUpgradePlanModal = useCallback(() => {
    client.writeQuery({
      query: GET_MODAL_STATE,
      data: {
        displayUpgradePlan: {
          isOpen: !data?.displayUpgradePlan.isOpen,
          __typename: 'DisplayUpgradePlanState',
        },
      },
    });
  }, [client, data?.displayUpgradePlan.isOpen]);

  // Function to get the modal state
  const getDisplayUpgradePlan = (): DisplayUpgradePlanState | undefined => {
    const data = client.readQuery({
      query: GET_MODAL_STATE,
    });
    return data?.displayUpgradePlan;
  };

  const isOpenModal = data?.displayUpgradePlan.isOpen;

  return {
    openUpgradePlanModal,
    toggleUpgradePlanModal,
    getDisplayUpgradePlan,
    closeUpgradePlanModal,
    isOpenModal,
  };
};

export default useModalUpgradePlan;
