import CustomBox from '../../../common/CustomBox/CustomBox';
import LockSession from './LockSession/LockSession';
import CreateSession from './CreateSession/CreateSession';
import { FC } from 'react';
import useInfiniteChat from '../../../../Hooks/useInfiniteChat';

interface IProps {
  todoId: number;
}
const Header: FC<IProps> = ({ todoId }) => {
  const isChatPage = useInfiniteChat();
  return (
    <CustomBox
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'flex-end',
        mr: 1,
      }}
    >
      {isChatPage ? <LockSession /> : null}

      <CustomBox mt={1}>
        <CreateSession todoId={todoId} />
      </CustomBox>
    </CustomBox>
  );
};

export default Header;
