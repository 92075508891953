import useGetBotSuggestion from '../../../../../Hooks/botSuggestions/useGetBotSuggestion';
import useCreateAndGoChatSession from '../../../hooks/useCreateAndGoToChatSession';
import useGetChatContext from '../../ChatBodyContainer/useGetChatContext';

interface IProps {
  messageId: number;
}

const useBotSuggestions = ({ messageId }: IProps) => {
  const { todoId } = useGetChatContext();
  const { botSuggestions } = useGetBotSuggestion({ messageId });
  const { onCreateAndGoToChatSession } = useCreateAndGoChatSession({
    todoId,
  });

  const handleBotClick = (bot: any) => {
    onCreateAndGoToChatSession(bot);
  };
  return {
    botSuggestions,
    handleBotClick,
  };
};

export default useBotSuggestions;
