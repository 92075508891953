import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

export const GET_TASK_DESCRIPTION = gql`
  query getTodoDescription {
    todoDescrition @client {
      todoId
      isDisplay
    }
  }
`;

const useDisplayTodoDescriptionSidebar = () => {
  const { data: dataselected } = useQuery(GET_TASK_DESCRIPTION);
  const currentTodoId = dataselected?.todoDescrition.todoId as number;
  const isDisplay = dataselected?.todoDescrition.isDisplay as boolean;
  
  const { taskID: isInChat } = useParams();

  const client = useApolloClient();

  const displayDescriptionSidbar = (todoId?: number) => {
    if (isInChat) {
      return;
    } else {
      client.writeQuery({
        query: GET_TASK_DESCRIPTION,
        data: {
          todoDescrition: {
            todoId,
            isDisplay: true,

            __typename: 'DisplayDescriptionSidbar',
          },
        },
      });
    }
  };
  const hiddeDescriptionSidbar = () => {
    client.writeQuery({
      query: GET_TASK_DESCRIPTION,
      data: {
        todoDescrition: {
          todoId: 0,
          isDisplay: false,

          __typename: 'DisplayDescriptionSidbar',
        },
      },
    });
  };

  return {
    displayDescriptionSidbar,
    hiddeDescriptionSidbar,
    isDisplay,

    currentTodoId,
  };
};

export default useDisplayTodoDescriptionSidebar;
