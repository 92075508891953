import React, { Suspense } from 'react';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

interface props {
    children: React.ReactNode;
    isPrintError: Boolean;
    size: number
    loadingComponente?: React.ReactNode;
}
const LazyBoundary = ({ children, isPrintError,loadingComponente, size }: props) => (
    <ErrorBoundary isPrintError={isPrintError}>
        <Suspense fallback={loadingComponente}>
            {children}
        </Suspense>
    </ErrorBoundary>
);

export default LazyBoundary