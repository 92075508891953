type SourceNumbers = {
  datasourceId: number;
  page: number;
} | null;
const getMetaSourceFromString = (str: string): SourceNumbers => {
  const pattern = /#source-(\d+)-(\d+)/;
  const match = str.match(pattern);

  if (match && match.length === 3) {
    // Parse the captured groups to integers.
    const datasourceId = parseInt(match[1], 10);
    const page = parseInt(match[2], 10);

    return { datasourceId, page };
  }

  return null;
};

export default getMetaSourceFromString;
