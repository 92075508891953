import { FC } from 'react';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import STPaper from '../../../common/STPaper/STPaper';
import CustomLink from '../../../common/CustomLink/CustomLink';
import CustomBox from '../../../common/CustomBox/CustomBox';
import STNotificationBadge from '../../../common/STNotificationBadge/STNotificationBadge';
import {
  CalendarMonthOutlined,
  CalendarTodayOutlined,
} from '@mui/icons-material';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';

interface IProps {
  project: {
    id: number;
    name: string;
    description?: string | null | undefined;
    createdAt: any;
    projectUnreadNotificationsCount: number;
  };
  isForSearch?: boolean;
}

const ProjectCard: FC<IProps> = ({ project,isForSearch }) => {
  const { workspaceId } = useParams();
  return (
    <CustomLink to={`../../../${workspaceId}/project/${project.id}` || ''}>
      <STPaper
        sx={{
          padding: (theme) => theme.spacing(2),
          height: (theme) => isForSearch? theme.spacing(11):theme.spacing(18),
          position: 'relative',
        }}
      >
        <CustomBox>
          <CustomTypography variant="h6" fontWeight="bold">
            {project.name}
          </CustomTypography>
        </CustomBox>
        {project.projectUnreadNotificationsCount ? (
          <CustomBox
            sx={{
              position: 'absolute',
              top: '-8px',
              right: '-8px',
            }}
          >
            <STNotificationBadge
              count={project.projectUnreadNotificationsCount}
            />
          </CustomBox>
        ) : null}
        <CustomBox
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: (theme) => theme.spacing(2),
            left: (theme) => theme.spacing(1.5),
          }}
        >
          <CustomTypography
            variant="caption"
            sx={{
              // Gray color
              color: (theme) => theme.palette.grey[500],
              display: 'flex',
            }}
          >
            <CalendarTodayOutlined
              sx={{
                marginRight: (theme) => theme.spacing(0.5),
              }}
            />
            <Moment fromNow>{project.createdAt}</Moment>
          </CustomTypography>
        </CustomBox>
      </STPaper>
    </CustomLink>
  );
};

export default ProjectCard;
