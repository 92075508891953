// A FC component that displays todo project name and add icon at the right side to open a popover to select other project

import { FC } from 'react';
import React from 'react';
import { graphql } from '../../../../../gql';
import { useQuery } from '@apollo/client';
import Loading from '../../../../common/Loading/Loading';
import STMenu from '../../../../common/STMenu/STMenu';
import ProjectMenuItem from '../ProjectMenuList/ProjectMenuItem/ProjectMenuItem';

const GET_MY_PROJECTS_SECTION = graphql(`
  query Project($projectId: Float!, $where: ProjectSectionWhereInput) {
    project(id: $projectId) {
      sections(where: $where) {
        id
        name
      }
    }
  }
`);

interface IProps {
  anchorElSection: any;
  projectId: number;
  selectedProjectSection?: string;
  handleSelectProjectSection: (project: any) => void;
  handleCloseSection(): void;
}

const ProjectSectionMenuList: FC<IProps> = ({
  anchorElSection,
  projectId,
  selectedProjectSection,
  handleSelectProjectSection,
  handleCloseSection,
}) => {
  const { data, loading } = useQuery(GET_MY_PROJECTS_SECTION, {
    variables: {
      projectId: projectId,
      where: {
        removed: {
          equals: false,
        },
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  const projectSectionData = data?.project.sections || [];
  const menuItems = [
    ...projectSectionData.map((section: any) => ({
      component: (
        <ProjectMenuItem
          key={section.id}
          roleToTest={selectedProjectSection as string}
          role={section.name}
        />
      ),
      onClick: () => {
        handleSelectProjectSection(section);
        handleCloseSection();
      },
    })),
  ];
  // Return list of projects in a Material ui Popover component
  if (!data) return null;
  return (
    <>
      {Boolean(anchorElSection) && (
        <STMenu
          anchorEl={anchorElSection}
          open={Boolean(anchorElSection)}
          handleClose={handleCloseSection}
          menuItems={menuItems}
        />
      )}
    </>
  );
};

export default ProjectSectionMenuList;
