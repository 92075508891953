import RestartAltIcon from '@mui/icons-material/RestartAlt';
import useResetChat from '../../../../../Hooks/useResetChat';
import CustomIconButton from '../../../../common/CustomIconButton/CustomIconButton';
import useConfirmDialog from '../../../../../Hooks/useConfirmDialog';

interface props  {
  chatSessionId: number;
}
const ResetChatButton = ({ chatSessionId }: props) => {
    const {displayConfirmDialog}=useConfirmDialog()
  const { onResetChat, loading } = useResetChat({
    sessionId: chatSessionId,
  });
  const handelResetChat = () => {
    displayConfirmDialog({
      message: 'reset.chat.alert.description',
      onConfirm: () => {
        onResetChat();
      },
    });
  };

  return (
    <CustomIconButton
    size='small'
    onClick={() => handelResetChat()}
    intlId={'reset.chat'}
  >
    <RestartAltIcon fontSize="small" />
  </CustomIconButton>
 
  );
};

export default ResetChatButton;
