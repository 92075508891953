import { Divider, Grid, styled } from '@mui/material';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { graphql } from '../../../../gql';
import { useQuery } from '@apollo/client';
import { Download, Visibility } from '@mui/icons-material';
import STList from '../../../common/STList/STList';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { useState } from 'react';
import ImageViewer from '../Attachments/ImageViewer/ImageViewer';
import ListItemLeft from './LIstItemLeft/LIstItemLeft';
import useDownLoadFile from '../../../../Hooks/useDownLoadFile';

const ListContainer = styled(CustomBox)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const GET_TODO_FILES = graphql(`
  query AllFiles($todoId: Float!) {
    todo(id: $todoId) {
      id
      allFiles {
        id
        url
        size
        name
        type
        removed
      }
    }
  }
`);

interface props {
  todoID: number;
}

interface File {
  id: number;
  url: string;
  primaryText: string;
  avatarImg?: string;
  LeftItem?: React.ReactNode;
}

const TodoListFiles = ({ todoID }: props) => {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState('');
  const { downloadFile } = useDownLoadFile();
  const { data } = useQuery(GET_TODO_FILES, {
    variables: {
      todoId: todoID,
    },
  });

  const handleDownload = (item: File) => {
    if (item.url) {
      downloadFile({ fileUrl: item.url });
    }
  };

  const handleViewImage = (item: File) => {
    setSrc(item.url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actions = [
    {
      iconColor: 'info',
      icon: Visibility,
      tooltipIntlId: 'context.menu.image.view',
      onClick: handleViewImage,
    },
    {
      iconColor: 'success',
      icon: Download,
      tooltipIntlId: 'context.menu.download',
      onClick: handleDownload,
    },
  ];

  if (!data?.todo?.allFiles?.length) return null;

  const files = data?.todo.allFiles
    ?.filter((el) => !el.removed)
    ?.map((file) => {
      const isImg = file.type.includes('image');
      return {
        id: file.id,
        url: file.url,
        primaryText: file.name,
        avatarImg: isImg ? file.url : null,
        ListItemLeft: !isImg ? <ListItemLeft fileName={file.name} /> : null,
      };
    });

  const items = files?.map((file) => ({
    ...file,
    actions: actions.map((el) => {
      const isCanView = Boolean(file.avatarImg);
      const isIconView = Boolean(el.icon === Visibility);
      return isIconView
        ? {
            ...el,
            iconColor: isCanView ? el.iconColor : 'disabled',
            disabled: !isCanView,
          }
        : { ...el };
    }),
  })) as any;

  return (
    <>
      <Divider />
      <Grid container py={2}>
        <Grid item mb={0} lg={12} sm={12} md={12} xs={12} xl={12}>
          <CustomTypography variant="h6" color="#34324A" fontWeight="700">
            <FormattedMessage
              id="task.description.attachements"
              defaultMessage={'Attachements'}
            />
          </CustomTypography>
        </Grid>
        <Grid item lg={12} sm={12} md={12} xs={12} xl={12}>
          <ListContainer>
            <STList
              items={items}
              displayAction={'ALWAYS'}
              isPrimaryEditable={false}
              itemTextStyle={{
                maxWidth: 'calc(100% - 100px)',
              }}
            />
          </ListContainer>
          <ImageViewer handleClose={handleClose} open={open} src={src} />
        </Grid>
      </Grid>
    </>
  );
};
export default TodoListFiles;
