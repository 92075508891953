import CustomBox from '../../../../../common/CustomBox/CustomBox';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../../../../common/Skeleton/Skeleton';

interface LoadingSkeletonProps {
  isMobile?: boolean;
}

const LoadingSkeleton = ({ isMobile }: LoadingSkeletonProps) => {
  return (
    <CustomBox
      sx={{
        overflowY: 'auto',
        maxHeight: '80vh',
        width: isMobile ? '100%' : (theme) => theme.spacing(30),
      }}
    >
      <ProviderSkeleton>
        {[...Array(6)].map((_, index) => (
          <CustomBox key={index} mt={1} mb={1}>
            <SkeletonContent height={35} />
          </CustomBox>
        ))}
      </ProviderSkeleton>
    </CustomBox>
  );
};

export default LoadingSkeleton;
