/* eslint-disable react-hooks/exhaustive-deps */
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CategoriePrompts from './CategoriePrompts/CategoriePrompts';
import { useContext, useEffect } from 'react';
import { StepContext } from '../../../Prompts';
import { styled } from '@mui/material';

interface StyledBoxProps {
  isMobile: boolean;
}

const StyledBox = styled(CustomBox, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StyledBoxProps>(({ theme, isMobile }) => ({
  height: isMobile ? 'auto' : theme.spacing(68),
  overflowY: 'auto',
}));

interface Category {
  id: number;
  title: string;
}

interface props {
  handleSelectedPrompt(id: number): void;
  prompts: any[];
  defaultSelectedId: number;
  loading: boolean;
  categorieList: any;
  isMobile: boolean;
}

const SideBarPrompts = ({
  categorieList,
  loading,
  isMobile,
  handleSelectedPrompt,
  defaultSelectedId,
  prompts,
}: props) => {
  const { handleInitCategory } = useContext(StepContext);

  useEffect(() => {
    if (
      categorieList?.categoryGeneral &&
      categorieList?.categoryGeneral.length > 0
    ) {
      const categories = categorieList?.categoryGeneral as Category[];
      if (handleInitCategory) {
        const customCategory = categories.find(
          (category: any) => category.isCustomWorkspace === true
        );

        if (customCategory) {
          handleInitCategory(customCategory.id, customCategory.title);
        } else {
          handleInitCategory(categories[0].id, categories[0].title);
        }
      }
    }
  }, [categorieList]);

  return (
    <StyledBox isMobile={isMobile}>
      <CategoriePrompts
        intlId="libraries.prompts.category.public"
        categories={categorieList?.categoryGeneral || []}
        loading={loading}
        isMobile={isMobile}
        handleSelectedPrompt={handleSelectedPrompt}
        defaultSelectedId={defaultSelectedId}
        prompts={prompts}
      />
      {/*  <CategoriePrompts
        intlId="libraries.prompts.category.workspaces"
        categories={categorieList?.categoryByWorkspace || []}
        hasInput={hasInput}
        hasPlusIcon={true}
        handleToogleShowInput={handleToogleShowInput}
        loading={loading}
      /> */}
    </StyledBox>
  );
};

export default SideBarPrompts;
