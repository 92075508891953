import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import useConfirmDialog from '../../../Hooks/useConfirmDialog';
import { graphql } from '../../../gql';
import useDisplayMessage from '../../common/GlobalMessage/useDisplayMessage';

const REMOVE_TODO = graphql(`
  mutation removeTodo($todoId: Int!) {
    removeTodo(todoId: $todoId) {
      id
      isCompleted
    }
  }
`);

const useRemoveTodo = ({
  projectId,
  isInChatPage,
  onDeleteComplete,
}: {
  projectId: number;
  isInChatPage?: boolean;
  onDeleteComplete?: () => void;
}) => {
  const { displayConfirmDialog } = useConfirmDialog();
  const { displayMessage } = useDisplayMessage();
  const navigate = useNavigate();
  const [removeTodo]: any = useMutation(REMOVE_TODO, {
    update: (cache, { data }) => {
      displayMessage({ message: 'chat.todo.remove.success', type: 'success' });
      if (projectId) {
        cache.modify({
          id: cache.identify({
            __typename: 'Project',
            id: projectId,
          }),
          fields: {
            projectTasks(existingTask = []) {
              // Filter the deleted todos in the project
              let updatedTask = existingTask.filter(
                (task: any) => task.__ref !== `Todo:${data?.removeTodo.id}`
              );
              return updatedTask;
            },
          },
        });
      }
      // Si c'est dans la page chat
      if (isInChatPage) {
        navigate(-2);
      }
      if (onDeleteComplete) {
        onDeleteComplete();
      }
    },
    onError: () => {
      displayMessage({ message: 'chat.todo.remove.error', type: 'error' });
    },
  });
  const handleDeleteTask = (e: any, todoId?: number) => {
    e.preventDefault();
    displayConfirmDialog({
      message: 'chat.title.dialog',
      onConfirm: () => {
        removeTodo({
          variables: { todoId },
        });
      },
    });
  };
  return { handleDeleteTask };
};

export default useRemoveTodo;
