import { FormattedMessage } from 'react-intl';
import ListBotsSearchLoading from './ListBotsSearch.loading';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import BotItem from '../../Description/Bots/Bot/Bot';
import { Bot } from '../../../../gql/graphql';
import { Grid } from '@mui/material';

interface ListBotsSearchProps {
  loading: boolean;
  bots: Bot[];
  handleBotClick(bot: any): void;
}

const ListBotsSearch = ({
  loading,
  bots,
  handleBotClick,
}: ListBotsSearchProps) => {
  const onSelect = (botSelected: any) => () => {
    handleBotClick(botSelected);
  };

  if (loading) {
    return <ListBotsSearchLoading />;
  }

  if (!bots || !bots.length) {
    return (
      <CustomBox
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <CustomBox
          sx={(theme) => ({
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            mt: (theme) => theme.spacing(1),
            justifyContent: 'center',
            height: '60vh',
          })}
        >
          <CustomBox mx={'auto'}>
            <SearchOffIcon
              sx={{
                width: 50,
                height: 50,
                color: (theme) => theme.palette.grey[200],
              }}
            />
          </CustomBox>
          <CustomTypography>
            <FormattedMessage id="search.no.result" />
          </CustomTypography>
        </CustomBox>
      </CustomBox>
    );
  }
  return (
    <Grid
      container
      sx={(theme) => ({
        maxHeight: '65vh',
        overflow: 'auto',

        [theme.breakpoints.down(750)]: {
          height: '55vh',
        },
      })}
      spacing={2}
    >
      {bots.map((bot: any, index: number) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            // mb: 1, //
            padding: 0,
          }}
        >
          <BotItem
            botSlug={bot.slug}
            botname={bot.name}
            botWorkspaceId={bot.workspaceId}
            isForDescription={true}
            avatarSize="large"
            avatar={bot.user?.profilePicture}
            botDescription={bot.description}
            isDisplayDescription={true}
            onClick={onSelect(bot)}
            isBot={false}
            botId={bot.id}
            key={bot.id}
            isOpenDocumentationOnClicked={true}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListBotsSearch;
