import * as React from 'react';
import { List, ListItem, useTheme } from '@mui/material';
import CalendarOption from '../CalendarOption/CalendarOption';
import Solar from '../SvgIcons/Solar';
import Today from '../SvgIcons/Today';
import Weekend from '../SvgIcons/Weekend';
import { NextWeek } from '@mui/icons-material';
import NoDate from '../SvgIcons/NoDate';
import moment from 'moment';
import { dueDateProps } from '../../../../../types/DueDates';

interface props {
  handleSetDueDate(duedate: dueDateProps): void;
  resetDueDate(): void;
}
const BodyCalendar = ({ handleSetDueDate, resetDueDate }: props) => {
  const theme = useTheme();
  const now = moment().format('ddd');
  const tomorrow = moment().add(1, 'days').format('ddd');
  const weekend = moment().day(6).format('ddd');
  const nextWeek = moment().add(1, 'week').day(1).format('DD MMM YYYY');
  const style = {
    px: 1,
    my: 0,
    cursor: 'pointer',
    '&:hover': { bgcolor: theme.palette.background.default },
  };
  return (
    <List>
      <ListItem
        disablePadding
        sx={style}
        onClick={() => handleSetDueDate({ label: 'Today', value: 'today' })}
      >
        <CalendarOption label="Today" value={now}>
          <Today />
        </CalendarOption>
      </ListItem>
      <ListItem
        disablePadding
        sx={style}
        onClick={() =>
          handleSetDueDate({ label: 'Tomorrow', value: 'tomorrow' })
        }
      >
        <CalendarOption label="Tomorrow" value={tomorrow}>
          <Solar />
        </CalendarOption>
      </ListItem>
      <ListItem
        disablePadding
        sx={style}
        onClick={() =>
          handleSetDueDate({ label: 'Saturday', value: 'weekend' })
        }
      >
        <CalendarOption label="This weekend" value={weekend}>
          <Weekend />
        </CalendarOption>
      </ListItem>
      <ListItem
        disablePadding
        sx={style}
        onClick={() => handleSetDueDate({ label: 'Monday', value: 'nextweek' })}
      >
        <CalendarOption isLong={true} label="Next week" value={nextWeek}>
          <NextWeek />
        </CalendarOption>
      </ListItem>
      <ListItem disablePadding sx={style} onClick={resetDueDate}>
        <CalendarOption label="No Date" value="">
          <NoDate />
        </CalendarOption>
      </ListItem>
    </List>
  );
};

export default BodyCalendar;
