import { Badge, BadgeProps, styled } from '@mui/material';
import React from 'react';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#F6AE2D',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '5px 10px',
    borderRadius: '20px',
    textTransform: 'uppercase',
    // Ponter
    cursor: 'pointer',
  },
}));

interface IProps extends BadgeProps {
  plan: string;
  onClick: () => void;
}

const STSubscriptionPlanBadge: React.FunctionComponent<IProps> = ({
  plan,
  onClick,
}) => {
  return (
    <StyledBadge badgeContent={plan} onClick={onClick}>
      {/* Other content to display */}
    </StyledBadge>
  );
};

export default STSubscriptionPlanBadge;
