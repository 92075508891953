import { styled } from '@mui/material/styles';
import Slider, { sliderClasses } from '@mui/material/Slider';
import { display, width } from '@mui/system';

// Définition des composants stylisés en utilisant le thème
export const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '7% 75% 10% 5%', // Two rows, one taking up 30% and the other 70% of the container's height
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: theme.spacing(1),
  background: theme.palette.background.default,
  '*': {
    boxSizing: 'border-box',
  },
  [theme.breakpoints.down(900)]: {
    gridTemplateColumns: '7% 70% 15% 5%', // Two rows, one taking up 30% and the other 70% of the container's height
  },
  [theme.breakpoints.down(540)]: {
    gridTemplateColumns: '10% 40% 30% 15%', // Two rows, one taking up 30% and the other 70% of the container's height
  },
}));

export const PlayButton = styled('button')(({ theme }) => ({
  padding: 0,
  margin: 0,
  background: 0,
  marginTop: 2,
  border: 0,
  cursor: 'pointer',
  color: theme.palette.grey[200],
  '&:disabled': {
    cursor: 'not-allowed',
  },
}));

export const TimeDisplay = styled('div')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.grey[200],
  textAlign: 'center',
}));

export const RangeWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  span: {
    height: '8px',
  },
  [`& .${sliderClasses.thumb}`]: {
    height: '8px',
    width: '8px',
  },
});
