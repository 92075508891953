import { useState, useEffect, useContext } from 'react';
import CustomPromtsStep1 from './ListPrompts/CustomPromptsStep1/CustomPromtsStep1';
import ListPrompts from './ListPrompts/ListPrompts';
import { StepContext } from '../../../Prompts';
import { Box } from '@mui/material';

interface props {
  handleSelectedPrompt(id: number): void;
  prompts: any[];
  defaultSelectedId: number;
  loading: boolean;
  isMobile?: boolean;
}

const ContainerPrompts = ({
  handleSelectedPrompt,
  prompts,
  defaultSelectedId,
  isMobile,
  loading,
}: props) => {
  const { idCategorie, handleNextStep, isCustomCategory } =
    useContext(StepContext);

  const [selectedPromptId, setSelectedPromptId] = useState(defaultSelectedId);

  useEffect(() => {
    setSelectedPromptId(defaultSelectedId);
  }, [defaultSelectedId]);

  const handleClickNewCustomPrompt = () => {
    handleNextStep(null, 2, 0);
  };

  const handleSelectPrompt = (id: number) => {
    setSelectedPromptId(id);
    handleSelectedPrompt(id);
  };

  return (
    <div key={idCategorie}>
      <Box>
        {isCustomCategory ? (
          <CustomPromtsStep1
            prompts={prompts}
            defaultSelectedId={selectedPromptId}
            loading={loading}
            handleClickNewCustomPrompt={handleClickNewCustomPrompt}
            handleSelectedPrompt={handleSelectPrompt}
          />
        ) : (
          <ListPrompts
            prompts={prompts}
            defaultSelectedId={selectedPromptId}
            isMobile={isMobile}
            loading={loading}
            handleClick={handleSelectPrompt}
            isForCustomPrompt={false}
          />
        )}
      </Box>
    </div>
  );
};

export default ContainerPrompts;
