import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeKatex from 'rehype-katex';
import rehypeHighlight from 'rehype-highlight';
import remarkMath from 'remark-math';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

interface IProps {
  textContent?: string;
  onChange: (value: string) => void;
}

const STMDEditor: React.FC<IProps> = ({ textContent = '', onChange }) => {
  const intl = useIntl();

  return (
    <Box display="flex" flexDirection="column" width="100%" height="400px">
      <MDEditor
        value={textContent}
        onChange={(value) => onChange(value || '')}
        height={400}
        placeholder={intl.formatMessage({
          id: 'markdown.placeholder.textarea',
          defaultMessage: 'Start typing...',
        })}
        previewOptions={{
          rehypePlugins: [[rehypeKatex], [rehypeHighlight]],
          remarkPlugins: [remarkMath],
        }}
      />
    </Box>
  );
};

export default STMDEditor;

/*Code à utiliser dans le formulaire pour que l'input soit editable*/
/*
  const [markdownValue, setMarkdownValue] = useState<string>('');
  const handleMarkdownChange = (value: string) => {
    setMarkdownValue(value);
  };
  <STMDEditor value={markdownValue} onChange={handleMarkdownChange} />;
*/
