import { FC, useRef, useState } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import useCreateFile, { FileType } from '../../../Hooks/useCreateFile';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  FormControl,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import { usePaste } from '../../../Hooks/usePaste';
import STDictaphone from '../STDictaphone/STDictaphone';
import STAudio from '../STAudio/STAudio';
import { isUrlSupportedFile } from '../../../utils/verifyUrlSupportedType';

interface IProps {
  onAddFiles(files: FileType[]): void;
  supportedFiletypes?: string;
  loading?: boolean;
  placeholder?: string | null;
  size?: 'large' | 'small';
  isDisplayLoader?: boolean;
  isDirectlyToUpload?: boolean;
  maxFileSize?: number;
  value: any;
}

// Example usage:
const STAudioFile: FC<IProps> = ({
  onAddFiles,
  supportedFiletypes,
  value,
  placeholder,
}) => {
  const [isRecording, setIsRecording] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { uploadProgress, isInsupported, loading, onUploadFile } =
    useCreateFile({
      onComplete: (files: FileType[]) => {
        onAddFiles(files);
      },
      supportedFiletypes,
    });

  const { onPasteFile } = usePaste({ callBack: onUploadFile });

  const theme = useTheme();

  const resetUploadFile = () => {
    onAddFiles([]);
  };

  const handleToogleRecording = () => {
    if (!isRecording) {
      resetUploadFile();
    }
    setIsRecording((state) => !state);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      resetUploadFile();
      onUploadFile(event.target.files);
    }
  };

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAddFiles([
      {
        url: event.target.value,
        id: 0,
        updatedAt: '',
        type: '',
        size: 0,
        removed: false,
        name: '',
        createdAt: '',
      },
    ]);
  };

  const handleUploadAudio = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input's click event
    }
  };

  const supportedValue = isUrlSupportedFile(value, supportedFiletypes);

  return (
    <CustomBox>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the input
        value=""
      />

      <FormControl
        sx={{
          width: '100%',
        }}
        variant="outlined"
      >
        <OutlinedInput
          type="text"
          sx={{
            '&.MuiOutlinedInput-root': {
              border: isInsupported
                ? `2px solid ${theme.palette.error.main}`
                : 0,
              borderRadius: '4px',
            },
          }}
          disabled={loading}
          value={value || ''}
          onChange={onChangeUrl}
          onPaste={onPasteFile}
          placeholder={placeholder || ''}
          startAdornment={supportedValue ? <STAudio src={value} /> : null}
          endAdornment={
            loading ? (
              <CustomBox
                sx={{
                  display: 'flex',
                  width: '75px',
                  alignItems: 'center',
                  gap: '5px',
                  color: (theme) => theme.palette.grey[200],
                }}
              >
                <b>{uploadProgress} %</b>
                <CircularProgress size={15} />
              </CustomBox>
            ) : (
              <CustomBox display="flex" alignItems="center">
                <CustomIconButton
                  onClick={handleUploadAudio}
                  disabled={isRecording}
                >
                  <CloudUploadIcon />
                </CustomIconButton>
                <STDictaphone
                  onUploadFile={onUploadFile}
                  onRecording={handleToogleRecording}
                  onStopRecording={handleToogleRecording}
                />
                {value ? (
                  <CustomIconButton onClick={resetUploadFile}>
                    <CloseIcon />
                  </CustomIconButton>
                ) : null}
              </CustomBox>
            )
          }
        />
      </FormControl>
      {isInsupported && (
        <CustomTypography
          sx={{
            color: theme.palette.error.main,
            fontSize: '14px',
          }}
        >
          <FormattedMessage id={'unsupported.file.label'} />
        </CustomTypography>
      )}
    </CustomBox>
  );
};

export default STAudioFile;
