import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const GET_GLOBAL_SEARCH = graphql(
  `
    query GetGlobalSearch($searchQuery: String!, $take: Int) {
      getGlobalSearch(searchQuery: $searchQuery) {
        todos {
          id
          isCompleted
          dueDate
          title
          description
          createdAt
          asignees(take: $take) {
            id
            assignedTo {
              id
              firstName
              profilePicture
            }
          }
        }
        bots {
          id
          name
          description
          baseBot {
            id
          }
          user {
            profilePicture
            id
          }
        }
        dataSources {
          id
          name
          description
        }
        projects {
          id
          name
          description
          createdAt
          projectUnreadNotificationsCount
        }
      }
    }
  `
);

const useGetGlobalSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [getMatchingTasks, { data, loading }] = useLazyQuery(GET_GLOBAL_SEARCH);

  useEffect(() => {
    if (!searchValue.trim()) {
      getMatchingTasks({ variables: { take: 1, searchQuery: '' } });
    }
  },[searchValue]);

  const debouncedGetMatchingTasks = useCallback(
    debounce((searchQuery: string) => {
      getMatchingTasks({ variables: { take: 1, searchQuery } });
    }, 500),
    [getMatchingTasks]
  );

  const onChangeInputSearch = (e: any) => {
    const newSearchTask = e.target.value;
    debouncedGetMatchingTasks(newSearchTask);
    setSearchValue(newSearchTask);
  };

  return { data, loading, searchValue, onChangeInputSearch };
};

export default useGetGlobalSearch;
