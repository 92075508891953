import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { styled } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

import CustomBox from '../../CustomBox/CustomBox';
import CustomTypography from '../../CustomTypography/CustomTypography';

interface IBotComponentProps {
  name: string;
  profileUrl?: string;
  modeMini?: boolean;
  isBaseBotTypetext?: boolean;
}

interface IStyledCustomBoxProps {
  modeMini: boolean;
}

const StyledCustomBox = styled(CustomBox)<IStyledCustomBoxProps>(
  ({ theme, modeMini }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: theme.spacing(1),
    padding: modeMini ? theme.spacing(0.5, 1) : theme.spacing(1),
  })
);

const StyledCustomBoxContainer = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: theme.spacing(0.5),
}));

const BaseBotDetail: FC<IBotComponentProps> = ({
  name,
  profileUrl,
  modeMini = false,
  isBaseBotTypetext = false,
}) => {
  const pictureSize = modeMini ? 12 : 16;

  if (!name) return null;

  if (isBaseBotTypetext) {
    return (
      <CustomTypography
        variant="caption"
        sx={{
          fontSize: '12px',
        }}
      >
        {name}
      </CustomTypography>
    );
  }

  return (
    <StyledCustomBoxContainer>
      <>
        {!modeMini && (
          <CustomTypography
            variant="h6"
            sx={{
              fontSize: '0.725rem',
            }}
          >
            <FormattedMessage id="basebot.agent.base.title" />:
          </CustomTypography>
        )}
        <StyledCustomBox modeMini={modeMini}>
          {profileUrl && typeof profileUrl === 'string' ? (
            <img
              src={profileUrl}
              alt={name}
              style={{ width: pictureSize, height: pictureSize }}
            />
          ) : (
            <SmartToyOutlinedIcon width={pictureSize} height={pictureSize} />
          )}
          <CustomTypography
            variant="body2"
            ml={1}
            sx={{
              fontSize: modeMini ? '0.6rem' : '0.675rem',
            }}
          >
            {name}
          </CustomTypography>
        </StyledCustomBox>
      </>
    </StyledCustomBoxContainer>
  );
};

export default BaseBotDetail;
