import { useState } from 'react';

const useBotTag = () => {
  const [botTagSlug, setBotTagSlug] = useState<string>('');

  const handleBotTagSlug = (tag: string) => {
    setBotTagSlug(tag);
  };

  return {
    botTagSlug,
    handleBotTagSlug,
  };
};

export default useBotTag;
