import moment from "moment"

const getDateFromEvent = (date: string | null | Date | undefined,isStartDate?:boolean) => {
    let response = { value: "", label: isStartDate?'Start date':' Due date' }
    if (!date) {
        return response
    } else if (moment(date).isValid()) {
        const tmpDate = moment(date).format('L')
        const today = moment().format('L')
        const tomorrow = moment().add(1, 'day').format('L')
        const weekend = moment().day(6).format('L')
        const nextWeek = moment().add(1, 'week').day(1).format('L')

        if (tmpDate === today) {
            // if date is today
            response = { label: 'Today', value: 'today' }
        } else if (tmpDate === tomorrow) {
            // if date is tomorrow
            response = { label: 'Tomorrow', value: 'tomorrow' }
        } else if (weekend === tmpDate) {
            // if date is weekend of the current week
            response = { label: 'Saturday', value: 'weekend' }
        } else if (nextWeek === tmpDate) {
            // if date is nextweek 
            response = { label: 'Monday', value: nextWeek }
        } else if (moment(date).isSame(new Date(), 'week')) {
            // if date and today is same week 
            response = { label: moment(date).format('dddd'), value: moment(date).toLocaleString() }
        } else {
            // if date is not include in the event
            let d = moment(date).format('MMM') + ' ' + moment(date).format('D')
            response = { label: d, value: moment(date).toLocaleString() }
        }
    }
    return response
}

export default getDateFromEvent