const AddUser = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_451_358)">
                    <path d="M21 11V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 13L3 8C3 6.67392 3.52678 5.40215 4.46446 4.46447C5.40215 3.52679 6.67392 3 8 3L16 3C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8L21 16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21L11 21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 12C13.3807 12 14.5 10.8807 14.5 9.5C14.5 8.11929 13.3807 7 12 7C10.6193 7 9.5 8.11929 9.5 9.5C9.5 10.8807 10.6193 12 12 12Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 16C7.69423 15.5544 8.01424 15.1751 8.42081 14.9087C8.82738 14.6423 9.30285 14.5002 9.78894 14.5H14.2111C14.6972 14.5002 15.1726 14.6423 15.5792 14.9087C15.9858 15.1751 16.3058 15.5544 16.5 16" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_451_358">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default AddUser