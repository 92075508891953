import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.7, 1, 1, 0),
    fontSize: theme.typography.body2.fontSize,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

interface propsSearchInput {
  searchValue: string;
  autoFocus?:boolean
  onChangeInputSearch(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
}

const SearchInput = ({
  onChangeInputSearch,
  searchValue,
  autoFocus,
  placeholder,
}: propsSearchInput) => {
  return (
    <StyledInputBase
      role="search"
      autoFocus={autoFocus}
      value={searchValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        onChangeInputSearch(event)
      }
      placeholder={placeholder}
      inputProps={{ 'aria-label': 'search', 'data-testid': 'search-input' }}
    />
  );
};

export default SearchInput;
