import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { onAfterSendMessage } from '../utils/onAfterSendMessage';
import useDisplayMessage from '../../common/GlobalMessage/useDisplayMessage';
import { useParams } from 'react-router-dom';

const ADD_NEW_MESSAGE = graphql(`
  mutation SendMessage($newMessageData: NewMessageInput!) {
    sendNewMessage(newMessageData: $newMessageData) {
      id
      createdAt
      content
      sentByUserId
      todoChatId
      updatedAt
      session {
        id
        title
      }
      files {
        id
        name
        url
        type
        size
        base64Image
      }
      sentBy {
        id
        firstName
        lastName
        profilePicture
      }
      __typename
    }
  }
`);
interface props {
  taskID: number;

  onMessageSent?(data: any): void;
}
const useSentNewMessage = ({ onMessageSent, taskID }: props) => {
  const { displayMessage } = useDisplayMessage();
  const params = useParams();
  const [addNewMessage, { error: errorAddMessage, loading: loadSendMessage }] =
    useMutation(ADD_NEW_MESSAGE, {
      update(cache, { data }) {
        onAfterSendMessage({
          cache,
          newMessage: data?.sendNewMessage,

          todoId: taskID,
          sessionId: (params?.sessionId ? +params.sessionId : 0) as number,
        });
        // Call onMessageSent
        if (onMessageSent) {
          onMessageSent(data);
        }
      },
      onError(err) {
        displayMessage({
          message: 'chat.send.message.error',
          type: 'error',
        });
      },
      // TODO: Optimistic response
    });
  return { addNewMessage, errorAddMessage, loadSendMessage };
};
export default useSentNewMessage;
