import { useContext, useEffect } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import { StepContext } from '../../Prompts';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import SideBarPrompts from './SideBarPrompts/SideBarPrompts';
import ContainerPrompts from './ContainerPrompts/ContainerPrompts';
import PromptsStepTwo from '../PromptsStepTwo/PromptsStepTwo';
import CustomPromtsStep2 from '../CustomPromtsStep2/CustomPromtsStep2';
import UpdateCustomPrompt from '../UpdateCustomPrompt/UpdateCustomPrompt';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';
import { orderBy } from 'lodash';
import { graphql } from '../../../../gql';
import { useQuery } from '@apollo/client';

export const GET_CATEGORIES_PROMPTS = graphql(`
  query GetCategoriesPrompts {
    getCategoriesPrompts {
      categoryGeneral {
        ...CategoryPromptsFragment
      }
      categoryByWorkspace {
        ...CategoryPromptsFragment
      }
    }
  }
`);

const StyledBox = styled(CustomBox)(({ theme }) => ({
  height: theme.spacing(80),
  overflowY: 'auto',
}));

interface Props {
  callback(prompts: string): void;
  searchValue: string;
}

const PromptsStepOne = ({ callback, searchValue }: Props) => {
  const {
    activeStep,
    idCategorie,
    idPrompts,
    handleNextStep,
    handlePreviousStep,
  } = useContext(StepContext);
  const { data: categoryPrompt, loading: loadcategory } = useQuery(
    GET_CATEGORIES_PROMPTS
  );
  const { data, loading: loadPrompt } = useGetCategoryPrompts(idCategorie);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let prompts = data?.getPromptsByCategory || [];
  if (searchValue) {
    const lowercasedSearchTerm = searchValue.toLowerCase();

    prompts = prompts.filter(
      (prompt: any) =>
        prompt.title.toLowerCase().includes(lowercasedSearchTerm) ||
        prompt.description.toLowerCase().includes(lowercasedSearchTerm)
    );
  }

  prompts = orderBy(prompts, ['createdAt'], ['desc']);
  const defaultSelectedId = prompts.length > 0 ? prompts[0].id : idPrompts;

  useEffect(() => {
    if (
      !idPrompts &&
      defaultSelectedId &&
      activeStep !== 2 &&
      activeStep !== 3 &&
      !isMobile
    ) {
      handleSelectedPrompt(defaultSelectedId);
    }
  }, [activeStep, data]);

  const handleSelectedPrompt = (id: number) => {
    Mixpanel.track('Prompts - Select Prompt', { id });
    if (id) {
      handleNextStep(null, 1, id);
    } else {
      handleNextStep(null, 0, 0);
    }
  };

  const handleEdit = () => {
    handleNextStep(null, 3, idPrompts);
  };

  const handleCancelEdit = () => {
    handlePreviousStep();
  };

  const renderContent = () => {
    if (isMobile) {
      switch (activeStep) {
        case 1:
          return <PromptsStepTwo callback={callback} onEdit={handleEdit} />;
        case 2:
          return <CustomPromtsStep2 />;
        case 3:
          return <UpdateCustomPrompt handleCancel={handleCancelEdit} />;
        default:
          return (
            <SideBarPrompts
              loading={loadcategory}
              categorieList={categoryPrompt?.getCategoriesPrompts}
              handleSelectedPrompt={handleSelectedPrompt}
              prompts={prompts}
              defaultSelectedId={defaultSelectedId}
              isMobile={isMobile}
            />
          );
      }
    }

    return (
      <Box
        display="grid"
        width="100%"
        height="100%"
        gridTemplateColumns="0.5fr 0.8fr 1.9fr"
      >
        <Box
          px={2}
          sx={{
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <SideBarPrompts
            categorieList={categoryPrompt?.getCategoriesPrompts}
            isMobile={isMobile}
            handleSelectedPrompt={handleSelectedPrompt}
            prompts={prompts}
            defaultSelectedId={defaultSelectedId}
            loading={loadcategory}
          />
        </Box>
        <StyledBox
          pt={loadPrompt ? 0 : 1}
          px={3}
          sx={{
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <ContainerPrompts
            handleSelectedPrompt={handleSelectedPrompt}
            prompts={prompts}
            defaultSelectedId={defaultSelectedId}
            loading={loadPrompt}
          />
        </StyledBox>
        <StyledBox>
          {activeStep === 1 && (
            <PromptsStepTwo callback={callback} onEdit={handleEdit} />
          )}
          {activeStep === 2 && <CustomPromtsStep2 />}
          {activeStep === 3 && (
            <UpdateCustomPrompt handleCancel={handleCancelEdit} />
          )}
        </StyledBox>
      </Box>
    );
  };

  return <Box width="100%">{renderContent()}</Box>;
};

export default PromptsStepOne;
