import * as React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';

interface props {
    children: React.ReactNode
    label: string
    value: string,
    isLong?: Boolean
}
const CalendarOption = ({ children, label, value, isLong }: props) => {
    const theme = useTheme()
    const rowX = isLong ? 5 : 8
    const rowY = isLong ? 5 : 2
    return (
        <Grid
            container
            display='flex'
            alignItems='center'
            justifyItems='center'
            justifySelf='center'
            padding='4px 0px'
        >
            <Grid item lg={2} xl={2} md={2} xs={2} sm={2} lineHeight='24px' width='100%'>
                {children}
            </Grid>
            <Grid item lg={rowX} xl={rowX} md={rowX} xs={rowX} sm={rowX}>
                <Typography variant="body2" color={theme.typography.h5.color}>{label}</Typography>
            </Grid>
            <Grid item lg={rowY} xl={rowY} md={rowY} xs={rowY} sm={rowY} display='flex' justifyContent="flex-end">
                <Typography variant="body2" color={theme.typography.caption.color}>{value}</Typography>
            </Grid>
        </Grid>
    );
}

export default CalendarOption 