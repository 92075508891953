import { FC } from 'react';
import CustomBox from '../CustomBox/CustomBox';

interface IProps {
  count: number;
}

const STNotificationBadge: FC<IProps> = ({ count }) => {
  return (
    <CustomBox
      component="span"
      sx={{
        minWidth: '20px',
        height: '20px',
        paddingLeft: (theme) => theme.spacing(1),
        paddingRight: (theme) => theme.spacing(1),
        borderRadius: '10px',
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
      }}
    >
      {count > 99 ? '99+' : count}
    </CustomBox>
  );
};

export default STNotificationBadge;
