import { graphql } from '../../gql';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { removeAuthToken } from '../../utils/authToken';
import { Mixpanel } from '../../mixpanel/mixpanel';

export const meQueryDocument = graphql(/* GraphQL */ `
  query MainLayoutQuery {
    me {
      id
      email
      username
      firstName
      lastName
      confirmed
      profilePicture
    }
  }
`);

const useLayout = () => {
  const navigate = useNavigate();

  const { data } = useQuery(meQueryDocument, {
    onError: (error) => {
      removeAuthToken();
      // redirect to login page
      window.location.href = '/signin';
    },
    onCompleted: (data) => {
      if (data.me) {
        Mixpanel.identify(data.me.id);
        Mixpanel.people.set({
          $email: data.me.email,
          $first_name: data.me?.firstName,
          $last_name: data.me?.lastName,
        });
        // Check if user is confirmed
        if (!data.me.confirmed) {
          Mixpanel.track('User not confirmed', {
            email: data.me.email,
            userId: data.me.id,
          });
          // TODO: redirect to confirm email page
          navigate('/confirm-email-request');
        }
      }
    },
  });

  return { data };
};

export default useLayout;
