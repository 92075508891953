// MessageRetry component
import { useMutation } from '@apollo/client';
import { Error, Replay } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '../../../../gql';

import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';

// Mutation retryBotMessage, that take todoChatId as argument
const RETRY_BOT_MESSAGE = graphql(
  `
    mutation RetryBotMessage($sessionId: Float!) {
      retryBotMessage(sessionId: $sessionId)
    }
  `
);

interface IProps {
  sessionId: number;
}

const MessageRetry: React.FC<IProps> = ({ sessionId }) => {
  const [retryBotMessage, { loading }] = useMutation(RETRY_BOT_MESSAGE);

  const handleRetry = () => {
    retryBotMessage({
      variables: {
        sessionId: sessionId,
      },
    });
  };

  return (
    <CustomBox
      sx={{
        display: 'flex',
        padding: (theme) => theme.spacing(1),
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <CustomBox
        sx={{
          // Center icon
          display: 'flex',
          alignItems: 'center',
          color: (theme) => theme.palette.error.main,
        }}
      >
        <Error />
      </CustomBox>
      <CustomBox
        sx={{
          marginleft: (theme) => theme.spacing(2),
          marginRight: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }}
      >
        <CustomTypography>
          <FormattedMessage id="chat.message.error" />
        </CustomTypography>
      </CustomBox>
      <div style={{ flexGrow: 1 }} />
      {/* Retry button  with icon */}
      <CustomBox
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CustomButton
          isLoading={loading}
          intlId="chat.message.retry"
          startIcon={<Replay />}
          onClick={handleRetry}
          variant="contained"
          size="small"
        />
      </CustomBox>
    </CustomBox>
  );
};

export default MessageRetry;
