import * as React from 'react';
import { FILE_FRAGMENT } from '../../../../../fragements/file';
import { FragmentType, useFragment } from '../../../../../gql';
import Attachments from '../../../Description/Attachments/Attachments';

interface IProps {
  files: FragmentType<typeof FILE_FRAGMENT>[];
}

const MessageFiles: React.FC<IProps> = ({ files: messageFiles }) => {
  const files = useFragment(FILE_FRAGMENT, messageFiles);
  if (!files) return null;
  if (files.length === 0) return null;

  const customSrc = (file: any) => {
    if (file.base64Image) {
      return `data:image/jpeg;base64,${file.base64Image}`;
    } else {
      return file.url;
    }
  };

  const data = files?.map((item, index: number) => ({
    url: customSrc(item),
    type: item.type,
    size: item.size,
    title: item.name,
    rows: 2,
    cols: 2,
  }));

  return <Attachments files={data} />;
};

export default MessageFiles;
