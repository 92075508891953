import { FormControlLabel, SwitchProps } from '@mui/material';
import Switch from '@mui/material/Switch';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import CustomTypography from '../CustomTypography/CustomTypography';

interface Props extends SwitchProps {
  label?: string;
  tooltip?: string;
}

const STSwitch: FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <CustomTooltip intlId={props.tooltip || props.label} title={props.tooltip}>
      <FormControlLabel
        label={
          <CustomTypography>
            {props.label
              ? intl.formatMessage({
                  id: props.label,
                })
              : ''}
          </CustomTypography>
        }
        control={
          <Switch
            sx={{
              ...props.sx,
            }}
            {...props}
          />
        }
      />
    </CustomTooltip>
  );
};

export default STSwitch;
