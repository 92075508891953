import * as React from 'react';
import { useIntl } from 'react-intl';
import useGetTodoLabel from '../../../hooks/useGetTodoLabel';
import LabelItem from './LabelItem/LabelItem';
import STAutoComplete from '../../../../common/STAutoComplete/STAutoComplete';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '../../../../../gql';
export const IS_LABELNAME_EQUALS_OF_QUERY = graphql(`
  query IsLabelNameEqualOfSearchQuery(
    $projectId: Float!
    $searchQuery: String!
  ) {
    isLabelNameEqualOfSearchQuery(
      projectId: $projectId
      searchQuery: $searchQuery
    )
  }
`);

export default function AutoCompleteLabel({
  projectId,
  childrenAction,
  handleClickOption,
  isShowCheckBox,
  renderAutoCompleteButton,
  onKeyPress,
  isChecked,
}: {
  projectId: number;
  renderAutoCompleteButton: React.ReactNode;
  handleClickOption: (label: any) => void;
  isShowCheckBox?: boolean;
  isChecked?: (label: any) => boolean;
  onKeyPress?:(e:any)=>void
  childrenAction?: (
    inputValue: string,
    isLabelNameEqualOfSearchQuery: boolean
  ) => React.ReactNode;
}) {
  const { allLabels, dataSearched, searchload, debouncedGetMatchingLabels } =
    useGetTodoLabel({ projectId });
  const [getCheckingLabels, { data: dataCheckName }] = useLazyQuery(
    IS_LABELNAME_EQUALS_OF_QUERY
  );
  const [inputValue, setInputvalue] = React.useState('');
  const intl = useIntl();
  const options = inputValue
    ? dataSearched?.searchLabel || []
    : allLabels?.getTodoLabel || [];
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInputvalue(newValue);
    debouncedGetMatchingLabels(newValue);
    setTimeout(() => {
      getCheckingLabels({ variables: { projectId, searchQuery: newValue } });
    }, 500);
  };

  return (
    <STAutoComplete
      maxHeight={'30vh'}
      sx={{overflowX:"hidden",overflowY:'auto'}}
      minWidth={'15vw'}
      onKeyPress={onKeyPress}
      isLoad={searchload}
      noOptiosMessage={'no.label.message'}
      renderAutoCompleteContent={(label) => (
        <LabelItem
          label={label}
          isShowCheckBox={isShowCheckBox}
          isChecked={isChecked && isChecked(label)}
        />
      )}
      autoCompletePlaceholder={intl.formatMessage({
        id: 'label.placeholder',
      })}
      renderAutoCompleteButton={renderAutoCompleteButton}
      options={options}
      inputValue={inputValue}
      onChange={handleChange}
      handleClickOption={handleClickOption}
      inputName={'todolabel'}
      childrenAction={
        childrenAction &&
        childrenAction(
          inputValue,
          dataCheckName?.isLabelNameEqualOfSearchQuery as boolean
        )
      }
    />
  );
}
