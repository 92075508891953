// Custom Chip component using Material UI Chip
import React from 'react';
import { Chip, ChipProps } from '@mui/material';

interface IProps extends ChipProps {}

const STChip: React.FC<IProps> = ({ ...rest }) => {
  return <Chip {...rest} />;
};

export default STChip;
