import * as React from 'react';
import { Box, Button, Grid, styled, Tooltip, useTheme } from '@mui/material';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../../../../gql';
import DueDate from '../../../../../Chat/Description/DueDate/DueDate';
import Moment from 'react-moment';
import { Todo } from '../../../../../../gql/graphql';
import customDateEvent from '../../../../../../utils/customDateEvent';
import { dueDateProps } from '../../../../../../types/DueDates';

interface props {
  todo: Todo;
  isOverdue: boolean;
}

const UPDATE_DUE_DATE = graphql(`
  mutation updateTodoDuedate($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      title
      description
      dueDate
    }
  }
`);

const EditDueDate = ({ todo, isOverdue }: props) => {
  const [updateTodoDuedate] = useMutation(UPDATE_DUE_DATE);

  const dateToFormat = new Date(todo?.dueDate);

  const handlSetDueDate = (duedate: dueDateProps) => {
    if (duedate.value) {
      updateTodoDuedate({
        variables: {
          updateTodoInput: {
            id: todo?.id as number,
            dueDate: customDateEvent(duedate.value),
          },
        },
      });
    }
  };
  const StyledButton = styled(Box)({
    height: 20,
    textTransform: 'capitalize',
    fontSize: 12,
    paddingLeft: 5,
    marginLeft: -5,
    color: !todo?.isCompleted && isOverdue ? '#FF7066' : '#A4A5B2',
    '&:hover': {
      border: `1px solid ${
        !todo?.isCompleted && isOverdue ? '#FF7066' : '#A4A5B2'
      }`,
      borderRadius: 5,
    },
  });
  return (
    <>
      {todo?.dueDate && (
        <DueDate isEditMode={false} getDueDate={handlSetDueDate}>
          <Tooltip title={'Edit Due date'} placement="bottom-start" arrow>
            <StyledButton mt={-1} width={82}>
              <Moment format="D MMM YYYY" date={dateToFormat} />
            </StyledButton>
          </Tooltip>
        </DueDate>
      )}
    </>
  );
};
export default EditDueDate;
