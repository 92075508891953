import CustomButton from '../../../common/CustomButton/CustomButton';

interface props {
  handleClick(): void;
  children: React.ReactNode;
  isDisabled: boolean;
  style?: React.CSSProperties;
}

export default function ActionButton({
  handleClick,
  children,
  isDisabled,
  style,
}: props) {
  return (
    <CustomButton
      sx={{ maxHeight: '30px', ...style }}
      disabled={isDisabled}
      size="small"
      variant="text"
      color="inherit"
      onClick={handleClick}
    >
      {children}
    </CustomButton>
  );
}
