import * as React from 'react';
import FileIcon from '../MessageInput/SvgIcon/FileIcon/FileIcons';
import { FormattedMessage } from 'react-intl';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import { styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: '95%',
  borderRadius: 5,
  top: '10%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  height: theme.spacing(10),
  display: 'flex',
  marginBottom: 10,
  width: '100%',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 10,
  backgroundColor: theme.palette.background.default,
}));

const BoxIcon = styled(Box)(({ theme }) => ({
  width: theme.spacing(8),
  marginLeft: 10,
  marginTop: 5,
}));

interface props {
  uploadProgress: number;
}

const Progressbar = ({ uploadProgress }: props) => {
  return (
    <BoxStyled>
      <BoxIcon>
        <FileIcon extension={''} />
      </BoxIcon>
      <Box width={'100%'} ml={2}>
        {uploadProgress < 100 ? (
          <>
            <Typography variant="h5" pt={1}>
              <FormattedMessage
                id={'chat.upload.loading.processing'}
                defaultMessage=" Processing..."
              />
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={uploadProgress}
            />
          </>
        ) : (
          <>
            <Typography variant="h5" pt={1}>
              <FormattedMessage
                id={'chat.upload.loading.preparing'}
                defaultMessage=" Preparing..."
              />
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={uploadProgress}
            />
          </>
        )}
      </Box>
    </BoxStyled>
  );
};

export default Progressbar;
