import config from '../../../config/config';
import React, { FC } from 'react';
import ListItem from '@mui/material/ListItem';
import STPopper from '../STPopper/STPopper';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import PapperClipAttache from '../../Chat/BodyChat/FormAddMessage/MessageInput/SvgIcon/PapperClipAttache';
import {
  StyledList,
  StyledInput,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledEndElement,
  StyledTypography,
  IconPro,
  IConAdvanced,
} from './STFilePicker.style';
import Divider from '@mui/material/Divider';
import { useIntl } from 'react-intl';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import useFilePicker from './useFilePicker';
import { ContentType } from '../../../gql/graphql';
import useGetSubscriptionContext from '../../Context/SubscriptionContext/useGetSubscriptionContext';
import getExtension from '../../../utils/getExtension';

const hasDocumentExtension = (contentTypes: ContentType[]): boolean => {
  const documentExtensions = config.parser.supportedMimeTypes;
  if (!documentExtensions) {
    return false;
  }
  if (!contentTypes) {
    return false;
  }
  if (contentTypes.length === 0) {
    return false;
  }
  return contentTypes.some((item) => documentExtensions.includes(item.type));
};

interface Props {
  onUplaod: (data: File[]) => void;
  supportedContentTypes: ContentType[];
}

const STFilePicker: FC<Props> = ({ onUplaod, supportedContentTypes }) => {
  const { isFreePlan } = useGetSubscriptionContext();
  const intl = useIntl();
  const {
    fileInputRef,
    handleOpen,
    handleClose,
    handleListItemClick,
    handleFileChange,
    open,
    id,
    anchorEl,
  } = useFilePicker(onUplaod);

  const formatContentType = (type: ContentType): string => {
    return type.extension
      ? type.extension.replace('.', '').toUpperCase()
      : type.type.split('/').pop() || '';
  };

  const getSimpleSupportedContentTypes = (): string => {
    if (!supportedContentTypes || supportedContentTypes.length === 0) {
      return '';
    }

    const formattedContentTypes = supportedContentTypes.map(formatContentType);
    const maxDisplayedTypes = 10;

    return formattedContentTypes.length > maxDisplayedTypes
      ? `${formattedContentTypes.slice(0, maxDisplayedTypes).join(', ')}, ...`
      : formattedContentTypes.join(', ');
  };

  const getAdvancedSupportedContentTypes = (): string => {
    const fomattedAdvancedContentTypes = config.parser.supportedMimeTypes.map(
      (mineType) => getExtension(mineType).toUpperCase()
    );

    const maxDisplayedTypes = 8;

    return fomattedAdvancedContentTypes.length > maxDisplayedTypes
      ? `${fomattedAdvancedContentTypes
          .slice(0, maxDisplayedTypes)
          .join(', ')}, ...`
      : fomattedAdvancedContentTypes.join(', ');
  };

  const listTypesSimple = getSimpleSupportedContentTypes();

  const listTypesAdvanced = getAdvancedSupportedContentTypes();

  return (
    <>
      <StyledInput
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple={true}
      />
      <STPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="top-end"
        transition={true}
        width="440px"
      >
        <StyledList>
          {hasDocumentExtension(supportedContentTypes) ? (
            <>
              <ListItem
                disablePadding={true}
                sx={{
                  mb: 1,
                }}
                secondaryAction={
                  <StyledEndElement>
                    {isFreePlan ? (
                      <StyledTypography>(Pro)</StyledTypography>
                    ) : null}
                    <IconPro />
                  </StyledEndElement>
                }
              >
                <StyledListItemButton onClick={handleListItemClick('ADVANCED')}>
                  <StyledListItemIcon>
                    <IConAdvanced />
                  </StyledListItemIcon>
                  <StyledListItemText
                    primary={intl.formatMessage({
                      id: 'file.type.advanced.title',
                    })}
                    secondary={listTypesAdvanced}
                  />
                </StyledListItemButton>
              </ListItem>
              <Divider />
            </>
          ) : null}

          <ListItem
            disablePadding={true}
            sx={{
              mt: 1,
            }}
          >
            <StyledListItemButton onClick={handleListItemClick('SIMPLE')}>
              <StyledListItemIcon>
                <InsertDriveFileOutlinedIcon fontSize="large" />
              </StyledListItemIcon>
              <StyledListItemText
                primary={intl.formatMessage({ id: 'file.type.simple.title' })}
                secondary={listTypesSimple}
              />
            </StyledListItemButton>
          </ListItem>
        </StyledList>
      </STPopper>

      <CustomIconButton onClick={handleOpen}>
        <PapperClipAttache />
      </CustomIconButton>
    </>
  );
};

export default React.memo(STFilePicker);
