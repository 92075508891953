import { FC } from 'react';
import useGetExternalAppAuthUrl from './useGetExternalAppAuthUrl';
import useGetAllExternalApps from './useGetAllExternalApps';
import useExternalAppConnection from './useExternalAppConnection';

interface IProps {
  onAuthSuccess?(accessToken: string): void;
}

const useExternalAppIntegration = (props: IProps) => {
  const { externalApps, loading } = useGetAllExternalApps();
  const { getExternalAppAuthUrl } = useGetExternalAppAuthUrl();
  const { getExternalAppConnection } = useExternalAppConnection();

  const getAppToken = async (appSlug: string) => {
    const externalAppConnection = await getExternalAppConnection({
      variables: { appSlug },
    });
    if (externalAppConnection?.data?.externalAppConnection?.accessToken) {
      return externalAppConnection?.data?.externalAppConnection?.accessToken;
    }
    return null;
  };
  const connectExternalApp = async (appSlug: string) => {
    const accessToken = await getAppToken(appSlug);
    if (accessToken) {
      if (props.onAuthSuccess) {
        props.onAuthSuccess(accessToken);
      }
      return;
    }

    getExternalAppAuthUrl({ variables: { appSlug } }).then((res) => {
      if (res?.data?.getExternalAppAuthUrl) {
        const authUrl = res?.data?.getExternalAppAuthUrl;
        // window.location.href = res?.data?.getAuthUrl;
        // Open window popup and get callback after close
        const popup = window.open(authUrl, 'popup', 'width=600,height=600');

        const timer = setInterval(() => {
          if (popup?.closed) {
            clearInterval(timer);
            getAppToken(appSlug).then((accessToken) => {
              if (accessToken) {
                if (props.onAuthSuccess) {
                  props.onAuthSuccess(accessToken);
                }
              }
            });
          }
        }, 1000);
      }
    });
  };

  return {
    connectExternalApp,
  };
};

export default useExternalAppIntegration;
