import { useMutation } from '@apollo/client';
import { graphql } from '../gql';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { Mixpanel } from '../mixpanel/mixpanel';

// create hooks mutation to call  canceledSubscriptionPlan in resolver
const CANCELED_SUBSCRIPTION_PLAN = graphql(`
  mutation CanceledSubscriptionPlan($reason: String!) {
    canceledSubscriptionPlan(reason: $reason) {
      message
      status
    }
  }
`);

const useCanceledSubsriptionPlan = (callBack: () => void) => {
  const { displayMessage } = useDisplayMessage();

  const [canceledSubscriptionPlan, { loading, data }] = useMutation(
    CANCELED_SUBSCRIPTION_PLAN
  );

  const handleCanceledSubscriptionPlan = (reason: string) => {
    canceledSubscriptionPlan({
      variables: {
        reason,
      },
      onError() {
        // here add function to display error messag using useDisplayMessage
        displayMessage({
          message: 'canceled.subscription.plan.message.error',
          type: 'error',
        });
      },

      update() {
        // here add function to display success messag using useDisplayMessage
        displayMessage({
          message: 'canceled.subscription.plan.created.success',
          type: 'success',
        });
        Mixpanel.track('Canceled subscription plan', {
          reason,
        });
        // Reload the page
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
        callBack();
      },
    });
  };

  return { handleCanceledSubscriptionPlan, loading };
};

export default useCanceledSubsriptionPlan;
