import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
import SigninForm from './SigninForm/SigninForm';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import LogoIcon from '../LogoIcon/LogoIcon';
import { useIntl } from 'react-intl';
import { StyledContainer } from '../Signup/Signup';
import { handleAfterAuth } from '../../../utils/authUtils';
import RedirectionInfo from '../RedirectionInfo/RedirectionInfo';

// Signin mutation
const signinMutation = graphql(`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`);

export default function Signin() {
  const intl = useIntl();
  const [error, SetError] = React.useState(false);
  const [inputValue, setInput] = React.useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });
  // Is empty input state
  const [isEmptyInput, setIsEmptyInput] = React.useState(false);

  // Mix panel page view event

  React.useEffect(() => {
    Mixpanel.track('Signin page view', { page: 'Signin' });
  }, []);

  // Signin appollo mutation

  const [signin, { loading }] = useMutation(signinMutation);

  // Handle input change
  const onChangeInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput({ ...inputValue, [e.currentTarget.name]: e.currentTarget.value });
  };
  const onSubmit = () => {
    if (inputValue.password && inputValue.email) {
      setIsEmptyInput(false);
      signin({
        variables: { email: inputValue.email, password: inputValue.password },
        update: (_, { data }) => {
          if (data?.login.token && data?.login.user) {
            Mixpanel.identify(data?.login.user.id);
            Mixpanel.track('Signin', {
              email: data?.login.user.email,
              firstName: data?.login.user.firstName,
              lastName: data?.login.user.lastName,
              method: 'email',
            });
            handleAfterAuth(data?.login.token);
          }
        },
        onError: (_error) => {
          SetError(true);
          setInput({ ...inputValue, password: '' });
        },
      });
    }
    if (!inputValue.password || !inputValue.email) {
      setIsEmptyInput(true);
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box>
          <LogoIcon />
        </Box>
        <Box mt={2}>
          <RedirectionInfo />
        </Box>

        <Box>
          <SigninForm
            inputValue={inputValue}
            onChange={onChangeInputChange}
            onSubmit={onSubmit}
            hasError={Boolean(error)}
            isEmptyInput={isEmptyInput}
            isLoading={loading}
          />
        </Box>
      </Box>
    </StyledContainer>
  );
}
