import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { GET_ALL_LABEL, GET_SEARCh_LABEL } from './useGetTodoLabel';
import { IS_LABELNAME_EQUALS_OF_QUERY } from '../Description/TodoLabel/AutoCompleteLabel/AutoCompleteLabel';

const CREATE_TODO_LABEL = graphql(`
  mutation CreateTodoLabel($newTodoLabelInput: NewTodoLabelInput!) {
    createTodoLabel(newTodoLabelInput: $newTodoLabelInput) {
      id
      labels {
        color
        bgColor
        id
        name
        description
      }
    }
  }
`);

const useCreateLabel = ({
  projectId,
  todoId,
}: {
  projectId: number;
  todoId?: number;
}) => {
  const [createTodoLabel, { loading }] = useMutation(CREATE_TODO_LABEL);
  const createLabel = (name: string) => {
    createTodoLabel({
      variables: {
        newTodoLabelInput: {
          projectId,
          name,
          todoId,
        },
      },
      update(cache, { data }) {
        if (!data) {
          return;
        }

        const existingSearchLabels = data.createTodoLabel.labels.filter(
          (label: any) => label.name === name
        );
        cache.writeQuery({
          query: GET_ALL_LABEL,
          variables: {
            projectId,
          },
          data: {
            getTodoLabel:
             data.createTodoLabel.labels
            ,
          },
        });
        cache.writeQuery({
          query: GET_SEARCh_LABEL,
          variables: {
            projectId,
            searchQuery: name,
          },
          data: {
            searchLabel: existingSearchLabels,
          },
        });
        cache.writeQuery({
          query: IS_LABELNAME_EQUALS_OF_QUERY,
          variables: {
            projectId,
            searchQuery: name,
          },
          data: {
            isLabelNameEqualOfSearchQuery: true,
          },
        });
      },
    });
  };

  return {
    createLabel,
    loading,
  };
};

export default useCreateLabel;
