import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getAuthToken } from '../../../utils/authToken';

interface ProtectedRouteProps {
  children: React.ReactNode;
  isPublic?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isPublic,
}) => {
  const location = useLocation();
  // Get current path
  const searchPath=location.search
  const currentPath = location.pathname;
  const accessToken = getAuthToken();
  useEffect(() => {
    if (!accessToken && !isPublic) {
      // Redirect to login page
      window.location.href = '/signin?redirect=' + currentPath + searchPath;
    }
    if (accessToken && isPublic) {
      // Redirect to home page
      window.location.href = '/';
    }
  }, [accessToken, currentPath]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
