import React, { useState, useRef, MouseEvent, ChangeEvent } from 'react';
import useGetSubscriptionContext from '../../Context/SubscriptionContext/useGetSubscriptionContext';
import useModalUpgradePlan from '../../../Hooks/useModalUpgradePlan';

class CustomFile extends File {
  analyzeMode: string;

  constructor(
    fileBits: BlobPart[],
    fileName: string,
    options: FilePropertyBag,
    analyzeMode: string
  ) {
    super(fileBits, fileName, options);
    this.analyzeMode = analyzeMode;
  }
}

const useFilePicker = (onUplaod: (data: File[]) => void) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [analyzeMode, setanalyzeMode] = useState<string | null>(null);
  const { isFreePlan } = useGetSubscriptionContext();
  const { openUpgradePlanModal } = useModalUpgradePlan();
  // Track selected file type
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick =
    (type: string) => (e: MouseEvent<HTMLElement>) => {
      if (isFreePlan && type === 'ADVANCED') {
        openUpgradePlanModal();
        return;
      }
      e.preventDefault();
      setanalyzeMode(type);
      fileInputRef.current?.click();
      handleClose();
    };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && analyzeMode) {
      const filesArray = Array.from(event.target.files).map(
        (file) =>
          new CustomFile(
            [file], // Use file's parts
            file.name,
            { type: file.type, lastModified: file.lastModified },
            analyzeMode // Initial value for analyzeMode
          )
      );
      onUplaod(filesArray as any[]);
      setanalyzeMode(null);
      // Reset the input value
      event.target.value = '';
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transition-popper' : undefined;

  return {
    fileInputRef,
    handleOpen,
    handleClose,
    handleListItemClick,
    handleFileChange,
    open,
    id,
    anchorEl,
  };
};

export default useFilePicker;
