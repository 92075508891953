import { useMutation } from '@apollo/client';
import { styled } from '@mui/material';
import { Box, Button } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql } from '../../../gql';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import SubmitButton from '../../Auth/AccountForm/SubmitButton/SubmitButton';
import LogoIcon from '../../Auth/LogoIcon/LogoIcon';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import SnackBar from '../../common/SnackBar/SnackBar';
import LogoutButton from '../../common/LogoutButton/LogoutButton';

export const Root = styled(Container)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minHeight: '100vh',
}));

export const BoxStyled = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  width: '100%',
  minHeight: '40vh',
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: '20px',
  width: '302px',
}));

const CONFIRM_EMAIL = graphql(/* GraphQL */ `
  mutation ConfirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`);

function EmailConfirm() {
  const [msgSnackBar, setMsqSnackBar] = React.useState() as any;
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [variantSnackBar, setVariantSnackBar] = React.useState(
    'info' as 'error' | 'info' | 'success' | 'warning'
  );
  useEffect(() => {
    Mixpanel.track('Email Confirmation Finish Page', {});
  }, []);
  const { token } = useParams();
  const navigate = useNavigate();
  const [confirmEmail, { loading }] = useMutation(CONFIRM_EMAIL);
  const onError = () => {
    setVariantSnackBar('error');
    setMsqSnackBar(
      <FormattedMessage
        id={'snackbar.error.comfirmEmail'}
        defaultMessage={
          'Your token has expired. Please request another confirmation email and try again.'
        }
      />
    );
    setOpenSnackBar(true);
  };
  const onConfirm = () => {
    confirmEmail({
      variables: {
        token: token as string,
      },
      update: (cache, { data }) => {
        if (data && data.confirmEmail) {
          Mixpanel.track('Email Confirmation Finish', {});
          navigate('/');
        }
      },

      onError: onError,
    });
  };
  const callbackSnackBar = () => {
    setOpenSnackBar(false);
  };

  return (
    <Root maxWidth="sm">
      <BoxStyled>
        <LogoIcon />
        <CustomTypography variant="h5" align="center" my={2} gutterBottom>
          <FormattedMessage
            id={'confirmEmail.title'}
            defaultMessage={
              "Congratulations! You now have full access to all the features and benefits of our service. We're excited to have you as a member of our community. Please click the confirm button below to start exploring."
            }
          />
        </CustomTypography>
        <ButtonWrapper>
          <SubmitButton
            onSubmit={onConfirm}
            disable={loading}
            isLoading={loading}
            buttonValue={
              <FormattedMessage
                id={'submit.button.Confirm'}
                defaultMessage={'Confirm'}
              />
            }
          />
        </ButtonWrapper>
        <Box>
          <LogoutButton />
        </Box>
        <SnackBar
          open={openSnackBar}
          callback={callbackSnackBar}
          label={msgSnackBar}
          variant={variantSnackBar}
        />
      </BoxStyled>
    </Root>
  );
}

export default EmailConfirm;
