export const isUrlSupportedFile = (
  url: string,
  supportedFiletypes?: string
) => {
  if (!url) {
    return false;
  }

  if (supportedFiletypes) {
    const fileExtension = url.split('.').pop()?.toLowerCase(); // Get the last part after the last dot

    return fileExtension ? supportedFiletypes.includes(fileExtension) : false;
  }
  return false;
};
