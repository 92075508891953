import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';

import UserProfil from '../../Chat/Description/TodoCreated/UserProfil/UserProfil';
import { User } from '../../../gql/graphql';

interface IProps {
  creator?: Partial<User>;
  miniMode?: boolean;
}

const STCreatorProfile: FC<IProps> = ({ creator, miniMode }) => {
  if (!creator) {
    return null;
  }

  const creatorName = `${creator.firstName || ''} ${
    creator.lastName || ''
  }`.trim();

  return (
    <CustomBox
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="fit-content"
      columnGap={1}
    >
      {!miniMode && (
        <CustomTypography
          variant="h6"
          sx={{
            fontSize: '0.725rem',
          }}
        >
          <FormattedMessage id="creator.profile.title" /> :
        </CustomTypography>
      )}
      {creatorName && (
        <UserProfil
          userProfil={creator.profilePicture}
          userName={creatorName}
          labelFontSize="0.725rem"
        />
      )}
    </CustomBox>
  );
};

export default STCreatorProfile;
