import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

const CHANGE_LABEL_COLOR = graphql(`
  mutation ChangeLabelColor($updateTodoLabelInput: UpdateTodoLabelInput!) {
    changeLabelColor(updateTodoLabelInput: $updateTodoLabelInput) {
      color
      bgColor
      id
    }
  }
`);

const useChangeLabelColor = () => {
  const [changeLabelColor] = useMutation(CHANGE_LABEL_COLOR);
  const onChangeColor = (baseColor: string, labelId: number) => {
    changeLabelColor({
      variables: {
        updateTodoLabelInput: {
          baseColor,
          labelId,
        },
      },
    });
  };

  return {
    onChangeColor,
  };
};

export default useChangeLabelColor;
