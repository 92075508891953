import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

const RENAME_TODO_SUBTASK = graphql(`
mutation RenameTodoSubTask($title: String!, $todoSubTaskId: Float!) {
    renameTodoSubTask(title: $title, todoSubTaskId: $todoSubTaskId) {
      id
      title
    }
  }
`);

const useRenameTodoSuTask = () => {
  const [renameTodoSubTask, { loading }] = useMutation(RENAME_TODO_SUBTASK);
  const renameSubTask = (title: string, todoSubTaskId: number) => {
    renameTodoSubTask({
      variables: {
        title,
        todoSubTaskId
      },

    });
  };

  return {
    renameSubTask,
    loading,
  };
};

export default useRenameTodoSuTask;
