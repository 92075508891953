import React from 'react';
import { useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';

interface LoadMoreSessionProps {
  isLoading: boolean;
  onLoadMore: () => void;
}

const LoadMoreSession: React.FC<LoadMoreSessionProps> = ({
  isLoading,
  onLoadMore,
}) => {
  const theme = useTheme();
  return (
    <CustomBox
      sx={{
        padding: theme.spacing(1),
      }}
    >
      <CustomButton
        color="primary"
        size="small"
        isLoading={isLoading}
        sx={{
          height: theme.spacing(4),
          justifyContent: 'flex-start',
          fontWeight: 400,
        }}
        onClick={onLoadMore}
        fullWidth
        startIcon={<MoreHorizIcon sx={{ color: theme.palette.common.black }} />}
      >
        <CustomTypography>
          <FormattedMessage id="button.session.view.more" />
        </CustomTypography>
      </CustomButton>
    </CustomBox>
  );
};

export default LoadMoreSession;
