import { useLazyQuery } from '@apollo/client';
import { graphql } from '../../../gql/gql';

// Check if bot is already authenticated
const CHECK_IS_BOT_ALREADY_AUTHENTICATED = graphql(`
  query CheckIsBotAlreadyAuthenticated($botId: Float!) {
    bot(id: $botId) {
      id
      isAlreadyAuthenticated
    }
  }
`);

const useCheckBotAuth = () => {
  // Lazy query to check if bot is already authenticated
  const [checkBotAuth, { loading }] = useLazyQuery(
    CHECK_IS_BOT_ALREADY_AUTHENTICATED,
    {
      fetchPolicy: 'network-only',
    }
  );

  return {
    checkBotAuth,
    loading,
  };
};

export default useCheckBotAuth;
