// create hook to get bot tags for input message
import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';

const GET_BOT_TAGS_FOR_INPUT_MESSAGE = graphql(`
  query GetBotTagsForInputMessage {
    getBotTagsForInputMessage {
      id
      slug
      title
      urlIcon
    }
  }
`);

const useBotTagsForInputMessage = () => {
  const { data, error, loading } = useQuery(GET_BOT_TAGS_FOR_INPUT_MESSAGE);
  return { list: data?.getBotTagsForInputMessage || [], error, loading };
};

export default useBotTagsForInputMessage;
