import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { dataItems } from './DataItems/DataItems';
import { useTheme } from '@mui/system';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid/Grid';
import StartIcon from './SvgIcon/StartIcon';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import useWindowDimensions from '../../../../../Hooks/useWindowDimensions';
import CustomBox from '../../../../common/CustomBox/CustomBox';
const ListContainer = styled(CustomBox)`
  max-height: 90vh;
  overflow: auto;
  width:100%
`;
declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
    'font-weight'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  fontWeight?: string;
  color?: string;
  labelInfo?: React.ElementType<SvgIconProps> | undefined;
  labelText: any;
};
interface props {
  onClose?(): void;
}
const StyledTreeView = styled(TreeView)(() => ({
  width: '100%',
  '& .MuiTreeItem-iconContainer .MuiSvgIcon-root': {
    marginLeft: '314px',
    marginTop: '-15px',
  },
}));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: -32,
  [`& .${treeItemClasses.content}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {},
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, labelInfo, fontWeight, labelText, ...other } = props;

  return (

    <StyledTreeItemRoot
      label={
        <Grid container sx={{ width: '100%', marginBottom: '15px' }}>
          <Grid xl={6} md={6} xs={6} sm={6} lg={6}>
            <CustomTypography
              variant="body2"
              style={{ 'font-weight': fontWeight }}
            >
              <FormattedMessage
                id={labelText}
                defaultMessage={labelText}
              />
            </CustomTypography>
          </Grid>
          <Grid xl={6} md={6} xs={6} sm={6} lg={6}>
            <Box component={labelInfo} sx={{ float: 'right' }} />
          </Grid>
        </Grid>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

export default function ListItems({ onClose }: props) {
  const [open, setOpen] = React.useState(false);
  const { width } = useWindowDimensions();

  const theme = useTheme();
  React.useEffect(() => {
    if (Number(width) < 600) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [width]);

  return (
    <ListContainer>
      <StyledTreeView
        defaultExpanded={['1']}
        defaultCollapseIcon={<KeyboardArrowDownIcon />}
        defaultExpandIcon={<KeyboardArrowRightIcon />}
      >
        {dataItems?.map((data: any) => (
          <NavLink
            style={{ textDecoration: 'none' }}
            to={data.link}
            onClick={() => (open && data.isClosable ? onClose?.() : null)}
          >
            <StyledTreeItem
              nodeId={data.id}
              labelText={data.name}
              bgColor="transparent"
              fontWeight={'bold'}
              color={theme.palette.secondary.main}
              labelInfo={data?.items?.length ? undefined : KeyboardArrowRightIcon}
            >
              {data?.items?.map((item: any) => (
                <NavLink
                  style={{ textDecoration: 'none', display: 'flex' }}
                  to={item.link}
                  onClick={() => (open && item.isClosable ? onClose?.() : null)}
                >
                  <StartIcon />
                  <StyledTreeItem
                    nodeId={item.id}
                    labelText={item.item}
                    color={theme.palette.secondary.main}
                    bgColor="transparent"
                    fontWeight={'normal'}
                  />
                </NavLink>
              ))}
            </StyledTreeItem>
          </NavLink>
        ))}
      </StyledTreeView>
    </ListContainer>
  );
}
