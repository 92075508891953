import React from 'react';
import Box from '@mui/system/Box';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import ToDoAsigneesLists from './ToDoAsigneesLists/ToDoAsigneesLists';
import CustomBox from '../../../common/CustomBox/CustomBox';

interface IProps {
  todoId: number;
}

const ToDoAsignees: React.FC<IProps> = ({ todoId }) => {
  return (
    <CustomBox
      sx={{
        marginTop: 1,
      }}
    >
      <Box marginBottom={2}>
        <CustomTypography variant="h6" color="#34324A" fontWeight="700">
          <FormattedMessage id="todo.asignees" defaultMessage="Assignees" />
        </CustomTypography>
      </Box>
      <ToDoAsigneesLists todoId={todoId} />
    </CustomBox>
  );
};

export default ToDoAsignees;
