import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

import { getUrlParam } from '../../../utils/url';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import { handleAfterAuth } from '../../../utils/authUtils';
import useDisplayMessage from '../../common/GlobalMessage/useDisplayMessage';
const AUTH0_LOGIN = graphql(
  `
    mutation Auth0Login($referralCode: String) {
      auth0Login(referralCode: $referralCode) {
        token
        user {
          id
          email
          firstName
          lastName
        }
        isNewSignup
      }
    }
  `
);

const useMicrosoftLogin = () => {
  const { displayMessage } = useDisplayMessage();
  const [mutation, { loading, error }] = useMutation(AUTH0_LOGIN, {
    onError: (error) => {
      displayMessage({
        message: 'signin.microsoft.error',
        type: 'error',
      });
    },
    update(cache, { data }) {
      // Redirect to home page if login success
      if (data?.auth0Login?.token) {
        Mixpanel.identify(data?.auth0Login.user.id);
        const isNewSignup = data?.auth0Login.isNewSignup;
        if (!isNewSignup) {
          Mixpanel.track('Signin', {
            email: data?.auth0Login.user.email,
            firstName: data?.auth0Login.user.firstName,
            lastName: data?.auth0Login.user.lastName,
            method: 'microsft',
          });
        }
        if (isNewSignup && isNewSignup === true) {
          Mixpanel.track('Signup', {
            email: data?.auth0Login.user.email,
            firstName: data?.auth0Login.user.firstName,
            lastName: data?.auth0Login.user.lastName,
            method: 'microsft',
          });
        }

        Mixpanel.people.set({
          $email: data?.auth0Login.user.email,
          $first_name: data?.auth0Login.user.firstName,
          $last_name: data?.auth0Login.user.lastName,
        });
        handleAfterAuth(data?.auth0Login.token);
      }
    },
  });

  const authHandler = (err: any, data: any) => {
    if (err) {
      displayMessage({
        message: 'signin.microsoft.error',
        type: 'error',
      });
      return;
    }
    auth0Login(data.accessToken);
  };

  const auth0Login = (accessToken: string) => {
    const referralCode = getUrlParam('invite') as string;
    mutation({
      variables: {
        referralCode,
      },
      context: {
        headers: {
          'auth0-token': `Bearer ${accessToken}`,
        },
      },
    });
  };

  return {
    loading: loading,
    error,
    authHandler,
  };
};

export default useMicrosoftLogin;
