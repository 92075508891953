import { IconButton, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import { OpenInNew, Refresh, Share } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import useHtmlPreview from './useHTMLPreview';
import Loading from '../../../../common/Loading/Loading';

interface IProps {
  htmlContent?: string;
  messageId: number;
}

const HTMLPreview: FC<IProps> = ({ htmlContent, messageId }) => {
  const theme = useTheme();
  const {
    iframeRef,
    refreshKey,
    iframeSrc,
    handleRefresh,
    handleCopy,
    loadingShare,
    handleShare,
    handleOpenInNewTab,
  } = useHtmlPreview({
    htmlContent,
    messageId,
  });

  if (!htmlContent) {
    return null;
  }
  return (
    <CustomBox
      sx={{
        width: '96%',
        height: 'auto',
        mt: theme.spacing(1),
        //paddingRight: theme.spacing(4),
        border: ` 1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px 4px 0 0',
      }}
    >
      <CustomBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          padding: theme.spacing(1),
          height: 40,

          // Create toolbar for the iframe modern color
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px 4px 0 0',
          color: theme.palette.primary.contrastText,
        }}
      >
        {/* Refresh and open to new tab icon here */}
        <IconButton color="inherit" size="small" onClick={handleRefresh}>
          <Refresh />
        </IconButton>

        <IconButton
          color="inherit"
          size="small"
          disabled={loadingShare}
          onClick={handleOpenInNewTab}
        >
          <OpenInNew />
        </IconButton>
        {loadingShare ? <Loading type="circular" /> : null}
        <CustomBox
          sx={{
            flexGrow: 1,
          }}
        />
        {/* Share button  */}

        <IconButton
          disabled={loadingShare}
          color="inherit"
          size="small"
          onClick={handleShare}
        >
          <Share />
        </IconButton>
      </CustomBox>
      <iframe
        style={{
          // The height should be dependent on the content of the iframe
          minHeight: '800px',
          // No border
          border: 'none',
        }}
        key={refreshKey}
        src={iframeSrc}
        ref={iframeRef}
        width="100%"
        title="html-preview"
        srcDoc={htmlContent}
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
      />
    </CustomBox>
  );
};

export default HTMLPreview;
