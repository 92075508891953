import { useMutation, ApolloCache } from '@apollo/client';
import { graphql } from '../../../gql';
import { GET_CHAT_SESSIONS } from './useGetAllChatSessions';
import {
  GetChatSessionsQuery,
  TodoChatSession,
  CreateChatSessionMutation,
  Exact,
  TodoChatSessionInput,
} from '../../../gql/graphql';
import { Mixpanel } from '../../../mixpanel/mixpanel';

const CREATE_CHAT_SESSION = graphql(`
  mutation CreateChatSession($data: TodoChatSessionInput!) {
    createTodoChatSession(data: $data) {
      id
      title
      isPrivate
      createdAt
      sessionUnreadNotificationsCount
      isTodoMainChat
      isCreatedByMe
      defaultBot {
        id
        user {
          profilePicture
          id
        }
      }
      dataSources {
        id
        name
        url
        fileType
      }
    }
  }
`);

const useCreateChatSession = ({
  todoId,
  onCompleted,
}: {
  todoId?: number;
  onCompleted?: (data: CreateChatSessionMutation) => void;
}) => {
  const [createChatSession, { loading: loadingNewSession }] = useMutation<
    CreateChatSessionMutation,
    Exact<{ data: TodoChatSessionInput }>
  >(CREATE_CHAT_SESSION, {
    update(cache: ApolloCache<any>, { data }) {
      if (!data?.createTodoChatSession) return;
      Mixpanel.track('Create chat session', {
        'Session title': data?.createTodoChatSession?.title,
      });
      cache.modify({
        fields: {
          getTodoChatSessions(
            existingData: any = {},
            { readField, toReference }
          ) {
            const newSessionRef = toReference(data.createTodoChatSession);

            if (!existingData || !existingData.sessions) {
              return {
                sessions: [newSessionRef],
                totalCount: 1,
                __typename: 'TodoChatSessionsResponse',
              };
            }

            const updatedSessions = [...existingData.sessions];
            updatedSessions.splice(1, 0, newSessionRef);

            return {
              ...existingData,
              sessions: updatedSessions,
              totalCount: existingData.totalCount + 1,
            };
          },
        },
      });

      onCompleted && onCompleted(data);
    },
    onError(error) {
      console.log('error', error);
    },
  });

  return {
    createChatSession,
    loadingNewSession,
  };
};

export default useCreateChatSession;
