import { styled } from '@mui/material/styles';
import { Popper, Paper } from '@mui/material';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.5),
}));

export const StyledPapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
}));
