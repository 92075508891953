import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
const CHECK_LABEL_TO_TASK = graphql(`
  mutation CheckLabelToTask($updateTodoLabelInput: UpdateTodoLabelInput!) {
    checkLabelToTask(updateTodoLabelInput: $updateTodoLabelInput) {
      id
      labels {
        id
        name
        description
      }
    }
  }
`);

const useCheckLabelToTask = () => {
  const [checkLabelToTask] = useMutation(CHECK_LABEL_TO_TASK);
  const handelCheckLabelToTask = (
    labelId: number,
    projectId: number,
    todoId: number
  ) => {
    checkLabelToTask({
      variables: {
        updateTodoLabelInput: {
          labelId,
          projectId,
          todoId,
        },
      },
    });
  };

  return {
    handelCheckLabelToTask,
  };
};

export default useCheckLabelToTask;
