import { IntlShape } from 'react-intl';

export const getAllCategoriesLabel = (intl: IntlShape): string => {
  return intl.formatMessage({
    id: 'datasource.all.categories',
  });
};

export const getAllStatusLabel = (intl: IntlShape): string => {
  return intl.formatMessage({
    id: 'datasource.all.status',
  });
};
