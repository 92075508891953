import React, { useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import CustomBox from '../CustomBox/CustomBox';
import { Box, IconButton, styled } from '@mui/material';
import {
  ArrowBack,
  ArrowForward,
  Refresh,
  ArrowDownward,
  ArrowUpward,
  ZoomIn,
  ZoomOut,
  OpenInFull,
} from '@mui/icons-material';
import Loading from '../Loading/Loading';
import useMermaidDiagram from './useMermaidDiagram';

interface MermaidDiagramProps {
  chart: string;
  height?: string;
}
export const ControlsWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(0.5),
  // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  zIndex: 99999,
}));

export const DiagramControlIcon = ({ children, onClick }: any) => (
  <IconButton
    sx={{
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
    size="small"
    color="inherit"
    onClick={onClick}
  >
    {children}
  </IconButton>
);

const MermaidDiagram: React.FC<MermaidDiagramProps> = ({ chart, height }) => {
  const {
    ref,
    handleRefresh,
    handleMoveUp,
    handleMoveDown,
    handleMoveLeft,
    handleMoveRight,
    handleZoom,
    loading,
  } = useMermaidDiagram({ chart });

  const Controls = () => (
    <ControlsWrapper>
      <Box
        sx={{
          display: 'flex',
          // Flex direction vertical
          flexDirection: 'column',

          alignItems: 'right',
          width: '100%',
        }}
      >
        {/* Up and zoom + */}
        <CustomBox
          sx={{
            display: 'flex',

            justifyContent: 'flex-end',

            gap: 1,
          }}
        >
          <DiagramControlIcon onClick={() => handleMoveUp()}>
            <ArrowUpward fontSize="small" />
          </DiagramControlIcon>
          <DiagramControlIcon onClick={() => handleZoom(1.1)}>
            <ZoomIn fontSize="small" />
          </DiagramControlIcon>
        </CustomBox>

        {/* Left refresh right */}
        <CustomBox sx={{ display: 'flex', gap: 1 }}>
          <DiagramControlIcon onClick={() => handleMoveLeft()}>
            <ArrowBack fontSize="small" />
          </DiagramControlIcon>
          <DiagramControlIcon onClick={handleRefresh}>
            <Refresh fontSize="small" />
          </DiagramControlIcon>
          <DiagramControlIcon onClick={() => handleMoveRight()}>
            <ArrowForward fontSize="small" />
          </DiagramControlIcon>
        </CustomBox>

        {/* Dowm and zoom - */}
        <CustomBox
          sx={{
            display: 'flex',
            gap: 1,

            justifyContent: 'flex-end',
          }}
        >
          <DiagramControlIcon onClick={() => handleMoveDown()}>
            <ArrowDownward fontSize="small" />
          </DiagramControlIcon>
          <DiagramControlIcon onClick={() => handleZoom(0.9)}>
            <ZoomOut fontSize="small" />
          </DiagramControlIcon>
        </CustomBox>
      </Box>
    </ControlsWrapper>
  );

  return (
    <CustomBox
      position="relative"
      sx={{
        overflow: 'hidden',

        height: height || 'auto',
        minHeight: '240px',
        padding: (theme) => theme.spacing(2),
        // Background color
        backgroundColor: '#24262C',
        color: 'white',
      }}
    >
      <CustomBox>
        {loading && (
          <CustomBox>
            <Loading />
          </CustomBox>
        )}
        <div ref={ref} className="mermaid" />
      </CustomBox>
      <CustomBox
        sx={{
          // Display in corner right bottom
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <Controls />
      </CustomBox>
    </CustomBox>
  );
};

export default MermaidDiagram;
