import * as React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const StyledChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '1px',
  paddingRight: '2px',
  paddingTop: '0px',
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
  fontSize: '12px',
  border: `1px  solid ${theme.palette.divider}`,
  borderRadius: '4px',
  '& .MuiChip-label': {
    paddingLeft: '2px',
    paddingRight: '1px',
  },
  '& .MuiChip-icon': {
    marginRight: '1px',
  },
}));

interface props {
  icon?: any;
  bgColor?: string;
  handleaAction?(e: any): void;
  onDelete?(): void;
  label?: string;
  labelTooltip?: string
  style?: Object;
}

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top"
    disableInteractive
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
  zIndex: 9999,
}));

const ButtonTaskAction = ({
  label,
  handleaAction,
  onDelete,
  icon,
  bgColor,
  style,
  labelTooltip,
}: props) => {
  const defaultColor = 'transparent';
  const [color, setColor] = React.useState(defaultColor);
  const theme = useTheme();
  const themeColor = theme.palette.background.paper;
  const customStyle = style ? style : {};

  return (
    <span
      onClick={handleaAction}
      onMouseOver={() => {
        setColor(themeColor);
      }}
      onMouseLeave={() => {
        setColor(defaultColor);
      }}
    >
      <CustomTooltip title={labelTooltip}>
        <StyledChip
          icon={icon}
          clickable={true}
          onDelete={onDelete}
          deleteIcon={<ClearIcon color="error" style={{ fontSize: '14px' }} />}
          sx={{ background: color, ...customStyle }}
          label={label}
        />
      </CustomTooltip>
    </span>
  );
};

export default ButtonTaskAction;
