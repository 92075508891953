import { RotateDirection } from '@react-pdf-viewer/core';
import type { ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { RenderZoomInProps, RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import { IconButton, styled } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import { RenderRotateProps } from '@react-pdf-viewer/rotate';
import { RotateRight } from '@mui/icons-material';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';

const PDFToolBar = ({ Toolbar, onSelect, isSelect }: any) => {
  return (
    <Toolbar>
      {(props: ToolbarSlot) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ZoomIn,
          ZoomOut,
          Rotate,
        } = props;
        return (
          <Box style={{ display: 'flex' }}>
            <Box style={{ padding: '2px' }}>
              <ZoomOut>
                {(props: RenderZoomOutProps) => (
                  <IconButton onClick={props.onClick}>
                    <ZoomOutIcon />
                  </IconButton>
                )}
              </ZoomOut>
            </Box>

            <Box style={{ padding: '2px' }}>
              <ZoomIn>
                {(props: RenderZoomInProps) => (
                  <IconButton onClick={props.onClick}>
                    <ZoomInIcon />
                  </IconButton>
                )}
              </ZoomIn>
            </Box>
            <Box style={{ padding: '2px' }}>
              <Rotate direction={RotateDirection.Forward}>
                {(props: RenderRotateProps) => (
                  <IconButton onClick={props.onClick}>
                    <RotateRight />
                  </IconButton>
                )}
              </Rotate>
            </Box>
            <Box style={{ padding: '2px' }}>
              <IconButton onClick={onSelect}>
                <HighlightAltIcon color={isSelect ? 'secondary' : 'inherit'} />
              </IconButton>
            </Box>
            <Box style={{ padding: '2px', marginLeft: 'auto' }}>
              <GoToPreviousPage>
                {(props: RenderGoToPageProps) => (
                  <IconButton onClick={props.onClick}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                )}
              </GoToPreviousPage>
            </Box>
            <Box style={{ padding: '2px' }}>
              <GoToNextPage>
                {(props: RenderGoToPageProps) => (
                  <IconButton onClick={props.onClick}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </GoToNextPage>
            </Box>
            <Box style={{ padding: '2px', width: '3rem' }}>
              <CurrentPageInput />
            </Box>
            <Box style={{ padding: '2px', marginTop: '5px' }}>
              / <NumberOfPages />
            </Box>
          </Box>
        );
      }}
    </Toolbar>
  );
};

export default PDFToolBar;
