import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql/gql';

const GET_BOT_TAGS = graphql(`
  query GetBotTags {
    getBotTags {
      id
      title
      searchDisplayOrder
      slug
    }
  }
`);

const useGetBotTags = () => {
  const { data, loading } = useQuery(GET_BOT_TAGS);
  return {
    data,
    loading,
  };
};

export default useGetBotTags;
