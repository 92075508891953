export function arrayToObject(arr: string[]): { [key: string]: string } {
  var obj: { [key: string]: string } = {};
  for (var i = 0; i < arr.length; i++) {
    var key = arr[i];
    obj[key] = '';
  }
  return obj;
}

export const getTagName =(description:string)=>{
  const regex = /#(\w+)/g;
  const matches = (description.match(regex) || []) as RegExpMatchArray;
  return matches.map((match) => match.slice(1));
}

export const getRandomColor = () => {
  const lightLetters = '89ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += lightLetters[Math.floor(Math.random() * lightLetters.length)];
  }

  return color + '80'; // Appends '80' for 50% opacity
};


export const spanTagName = (tagName: string, color: string) => {
  return `<span style="padding:2px;border-radius:4px;background:${color}">#${tagName}</span>`;
};


export const setTagNameRegex = (
  tagName: string,
  color: string,
  isInit: boolean
) => {
  const value = isInit ? `#${tagName}` : spanTagName(tagName, color);
  const regex = new RegExp(value, 'g');
  return regex;
};


export const replaceTagName = (
  str: string,
  oldTagName: string,
  tagName: string,
  color: string,
  isInit: boolean
) => {
  const newTagName = spanTagName(tagName, color);
  return str.replace(setTagNameRegex(oldTagName, color, isInit), newTagName);
};
export interface formPromps {
  [key: string]: string;
}


export const onChangeDescriptionFromTag = (
  str: string,
  value: string,
  tagName: string,
  color: string,
  formPromps: formPromps
) => {
  if (value) {
    if (str.indexOf(spanTagName(tagName, color)) > -1) {
      str = replaceTagName(str, tagName, value, color, false);
    } else {
      const oldValue = formPromps[tagName];
      str = replaceTagName(str, oldValue, value, color, false);
    }
  } else {
    str = replaceTagName(str, formPromps[tagName], tagName, color, false);
  }
  return str;
};


export const removeTags = (str: string) => {
  const regex =
    /<span style="padding:2px;border-radius:4px;background:(.*?)">#(.*?)<\/span>/g;
  return str.replace(regex, '$2');
};
