import { styled } from '@mui/material';
import CustomBox from '../CustomBox/CustomBox';

export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  width: theme.spacing(40),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const StyledItem = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1.5),
  width: theme.spacing(6),
  height: theme.spacing(6),
}));
