import { FC } from 'react';
import { graphql } from '../../gql';
import { useQuery } from '@apollo/client';
const GET_BOT_SUGGESTION = graphql(
  `
    query TodoChatMessage($id: Float!) {
      TodoChatMessage(id: $id) {
        id
        botSuggestions {
          id
          bot {
            id
            name
            slug
            description
            user {
              id
              firstName
              lastName
              profilePicture
            }
          }
          prompt
        }
      }
    }
  `
);

interface IProps {
  messageId: number;
}

const useGetBotSuggestion = ({ messageId }: IProps) => {
  // Use query to get bot suggestions
  const { data, error, loading } = useQuery(GET_BOT_SUGGESTION, {
    variables: { id: messageId },
    //fetchPolicy: 'network-only',
  });

  const botSuggestions = data?.TodoChatMessage?.botSuggestions || [];
  return {
    botSuggestions,
    loading,
    error,
  };
};

export default useGetBotSuggestion;
