import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
type ToggleType = {
    value: any;
    intlId: string;
};
interface ToggleButtonProps extends ToggleButtonGroupProps {
    toggleData:ToggleType[]
}
const STToggleButton = ({toggleData, ...rest }: ToggleButtonProps) => {
  return (
    <ToggleButtonGroup {...rest}>
      {toggleData.map((toggle) => (
        <ToggleButton sx={{textTransform:'capitalize'}} value={toggle.value}>
            <FormattedMessage id={toggle.intlId} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default STToggleButton;
