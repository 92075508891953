import { SvgIconComponent } from '@mui/icons-material';
import {
  compareDesc,
  isSameYear,
  isToday,
  isYesterday,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { groupBy } from 'lodash';
import { useIntl } from 'react-intl';
type IconColor =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type IconSize = 'inherit' | 'large' | 'medium' | 'small';
interface Action {
  disabled?: boolean;
  iconColor?: IconColor;
  iconSize?: IconSize;
  icon: SvgIconComponent;
  tooltipIntlId: string;
  onClick: (item: Item) => void;
}
interface Item {
  id: number;
  primaryText: string;
  userProfil?: string;
  secondaryText?: string;
  createdAt?: any;
  avatarImg?: string; // optional avatarImg property
  unreadNotificationsCount?: number;
  ListItemLeft?: React.ReactNode; // optional LeftItem property
  actions?: Action[]; // optional actions property
  onItemTextClick?: (item: Item) => void; // optional onItemTextClick property
  onItemClick?: (item: Item) => void; // optional onItemClicked property
  href?: string; // optional href property
}
type groupByType = 'createdAt' | 'updatedAt';
const useGroupSTList = (items: Item[], groupByProp?: groupByType) => {
  const intl = useIntl();
  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);

  if (groupByProp !== 'createdAt') return null;

  const groupedItems = items.reduce((acc, item) => {
    const itemDate = parseISO(item.createdAt!);
    let key: string;
    let sortDate: Date;
    if (isToday(itemDate)) {
      key = intl.formatMessage({ id: 'group.name.today' });
      sortDate = new Date(8640000000000000);
    } else if (isYesterday(itemDate)) {
      key = intl.formatMessage({ id: 'group.name.yesterday' });
      sortDate = new Date(today.getTime() - 86400000);
    } else if (itemDate > firstDayOfMonth && itemDate < today) {
      const previousDays = today.getDate() - firstDayOfMonth.getDate();
      key = intl.formatMessage(
        { id: 'group.name.previous.day' },
        { previousDays }
      );
      sortDate = firstDayOfMonth;
    } else {
      const yearName = isSameYear(itemDate, today)
        ? ''
        : itemDate.getFullYear().toString();
      const monthName = itemDate.toLocaleString(intl.locale, { month: 'long' });
      key = `${monthName} ${yearName}`.trim();
      sortDate = new Date(itemDate.getFullYear(), itemDate.getMonth(), 1);
    }

    if (!acc[key]) {
      acc[key] = { name: key, items: [], sortDate };
    }
    acc[key].items.push(item);
    return acc;
  }, {} as Record<string, { name: string; items: Item[]; sortDate: Date }>);

  return Object.values(groupedItems).sort((a, b) =>
    compareDesc(a.sortDate, b.sortDate)
  );
};

export default useGroupSTList;
