import { Box } from '@mui/material';
import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomBox from '../../../common/CustomBox/CustomBox';
import STDynamicForms from '../../../common/STDynamicForm/STDynamicForm';
import useCustomPromptsStep2 from './useCustomPromptStep2';

const CustomPromtsStep2 = () => {
  const { formik, handleChange, fields, loadingAddPrompt } =
    useCustomPromptsStep2();

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
      <CustomBox
        width="100%"
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <CustomBox px={2} py={1} flex="1" width="100%" display={'flex'}>
          <CustomBox width="100%">
            <STDynamicForms
              fields={fields}
              currentValues={formik.values}
              onChange={() => {}}
              onChangeWithEvent={handleChange}
            />
          </CustomBox>
        </CustomBox>
        <Box
          justifyContent="flex-end"
          display="flex"
          pb={2.5}
          height="auto"
          alignItems="flex-end"
        >
          <CustomButton
            intlId="libraries.prompts.save"
            type="submit"
            sx={{ mr: 2 }}
            variant="contained"
            isLoading={loadingAddPrompt}
            disabled={!formik.values.title || !formik.values.description}
          />
        </Box>
      </CustomBox>
    </form>
  );
};

export default CustomPromtsStep2;
