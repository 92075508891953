import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import React from 'react';
import { useParams } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

export const GET_CHAT = graphql(`
  query sessionMessages($sessionId: Float!) {
    sessionMessages(sessionId: $sessionId) {
      ...ChatMessageFragment
    }
  }
`);

const useGetChatTodo = ({
  todoId,
  sessionId,
}: {
  todoId: number;
  sessionId?: number;
}) => {
  const params = useParams();
  let theSessionId = sessionId || +(params?.sessionId ? params.sessionId : 0);

  const { data, error, loading, refetch } = useQuery(GET_CHAT, {
    fetchPolicy: 'cache-first',
    variables: {
      sessionId: theSessionId,
    },
  });
  React.useEffect(() => {
    refetch();
  }, [params?.sessionId]);

  // Use memo and use _uniqBy to prevent duplicate messages
  const sessionMessages = React.useMemo(() => {
    return uniqBy(data?.sessionMessages, 'id');
  }, [data?.sessionMessages]);

  return {
    refetch,
    data,
    error,
    loading,
    currentSessionId: theSessionId,
    sessionMessages,
  };
};

export default useGetChatTodo;
