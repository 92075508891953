/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomDialog from '../../../../common/CustomDialog/CustomDialog';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import useDownLoadFile from '../../../../../Hooks/useDownLoadFile';
import CustomBox from '../../../../common/CustomBox/CustomBox';

interface props {
  open: boolean;
  src: string;
  handleClose(): void;
}

export default function ImageViewer({ open, src, handleClose }: props) {
  const { downloadFile } = useDownLoadFile();
  const handleDownload = () => {
    downloadFile({ fileUrl: src });
  };

  return (
    <CustomDialog
      open={open}
      fullScreen={false}
      maxWidth="md"
      sx={{
        '& .MuiDialogContent-root': {
          maxWidth: '100%',
          maxHeight: '100%',
        },
      }}
      actions={[
        {
          tooltipIntlId: 'message.download',
          icon: VerticalAlignBottomIcon,
          onClick: handleDownload,
        },
      ]}
      handleClose={handleClose}
      contentChildren={
        <img style={{ objectFit: 'cover' }} src={src} alt="image" />
      }
    />
  );
}
