import { FC, FormEvent, useEffect } from 'react';
import useSentNewMessage from '../../../hooks/useSendNewMessage';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../common/CustomButton/CustomButton';
import STDynamicForms from '../../../../common/STDynamicForm/STDynamicForm';
import { FormField } from '../../../../../gql/graphql';
import useDisplayMessage from '../../../../common/GlobalMessage/useDisplayMessage';
import { useContextFormChat } from '../../../hooks/useContextFormChat';

interface IFormFields {
  formFields: FormField[] | [];
  option?: {
    allowSendNewMessage?: boolean;
    todoId: number;
    sessionId: number;
    botId: number;
    callBackSendMessage?: () => void;
  };
}
const BotExtraConfig: FC<IFormFields> = ({ formFields, option }) => {
  const { extraConfig, handleChangeExtraConfig, verifyRequiredExtraConfig } =
    useContextFormChat();
  const { displayMessage } = useDisplayMessage();

  const { addNewMessage, loadSendMessage } = useSentNewMessage({
    taskID: option?.todoId || 0,
  });

  const setDefaultValueData = () => {
    formFields.forEach((formField) => {
      // Reset only formName in formFields
      if (formField.defaultValue !== undefined) {
        handleChangeExtraConfig(formField.formName, formField.defaultValue);
      }
    });

    handleChangeExtraConfig('errors', '');
  };

  useEffect(() => {
    setDefaultValueData();
  }, [formFields.length]);

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const hasNotError = verifyRequiredExtraConfig(formFields);

    if (hasNotError) {
      if (option?.callBackSendMessage) {
        option.callBackSendMessage();
      }
      addNewMessage({
        variables: {
          newMessageData: {
            mentionedBotId: option?.botId,
            sessionId: +(option?.sessionId || 0),
            extraConfig,
          },
        },
        onError() {
          displayMessage({
            message: 'send.image.error',
            type: 'error',
          });
        },
      });
    }
  };

  return (
    <form onSubmit={handleSendMessage}>
      <CustomBox sx={{ position: 'relative', height: '80vh' }}>
        <CustomBox
          pt={1}
          sx={(theme) => ({
            position: 'relative',
            wordWrap: 'break-word',
            boxSizing: 'border-box',
            pr: 1,
            height: '100%',
            overflowY: 'auto',
            width: '100%',
            overflowX: 'hidden',
            [theme.breakpoints.down(1200)]: {
              top: 0,
              left: 0,
            },
          })}
        >
          <STDynamicForms
            fields={formFields as any}
            currentValues={extraConfig}
            onChange={handleChangeExtraConfig}
            formik={extraConfig}
          />
        </CustomBox>
        <CustomBox
          m={1}
          sx={(theme) => ({
            right: 0,
            bottom: 0,
            display: 'flex',
            gap: theme.spacing(1),
            justifyContent: 'flex-end',
          })}
        >
          <CustomButton
            onClick={setDefaultValueData}
            intlId="drawer.button.sitting.extraConfig.reset"
            disabled={loadSendMessage}
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.grey[200],
              borderColor: (theme) => theme.palette.grey[200],
            }}
          />
          {option?.allowSendNewMessage ? (
            <CustomButton
              disabled={loadSendMessage}
              type="submit"
              variant="contained"
              intlId="chat.form.send.message"
              isLoading={loadSendMessage}
            />
          ) : null}
        </CustomBox>
      </CustomBox>
    </form>
  );
};

export default BotExtraConfig;
