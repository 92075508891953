import { Box } from '@mui/material';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { useContext } from 'react';
import { StepContext } from '../../Prompts';
import CustomBox from '../../../common/CustomBox/CustomBox';
import STDynamicForms from '../../../common/STDynamicForm/STDynamicForm';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';
import useUpdatePromptForm from './useUpdatePromptForm';

interface UpdateCustomPromptProps {
  handleCancel: () => void;
}

const UpdateCustomPrompt = ({ handleCancel }: UpdateCustomPromptProps) => {
  const { idCategorie, idPrompts } = useContext(StepContext);
  const { onUpdatePrompt, loadingAddPrompt } =
    useGetCategoryPrompts(idCategorie);

  const { formik, fields } = useUpdatePromptForm(
    idCategorie,
    idPrompts,
    onUpdatePrompt
  );

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
      <CustomBox width="100%" height="100%">
        <CustomBox px={2} py={1} width="100%" display="flex" height="85%">
          <CustomBox width="100%">
            <STDynamicForms
              fields={fields}
              currentValues={formik.values}
              onChange={formik.handleChange}
              onChangeWithEvent={formik.handleChange}
            />
          </CustomBox>
        </CustomBox>
        <Box
          justifyContent="flex-end"
          display="flex"
          pb={2.5}
          gap={1}
          height="15%"
          alignItems="flex-end"
        >
          <CustomButton
            intlId="Cancel"
            variant="contained"
            isLoading={loadingAddPrompt}
            color="inherit"
            onClick={handleCancel}
          />
          <CustomButton
            intlId="libraries.prompts.save.change"
            sx={{ mr: 2 }}
            variant="contained"
            isLoading={loadingAddPrompt}
            disabled={
              formik.values.title === formik.initialValues.title &&
              formik.values.description === formik.initialValues.description
            }
            type="submit"
          />
        </Box>
      </CustomBox>
    </form>
  );
};

export default UpdateCustomPrompt;
