import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';

const SearchBox = ({
  isDisplaySearchDialog,
}: {
  isDisplaySearchDialog: boolean;
}) => {
  const theme = useTheme();
  return (
    <CustomBox
      width="80%"
      p={theme.spacing(0.8, 1, 1, 0)}
      pl={theme.spacing(5)}
      fontSize={theme.typography.body2.fontSize}
      sx={{ background: 'inherit' }}
    >
      <CustomTypography
        sx={{
          color: isDisplaySearchDialog
            ? theme.palette.secondary.main
            : 'inherit',
          fontWeight: isDisplaySearchDialog ? 'bold' : 'normal',
        }}
      >
        <FormattedMessage id="search.bar.placeholder" />
      </CustomTypography>
    </CustomBox>
  );
};

export default SearchBox;
