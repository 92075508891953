import { useTheme } from '@mui/material';
import * as React from 'react';

const ScreenCastIcon = () => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark' ? 'white' : '#34324A';
  return (
    <>
      <svg
        fill={color}
        width="20"
        height="20"
        viewBox="0 0 256 256"
        id="Flat"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M227.99414,56V200a12.01343,12.01343,0,0,1-12,12h-72a4,4,0,0,1,0-8h72a4.00427,4.00427,0,0,0,4-4V56a4.00427,4.00427,0,0,0-4-4h-176a4.00427,4.00427,0,0,0-4,4V96a4,4,0,0,1-8,0V56a12.01343,12.01343,0,0,1,12-12h176A12.01343,12.01343,0,0,1,227.99414,56Zm-196,132a4,4,0,0,0,0,8,12.01343,12.01343,0,0,1,12,12,4,4,0,0,0,8,0A20.0226,20.0226,0,0,0,31.99414,188Zm0-32a4,4,0,0,0,0,8,44.04978,44.04978,0,0,1,44,44,4,4,0,0,0,8,0A52.059,52.059,0,0,0,31.99414,156Zm0-32a4,4,0,0,0,0,8,76.08614,76.08614,0,0,1,76,76,4,4,0,0,0,8,0A84.0953,84.0953,0,0,0,31.99414,124Z" />
      </svg>
    </>
  );
};

export default ScreenCastIcon;
