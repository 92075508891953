import * as React from 'react';
import ModalPrompts from './ModalPrompts/ModalPrompts';
import ButtonPrompts from './ButtonPrompts/ButtonPrompts';
import { createContext } from 'react';
import { Mixpanel } from '../../mixpanel/mixpanel';
import CustomBox from '../common/CustomBox/CustomBox';
import { useMediaQuery } from '@mui/system';
import CustomIconButton from '../common/CustomIconButton/CustomIconButton';
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
  handleValidePrompts(prompts: string): void;
}

interface StepContextType {
  activeStep: number;
  lastStep: number;
  idPrompts: number;
  idCategorie: number;
  categoryName: string;
  isCustomCategory?: boolean | null;
  updateStep: (newStep: StepContextType) => void;
  handleNextStep: (
    callBack?: any,
    currentActive?: number,
    idPrompts?: number
  ) => void;
  handlePreviousStep: (callBack?: any) => void;
  handleInitCategory?: (idCategorie: number, categoryName: string) => void;
}

export const StepContext = createContext<StepContextType>({
  activeStep: 0,
  idPrompts: 0,
  lastStep: 0,
  idCategorie: 0,
  categoryName: '',
  updateStep: () => {},
  handleNextStep: () => {},
  handlePreviousStep: () => {},
  handleInitCategory: () => {},
  isCustomCategory: true,
});

const Prompts = ({ handleValidePrompts }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState<StepContextType>({
    activeStep: 0,
    idPrompts: 0,
    lastStep: 0,
    idCategorie: 0,
    categoryName: '',
    updateStep: (newStep: StepContextType) => setStep(newStep),
    handleNextStep: () => {},
    handlePreviousStep: () => {},
    handleInitCategory: () => {},
    isCustomCategory: true,
  });

  const isMobile = useMediaQuery('(max-width:600px)');

  const restStep = () => {
    setStep((prevState) => ({ ...prevState, activeStep: 0, idPrompts: 0 }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    Mixpanel.track('Open Prompts Library', {});
    setOpen(true);
    restStep();
  };

  const handleNextStep = (
    callBack: any,
    currentActive?: number,
    idPrompts?: number,
    idCategorie?: number,
    categoryName?: string
  ) => {
    if (step.activeStep < 3) {
      setStep((prevState) => ({
        ...prevState,
        activeStep: currentActive ? currentActive : prevState.activeStep + 1,
        lastStep: prevState.activeStep,
        idPrompts: idPrompts ? idPrompts : prevState.idPrompts,
        idCategorie: idCategorie ? idCategorie : prevState.idCategorie,
        categoryName: categoryName ? categoryName : prevState.categoryName,
      }));
      if (callBack) {
        callBack();
      }
    }
  };

  const handlePreviousStep = (
    callBack: any,
    idCategorie?: number,
    categoryName?: string
  ) => {
    setStep((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep ? prevState.lastStep : 0,
      lastStep: prevState.activeStep ? prevState.activeStep - 1 : 0,
      idCategorie: idCategorie ? idCategorie : prevState.idCategorie,
      categoryName: categoryName ? categoryName : prevState.categoryName,
    }));
    if (callBack) {
      callBack();
    }
  };

  const handleInitCategory = (idCategorie: number, categoryName: string) => {
    setStep((prevState) => ({
      ...prevState,
      idCategorie: idCategorie ? idCategorie : prevState.idCategorie,
      categoryName: categoryName ? categoryName : prevState.categoryName,
    }));
  };

  return (
    <StepContext.Provider
      value={{
        ...step,
        handleNextStep,
        handlePreviousStep,
        handleInitCategory,
      }}
    >
      <CustomBox>
        {isMobile ? (
          <CustomIconButton onClick={handleClickOpen}>
            <DescriptionIcon />
          </CustomIconButton>
        ) : (
          <ButtonPrompts handleClick={handleClickOpen} />
        )}
      </CustomBox>
      {open ? (
        <ModalPrompts
          open={open}
          handleValidePrompts={handleValidePrompts}
          handleClose={handleClose}
        />
      ) : null}
    </StepContext.Provider>
  );
};

export default Prompts;
