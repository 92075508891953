import * as React from 'react';

import MemberManagement from '../../../../../../common/MemberManagement/MemberManagement';
import useGetProjectMembers from '../../../../../../../Hooks/useGetProjectMembers';
import { Member } from '../../../../../../common/MemberManagement/type';
import { Role } from '../../../../../../../gql/graphql';
import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import STInfoHelp from '../../../../../../common/STInfoHelp/STInfoHelp';

interface IProps {
  projectId: number;
}

const InviteCollaboratorContent: React.FC<IProps> = ({ projectId }) => {
  // User email to invite

  const {
    data,
    loading: loadingMember,
    addProjectCollaborator,
    removeProjectCollaborator,
    updateCollaboratorRole,
    isInviteInProgress,
    isInviteError,
  } = useGetProjectMembers({ projectId });

  // User role to invite
  const [role, setRole] = React.useState('MEMBER');

  const projectMembers: Member[] = (data?.project?.members || []) as Member[];

  const handleInvite = (email: string, role: string) => {
    if (email) {
      addProjectCollaborator({
        variables: {
          newProjectCollaboratorData: {
            projectId,
            email,
            role,
          },
        },
      });
    }
  };
  const handleRemove = (memberId: number) => {
    removeProjectCollaborator({
      variables: {
        memberId,
      },
    });
  };
  const handleUpdate = (role: string, memberId: number) => {
    updateCollaboratorRole({
      variables: {
        memberId,
        role,
      },
    });
  };

  const roles = [
    {
      value: Role.Owner,
      label: 'Owner',
    },
    {
      value: Role.Admin,
      label: 'Admin',
    },
    {
      value: Role.Member,
      label: 'Member',
    },
    {
      value: Role.Viewer,
      label: 'Viewer',
    },
  ];

  return (
    <>
      <MemberManagement
        members={projectMembers}
        handleInvite={handleInvite}
        isInviteError={Boolean(isInviteError)}
        inviteInProgress={isInviteInProgress}
        roles={roles}
        handleRemoveMember={handleRemove}
        handleUpdateMember={handleUpdate}
        inviteMoreInfoComponent={
          <STInfoHelp
            intlId="project.member.invite.moreInfo"
            defaultMessage="Inviting a user who is not already a member of the workspace will result in additional charges on your subscription bill."
          />
        }
      />
    </>
  );
};

export default InviteCollaboratorContent;
