import CustomBox from '../../../../common/CustomBox/CustomBox';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../../../common/Skeleton/Skeleton';

const Loader = () => {
  return (
    <CustomBox
      sx={{
        overflowY: 'auto',
        maxHeight: '80vh',
        width: '100%',
        padding: (theme) => theme.spacing(1),
      }}
    >
      <ProviderSkeleton>
        <CustomBox mt={1} mb={1}>
          <SkeletonContent height={35} />
        </CustomBox>
        <CustomBox mt={1} mb={1}>
          <SkeletonContent height={35} />
        </CustomBox>
        <CustomBox mt={1} mb={1}>
          <SkeletonContent height={35} />
        </CustomBox>
        <CustomBox mt={1} mb={1}>
          <SkeletonContent height={35} />
        </CustomBox>
        <CustomBox mt={1} mb={1}>
          <SkeletonContent height={35} />
        </CustomBox>
      </ProviderSkeleton>
    </CustomBox>
  );
};

export default Loader;
