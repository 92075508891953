import * as React from 'react';
import CardLinkForgotPassword from '../../AccountForm/CardLink/CardLinkForgotPassword/CardLinkForgotPassword';
import EmailInput from '../../AccountForm/EmailInput/EmailInput';
import SubmitBottom, { propsSubmit } from '../../AccountForm/SubmitButton/SubmitButton';
import Box from '@mui/material/Box';
import AlertText from '../../../common/AlertText/AlertText';
import { styled } from '@mui/material';
import { useIntl } from 'react-intl';



export const BoxStyled = styled(Box)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: '13px',
    padding: '10px 5px',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    color: theme.palette.text.secondary,
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));

export interface Formprops {
  inputValue: { email: string };
  buttonValue: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: propsSubmit["onSubmit"];
  hasError?: boolean;
}
const ForgotPasswordForm = ({ inputValue, onChange, onSubmit, hasError, buttonValue }: Formprops) => {
  const intl=useIntl()
  return (
    <Box
      component="form"
      noValidate
      sx={{ mt: 1, width: 302 }}
    >

      <BoxStyled>
        <EmailInput email={inputValue.email} placeholder={intl.formatMessage({
        id: 'input.email',
        defaultMessage: 'Email address',
      })} onChange={onChange} />


        {
          hasError && <AlertText message="Invalid email." severity="error" />
        }
        <SubmitBottom onSubmit={onSubmit} buttonValue={buttonValue} disable={false} />

        <CardLinkForgotPassword />
      </BoxStyled>
    </Box>
  );
}
export default ForgotPasswordForm