import * as React from 'react';
import { Box, Divider, Grid, Paper, styled, useTheme } from '@mui/material';

import CustomButton from '../../common/CustomButton/CustomButton';
import { useIntl } from 'react-intl';
import { Member, MemberRole } from './type';
import MemberList from './MemberList/MemberList';
import SeclectRole from './SeclectRole/SeclectRole';
import CustomBox from '../CustomBox/CustomBox';
import STAlertMessage from '../GlobalMessage/STAlertMessage/STAlertMessage';
import STEmailField from '../STEmailField/STEmailField';
import { checkEmail } from '../../../utils/emailUtil';

interface IProps {
  handleInvite: (email: string, role: string) => void;
  isInviteError: boolean;
  inviteInProgress: boolean;
  members: Member[];
  isLoadingMembers?: boolean;
  roles: MemberRole[];
  handleRemoveMember?(memberId: number): void;
  handleUpdateMember?(role: string, memberId: number): void;
  moreInfoComponent?: React.ReactNode;
  inviteMoreInfoComponent?: React.ReactNode;
}

const MemberManagement: React.FC<IProps> = ({
  handleInvite,
  inviteInProgress,
  members,
  isLoadingMembers,
  roles,
  handleRemoveMember,
  handleUpdateMember,
  moreInfoComponent,
  inviteMoreInfoComponent,
}) => {
  // User email to invite
  const [email, setEmail] = React.useState('');

  // User role to invite
  const [role, setRole] = React.useState('MEMBER');
  const intl = useIntl();
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark' ? true : false;

  const handleInviteUser = () => {
    if (email) {
      handleInvite(email, role);
      setEmail('');
    }
  };

  const handleChangeRole = (newRole: any) => {
    setRole(newRole);
  };

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const ButtonStyled = styled(CustomButton)(({ theme }) => ({
    float: 'right',
    width: '100%',
    height: '42px',
    backgroundColor: email
      ? isDark
        ? theme.palette.primary.main
        : theme.palette.primary.main
      : undefined,
  }));
  const isEmailValid = checkEmail(email as string | undefined);

  return (
    <>
      <Grid container spacing={2} width="100%">
        <Grid item xs={12} width="100%">
          <CustomBox mt={1}>
            <STAlertMessage />
          </CustomBox>
          {inviteMoreInfoComponent && (
            <CustomBox mb={1}>{inviteMoreInfoComponent}</CustomBox>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9} xl={9} md={9} lg={9} sm={9} my={1.5}>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  pl: (theme) => theme.spacing(1),
                  pr: (theme) => theme.spacing(1),
                  pb: (theme) => theme.spacing(1),
                  background: theme.palette.background.default,
                  display: 'flex',
                  borderRadius: 2,
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <CustomBox
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <STEmailField
                    value={email}
                    onChange={handleChangeEmail}
                    displayEmailValidationError={false}
                    labelIntl="Email"
                    placeholder="Email Address"
                    name="email"
                    sx={{ width: '100%' }}
                    fullWidth
                  />
                </CustomBox>
                <CustomBox
                  sx={{
                    mt: 1,
                  }}
                >
                  <SeclectRole
                    initRole="MEMBER"
                    getRole={handleChangeRole}
                    roles={roles}
                  />
                </CustomBox>
              </Paper>
            </Grid>
            <Grid item xs={3} xl={3} lg={3} md={3} sm={3}>
              <Box width={'100%'}>
                <ButtonStyled
                  disabled={!isEmailValid}
                  onClick={handleInviteUser}
                  variant="contained"
                  size="large"
                  isLoading={inviteInProgress}
                  label={intl.formatMessage({
                    id: 'project.button.invite.collaborator',
                    defaultMessage: 'Send invite',
                  })}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {moreInfoComponent && (
            <CustomBox mt={1}>{moreInfoComponent}</CustomBox>
          )}
          <CustomBox>
            <MemberList
              members={members}
              isLoadingMembers={isLoadingMembers}
              roles={roles}
              handleRemoveMember={handleRemoveMember}
              handleUpdateMember={handleUpdateMember}
            />
          </CustomBox>
        </Grid>
      </Grid>
    </>
  );
};

export default MemberManagement;
