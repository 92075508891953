import { Box } from '@mui/material';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import customDateEvent from '../../../../../utils/customDateEvent';
import getDateFromEvent from '../../../../../utils/getDateFromEvent';
import './style.css';
import { dueDateProps } from '../../../../../types/DueDates';

interface props {
  dueDate: dueDateProps;
  handleSetDueDate(dueDate: dueDateProps): void;
}

function CalendarPicker({ dueDate, handleSetDueDate }: props) {
  const setDueDateFromDate = (date: string | null | Date) => {
    const events = getDateFromEvent(date);
    handleSetDueDate(events);
  };

  const tileDisabled = ({ activeStartDate, date, view }: any) => {
    return moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
  };

  return (
    <Box bgcolor="none">
      <Calendar
        tileDisabled={tileDisabled}
        onChange={(value: any, event: any) => {
          setDueDateFromDate(value);
        }}
        value={customDateEvent(dueDate.value)}
        selectRange={false}
        showDoubleView={false}
        locale="us-US"
      />
    </Box>
  );
}

export default CalendarPicker;
