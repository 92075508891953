import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const clearAllLocalStorage = () => {
    const keysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith('lastSelectedView_')) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach((key) => {
      return localStorage.removeItem(key);
    });
  };
  const logout = () => {
    localStorage.clear();
    clearAllLocalStorage();
    navigate(`/signin`);
  };
  return { logout };
};

export default useLogout;
