import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Title from '../Title/Title';
import SignupForm from './SignupForm/SignupForm';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import LogoIcon from '../LogoIcon/LogoIcon';
import AlertText from '../../common/AlertText/AlertText';
import { styled } from '@mui/material';
import { handleAfterAuth } from '../../../utils/authUtils';
import { getUrlParam } from '../../../utils/url';

export const StyledContainer = styled(Container)(({ theme }) => ({
  background:
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.background.default,
}));

// Signup graphql mutation
const signupMutation = graphql(`
  mutation signup(
    $referralCode: String
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    signup(
      referralCode: $referralCode
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      token
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`);
type ErrorSignup = {message?: string, hasError: boolean};
export default function Signup() {
  const [OpenEmailInput, SetOpenEmailInput] = React.useState(true);
  const [OpenInputFirstName, SetOpenInputFirstName] = React.useState(false);
  const [OpenInputLastName, SetOpenInputLastName] = React.useState(false);
  const [OpenPassordInput, SetOpenPassordInput] = React.useState(false);
  const [OnSubmit, SetOnSubmit] = React.useState(false);
  const [error, SetError] = React.useState<ErrorSignup>({
    message: '',
    hasError: false,
  });
  const [inputValue, setInput] = React.useState<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>({ email: '', password: '', firstName: '', lastName: '' });

  const referralCode = getUrlParam('invite') as string;

  // Mix panel page view event
  React.useEffect(() => {
    Mixpanel.track('Signup page view', { page: 'Signup' });
  }, []);

  // Handle input change
  const onChangeInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInput({ ...inputValue, [e.currentTarget.name]: e.currentTarget.value });
  };
  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //Signup appollo mutation
  const [signup, { data, loading }] = useMutation(signupMutation);

  const onSubmit = () => {
    SetOnSubmit(true);
    if (emailRegex.test(inputValue.email)) {
      SetError({ message: '', hasError: false });
      SetOpenEmailInput(false);
      SetOpenInputFirstName(false);
      SetOpenInputLastName(false);
      SetOpenPassordInput(true);
    }
    if (inputValue.password) {
      SetOpenEmailInput(false);
      SetOpenInputFirstName(true);
      SetOpenInputLastName(false);
      SetOpenPassordInput(false);
    }
    if (inputValue.firstName) {
      SetOpenEmailInput(false);
      SetOpenInputFirstName(false);
      SetOpenInputLastName(true);
      SetOpenPassordInput(false);
    }
    if (inputValue.lastName) {
      signup({
        variables: {
          referralCode: referralCode,
          email: inputValue.email,
          password: inputValue.password,
          firstName: inputValue.firstName,
          lastName: inputValue.lastName,
        },
        onCompleted: (data) => {
          SetOpenEmailInput(true);
          SetOpenInputFirstName(false);
          SetOpenInputLastName(false);
          SetOpenPassordInput(false);
        },
        update: (cache, { data }) => {
          // Mixpanel identify and track
          if (data?.signup.user.id) {
            Mixpanel.identify(data?.signup.user.id);
            Mixpanel.track('Signup', {
              email: data?.signup.user.email,
              firstName: data?.signup.user.firstName,
              lastName: data?.signup.user.lastName,
              method: 'email',
            });

            Mixpanel.people.set({
              $email: data?.signup.user.email,
              $first_name: data?.signup.user.firstName,
              $last_name: data?.signup.user.lastName,
            });
            handleAfterAuth(data.signup.token);
          }
        },
        onError: (_error) => {
          console.log('_error',_error.message);
          SetError({ message: _error.message, hasError: true });
          SetOnSubmit(false);
        },
      });
    }
  };

  return (
    <StyledContainer maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Box>
          <LogoIcon />
        </Box>
        <Box>
          {OnSubmit &&
            inputValue.email &&
            !emailRegex.test(inputValue.email) && (
              <AlertText
                message="Please enter a valid email address."
                severity="warning"
              />
            )}
          <SignupForm
            inputValue={inputValue}
            onChange={onChangeInputChange}
            onSubmit={onSubmit}
            hasError={Boolean(error.hasError)}
            OpenInputFirstName={OpenInputFirstName}
            OpenPassordInput={OpenPassordInput}
            OpenInputLastName={OpenInputLastName}
            OpenEmailInput={OpenEmailInput}
            isLoading={loading}
            message={error.message}
          />
        </Box>
      </Box>
    </StyledContainer>
  );
}
