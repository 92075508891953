import { useSubscription } from '@apollo/client';
import { graphql } from '../../../../gql';
import { useState } from 'react';
const AGENT_TOOL_CALL = graphql(`
  subscription onAgentToolCall($sessionId: Float!) {
    onAgentToolCall(sessionId: $sessionId) {
      id
      tool
      status
    }
  }
`);

interface IProps {
  sessionId: number;
}

const useSubscribeAgentToolCall = ({ sessionId }: IProps) => {
  const [agentToolCall, setAgentToolCall] = useState<{
    id: number;
    tool: string;
    status: string;
  } | null>(null);

  const { data } = useSubscription(AGENT_TOOL_CALL, {
    variables: {
      sessionId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (subscriptionData.data?.onAgentToolCall) {
        setAgentToolCall(subscriptionData.data.onAgentToolCall);
      }
    },
  });

  return {
    agentToolCall,
  };
};

export default useSubscribeAgentToolCall;
