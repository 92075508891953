import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton from '../../../../common/CustomButton/CustomButton';

const ShareCreditButton = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const handleShareCredit = () => {
    navigate(`../../${workspaceId}/share_credit`);
  };
  return (
    <CustomButton onClick={handleShareCredit} variant="contained" size="small">
      <FormattedMessage id="button.share.credit" />
    </CustomButton>
  );
};

export default ShareCreditButton;
