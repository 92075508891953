export const ROUTE_DEFINITIONS = {
  SIGNUP: {
    path: '/signup',
    name: 'Signup',
  },
  SIGNIN: {
    path: '/signin',
    name: 'Signin',
  },
  FORGOT_PASSWORD: {
    path: '/forgot',
    name: 'ForgotPassword',
  },
};
