import React from 'react';
import useChatLayoutFormField from '../hooks/useChatLayoutFormField';
import {
  ConfigContainer,
  ChatContainer,
  LayoutChatContainer,
  LayoutChatAndFormContainer,
  IConButtonClose,
  ChatAndFormContainer,
  ChatAndFormConfigContainer,
} from './ChatContainerLayoutStyle';
import ChatBodyContainer from '../BodyChat/ChatBodyContainer/ChatBodyContainer';
import TodoDetailsContainer from '../Description/TodoDetailsContainer/TodoDetailsContainer';
import BotExtraConfig from '../Description/TodoDetailsContainer/BotExtraConfig/BotExtraConfig';
import { useParams } from 'react-router-dom';
import CustomTypography from '../../common/CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import BodyLoading from '../BodyLoading/BodyLoading';
import Close from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import { useContextFormChat } from '../hooks/useContextFormChat';

interface IProps {
  todoId: number;
}

const ChatContainerLayout: React.FC<IProps> = ({ todoId }) => {
  const { sessionId } = useParams();
  const { defaultBot, loading } = useChatLayoutFormField();
  const {
    openDrawerConfigWhenMobile,
    handleOpenDrawerConfigWhenMobile,
    isInifiniteChat,
  } = useContextFormChat();

  const theme = useTheme(); // Adding theme hook

  if (loading) {
    return (
      <LayoutChatContainer hasExtraConfig={false}>
        <div />
        <BodyLoading />
        <div />
      </LayoutChatContainer>
    );
  }

  if (defaultBot.uiLayout === 'FORM_AND_CHAT') {
    return (
      <LayoutChatAndFormContainer>
        <ChatAndFormConfigContainer
          sx={{
            padding: theme.spacing(2),
            [theme.breakpoints.down(1300)]: {
              left: openDrawerConfigWhenMobile,
            },
          }}
        >
          <IConButtonClose onClick={handleOpenDrawerConfigWhenMobile}>
            <ReplyIcon />
          </IConButtonClose>
          <CustomTypography component="h2" sx={{ margin: '4px 0 4px 0' }}>
            <FormattedMessage id="todo.tab.botUI" />
          </CustomTypography>
          <BotExtraConfig
            formFields={defaultBot.formFields as any}
            option={{
              allowSendNewMessage: true,
              botId: defaultBot.id,
              sessionId: +(sessionId || 0),
              todoId: todoId,
              callBackSendMessage: handleOpenDrawerConfigWhenMobile,
            }}
          />
        </ChatAndFormConfigContainer>
        <ChatAndFormContainer>
          <ChatBodyContainer todoId={todoId} />
        </ChatAndFormContainer>
      </LayoutChatAndFormContainer>
    );
  }

  const hasExtraConfig =
    (defaultBot?.formFields && defaultBot.formFields.length > 0) ||
    !isInifiniteChat;

  return (
    <LayoutChatContainer hasExtraConfig={hasExtraConfig}>
      <div />
      <ChatContainer>
        <ChatBodyContainer todoId={todoId} />
      </ChatContainer>
      <ConfigContainer
        px={2}
        sx={{
          [theme.breakpoints.down(1340)]: {
            left: openDrawerConfigWhenMobile,
          },
        }}
      >
        <IConButtonClose onClick={handleOpenDrawerConfigWhenMobile}>
          <Close />
        </IConButtonClose>
        <TodoDetailsContainer todoId={todoId} isForChat={true} />
      </ConfigContainer>
    </LayoutChatContainer>
  );
};

export default React.memo(ChatContainerLayout);
