import { removeAuthToken } from './authToken';

export const logout = (searchPath?: string) => {
  removeAuthToken();
  if (searchPath) {
    window.location.href = `/signin?redirect=/${searchPath}`;
  } else {
    window.location.href = `/signin`;
  }
};
