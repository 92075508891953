import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';
import { GET_TODO_SUB_TASK } from './useGetTodoSubTask';
const CREATE_TODO_SUBTASK = graphql(`
  mutation CreateTodoSubTask($newTodoSubTaskInput: NewTodoSubTaskInput!) {
    createTodoSubTask(newTodoSubTaskInput: $newTodoSubTaskInput) {
        id
      title
      todoId
    }
  }
`);

const useCreateTodoSuTask = () => {
  const [createTodoSubTask, { loading }] = useMutation(CREATE_TODO_SUBTASK);
  const createSubTask = (title: string,todoId:number) => {
    createTodoSubTask({
      variables: {
        newTodoSubTaskInput: {
            title,
          todoId,
        },
      },

      update(cache,{data}){
        if(!data){
          return
        }
        const existingSubTask :any= cache.readQuery({
          query: GET_TODO_SUB_TASK,
          variables: {
            todoId,
          },
        });
        cache.writeQuery({
          query: GET_TODO_SUB_TASK,
          variables: {
            todoId,
          },
          data: {
            getTodoSubTask:
             [...existingSubTask?.getTodoSubTask,data?.createTodoSubTask]
            ,
          },
        });
      }
    });
  };

  return {
    createSubTask,
    loading,
  };
};

export default useCreateTodoSuTask;
