import { useCallback } from 'react';
import { ContentType } from '../../../gql/graphql';
import { ChatContext } from '../Chat';
import React from 'react';
import useDisplayMessage from '../../common/GlobalMessage/useDisplayMessage';
import { useIntl } from 'react-intl';
import config from '../../../config/config';

interface UseDropFilesProps {
  contentTypes: ContentType[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const useDropFiles = (props: UseDropFilesProps) => {
  const { contentTypes, setFiles } = props;
  const intl = useIntl();

  const chatContext = React.useContext(ChatContext);
  const { displayMessage } = useDisplayMessage();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const oversizedFiles = acceptedFiles.every(
        (file) => file.size > config.maxFileSize
      );

      if (oversizedFiles) {
        displayMessage({
          message: intl.formatMessage(
            { id: 'drag.drop.file.chat.error.size.file' },
            {
              maxSize: `${config.maxFileSizeMo} Mo`,
            }
          ),
          type: 'error',
        });
        setFiles([]);
        return; // Exit early if there are oversized files
      }

      if (chatContext.isInifiniteChat) {
        const isAcceptedFilesType = acceptedFiles?.map((a) =>
          contentTypes?.map((b) => a.type === b.type)
        );
        const containsAllFalse = isAcceptedFilesType.some((a) =>
          a.every((b) => b === false)
        );
        if (containsAllFalse) {
          displayMessage({
            message: intl.formatMessage(
              { id: 'drag.drop.file.chat.error.type.file' },
              {
                format: contentTypes
                  .map(
                    (contentType) =>
                      `${contentType.type}(${contentType.extension})`
                  )
                  .join(', '),
              }
            ),
            type: 'error',
          });
          setFiles([]);
        } else {
          setFiles(
            acceptedFiles.map((file: File) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      } else {
        setFiles(
          acceptedFiles.map((file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
    [contentTypes, setFiles, chatContext.isInifiniteChat, displayMessage, intl]
  );

  return { onDrop };
};

export default useDropFiles;
