import { useApolloClient, useMutation } from '@apollo/client';
import { graphql } from '../gql';
import { GET_CHAT } from '../components/Chat/hooks/useGetChatTodo';

const RESET_CHAT = graphql(`
  mutation ResetChat($chatSessionId: Float!) {
    resetChat(chatSessionId: $chatSessionId) {
      id
    }
  }
`);
interface props {
  sessionId: number;
}
const useResetChat = ({ sessionId }: props) => {
  const [resetChat, { loading }] = useMutation(RESET_CHAT);
  const client = useApolloClient();
  const onResetChat = () => {
    resetChat({
      variables: {
        chatSessionId: sessionId,
      },
      update() {
        client.writeQuery({
          query: GET_CHAT,
          variables: { sessionId },
          data: {
            sessionMessages: [],
          },
        });
      },
    });
  };
  return { onResetChat, loading };
};
export default useResetChat;
