import moment from 'moment';

const customDateEvent = (dateInput: string | Date | number | null) => {
  let date = null;
  if (dateInput) {
    switch (dateInput) {
      case 'today':
        date = moment().toDate();
        break;
      case 'tomorrow':
        date = moment().add(1, 'days').toDate();
        break;
      case 'nextweek':
        date = moment().add(1, 'week').day(1).toDate();
        break;
      case 'weekend':
        date = moment().day(6).toDate();
        break;
      case '0':
        date = null;
        break;
      default:
        if (moment(dateInput).isValid()) {
          date = new Date(dateInput);
        }
    }
  }
  return date;
};

export default customDateEvent;
