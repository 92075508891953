import * as React from 'react';

const Weekend = () => {
    return (
        <>
            <svg color='#246fe0' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path fill="currentColor" d="M16 6a3 3 0 013 3v1h.1c1 0 1.9 1 1.9 2v4c0 1-.8 2-1.9 2H18v.5a.5.5 0 01-1 0V18H7v.5a.5.5 0 01-1 0V18H5a2 2 0 01-2-2v-4c0-1.1.9-2 2-2V9a3 3 0 013-3h8zm3 5a1 1 0 00-1 .9V15H6v-3a1 1 0 00-2-.1V16c0 .5.4 1 .9 1H19a1 1 0 001-.9V12c0-.6-.4-1-1-1zm-3-4H8c-1 0-2 .8-2 1.9v1.4c.6.3 1 1 1 1.7v2h10v-2a2 2 0 011-1.7V9c0-1-.8-2-1.9-2H16z"></path></svg>
        </>
    );
}

export default Weekend 