// Custom avatar component using Material UI Avatar
import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { UserPublicInfoFragment } from '../../../fragements/user';
import { FragmentType, useFragment } from '../../../gql';
import CustomTooltip from '../CustomToolTip/CustomToolTip';

interface IProps extends AvatarProps {
  src?: string;
  alt?: string;
  size?: number;
  userInfo?: FragmentType<typeof UserPublicInfoFragment> | null;
}

const STAvatar: React.FC<IProps> = ({
  src,
  alt,
  size,
  userInfo: userData,
  ...rest
}) => {
  const userInfo = useFragment(UserPublicInfoFragment, userData);
  if (!userInfo) return null;
  const customSrc = (user: any) => {
    if (user.profilePicture) {
      return user.profilePicture;
    } else {
      return '/default-bot.png';
    }
  };

  return (
    <CustomTooltip title={`${userInfo?.firstName}`}>
      <Avatar
        src={customSrc(userInfo)}
        alt={alt}
        sx={{
          width: size ? size : 40,
          height: size ? size : 40,
          ...rest.sx,
        }}
        {...rest}
      />
    </CustomTooltip>
  );
};

export default STAvatar;
