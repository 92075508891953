import { useState, useContext } from 'react';
import { StepContext } from '../Prompts';
import { useIntl } from 'react-intl';
import { useTheme, useMediaQuery } from '@mui/material';

const usePrompts = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const { activeStep, handlePreviousStep } = useContext(StepContext);
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return {
    searchValue,
    isFocused,
    activeStep,
    isMobile,
    intl,
    handlePreviousStep,
    handleSearchChange,
    handleFocus,
    handleBlur,
  };
};

export default usePrompts;
