import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import 'react-loading-skeleton/dist/skeleton.css';

const ListBotsSearchLoading = () => {
  const theme = useTheme();

  return (
    <SkeletonTheme
      baseColor={theme.palette.background.default}
      highlightColor={theme.palette.grey['400']}
    >
      <CustomBox
        mt={1}
        mb={1}
        sx={(theme) => ({
          height: '65vh',
          overflow: 'auto',
          display: 'grid',
          flexWrap: 'wrap',
          gridTemplateColumns: 'repeat(2, 1fr)',
          alignItems: 'stretch',
          gap: theme.spacing(1),
          [theme.breakpoints.down(750)]: {
            display: 'flex',
            gap: 1,
          },
        })}
      >
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
        <Skeleton height={110} />
      </CustomBox>
    </SkeletonTheme>
  );
};

export default ListBotsSearchLoading;
