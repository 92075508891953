import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import STChip from '../../../../common/STChip/STChip';
import useGetBotTags from '../../../hooks/useGetBotTags';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';

interface Props {
  onItemClick(item: any): void;
  tagSlug: string;
  hideRecent?: boolean;
  avatar?: (
    tag: any
  ) =>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const BotTagsList = ({ tagSlug, onItemClick, avatar, hideRecent }: Props) => {
  const { data } = useGetBotTags();
  const theme = useTheme();

  const onTagClick = (tag: any) => {
    Mixpanel.track('Bot tag clicked', { tag: tag.title, slug: tag.slug });
    onItemClick(tag);
  };

  const botTags = useMemo(() => {
    return data?.getBotTags?.filter(
      (tag) => !hideRecent || tag.slug !== 'recent'
    );
  }, [data?.getBotTags, hideRecent]);

  if (!botTags || botTags.length === 0) {
    return null;
  }

  const backgroundColor = (slug: string, value: string) => {
    if (slug === value) {
      return theme.palette.secondary.main;
    }

    return theme.palette.mode === 'light'
      ? theme.palette.customColor.cardBackground
      : theme.palette.background.paper;
  };

  return (
    <CustomBox position={'relative'} display={'flex'} flexWrap={'wrap'} gap={1}>
      {botTags.map((category: any) => (
        <STChip
          avatar={avatar && avatar(category)}
          sx={{
            borderRadius: theme.spacing(1),
            whiteSpace: 'nowrap',
            color: theme.palette.customColor.blackColor,
            backgroundColor: backgroundColor(category.slug, tagSlug),
          }}
          key={category.id}
          onClick={() => onTagClick(category)}
          label={category.title}
          variant="filled"
        />
      ))}
    </CustomBox>
  );
};

export default BotTagsList;
