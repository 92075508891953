
import { Todo } from '../../../../../../gql/graphql';
import { Avatar, Box, styled } from '@mui/material';
import CustomTypography from '../../../../../common/CustomTypography/CustomTypography';



const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
}));


const StyledTypography = styled(CustomTypography)(({ theme }) => ({

}));

const MessageContainer = styled(CustomTypography)(({ theme }) => ({
    height: '20px',
    overflowY: "hidden",
    overflowX:"hidden",
    marginLeft: "8px"
}));

interface propsLabel {
    todo?: Todo;
    isProjectRoute?: Boolean
}

const LastMessage = ({ todo, isProjectRoute }: propsLabel) => {
    const size = isProjectRoute ? 24 : 19
    return (
        <Container>
            <Box>
                <Avatar
                    sx={{ width: size, height: size }}
                    variant="rounded"
                    alt="U"
                    src={todo?.chat?.messages[0]?.sentBy?.profilePicture as string}
                />
            </Box>
            <MessageContainer>
                <StyledTypography
                    fontSize={12}
                    variant="caption"
                    style={{ color: '#A4A5B2' }}
                >
                    {todo?.chat?.messages[0].content}
                </StyledTypography>
            </MessageContainer>
        </Container>
    );
};

export default LastMessage;
