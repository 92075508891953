// Loading component

import { FC } from 'react';
import React from 'react';
import {
  Box,
  CircularProgress,
  LinearProgress,
  LinearProgressProps,
  useTheme,
} from '@mui/material';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';

// FC component
interface IProps extends LinearProgressProps {
  type?: string;
  color?:
    | 'error'
    | 'inherit'
    | 'secondary'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning';
  size?: number;
  isWithText?: boolean;
  intlTextId?: string;
}

const Loading: FC<IProps> = ({ type, size, color, isWithText, intlTextId }) => {
  const theLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {type === 'circular' ? (
          <CircularProgress size={size} color={color || 'primary'} />
        ) : (
          <LinearProgress color={color || 'primary'} />
        )}
      </Box>
    );
  };

  if (isWithText && intlTextId) {
    return (
      <CustomBox
        sx={{
          display: 'flex',
        }}
      >
        <CustomBox
          sx={{
            mr: 1,
          }}
        >
          <Loading size={20} type="circular" />
        </CustomBox>
        <CustomBox>
          <CustomTypography variant="body2">
            <FormattedMessage
              id="project.task.generating.tasks.loading"
              defaultMessage="Generating tasks..."
            />
          </CustomTypography>
        </CustomBox>
      </CustomBox>
    );
  }
  return theLoading();
};

export default Loading;
