import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useGetProjectBreadCumb from '../../../../../Hooks/useGetProjectBreadCumb';
import STLink from '../../../../common/STLink/STLink';

const ProjectBreadCumb = () => {
  const { workspaceId } = useParams();
  const { data } = useGetProjectBreadCumb();
  const project = data?.todoChat.todo.project;
  return (
    <>
      {project ? (
        <Breadcrumbs aria-label="breadcrumb">
          <STLink to={`/${workspaceId}/project-list`}>
            <FormattedMessage id="projects.chat.name" />
          </STLink>
          <STLink to={`/${workspaceId}/project/${project.id}`}>
            {project.name}
          </STLink>
        </Breadcrumbs>
      ) : null}
    </>
  );
};
export default ProjectBreadCumb;
