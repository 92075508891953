import { useMutation } from '@apollo/client';
import { graphql } from '../gql/gql';
import { Mixpanel } from '../mixpanel/mixpanel';
import { isEmptyString } from '../utils/stringUtils';

export const RE_GENERATE_TEXT_REQUEST = graphql(`
  mutation improvePrompt($prompt: String!) {
    improvePrompt(prompt: $prompt)
  }
`);

const useImprovePrompt = (prompt: string, text: string) => {
  const [improvePrompt, { data, loading }] = useMutation(
    RE_GENERATE_TEXT_REQUEST
  );

  const reGeneratePrompt = () => {
    Mixpanel.track('STMagicPrompt - Regenerate text', {});

    if (isEmptyString(text)) {
      return;
    }
    improvePrompt({
      variables: {
        prompt: prompt + ':' + text,
      },
    });
  };
  return {
    loading,
    newPrompt: data?.improvePrompt || '',
    reGeneratePrompt,
  };
};

export default useImprovePrompt;
