export const dataItems = [
  {
    id: '1',
    name: 'Account',
    link: '/profil/account',
    isClosable: true,
    items: [],
  },
  // {
  //   id: '2',
  //   name: 'Notification',
  //   isClosable:false,
  //   items: [
  //     { id: '3', item: 'Email',link:"/",isClosable:true, },
  //     { id: '4', item: 'Push' ,link:"/",isClosable:true,},
  //     { id: '5', item: 'In app notifications' ,link:"/",isClosable:true,},
  //   ],
  // },
  // {
  //   id: '2',
  //   name: 'Subscription',
  //   isClosable: true,
  //   link: '/profil/subscription',
  //   items: [],
  // },
  // {
  //   id: '11',
  //   name: 'Integrations',
  //   isClosable:false,
  //   items: [],
  // },
  // {
  //   id: '12',
  //   name: 'Language',
  //   isClosable:false,
  //   items: [],
  // },
  // {
  //   id: '13',
  //   name: 'Time zone',
  //   isClosable:false,
  //   items: [],
  // },
  // {
  //   id: '14',
  //   name: 'Time',
  //   isClosable:false,
  //   items: [],
  // },
  // {
  //   id: '15',
  //   name: 'Support',
  //   isClosable:false,
  //   items: [],
  // },
];
