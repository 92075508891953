import React from 'react';
import { IconButton, List, ListItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '../CustomBox/CustomBox';
import { StyledLink, StyledItem } from './STFileForm.style';
import FileIcons from '../../Chat/BodyChat/FormAddMessage/MessageInput/SvgIcon/FileIcon/FileIcons';

type File = {
  name: string;
  url: string;
  type: string;
};

type FileListProps = {
  files: File[];
  onDelete: (index: number) => void;
};

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
  const deleteFileByIndex = (index: number) => () => {
    onDelete(index);
  };
  return (
    <Box>
      <List>
        {files.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: (theme) => theme.spacing(1),
            }}
          >
            <StyledItem>
              <FileIcons extension={file.type.split('/').pop() || ''} />
            </StyledItem>
            <StyledLink href={file.url} target="_blank" rel="noreferrer">
              {file.name}
            </StyledLink>
            <IconButton onClick={deleteFileByIndex(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FileList;
