import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import useDisplayMessage from '../components/common/GlobalMessage/useDisplayMessage';
import { useIntl } from 'react-intl';
import { Mixpanel } from '../mixpanel/mixpanel';

const GET_SPEECH_FROM_TEXT = graphql(`
  query GetSpeechFromText($messageId: Float!) {
    getSpeechFromText(messageId: $messageId)
  }
`);

const useGenerateText2Speech = () => {
  const [fetchSpeech, { loading: isSpeechAudioLoading }] = useLazyQuery(
    GET_SPEECH_FROM_TEXT,
    {
      onError: () => {
        displayMessage({
          type: 'error',
          message: intl.formatMessage({ id: 'text.speech.error' }),
        });
      },
    }
  );
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentMessagePlayingId, setCurrentMessagePlayingId] = useState(0);
  const { displayMessage } = useDisplayMessage();
  const intl = useIntl();

  const togglePlayPause = useCallback((audio: HTMLAudioElement) => {
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  }, []);

  const generateSpeech = useCallback(
    (messageId: number) => {
      Mixpanel.track('message to speech', {
        messageId,
      });
      if (messageId === currentMessagePlayingId && currentAudio) {
        togglePlayPause(currentAudio);
      } else {
        if (currentAudio) {
          currentAudio.pause();
          currentAudio.removeEventListener('ended', () => setIsPlaying(false));
          setIsPlaying(false);
        }
        fetchSpeech({ variables: { messageId } }).then(({ data }: any) => {
          const newAudio = new Audio(data.getSpeechFromText);
          setCurrentAudio(newAudio);
          setCurrentMessagePlayingId(messageId);
          togglePlayPause(newAudio);
          newAudio.addEventListener('ended', () => setIsPlaying(false));
        });
      }
    },
    [currentMessagePlayingId, currentAudio, fetchSpeech, togglePlayPause]
  );

  useEffect(() => {
    return () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.removeAttribute('src');
        currentAudio.load();
      }
    };
  }, [currentAudio]);

  return {
    isSpeechAudioLoading,
    isPlaying,
    generateSpeech,
    currentMessagePlayingId,
  };
};

export default useGenerateText2Speech;
