import { graphql } from '../gql';

export const FILE_FRAGMENT = graphql(`
  fragment FileFragment on File {
    id
    name
    url
    type
    size
  }
`);
