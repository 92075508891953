import { FabProps } from '@mui/material';
import CustomFab from '../../../common/CustomFab/CustomFab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
interface props extends FabProps {}
const FloatingScroolButton = ({ ...rest }: props) => {
  return (
    <CustomFab
      size="small"
      {...rest}
      sx={{
        position: 'absolute',
        left: '48%',
        bottom: '210px',
        transition: 'all 0.3s',
        zIndex: 50,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#00001F' : '#fff',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#00001F' : '#fff',
          transform: 'scale(1.1)',
        },
      }}
      color="inherit"
    >
      <ArrowDownwardIcon fontSize="small" />
    </CustomFab>
  );
};
export default FloatingScroolButton;
