import ButtonTaskAction from '../../../../../MyDay/FormAddTask/ButtonTaskAction/ButtonTaskAction';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseIcon from '@mui/icons-material/Pause';
import CircularProgress from '@mui/material/CircularProgress';

interface TextToSpeechProps {
  style?: Object;
  isPlaying: boolean;
  isSpeechAudioLoading: boolean;
  handleAudioToggle():void;
}

const TextToSpeechButton = ({
  style,
  isPlaying,
  isSpeechAudioLoading,
  handleAudioToggle
}: TextToSpeechProps) => {
  let icon;
  switch (true) {
    case isPlaying && !isSpeechAudioLoading:
      icon = (
        <PauseIcon
          sx={{
            fontSize: '17px',
            animation: 'blinking 1s infinite',
            '@keyframes blinking': {
              '0%': { opacity: 1 },
              '50%': { opacity: 0.5 },
              '100%': { opacity: 1 },
            },
          }}
        />
      );
      break;
    case isSpeechAudioLoading:
      icon = <CircularProgress size={14} sx={{ fontSize: '17px' }} />;
      break;
    default:
      icon = <VolumeUpIcon sx={{ fontSize: '17px' }} />;
      break;
  }

  return (
    <ButtonTaskAction
      handleaAction={handleAudioToggle}
      style={style}
      icon={icon}
    />
  );
};

export default TextToSpeechButton;
