import Box from '@mui/material/Box';
import { FC } from 'react';
import CustomTooltip from '../../../../../common/CustomToolTip/CustomToolTip';

interface IProps {
  onClick?: () => void;
  page: number;
}

const MetaSourcePageBadge: FC<IProps> = ({ onClick, page }) => {
  const thePage = page === 0 ? 1 : page;
  return (
    <CustomTooltip
      title="chat.jumpTo.pdfPage"
      intlId="chat.jumpTo.pdfPage"
      intlValues={{ page: thePage }}
    >
      <Box
        component={'div'}
        sx={{
          color: 'white',
          borderRadius: '8px',
          fontSize: '14px',
          pl: '8px',
          pr: '8px',
          pt: '2px',
          pb: '2px',
          display: 'inline',
          backgroundColor: '#7baaf7',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {thePage}
      </Box>
    </CustomTooltip>
  );
};

export default MetaSourcePageBadge;
