import AlertText from '../../../../../common/AlertText/AlertText';
import STList from '../../../../../common/STList/STList';
import LoadingSkeleton from './LoadingSkeleton';

interface Props {
  handleClick(id: number): void;
  isForCustomPrompt?: boolean;
  prompts: any[];
  defaultSelectedId: number;
  isMobile?: boolean;
  loading: boolean;
}

const ListPrompts = ({
  handleClick,
  prompts,
  defaultSelectedId,
  isMobile,
  loading,
}: Props) => {
  const items =
    prompts.map((el) => ({
      id: el.id,
      primaryText: el.title,
      onItemClick: () => handleClick(el.id),
    })) || [];

  if (loading) {
    return <LoadingSkeleton isMobile={isMobile} />;
  }

  return (
    <>
      <STList selectedItemId={defaultSelectedId} items={items} />
      {prompts.length === 0 && !loading && (
        <AlertText
          severity="info"
          message={'No custom prompts yet'}
          id="prompt.category.empty"
        />
      )}
    </>
  );
};

export default ListPrompts;
