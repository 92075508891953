import { Divider, useTheme } from '@mui/material';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useFollowUpQuestion from './useFollowUpQuestion';

const FollowUpQuestions = ({
  onMessageSent,
  sessionId,
  todoId,
}: {
  onMessageSent(): void;
  sessionId: number;
  todoId: number;
}) => {
  const theme = useTheme();
  const { onSendNewFollowUpQuestion, followUpQuestions } = useFollowUpQuestion({
    onMessageSent,
    sessionId,
    todoId,
  });
  if (!followUpQuestions?.length) return null;
  return (
    <CustomBox width={'100%'} pt={theme.spacing(1)} mb={4}>
      <Divider />
      {followUpQuestions?.map((question: any, i) => (
        <CustomBox key={i} onClick={() => onSendNewFollowUpQuestion(question)}>
          <CustomBox
            display={'flex'}
            width={'100%'}
            p={theme.spacing(1)}
            gap={1}
            sx={{
              pl: (theme) => theme.spacing(5),
              pr: (theme) => theme.spacing(4),
              '&:hover': {
                cursor: 'pointer',
                color: theme.palette.secondary.main,
                '& .MuiTypography-root': {
                  color: theme.palette.secondary.main,
                },
              },
            }}
          >
            <CustomTypography
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              fontWeight={'inherit'}
              color={'inherit'}
            >
              {question}
            </CustomTypography>
            <ArrowForwardIcon
              sx={{ ml: 'auto', width: '18px', fontWeight: 'bold' }}
            />
          </CustomBox>
          <Divider />
        </CustomBox>
      ))}
    </CustomBox>
  );
};
export default FollowUpQuestions;
