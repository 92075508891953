import { useState, useRef, FC, useEffect } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CustomBox from '../CustomBox/CustomBox';
import CustomTypography from '../CustomTypography/CustomTypography';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';

interface Props {
  onUploadFile: (files: FileList) => void;
  onRecording?: () => void;
  onStopRecording?: () => void;
}

const STDictaphone: FC<Props> = ({
  onUploadFile,
  onRecording,
  onStopRecording,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0); // New state for elapsed time
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const mediaStreamRef = useRef<MediaStream | null>(null); // New reference for the media stream
  const { displayMessage } = useDisplayMessage();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isRecording) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setElapsedTime(0); // Reset elapsed time when not recording
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRecording]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current?.stop();
    mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    setIsRecording(false);
    onStopRecording && onStopRecording();
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream; // Store the media stream reference
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      // On stop, combine all chunks into a single blob
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/webm',
        });

        console.log(audioBlob.type);
        audioChunksRef.current = []; // Clear chunks after processing
        // console log the audioBlob type

        const audioFile = new File([audioBlob], 'recording.webm', {
          type: 'audio/webm',
        });

        const fileList = {
          0: audioFile,
          length: 1,
          item(index: number) {
            return index === 0 ? audioFile : null;
          },
          [Symbol.iterator]: function* () {
            yield audioFile;
          },
        };

        if (fileList.length > 0) {
          onUploadFile(fileList as FileList);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      onRecording && onRecording();
    } catch (error) {
      displayMessage({
        message: 'microphone.error',
        type: 'error',
      });
    }
  };

  return (
    <CustomBox display="flex" alignItems="center">
      <CustomIconButton
        onClick={isRecording ? handleStopRecording : handleStartRecording}
      >
        {isRecording ? <StopIcon /> : <MicIcon />}
      </CustomIconButton>
      {isRecording && (
        <CustomTypography variant="h6">
          {formatTime(elapsedTime)}
        </CustomTypography>
      )}
    </CustomBox>
  );
};

export default STDictaphone;
