import { FC, FormEvent } from 'react';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import STInputBase from '../STInputBase/STInputBase';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

interface Props {
  maxValue?: number;
  minValue?: number;
  value: number;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  step?: number;
  name: string;
  placeholder?: string;
}

const STFieldNumber: FC<Props> = ({
  maxValue,
  minValue,
  value,
  name,
  onChange,
  step = 1,
  placeholder = '',
}) => {
  const handleChangeCredit = (e: any) => {
    const newValue = Number(e.target.value);
    if (
      maxValue &&
      maxValue > newValue &&
      minValue !== undefined &&
      newValue < minValue
    ) {
      return;
    }
    onChange({
      currentTarget: {
        name,
        value: newValue,
      },
    } as any);
  };

  const handleReduce = () => {
    const newValue = Number(value) - step;
    if (minValue && newValue < minValue) {
      return;
    }
    onChange({
      currentTarget: {
        name,
        value: newValue,
      },
    } as any);
  };

  const handleIncrease = () => {
    const newValue = Number(value) + step;
    if (maxValue && newValue > maxValue) {
      return;
    }

    onChange({
      currentTarget: {
        name,
        value: newValue,
      },
    } as any);
  };

  const disableMin =
    minValue !== undefined && step ? value - step < minValue : false;
  const disableMax = maxValue && step ? value + step > maxValue : false;

  return (
    <STInputBase
      fullWidth={true}
      value={value}
      placeholder={placeholder}
      disabled={false}
      onChange={handleChangeCredit}
      sx={{
        '& .MuiInputBase-input': {
          textAlign: 'center',
        },
        marginTop: (theme) => theme.spacing(2),
        marginBottom: (theme) => theme.spacing(2),
      }}
      startAdornment={
        <CustomIconButton
          onClick={handleReduce}
          size="small"
          disabled={disableMin}
        >
          <HorizontalRuleIcon fontSize="small" />
        </CustomIconButton>
      }
      endAdornment={
        <CustomIconButton
          onClick={handleIncrease}
          size="small"
          disabled={disableMax}
        >
          <AddIcon fontSize="small" />
        </CustomIconButton>
      }
    />
  );
};

export default STFieldNumber;
