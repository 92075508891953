import * as React from 'react';
import { Stack } from '@mui/material';
import ButtonTaskAction from '../../../../MyDay/FormAddTask/ButtonTaskAction/ButtonTaskAction';
import { Reply, MoreHoriz, Refresh } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import TextToSpeechButton from './TextToSpeechButton/TextToSpeechButton';

interface props {
  handleContextMenu(e: any): void;
  onReply: () => void;
  handleCopyClick: () => void;
  shouldShowRefreshButton?: boolean;
  isDisplayText2SpeechButton?: boolean;
  handleRegenerateBotResponse?: () => void;
  isPlaying: boolean;
  isSpeechAudioLoading: boolean;
  handleAudioToggle(): void;
}
const ActionMessage = ({
  handleContextMenu,
  handleCopyClick,
  onReply,
  isDisplayText2SpeechButton,
  shouldShowRefreshButton,
  handleRegenerateBotResponse,
  isPlaying,
  isSpeechAudioLoading,
  handleAudioToggle,
}: props) => {
  const intl = useIntl();
  const style = {
    //backgroundColor: theme.palette.background.paper,
    border: 'none',
    paddinRight: '16px',

    '&.MuiChip-root': {
      height: '25px',
    },
  };

  return (
    <CustomBox>
      <Stack direction="row" display="flex" flexWrap="wrap">
        <ButtonTaskAction
          handleaAction={onReply}
          style={style}
          label={intl.formatMessage({ id: 'context.menu.reply' })}
          icon={<Reply sx={{ fontSize: '14px' }} />}
        />
        {shouldShowRefreshButton && (
          <ButtonTaskAction
            handleaAction={handleRegenerateBotResponse}
            style={style}
            labelTooltip={
              intl.formatMessage({ id: 'bot.response.regenerate' }) || ''
            }
            icon={<Refresh sx={{ fontSize: '17px' }} />}
          />
        )}
        <ButtonTaskAction
          handleaAction={handleCopyClick}
          style={style}
          icon={<ContentCopy sx={{ fontSize: '17px' }} />}
        />
        {isDisplayText2SpeechButton && (
          <TextToSpeechButton
            style={style}
            isPlaying={isPlaying}
            isSpeechAudioLoading={isSpeechAudioLoading}
            handleAudioToggle={handleAudioToggle}
          />
        )}

        <ButtonTaskAction
          handleaAction={handleContextMenu}
          style={style}
          labelTooltip={intl.formatMessage({
            id: 'message.actionMessage.more',
          })}
          icon={<MoreHoriz style={{ marginRight: '2px' }} />}
        />
      </Stack>
    </CustomBox>
  );
};

export default ActionMessage;
