import { useMutation } from '@apollo/client';
import { graphql } from '../../../gql';

const RENAME_TODO_LABEL = graphql(`
mutation RenameLabel($updateTodoLabelInput: UpdateTodoLabelInput!) {
    renameLabel(updateTodoLabelInput: $updateTodoLabelInput) {
      name
      id
    }
  }
`);

const useRenameLabel = () => {
  const [renameTodoLabel] = useMutation(RENAME_TODO_LABEL);
  const renameLabel = (name: string,labelId:number) => {
    renameTodoLabel({
      variables: {
        updateTodoLabelInput: {
          name,
          labelId
        },
      },
    });
  };

  return {
    renameLabel,
  };
};

export default useRenameLabel;
