import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '70%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  gap: theme.spacing(5),
}));

export const ContainerTag = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}));

export const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderColor: theme.palette.grey[200],
  borderRadius: theme.spacing(1),
  borderWidth: 5,
  backgroundColor: theme.palette.customColor.cardBackground,
  cursor: 'pointer',
  padding: theme.spacing(3),
  flex: '1 1 calc(50% - 10px)', // 2 items in a row on medium screens
  margin: '5px',
  boxSizing: 'border-box',
  minWidth: 200, // Add minWidth property
  transition: 'all 0.3s',
  [theme.breakpoints.down('md')]: {
    minWidth: 'auto',
    padding: theme.spacing(2),
    '& p': {
      fontSize: '12px',
    },
  },
}));
