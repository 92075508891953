import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/system/Box';
import {
  Avatar,
  Checkbox,
  Grid,
  IconButton,
  styled,
  useTheme,
} from '@mui/material';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';
import AddUser from '../../SvgIcon/AddUser';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { graphql } from '../../../../../gql';
import Loading from '../../../../common/Loading/Loading';
import UserAvatar from '../../../../common/UserAvatar/UserAvatar';

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    backgroundColor: theme.palette.background.paper,
  },
  zIndex: 9999,
}));

const GET_PROJECT_MEMBERS = graphql(`
  query GetProjectUsers($id: Float!) {
    project(id: $id) {
      id
      members {
        id
        role
        email
        user {
          id
          profilePicture
          firstName
          lastName
          email
        }
      }
    }
  }
`);

const ASIGN_USER_TO_TODO = graphql(`
  mutation AsignUserToTodo($asignUserToTodoInput: AsignUserToTodoInput!) {
    asignUserToTodo(asignUserToTodoInput: $asignUserToTodoInput) {
      id
      asignees {
        id
        assignedTo {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`);
// Query to get todo asignees using todo query with id as argument

const TODO_ASIGNEES = graphql(`
  query GetTodoAsignees($id: Float!) {
    todo(id: $id) {
      id
      projectId
      asignees {
        id
        assignedTo {
          id
          firstName
          lastName
          email
          profilePicture
        }
      }
    }
  }
`);

// FC component

interface IProps {
  todoId: number;
}

const ToDoAsigneesLists: React.FC<IProps> = ({ todoId }) => {
  const theme = useTheme();
  // state to store project id
  const [projectId, setProjectId] = React.useState<number>(0);
  const { data, loading } = useQuery(GET_PROJECT_MEMBERS, {
    variables: {
      id: projectId,
    },
  });
  // Query to get todo asignees using todo query with id as argument
  const { data: todoAsignees } = useQuery(TODO_ASIGNEES, {
    variables: {
      id: todoId,
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  // Use effect to get project id from todo asignees query
  React.useEffect(() => {
    if (todoAsignees?.todo?.projectId) {
      setProjectId(todoAsignees.todo.projectId);
    }
  }, [todoAsignees]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [asignUserToTodo] = useMutation(ASIGN_USER_TO_TODO, {
    update(cache, { data }) {
      // Mixpanel track
      if (!data?.asignUserToTodo) return;
      Mixpanel.track('Asign user to todo', {
        todoId,
      });
    },
  });

  const handleAsignUser = (userId: number) => {
    asignUserToTodo({
      variables: {
        asignUserToTodoInput: {
          todoId,
          userId,
          // Change action to remove if user is already asignee
          action: todoAsignees?.todo?.asignees.some(
            (asignee: any) => asignee.assignedTo.id === userId
          )
            ? 'UNASIGN'
            : 'ASIGN',
        },
      },
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (loading) {
    return <Loading />;
  }
  // Display only members that has user object
  const toDisplayMembers =
    data?.project?.members.filter((member: any) => member.user) || [];

  return (
    <>
      <Box display={'flex'}>
        {/* Display AvatarGroup with asignees */}
        <Box>
          <AvatarGroup max={4}>
            {todoAsignees?.todo?.asignees.map((asignee: any) => {
              return (
                <UserAvatar
                  key={asignee.id}
                  sx={{ width: 21, height: 21 }}
                  alt={asignee?.assignedTo?.email}
                  userProfil={asignee?.assignedTo?.profilePicture}
                />
              );
            })}
          </AvatarGroup>
        </Box>

        <Box>
          {/* Display icon button plus material ui to open popover */}

          <Box
            sx={{
              //mt: 0.5,
              backgroundColor: theme.palette.primary.main,
              cursor: 'pointer',
              width: 23,
              height: 23,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              paddingX: '2px',
              marginLeft: '4px',
            }}
          >
            <IconButton onClick={handleClick}>
              <AddUser />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {toDisplayMembers.map((member: any) => {
            // List item with avatar and name
            return (
              <ListItem
                key={member.id}
                sx={{
                  cursor: 'pointer',
                  '&.MuiListItem-root': {
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '10px',
                  },
                  '& .MuiListItemAvatar-root': {
                    minWidth: '32px',
                  },
                  '&:hover': {
                    background: theme.palette.background.default,
                  },
                }}
                onClick={() => {
                  handleAsignUser(member?.user?.id);
                }}
                secondaryAction={
                  // Display check icon if user is asignee
                  <Checkbox
                    edge="end"
                    size="small"
                    checked={todoAsignees?.todo?.asignees.some(
                      (asignee: any) =>
                        asignee?.assignedTo?.id === member?.user.id
                    )}
                  />
                }
              >
                <ListItemAvatar>
                  <UserAvatar
                    sx={{ width: 21, height: 21 }}
                    alt={member?.user?.email}
                    userProfil={member?.user?.profilePicture}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <CustomTypography variant="h5" fontSize={12}>
                      {member?.user?.firstName} {member?.user?.lastName}
                    </CustomTypography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </StyledPopover>
    </>
  );
};

export default ToDoAsigneesLists;
