// Custom popper component using material-ui Popper component
import { FC, ReactNode } from 'react';
import { Fade, PopperProps } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { StyledPopper, StyledPapper } from './STPopper.style';
import config from '../../../config/config';

interface IProps extends PopperProps {
  onClose?: () => void;
  children: ReactNode;
  width?: string;
}

const STPopper: FC<IProps> = ({
  children,
  onClose,
  open,
  width = '400px',
  ...rest
}) => {
  const onClickAway = () => {
    if (onClose) {
      onClose();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledPopper
        open={open}
        {...rest}
        sx={{
          ...rest.sx,
          zIndex: config.zIndex.NIVEAU_POPPER,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledPapper
              sx={{
                minWidth: width,
              }}
            >
              {' '}
              {children}
            </StyledPapper>
          </Fade>
        )}
      </StyledPopper>
    </ClickAwayListener>
  );
};

export default STPopper;
