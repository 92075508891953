import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_TOOLS, FormeNoteBox } from './EditorTools/EditorTools';
import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { ClickAwayListener } from '@mui/material';

const ReactEditorJS = createReactEditorJS();
interface props {
  defaultValue: any;
  onChangeEditor?: (value: any) => void;
  renderButton?: React.ReactNode;
  save: (value: any) => void;
}

const STEditor = ({
  defaultValue,
  renderButton,

  save,
}: props) => {
  const [inputValue, setInputValue] = useState('');

  // Use useCallback hook to memoize the debounced save function so that it
  // doesn't get recreated on each render.
  const debouncedSave = useCallback(
    debounce((valueToSave) => {
      save(valueToSave);
    }, 1000), // Wait for 1 second of inactivity before invoking the save
    [save] // Only recreate the debounced function when the save function changes
  );

  // Use useEffect to save the input value whenever it changes.
  // The debounced function will take care of the saving interval.
  useEffect(() => {
    if (inputValue !== '') {
      debouncedSave(inputValue);
    }
    // Cancel the debounce on useEffect cleanup.
    return debouncedSave.cancel;
  }, [inputValue, debouncedSave]);

  // Handle input changes
  const handleChange = (value: any) => {
    value.saver.save().then((outputData: any) => {
      const stringValue = JSON.stringify(outputData);
      setInputValue(stringValue);
    });
  };
  const handleSave = () => {
    save(inputValue);
  };
  return (
    <ClickAwayListener onClickAway={handleSave}>
      <FormeNoteBox>
        {defaultValue ? (
          <ReactEditorJS
            tools={EDITOR_TOOLS}
            defaultValue={defaultValue}
            minHeight={50}
            onChange={handleChange}
          />
        ) : (
          renderButton
        )}
      </FormeNoteBox>
    </ClickAwayListener>
  );
};

export default STEditor;
