import * as React from 'react';

import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import AlertText from '../../../common/AlertText/AlertText';
import PasswordInput from '../../../common/PasswordInput/PasswordInput';
import PasswordStrengthIndicator from '../../../common/PasswordStrengthIndicator/PasswordStrengthIndicator';
import TextInput from '../../../common/TextInput/TextInput';
import CardLinkSignup from '../../AccountForm/CardLink/CardLinkSignup/CardLinkSignup';
import EmailInput from '../../AccountForm/EmailInput/EmailInput';
import SubmitBottom, {
  propsSubmit,
} from '../../AccountForm/SubmitButton/SubmitButton';
import SubmitWithGoogleBottom from '../../AccountForm/SubmitWithGoogle/SubmitWithGoogle';
import { BoxStyled } from '../../ForgotPassword/ForgotPasswordForm/ForgotPasswordForm';
import { styled } from '@mui/material';
import DividerChoice from '../../Signin/DividerChoice/DividerChoice';
import Agreement from '../../AccountForm/Agreement/Agreement';
import Auth0Login from '../../Auth0Login/Auth0Login';

const GoogleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
}));

// Password indicator box container
const PasswordIndicatorBox = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export interface Formprops {
  inputValue: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: propsSubmit['onSubmit'];
  hasError?: boolean;
  OpenInputFirstName: boolean;
  OpenPassordInput: boolean;
  OpenInputLastName: boolean;
  OpenEmailInput: boolean;
  isLoading?: boolean;
  message?: string;
}
const AccountForm = ({
  inputValue,
  onChange,
  onSubmit,
  OpenEmailInput,
  hasError,
  message,
  isLoading,
}: Formprops) => {
  const intl = useIntl();
  return (
    <Box component="form" noValidate mt={1} width={302}>
      <BoxStyled>
        <Agreement />
        <EmailInput
          email={inputValue.email}
          placeholder={intl.formatMessage({
            id: 'input.email',
            defaultMessage: 'Email address',
          })}
          onChange={onChange}
        />
        <Box sx={{ width: 325 }}>
          <PasswordInput
            password={inputValue.password}
            onChange={onChange}
            name={'password'}
            placeholder={intl.formatMessage({
              id: 'input.password',
              defaultMessage: 'Password',
            })}
          />
        </Box>
        <PasswordIndicatorBox>
          <PasswordStrengthIndicator password={inputValue.password} />
        </PasswordIndicatorBox>

        <TextInput
          value={inputValue.firstName}
          onChange={onChange}
          id="firstName"
          autocomplete="firstName"
          name="firstName"
          placeholder={intl.formatMessage({
            id: 'firstName',
            defaultMessage: 'First Name',
          })}
        />

        <TextInput
          value={inputValue.lastName}
          onChange={onChange}
          id="lastName"
          autocomplete="lastName"
          name="lastName"
          placeholder={intl.formatMessage({
            id: 'lastName',
            defaultMessage: 'Last Name',
          })}
        />

        {hasError && (
          <AlertText
            message={intl.formatMessage({
              //id: 'signup.error',
              id: message,
              defaultMessage:
                'An error occurred while signing up. Please try again.',
            })}
            severity="error"
          />
        )}
        <SubmitBottom
          onSubmit={onSubmit}
          buttonValue={'Continue'}
          disable={isLoading}
          isLoading={isLoading}
        />

        <CardLinkSignup />
        <DividerChoice />
        <GoogleBox>
          <SubmitWithGoogleBottom from="signup" />
        </GoogleBox>
        <GoogleBox>
          <Auth0Login />
        </GoogleBox>
      </BoxStyled>
    </Box>
  );
};
export default AccountForm;
