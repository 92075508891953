import { StreamMessageSubscriptionSubscription } from '../../../../gql/graphql';

import { SubscriptionResult } from '@apollo/client';

const useRealtimeUpdate = () => {
  // Add message to TodoChat
  const addMessageToTodoChat = (
    client: any,
    subscriptionData: SubscriptionResult<
      StreamMessageSubscriptionSubscription,
      any
    >
  ) => {
    // Modify client cache TodoChatMessage
    if (subscriptionData?.data?.onMessageStream?.botResponseMessageId) {
      const todoChatMessageId =
        subscriptionData?.data?.onMessageStream.botResponseMessageId;
      //if (todoChatMessageId === temporalMessageId) {
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'TodoChatMessage',
          id: todoChatMessageId,
        }),
        // Edit field content
        fields: {
          content(existingContent = '') {
            return (existingContent +=
              subscriptionData?.data?.onMessageStream.messageChunk);
          },
        },
      });
    }
  };

  return {
    addMessageToTodoChat,
  };
};

export default useRealtimeUpdate;
