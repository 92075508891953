import { FC } from 'react';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import PDFViewer from '../../PDFViewer/PDFViewer';
import useChangePDFPage from '../../../hooks/useChangePDFPage';
import React from 'react';

interface IProps {
  dataSources: any;
  pdfUrl: string;
  todoId: number;
}

const ChatSessionDataSources: FC<IProps> = ({
  dataSources,
  todoId,
  pdfUrl,
}) => {
  // hook to store the page url
  const [pageUrl, setPageUrl] = React.useState<string>(pdfUrl);

  const { pdfViewerPage, pdfViewerUrl, clickHash } = useChangePDFPage();
  const defaultBotId = dataSources?.getOneTodoChatSession?.defaultBot?.id || 0;

  // When the page is changed, we update the page url
  React.useEffect(() => {
    if (pdfViewerUrl) {
      setPageUrl(pdfViewerUrl);
    }
  }, [pdfViewerUrl]);

  React.useEffect(() => {
    if (pdfUrl) setPageUrl(pdfUrl);
  }, [pdfUrl]);

  return (
    <CustomBox
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <PDFViewer
        defaultBotId={defaultBotId}
        url={pageUrl}
        todoId={todoId}
        initialPage={pdfViewerPage}
      />
    </CustomBox>
  );
};

export default ChatSessionDataSources;
