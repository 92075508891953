import * as React from 'react';
import SubmitBottom, {
  propsSubmit,
} from '../../AccountForm/SubmitButton/SubmitButton';
import Box from '@mui/material/Box';
import PasswordInput from '../../../common/PasswordInput/PasswordInput';
import { BoxStyled } from '../../ForgotPassword/ForgotPasswordForm/ForgotPasswordForm';
import { useIntl } from 'react-intl';



export interface Formprops {
  inputValue: { password: string; confirmPassword: string };
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: propsSubmit['onSubmit'];
  hasError?: boolean;
}
const ResetPasswordForm = ({
  inputValue,
  onChange,
  onSubmit,
}: Formprops) => {
  const intl=useIntl()
  return (
    <Box component="form" noValidate width={302} mt={1}>
      <BoxStyled>
          <Box width={325}>
          <PasswordInput
            password={inputValue.password}
            onChange={onChange}
            placeholder={intl.formatMessage({
              id: 'input.password',
              defaultMessage: 'Password',
            })}
            name={'password'}
          />
          <PasswordInput
            password={inputValue.confirmPassword}
            onChange={onChange}
            placeholder={intl.formatMessage({
              id: 'input.confirm.password',
              defaultMessage: 'Confirm Password',
            })}
            name={'confirmPassword'}
          />
          </Box>

        <SubmitBottom
          onSubmit={onSubmit}
          buttonValue={'Continue'}
          disable={false}
        />
      </BoxStyled>
    </Box>
  );
};
export default ResetPasswordForm;
