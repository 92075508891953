import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import theme from './theme';
import Router from './router';
import { IntlProvider } from 'react-intl';
// load locale json files
import en from './lang/en-US.json';
import fr from './lang/fr.json';
import config from './config/config';
import { getModeTheme, setModeTheme } from './utils/modeTheme';
import { getNativatorLanguage } from './utils/language';
import client from './apollo/client';
import { ApolloProvider } from '@apollo/client';

import mermaid from 'mermaid';

mermaid.initialize({
  startOnLoad: true,
  theme: 'dark',
  securityLevel: 'loose',
  suppressErrorRendering: true,
  fontFamily: 'monospace',
});

const messages: any = {
  en,
  fr,
};

// Detect user's locale
let locale = getNativatorLanguage();

// If locale is not supported, use English
if (!messages[locale]) {
  locale = 'en';
}

const localeMessages = messages[locale];
export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
  const isDarkTheme = darkThemeMq.matches;
  let lastMode = getModeTheme()
    ? getModeTheme()
    : isDarkTheme
    ? 'dark'
    : 'light';
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    lastMode as 'light' | 'dark'
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setModeTheme(mode === 'light' ? 'dark' : 'light');
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [mode]
  );

  const customTheme = React.useMemo(() => theme(mode), [mode]);
  return (
    <ApolloProvider client={client()}>
      <GoogleOAuthProvider clientId={config.OAUTH_GOOGLE_CLIENT_ID}>
        <IntlProvider
          defaultLocale="en"
          messages={localeMessages}
          locale={locale}
        >
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={customTheme}>
              <Router />
            </ThemeProvider>
          </ColorModeContext.Provider>
        </IntlProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}
export default App;
