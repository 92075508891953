import * as React from 'react';
import { useTheme } from '@mui/material';
import Message from './Message/MessageDataLoader';
import messagesByDate from '../../../utils/messageByDate';
import MessageDivider from './MessageDivider/MessageDivider';
import RealTimeUpdate from './RealtimeUpdate/RealtimeUpdate';
import MessageToReply from './MessageToReply/MessageToReply';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import CustomBox from '../../common/CustomBox/CustomBox';
import MessageRetry from './MessageRetry/MessageRetry';
import { CHAT_MESSAGE_FRAGMENT } from '../../../fragements/message';
import { FragmentType } from '../../../gql';
import { reverseChatMessages } from '../utils/reverseChatMessage';
import FormAddMessage from './FormAddMessage/FormAddMessage';
import HeadContainer from './HeadMessage/HeadContainer';
import ChatOnBoardingContainer from './ChatOnboarding/ChatOnboardingContainer';
import useGenerateText2Speech from '../../../Hooks/useGenerateText2Speech';
import CreditWarningIndication from './CreditWarningIndication/CreditWarningIndication';
import { FormAddContainer } from './bodyChatStyle';
import CustomDropZone from '../../common/CustomDropZone/CustomDropZone';
import { useContextFormChat } from '../hooks/useContextFormChat';
import SearchBotDialog from '../SearchBot/SearchBotDialog/SearchBotDialog';
import MessageContainer from './MessageContainer/MessageContainer';

const MemorizedMessageContainer = React.memo(MessageContainer);

interface props {
  todoId: number;
  messages?: FragmentType<typeof CHAT_MESSAGE_FRAGMENT>[];
  sessionId?: number;
  isBotConfigMode?: boolean;
}

const BodyChat = ({
  todoId,
  messages: chatMessages,
  sessionId,
  isBotConfigMode = false,
}: props) => {
  const scrollToBottomRef = React.useRef<HTMLDivElement>(null);

  const {
    onUploadFile,
    openDialogSwitchBot,
    handleToogleDialogSwitchBot,
    botTagSlug,
  } = useContextFormChat();

  // Reverse messages
  const messages = React.useMemo(() => {
    return reverseChatMessages(chatMessages);
  }, [chatMessages]);

  // Chat context
  // Parent message id for reply
  const theme = useTheme();

  const [parentMessageId, setParentMessageId] = React.useState<number | null>(
    null
  );

  //use text to speech
  const {
    isSpeechAudioLoading,
    isPlaying,
    currentMessagePlayingId,
    generateSpeech,
  } = useGenerateText2Speech();

  //Handle text2speech
  const handleTextToSpeech = React.useCallback((messageId: number) => {
    Mixpanel.track('Click Chat Text to speech', {});
    generateSpeech(messageId);
  }, []);

  // Handle reply menu click
  const handleReply = React.useCallback((messageId: number) => {
    setParentMessageId(messageId);
    Mixpanel.track('Reply a message', {});
  }, []);

  const handleCloseReply = React.useCallback(() => {
    setParentMessageId(null);
  }, []);

  const onMessageSent = React.useCallback(() => {
    setParentMessageId(null);
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollTop =
        scrollToBottomRef.current.scrollHeight;
    }
  }, []);

  const isEmptyMessage = !messages || messages.length === 0;

  const messagesGroupByDate = React.useMemo(
    () => messagesByDate(messages),
    [messages]
  );

  const lastMessage: any = messages ? messages[messages.length - 1] : null;
  const lastMessageId = lastMessage ? lastMessage.id : null;
  return (
    <>
      <CustomDropZone
        onDropFile={onUploadFile}
        disabled={isBotConfigMode}
        multiple={true}
        option={{
          cannotClick: true,
        }}
      >
        <CustomBox
          display={'flex'}
          overflow={'hidden'}
          flexDirection={'column'}
          sx={(theme) => ({
            transition: 'all 0.3s',
            paddingBottom: (theme) => theme.spacing(2),
            boxSizing: 'border-box',
            height: '100vh',
            [theme.breakpoints.down(750)]: {
              height: 'calc(100vh - 44px)',
            },
          })}
        >
          <CustomBox mb={2}>
            <HeadContainer />
          </CustomBox>

          <MemorizedMessageContainer
            messages={messages}
            scrollToBottomRef={scrollToBottomRef}
          >
            {!isBotConfigMode && isEmptyMessage ? (
              <CustomBox
                sx={{
                  marginTop: (theme) => theme.spacing(2),
                  // Breakpoints xs
                  [theme.breakpoints.down('sm')]: {
                    marginTop: (theme) => theme.spacing(2),
                    marginRight: (theme) => theme.spacing(1),
                    marginLeft: (theme) => theme.spacing(1),
                    marginBottom: (theme) => theme.spacing(2),
                  },
                }}
              >
                <ChatOnBoardingContainer
                  todoId={todoId}
                  sessionId={sessionId || 0}
                />
              </CustomBox>
            ) : null}
            <CustomBox>
              {Object.keys(messagesGroupByDate).map((date, index) => (
                <React.Fragment key={date}>
                  {index !== 0 && <MessageDivider date={date} />}
                  {messagesGroupByDate[date].map(
                    (message: any, index: number) => (
                      <>
                        <Message
                          key={index.toString()}
                          todoId={todoId}
                          message={message}
                          isLastMessage={lastMessageId === message.id}
                          onReply={handleReply}
                          sessionId={sessionId as number}
                          isPlaying={
                            isPlaying && currentMessagePlayingId === message.id
                          }
                          isSpeechAudioLoading={isSpeechAudioLoading}
                          handleAudioToggle={() =>
                            handleTextToSpeech(message.id)
                          }
                        />
                      </>
                    )
                  )}
                </React.Fragment>
              ))}

              {lastMessage && lastMessage.isBotError && (
                <CustomBox
                  sx={{
                    marginRight: (theme) => theme.spacing(1),
                  }}
                >
                  <MessageRetry sessionId={sessionId as number} />
                </CustomBox>
              )}

              <RealTimeUpdate
                todoId={todoId}
                lastMessageId={lastMessageId}
                sessionId={sessionId || 0}
                onNewMessage={() => {}}
                onNewTyping={() => {}}
              />
            </CustomBox>
          </MemorizedMessageContainer>
          <FormAddContainer id="chat-message-input">
            {parentMessageId && (
              <MessageToReply
                messageId={parentMessageId}
                onClose={handleCloseReply}
              />
            )}

            <CreditWarningIndication />

            <FormAddMessage
              parentMessageId={parentMessageId}
              onMessageSent={onMessageSent}
              sessionId={sessionId}
              isBotConfigMode={isBotConfigMode}
              isEmptyMessage={isEmptyMessage}
            />
          </FormAddContainer>
          {openDialogSwitchBot ? (
            <SearchBotDialog
              todoId={todoId}
              openDialog={openDialogSwitchBot}
              handleCloseDialog={handleToogleDialogSwitchBot}
              shouldCreateSession={false}
              botTagSlug={botTagSlug}
            />
          ) : null}
        </CustomBox>
      </CustomDropZone>
    </>
  );
};

export default React.memo(BodyChat);
