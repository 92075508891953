import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const GET_BOT = graphql(
  `
    query GetBot($botId: Float!) {
      bot(id: $botId) {
        id
        name
        slug
        description
        gptModel
        isSupportAgentTools
        user {
          id
          firstName
          lastName
          profilePicture
        }
        illustrationUrl
        isDefaultChatLMM
        isChatLMM
        isBotPartOfSubscription
        currentUserCredit {
          remainingExtraCredit
          remainingMonthlyCredit
          spendedMonthlyCredit
          monthlyCredit
          totalRemainingCredit
        }
        stripePaymentLink
        purchasePageOrder
        creditUnitPrice
        creditAmountPerUnitPrice
        quantityLabel
        quantityLabelFR
        greetingMessage
        greetingMessageFR
        systemPrompt
        isSupportsDataSources
        ragRetrievalTopKChunk
        ragDefaultChunkSize
        gptTemperature
        recursionLimit
        gradingPrompt
        gradeEachChunk
        useParentRetriever
        enableSourcesGrading
        enableQuestionReformulation
        forceAgentToChooseTool
        llmModel {
          swiftaskTokenCoef
          id
        }
        clientToken
        baseBot {
          id
          name
          slug
          user {
            id
            profilePicture
          }
        }
        descriptionFR
        botPromptVersions {
          id
          name
          isSelected
        }
      }
    }
  `
);
interface GetBotArg {
  botId: number;
}

const useGetBot = ({ botId }: GetBotArg) => {
  const { loading, data } = useQuery(GET_BOT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      botId,
    },
  });

  return { loading, data };
};

export default useGetBot;
