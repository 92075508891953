import { useQuery } from '@apollo/client';
import { graphql } from '../gql';

const BOT_ATTRIBUTES = graphql(
  `
    query Attribute($botId: Float!) {
      bot(id: $botId) {
        id
        swiftaskTokenCoef
        quantityLabel
        name
        attribute {
          id
          speed
          tags
          videoDemoUrl
          context
          intelligence
          creditCost
          botId
        }
      }
    }
  `
);
interface BotAttributesArg {
  botId: number;
}

const useBotAttributes = ({ botId }: BotAttributesArg) => {
  const { loading, data } = useQuery(BOT_ATTRIBUTES, {
    variables: {
      botId,
    },
  });

  return { loading, data };
};
export default useBotAttributes;
