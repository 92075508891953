import React from 'react';
import { Bot } from '../../../../gql/graphql';

export interface IChatContext {
  isBotConfigMode?: boolean;
  defaultBot?: Bot;
  sessionId?: number;
  dataSources: any[];
  todoChatId?: number;
  isStoppable?: boolean;
  enableFollowupQuestion?: boolean;
  todoId?: number;
}
const ChatContext = React.createContext<IChatContext>({
  isBotConfigMode: false,
  defaultBot: {} as Bot,
  dataSources: [],
  todoChatId: 0,
  isStoppable: false,
  enableFollowupQuestion: false,
  todoId: 0,
});

export default ChatContext;
