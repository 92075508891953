// ChatLayout component
import { Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import LazyBoundary from '../LazyBoundary/LazyBoundary';
import { ChatContext } from './Chat';
import useGetInfiniteTodo from './hooks/useGetInfiniteTodo';
import useExtraConfig from './hooks/useExtraConfig';
import useFiles from './hooks/useFiles';
import useDialogSwitchBot from './hooks/useDialogSwitchBot';
import ChatSessionLayout from './ChatSession/ChatSession.layout';
import { memo, useEffect } from 'react';
import useDisplayHistoryChat from './ChatSession/Hooks/useDisplayHistoryChat';
import useInfiniteChat from '../../Hooks/useInfiniteChat';
import Hidden from '@mui/material/Hidden';
import useBotTag from './hooks/useBotTag';

const ChatLayout = () => {
  // For my assistant
  const { infiniteTodoId } = useGetInfiniteTodo();

  const params = useParams();

  const {
    extraConfig,
    openDrawerConfig,
    onChangeExtraConfig,
    resetExtraConfig,
    handleOpenDrawerConfig,
  } = useExtraConfig();

  const isChat = useInfiniteChat();

  const { makeRelativePosition, clearDisplayHistoryChatState } =
    useDisplayHistoryChat();

  const { files, setFiles } = useFiles();
  const { openDialogSwitchBot, handleOpenDialogSwitchBot } =
    useDialogSwitchBot();
  const { botTagSlug, handleBotTagSlug } = useBotTag();

  const todoIdParam = params?.taskID ? parseInt(params?.taskID) : 0;

  const todoId = todoIdParam || infiniteTodoId || 0;

  useEffect(() => {
    if (!isChat) {
      makeRelativePosition();
    } else {
      clearDisplayHistoryChatState();
    }

    return () => {
      clearDisplayHistoryChatState();
    };
  }, [isChat]);

  return (
    <ChatContext.Provider
      value={{
        isInifiniteChat: infiniteTodoId === todoId,
        openDialogSwitchBot,
        openDrawerConfigWhenMobile: openDrawerConfig,
        extraConfig,
        files,
        botTagSlug,

        onChangeExtraConfig,
        resetExtraConfig,
        setFiles,
        handleOpenDialogSwitchBot,
        handleOpenDrawerConfigWhenMobile: handleOpenDrawerConfig,
        setBotTagSlug: handleBotTagSlug,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          overflow: 'hidden',
          top: '0',
          '*': {
            transition: 'all 0.75s',
          },
        }}
      >
        <Hidden mdDown={true}>
          <ChatSessionLayout todoId={todoId} />
        </Hidden>

        <LazyBoundary isPrintError={true} size={30}>
          <Outlet />
        </LazyBoundary>
      </Box>
    </ChatContext.Provider>
  );
};

export default memo(ChatLayout);
