// Global alert message component

import React, { FC } from 'react';
import AlertText from '../../AlertText/AlertText';
import useDisplayMessage from '../useDisplayMessage';

const STAlertMessage = () => {
  const { message: msg, clearMessage } = useDisplayMessage();

  if (!msg?.message) {
    return null;
  }
  const message = msg.message;
  const type = msg.type;

  return (
    <AlertText severity={type}  onClose={clearMessage} id={message} message="" />
  );
};

export default STAlertMessage;
