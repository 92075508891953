import { FC, RefObject } from 'react';

import ContentEditable from 'react-contenteditable';

interface IProps {
  style?: any;
  isDisable: boolean;
  labelContent: string;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
  onChange(e: any): void;
  onKeyDown?(e: any): void;
  contentEditableRef?: RefObject<HTMLElement>;
}

const CustomContentEditable: FC<IProps> = ({
  style,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  isDisable,
  labelContent,
  contentEditableRef
}) => {
  return (
    <ContentEditable
      tagName="p"
      style={style}
      onBlur={onBlur}
      inputMode="text"
      onFocus={onFocus}
      html={labelContent}
      disabled={isDisable}
      aria-multiline={false}
      innerRef={contentEditableRef}
      onChange={(e: any) => onChange(e)}
      onKeyUp={(e) => onKeyDown && onKeyDown(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      onKeyUpCapture={(e) => onKeyDown && onKeyDown(e)}
      onKeyDownCapture={(e) => onKeyDown && onKeyDown(e)}
    />
  );
};

export default CustomContentEditable;
