import { graphql } from '../gql';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateChatSession from '../components/Chat/hooks/useCreateChatSession';
import { getUrlParam } from '../utils/url';
import { stringToNumber } from '../utils/numberUtil';

export const MY_LAST_SESSIONS = graphql(`
  query GetMyLastSession {
    getMyLastSession
  }
`);

export const useGetMyLastSession = (todoId: number) => {
  const client = useApolloClient();

  const navigate = useNavigate();
  const params = useParams();
  const botId = stringToNumber(getUrlParam('bot'));

  const sessionIdParams = params?.sessionId;

  const { createChatSession } = useCreateChatSession({
    todoId,
  });

  const createAndRedirectToNewSession = async (botId?: number) => {
    const createdChatSession = await createChatSession({
      variables: {
        data: {
          todoId,
          defaultBotId: botId,
        },
      },
    });

    if (createdChatSession?.data?.createTodoChatSession) {
      client.writeQuery({
        query: MY_LAST_SESSIONS,
        data: {
          getMyLastSession: createdChatSession?.data?.createTodoChatSession?.id,
        },
      });
      navigate(
        `/${params.workspaceId}/talk/${createdChatSession?.data?.createTodoChatSession?.id}`
      );
    }
  };

  const { data, loading, error } = useQuery(MY_LAST_SESSIONS, {
    onCompleted: async (data) => {
      if (sessionIdParams) {
        return;
      }
      if (botId > 0) {
        createAndRedirectToNewSession(botId);
      } else if (!data.getMyLastSession) {
        createAndRedirectToNewSession();
      } else if (data.getMyLastSession) {
        navigate(`/${params.workspaceId}/talk/${data.getMyLastSession}`);
      }
    },
  });

  return { data, isLoadingSession: loading, error };
};
