import { Checkbox, useMediaQuery, useTheme } from '@mui/material';
import CustomBox from '../../../../../common/CustomBox/CustomBox';
import TodoLabelTag from '../TodoLabelTag/TodoLabelTag';

const LabelItem = ({
  label,
  isChecked,
  isShowCheckBox,
}: {
  label: any;
  isShowCheckBox?: boolean;
  isChecked?: boolean;
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <CustomBox
      display="flex"
      width={'100%'}
      p={1}
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <CustomBox display="flex" width={'80%'}>
        <TodoLabelTag
          showLabel={!isSm}
          label={label?.name}
          labelId={label?.id}
          labelColor={label.color}
          actionbgColor={label.bgColor}
          sx={{ backgroundColor: label.bgColor }}
        />
        <span>{label?.description}</span>
      </CustomBox>
      {isShowCheckBox && (
        <CustomBox width={'12%'} sx={{ mt: -1 }} ml={'auto'}>
          <Checkbox checked={isChecked} color="default" size="small" />
        </CustomBox>
      )}
    </CustomBox>
  );
};
export default LabelItem;
