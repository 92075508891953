export const getLastedVoiceSelected = () => {
  return JSON.parse(`${localStorage.getItem('elevenlabs:lastvoice')}`) || [];
};

export const setLastedVoiceSelected = (voiceSelected: string) => {
  let lastedVoice: string[] = getLastedVoiceSelected() || [];

  if (!lastedVoice.includes(voiceSelected)) {
    lastedVoice = [voiceSelected, ...lastedVoice];
  }
  localStorage.setItem('elevenlabs:lastvoice', JSON.stringify(lastedVoice));
};
