import { useState } from 'react';
import { File } from '../../../gql/graphql';

const useDialogSwitchBot = () => {
  const [openDialogSwitchBot, setOpenDialogSwitchBot] =
    useState<boolean>(false);

  const handleOpenDialogSwitchBot = () => {
    setOpenDialogSwitchBot((state) => !state);
  };

  return {
    openDialogSwitchBot,
    handleOpenDialogSwitchBot,
  };
};

export default useDialogSwitchBot;
