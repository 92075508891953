import { CircularProgress } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';
import useNavigateToChatSession from '../../../hooks/useNavigateToChatSession';
import CustomIconButton from '../../../../common/CustomIconButton/CustomIconButton';
import useCreateChatSession from '../../../hooks/useCreateChatSession';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import { FC } from 'react';
import { useApolloClient } from '@apollo/client';
import { MY_LAST_SESSIONS } from '../../../../../Hooks/useGetMyLastSession';
import { AddCircleRounded } from '@mui/icons-material';

interface IProps {
  todoId: number;
}
const CreateSession: FC<IProps> = ({ todoId }) => {
  const goToChatSession = useNavigateToChatSession();
  const client = useApolloClient();

  const { createChatSession, loadingNewSession } = useCreateChatSession({
    todoId: todoId as number,
    onCompleted: (data) => {
      Mixpanel.track('Create chat session', {
        'Session title': data?.createTodoChatSession?.title,
      });

      // Go to chat session
      client.writeQuery({
        query: MY_LAST_SESSIONS,
        data: {
          getMyLastSession: data?.createTodoChatSession?.id,
        },
      });

      onItemClick(data?.createTodoChatSession);
    },
  });

  const createNewSession = () => {
    createChatSession({
      variables: {
        data: {
          todoId,
        },
      },
    });
  };

  const onItemClick = (item: any) => {
    goToChatSession({
      todoId,
      chatSessionId: item.id,
      dataSources: item.dataSources,
    });
  };

  return (
    <CustomIconButton
      disabled={loadingNewSession}
      intlId="chat.newSession"
      onClick={createNewSession}
      sx={{ padding: 0 }}
    >
      {loadingNewSession ? (
        <CircularProgress size={20} />
      ) : (
        <AddCircleRounded fontSize="small" />
      )}
    </CustomIconButton>
  );
};

export default CreateSession;
