import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql/gql';
import { ChatDataSourceType } from '../../../gql/graphql';

const GET_DATASOURCE_SESSION = graphql(`
  query GetOneTodoChatSession($sessionId: Float!) {
    getOneTodoChatSession(sessionId: $sessionId) {
      id
      autoReplyLMM
      todoChatId
      defaultBot {
        id
        name
        slug
        description
        user {
          id
          firstName
          lastName
          profilePicture
        }
        illustrationUrl
        supportedContentTypes {
          id
          type
          extension
        }
        formFields {
          id
          type
          description
          placeholder
          rangeMin
          rangeMax
          label
          formName
          order
          required
          options {
            id
            label
            value
          }
          supportedFiletypes
          defaultValue
        }
      }
      allDataSources {
        id
        name
        url
        fileType
        type
      }
    }
  }
`);
interface IProps {
  skip?: boolean;
}
const useGetChatSessionDataSource = (props?: IProps) => {
  const params = useParams();
  const { data, loading } = useQuery(GET_DATASOURCE_SESSION, {
    variables: {
      sessionId: +(params?.sessionId || 0),
    },
    skip: props?.skip || false,
  });

  const isPdf = (datasources: any) => {
    if (!datasources?.length) return false;
    const oneDatasource = datasources[0];
    // If the url end with .pdf, then we return true
    if (oneDatasource?.url && oneDatasource?.url.endsWith('.pdf')) {
      return true;
    }
    return false;
  };
  const isYoutube = (datasources: any) => {
    if (!datasources?.length) return false;
    const oneDatasource = datasources[0];
    if (
      oneDatasource?.type &&
      oneDatasource?.url &&
      oneDatasource?.type === ChatDataSourceType.Youtube
    ) {
      return true;
    }
    return false;
  };
  const getDataSourceUrl = (datasources: any) => {
    if (!datasources?.length) return '';
    const oneDatasource = datasources[0];
    return oneDatasource?.url || '';
  };
  const dataSources = data?.getOneTodoChatSession?.allDataSources;
  // Check mime type of file
  const isDataSourcePDF = isPdf(dataSources);
  const isDataSourceYoutube = isYoutube(dataSources);
  const shouldDisplayDataSource =
    isDataSourcePDF &&
    data?.getOneTodoChatSession?.defaultBot?.slug === 'chatpdf';

  const pdfUrl = getDataSourceUrl(dataSources);
  return {
    data,
    loading,
    isDataSourcePDF,
    getDataSourceUrl,
    isDataSourceYoutube,
    pdfUrl,
    shouldDisplayDataSource,
  };
};

export default useGetChatSessionDataSource;
