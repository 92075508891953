import { useQuery } from '@apollo/client';
import { graphql } from '../gql';
const GET_SUBSCRIPTION_PLANS = graphql(
  `
    query GetSubscriptionPlans {
      getSubscriptionPlans {
        id
        code
        description
        name
        price
        currency
        removed
        maxUser
        isEnterprisePlan
        landingPageCTA
        landingPageDisplayOrder
        landingPagePrice
        isYearly
        priceToCompare
        subscriptionPlanDetails
      }
    }
  `
);
const useGetSubscriptionPlans = () => {
  const { loading, data } = useQuery(GET_SUBSCRIPTION_PLANS);
  return { loading, data };
};
export default useGetSubscriptionPlans;
