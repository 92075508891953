import { useState } from 'react';

const useExtraConfig = () => {
  const [extraConfig, setExtraConfig] = useState<any>({});
  const [openDrawerConfig, setOpenDrawerConfig] = useState<string>('100%');

  const handleOpenDrawerConfig = () => {
    setOpenDrawerConfig((prevState) => (prevState === '100%' ? '0' : '100%'));
  };

  const onChangeExtraConfig = (name: string, value: any) => {
    setExtraConfig((lasted: any) => ({
      ...lasted,
      [name]: value,
    }));
  };

  const resetExtraConfig = () => {
    setExtraConfig({});
  };

  return {
    openDrawerConfig,
    extraConfig,
    onChangeExtraConfig,
    handleOpenDrawerConfig,
    resetExtraConfig,
  };
};

export default useExtraConfig;
