import { FC } from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import AlertText from '../../../common/AlertText/AlertText';
import useGetChatContext from '../ChatBodyContainer/useGetChatContext';

interface IProps {}

const CreditWarningIndication: FC<IProps> = ({}) => {
  const { defaultBot } = useGetChatContext();
  if (!defaultBot) return null;
  if (!defaultBot.currentUserCredit) return null;
  if (!defaultBot.stripePaymentLink) return null;
  const hideWarning =
    (defaultBot?.currentUserCredit?.totalRemainingCredit || -1) > 0;
  if (hideWarning) return null;
  return (
    <CustomBox>
      <AlertText
        id="input.message.credit.warning"
        severity="warning"
        message={
          "You've reached your credit limit with {name}. Subscribe or buy more credit. Or try another AI assistant."
        }
      />
    </CustomBox>
  );
};

export default CreditWarningIndication;
