import styled from "@emotion/styled";
import Box from "@mui/material/Box/Box";

export const DropZoneContainer = styled(Box)(({ theme }) =>({
  display: 'inline-grid',
  borderRadius: 5,
  marginBottom: 18,
  marginRight: 20,
  width: 130,
  height: 130,
  padding: 2,
  boxSizing: 'border-box',
}));

export const ImageContainer = styled(Box)({
  minWidth: 0,
  overflow: 'hidden',
});

export const Image =styled.img ({
  display: 'flex',
  width: 'auto',
  margin:"auto",
  height: '100%',
});
export const Aside :any= styled(Box)({
  display: 'flex',
  position: 'relative',
  borderTopLeftRadius:"10px",
  borderTopRightRadius:"10px",
  maxHeight: '175px',
  overflow: 'auto',
});
export const ImgAction = styled(Box)({
  display: 'inline-flex',
  marginLeft: ' auto ',
  padding:5,
  borderRadius:5,
  height: 25,
});
export const BoxIcon = styled(Box)({
  margin:"auto",
  maxWidth:"50%"
});
