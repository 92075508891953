import * as React from 'react';
import html2canvas from 'html2canvas';
import useCreateFile, { FileType } from '../../../Hooks/useCreateFile';
type PositionArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};
const useCanvaToImage = ({
  afterCaptureImage,
}: {
  afterCaptureImage(files: FileType[]): void;
}) => {
  const [loadingConvert, setLoadingConvert] = React.useState(false);
  const { loading: loadingUpload, onDropFile } = useCreateFile({
    onComplete: (files: FileType[]) => {
      afterCaptureImage(files);
    },
    isDirectlyToUpload: true,
  });
  const canvaToImage = (
    captureElement: HTMLDivElement,
    selectedPositionArea: PositionArea
  ) => {
    setLoadingConvert(true);
    html2canvas(captureElement, {
      ...selectedPositionArea,
      useCORS: true,
    })
      .then((canvas) => {
        try {
          const imageDataURL = canvas.toDataURL('image/png');
          fetch(imageDataURL)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], 'swiftask_drawing.png', {
                type: 'image/png',
              });
              setLoadingConvert(false);
              onDropFile([file]);
            });
        } catch (error) {
          console.error('Error converting canvas to image:', error);
          setLoadingConvert(false);
        }
      })
      .catch((error) => {
        console.error('Error capturing node:', error);
        setLoadingConvert(false);
      });
  };
  const loading = loadingConvert || loadingUpload;
  return { canvaToImage, loading };
};
export default useCanvaToImage;
