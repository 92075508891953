import * as React from 'react';
import { FileType } from '../../../../Hooks/useCreateFile';
import useCanvaToImage from '../../hooks/useCanvaToImage';

export const clamp = (val: number, min: number, max: number): number =>
  Math.max(min, Math.min(max, val));

export enum Selection {
  None = 'None',
  Clicked = 'Clicked',
  Dragging = 'Dragging',
  Selected = 'Selected',
}

interface IProps {
  onAddFiles(files: FileType[]): void;
}

export const useClickToSelect = ({ onAddFiles }: IProps) => {
  const { canvaToImage, loading } = useCanvaToImage({
    afterCaptureImage: (files: FileType[]) => {
      onAddFiles(files);
    },
  });
  const [selection, setSelection] = React.useState<Selection>(Selection.None);
  const [node, setNode] = React.useState<HTMLElement | undefined>();
  const [{ dx, dy }, setOffset] = React.useState<{ dx: number; dy: number }>({
    dx: 0,
    dy: 0,
  });
  const [{ startX, startY }, setStartPosition] = React.useState<{
    startX: number;
    startY: number;
  }>({
    startX: 0,
    startY: 0,
  });

  const captureRef = React.useRef<HTMLDivElement>(null);
  const selectionRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useCallback((nodeEle: HTMLElement) => {
    setNode(nodeEle);
  }, []);

  const resetCoordinates = () => {
    setOffset({ dx: 0, dy: 0 });
    setStartPosition({ startX: 0, startY: 0 });
  };
  const handleMouseDown = React.useCallback(
    (e: MouseEvent) => {
      if (!node) {
        return;
      }
      const eleRect = node.getBoundingClientRect();
      const startRelativePos = {
        startX: e.clientX - eleRect.left,
        startY: e.clientY - eleRect.top,
      };
      setOffset({ dx: 0, dy: 0 });
      setStartPosition(startRelativePos);
      const startPos = {
        x: e.clientX,
        y: e.clientY,
      };
      setSelection(Selection.Clicked);

      const handleMouseMove = (e: MouseEvent) => {
        let dx = e.clientX - startPos.x;
        let dy = e.clientY - startPos.y;
        const maxX = eleRect.width - startRelativePos.startX;
        const maxY = eleRect.height - startRelativePos.startY;
        dx = clamp(dx, 0, maxX);
        dy = clamp(dy, 0, maxY);
        setOffset({ dx, dy });
        setSelection(Selection.Dragging);
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        setSelection(Selection.Selected);
        downloadSelectionAsImage();
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [node, dx, dy]
  );

  const downloadSelectionAsImage = () => {
    if (!node || !captureRef.current || !selectionRef.current) {
      return;
    }
    const captureElement = captureRef.current;
    const selectedElement = selectionRef.current;

    const captureRect = captureElement.getBoundingClientRect();
    const selectedRect = selectedElement.getBoundingClientRect();

    const selectedArea = {
      x: selectedRect.left - captureRect.left,
      y: selectedRect.top - captureRect.top,
      width: selectedRect.width,
      height: selectedRect.height,
    };
    canvaToImage(captureElement, selectedArea);
  };

  React.useEffect(() => {
    if (!node) {
      return;
    }
    node.addEventListener('mousedown', handleMouseDown);
    return () => {
      node.removeEventListener('mousedown', handleMouseDown);
    };
  }, [node, dx, dy]);
  return {
    ref,
    dx,
    dy,
    startX,
    startY,
    selection,
    captureRef,
    selectionRef,
    loading,
    offsetHeight:node?.offsetHeight||0,
    offsetWidth:node?.offsetWidth||0,
    resetCoordinates,
  };
};
