import CustomBox from '../../../../common/CustomBox/CustomBox';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GanttIcon from './GanttIcon/GanttIcon';
import WhiteBoardIcon from './WhiteBoardIcon/WhiteBoardIcon';

const ProjectViewsIcon = ({ code }: { code?: string }) => {
  let icon;

  switch (code) {
    case 'KABAN':
      icon = <ViewKanbanIcon fontSize="small" />;
      break;
    case 'LIST':
      icon = <FormatListBulletedIcon fontSize="small" />;
      break;
    case 'CALENDAR':
      icon = <EventNoteIcon fontSize="small" />;
      break;
    case 'GANTT':
      icon = <GanttIcon />;
      break;
    case 'TABLE':
      icon = <BackupTableIcon fontSize="small" />;
      break;
    case 'CHAT':
      icon = <ChatBubbleOutlineIcon fontSize="small" />;
      break;
      case 'WHITEBOARD':
      icon = <WhiteBoardIcon />;
      break;
    default:
      icon = null;
  }

  return <CustomBox>{icon}</CustomBox>;
};

export default ProjectViewsIcon;
