// Enum with free, pro, business, and enterprise subscription levels
export const SUBSCRIPTION_LEVEL = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  ULTRA: 'ULTRA',
  PRO_MAX: 'PRO_MAX',
  BUSINESS: 'BUSINESS',
  ENTERPRISE: 'ENTERPRISE',
  PRO_ANNUAL: 'PRO_ANNUAL',
  ULTRA_ANNUAL: 'ULTRA_ANNUAL',
};
