import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql/gql';
import { useEffect } from 'react';

export const GET_MY_BOTS_LIST = graphql(
  `
    query GetMyBotsList($botType: String) {
      getMyBotsList(botType: $botType) {
        id
        name
        slug
        subTitle
        description
        workspaceId
        createdAt
        resourceAccess {
          id
          userId
          resourceAccessRole {
            id
            code
          }
        }
        createdBy {
          id
          lastName
        }
        user {
          id
          firstName
          lastName
          profilePicture
        }
        baseBot {
          id
          name
          slug
          description
          createdAt
          user {
            id
            firstName
            lastName
            profilePicture
          }
        }
      }
    }
  `
);

const useGetMyBotsList = () => {
  const [getBots, { data, error, loading }] = useLazyQuery(GET_MY_BOTS_LIST, {
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    getBots({ variables: { botType: 'PERSONAL' } });
  }, []);

  const bots = data?.getMyBotsList || [];
  return {
    getBots,
    bots,
    error,
    loading,
  };
};

export default useGetMyBotsList;
