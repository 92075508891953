import * as React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import { Link } from 'react-router-dom';
import CustomLink from '../../../../common/CustomLink/CustomLink';
import { ROUTE_DEFINITIONS } from '../../../../../router/routDefinitions';
const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));

const LinkStyle = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

const CardLinkSignin = () => {
  const theme = useTheme();

  // Get redirect url from location

  const styleSignupText = {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    marginLeft: '5px',
  };

  return (
    <Box role="box">
      <Box textAlign={'center'}>
        <StyledTypography>
          <FormattedMessage
            id="signin.DontHaveAcount"
            defaultMessage={"Don't have an account?"}
          />
          <CustomLink
            shouldInclueRedirect={true}
            shouldIncludeInvite={true}
            to={ROUTE_DEFINITIONS.SIGNUP.path}
            sx={styleSignupText}
          >
            <FormattedMessage id={'signup.button'} defaultMessage={'Sign Up'} />{' '}
          </CustomLink>
        </StyledTypography>
      </Box>
      <Box>
        <LinkStyle to={ROUTE_DEFINITIONS.FORGOT_PASSWORD.path}>
          <StyledTypography mt={1} textAlign="center">
            <FormattedMessage
              id="signin.forgotPassword"
              defaultMessage={'Forgot password?'}
            />
          </StyledTypography>
        </LinkStyle>
      </Box>
    </Box>
  );
};
export default CardLinkSignin;
