import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
const ME_INFINITE_TODO = graphql(`
  query MeInfinitetodo {
    me {
      id
      currentWorkspaceInfiniteTodo {
        id
      }
    }
  }
`);

const useGetInfiniteTodo = () => {
  const { data, loading, error } = useQuery(ME_INFINITE_TODO);

  const infiniteTodoId = data?.me?.currentWorkspaceInfiniteTodo?.id;

  return {
    infiniteTodoId,
    error,
    loading,
  };
};

export default useGetInfiniteTodo;
