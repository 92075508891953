// Custom text field component using material-ui TextField component

import { FC } from 'react';
import { TextField, StandardTextFieldProps } from '@mui/material';
import { useIntl } from 'react-intl';

interface IProps extends StandardTextFieldProps {
  labelIntl?: string;
}

const STTextField: FC<IProps> = ({ variant, labelIntl, label, ...rest }) => {
  const intl = useIntl();
  return (
    <TextField
      {...rest}
      variant={variant || 'standard'}
      label={labelIntl ? intl.formatMessage({ id: labelIntl }) : label}
    />
  );
};

export default STTextField;
