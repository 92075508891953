import { FC } from 'react';
import { FragmentType, useFragment } from '../../../../gql';
import { CHAT_MESSAGE_FRAGMENT } from '../../../../fragements/message';
import Message from './Message';
import { isEmptyString } from '../../../../utils/stringUtils';
import { isEmptyArray } from '../../../../utils/arrayUtils';

interface IProps {
  message: FragmentType<typeof CHAT_MESSAGE_FRAGMENT>;
  todoId?: number;
  isPlaying: boolean;
  isSpeechAudioLoading: boolean;
  handleAudioToggle(): void;
  onReply: (messageId: number) => void;
  sessionId: number;
  isLastMessage?: boolean;
}

const MessageDataLoader: FC<IProps> = ({
  message: messageData,
  todoId,
  onReply,
  sessionId,
  isPlaying,
  handleAudioToggle,
  isSpeechAudioLoading,
  isLastMessage,
}) => {
  const message = useFragment(CHAT_MESSAGE_FRAGMENT, messageData);
  // If message don't have content, we don't display i
  if (
    isEmptyString(message?.content) &&
    isEmptyArray(message?.files) &&
    !message?.isBotError &&
    !message?.isSubscriptionUsageLimitReachedMessage
  ) {
    return null;
  }

  return (
    <Message
      sessionId={sessionId}
      message={message}
      todoId={todoId}
      onReply={onReply}
      isLastMessage={isLastMessage}
      isPlaying={isPlaying}
      isSpeechAudioLoading={isSpeechAudioLoading}
      handleAudioToggle={handleAudioToggle}
    />
  );
};

export default MessageDataLoader;
