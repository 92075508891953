import { useTheme } from "@mui/material";

const Icon = () => {
    const theme = useTheme()
    const color = theme.palette.mode === "dark" ? "#F5F5F5" : "#34324A"
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="20" height="20" rx="5" fill="none" stroke={color} />
            </svg>
        </>
    );
}

export default Icon 