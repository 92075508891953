import * as React from 'react';
import Loading from '../../../common/Loading/Loading';
import { Button, styled, useTheme } from '@mui/material';

const ButtonStyle = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  fontFamily: 'Lato',
  boxShadow: 'none',
  fontSize: theme.typography.body2.fontSize,
  borderRadius: '10px',
  padding: '10px 10px',
  fontWeight: 'bold',
  lineHeight: 1.5,
  bgColor: theme.palette.background.default,
  '&:hover': {
    boxShadow: 'none',
  },
}));

export interface propsSubmit {
  onSubmit(): void;
  disable: Boolean | any;
  buttonValue: any;
  isLoading?: Boolean;
}
const SubmitButton = ({
  onSubmit,
  buttonValue,
  disable,
  isLoading,
}: propsSubmit) => {
  const theme = useTheme()
  const isDark = theme.palette.mode === "dark" ? true : false
  return (
    <ButtonStyle
      variant="contained"
      color={isDark ? 'secondary' : 'primary'}
      sx={{ mt: 2, mb: 2 }}
      fullWidth
      disableRipple
      disabled={disable}
      onClick={onSubmit}
    >
      {buttonValue}
      {isLoading ? <Loading type="circular" size={20} /> : null}
    </ButtonStyle>
  );
};

export default SubmitButton;
