import Box, { BoxProps } from '@mui/material/Box/Box';
interface props extends BoxProps {}
const CustomUnreadnotification = ({ ...rest }: props) => {
  return (
    <Box
      bgcolor={(theme) => theme.palette.text.secondary}
      width={9}
      height={9}
      borderRadius={'50%'}
      {...rest}
    />
  );
};
export default CustomUnreadnotification;
