import { graphql } from '../gql';

export const CATEGORY_PROMPTS_FRAGMENT = graphql(`
  fragment CategoryPromptsFragment on CategoryPrompts {
    id
    title
    isCustomWorkspace
    prompts {
      ...PromptsFragment
    }
  }
`);
