import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { StepContext } from '../../Prompts';
import useGetCategoryPrompts from '../../../../Hooks/useGetCategoryPrompts';
import { useIntl } from 'react-intl';
import { FormFieldType } from '../../../../gql/graphql';

interface FormValues {
  title: string;
  description: string;
  categoryPromptsId: number;
  workspaceId: number;
}

const useCustomPromptsStep2 = () => {
  const { idCategorie, updateStep } = useContext(StepContext);
  const { addNewPrompt, loadingAddPrompt } = useGetCategoryPrompts(idCategorie);
  const { workspaceId } = useParams();
  const intl = useIntl();

  const formik = useFormik<FormValues>({
    initialValues: {
      title: '',
      description: '',
      categoryPromptsId: idCategorie,
      workspaceId: +(workspaceId || 0),
    },
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  const handleSave = (values: FormValues) => {
    if (values.categoryPromptsId && values.description && values.title) {
      addNewPrompt({
        variables: {
          newPromptInput: {
            ...values,
          },
        },
      }).then(() => {
        updateStep({
          activeStep: 0,
          lastStep: 2,
          idPrompts: 0,
          categoryName: '',
          idCategorie: values.categoryPromptsId,
          updateStep,
          handleNextStep: () => {},
          handlePreviousStep: () => {},
          isCustomCategory: true,
        });
      });
    }
  };

  const commonField = {
    createdAt: new Date(),
    updatedAt: new Date(),
    options: [],
    removed: false,
  };

  const fields = [
    {
      formName: 'title',
      label: intl.formatMessage({
        id: 'text.field.prompt.name',
      }),
      placeholder: intl.formatMessage({
        id: 'text.field.prompt.title.placeholder',
      }),
      required: true,
      id: 1,
      type: FormFieldType.Text,
      ...commonField,
    },
    {
      formName: 'description',
      label: intl.formatMessage({
        id: 'text.field.prompt.description',
      }),
      placeholder: intl.formatMessage({
        id: 'text.field.prompt.description.placeholder',
      }),
      required: false,
      id: 2,
      type: FormFieldType.Textarea,
      ...commonField,
    },
  ];

  return {
    formik,
    handleChange,
    fields,
    loadingAddPrompt,
  };
};

export default useCustomPromptsStep2;
