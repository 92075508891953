export const convertToKFormat = (number: number): string => {
    if (number >= 1000000) {
      const formattedNumber: string = (number / 1000000).toFixed(1);
      const decimalPart: string = formattedNumber.split(".")[1];
      if (decimalPart === "0") {
        return formattedNumber.split(".")[0] + "M";
      } else {
        return formattedNumber + "M";
      }
    } else if (number >= 1000) {
      const formattedNumber: string = (number / 1000).toFixed(1);
      const decimalPart: string = formattedNumber.split(".")[1];
      if (decimalPart === "0") {
        return formattedNumber.split(".")[0] + "K";
      } else {
        return formattedNumber + "K";
      }
    } else {
      return number.toString();
    }
  };
  