import { useIntl } from 'react-intl';
import CustomDialog from '../../common/CustomDialog/CustomDialog';
import PricingPlansContent from './PricingPlansContent/PricingPlansContent';

interface PricingPlansDialogProps {
  isPricingDialogOpen: boolean;
  handleClosePricingDialog(): void;
}
const PricingPlansDialog = ({
  isPricingDialogOpen,
  handleClosePricingDialog,
}: PricingPlansDialogProps) => {
  const intl = useIntl();
  return (
    <CustomDialog
      maxWidth={'lg'}
      title={intl.formatMessage({ id: 'pricing.plan.title' })}
      contentChildren={<PricingPlansContent />}
      open={isPricingDialogOpen}
      handleClose={handleClosePricingDialog}
    />
  );
};
export default PricingPlansDialog;
