import { StandardTextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { checkEmail } from '../../../utils/emailUtil';
import AlertText from '../AlertText/AlertText';
import CustomBox from '../CustomBox/CustomBox';
import STTextField from '../STTextField/STTextField';

interface IProps extends StandardTextFieldProps {
  labelIntl?: string;
  displayEmailValidationError?: boolean;
}

const STEmailField: FC<IProps> = ({
  variant,
  labelIntl,
  label,
  displayEmailValidationError,
  onChange,
  value,
  ...rest
}) => {
  const intl = useIntl();
  const handleChangeEmail = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };
  const isEmailValid = checkEmail(value as string | undefined);

  return (
    <CustomBox>
      {displayEmailValidationError && !isEmailValid && (
        <CustomBox mb={1}>
          <AlertText
            severity="error"
            message={intl.formatMessage({ id: 'email.invalid' })}
          />
        </CustomBox>
      )}
      <STTextField
        {...rest}
        value={value}
        variant={variant || 'standard'}
        label={labelIntl ? intl.formatMessage({ id: labelIntl }) : label}
        type="email"
        autoComplete="email"
        onChange={handleChangeEmail}
      />
    </CustomBox>
  );
};

export default STEmailField;
