/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CalendarDueDate from '../CalendarDueDate/CalendarDueDate';
import { Box, styled } from '@mui/material';
import { graphql } from '../../../../gql';
import { useMutation } from '@apollo/client';
import getDateFromEvent from '../../../../utils/getDateFromEvent';
import customDateEvent from '../../../../utils/customDateEvent';
import { dueDateProps } from '../../../../types/DueDates';

const DialogContainer = styled(DialogContent)(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '250px',
}));
interface props {
  todoID?: number;
  isEditMode: boolean;
  isForStartDate?: boolean;
  children: React.ReactNode;
  dueDateInput?: Date | string | null;
  getDueDate?(dueDate: dueDateProps): void;
}

const UPDATE_DUEDATE = graphql(`
  mutation updateTodoDueDate($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      dueDate
      startDate
    }
  }
`);

export default function DueDate({
  todoID,
  isEditMode,
  children,
  dueDateInput,
  isForStartDate,
  getDueDate,
}: props) {
  const [openEditDueDate, setOpenEditDueDate] = React.useState(false);
  const [dueDate, setDueDate] = React.useState<dueDateProps>(
    getDateFromEvent(dueDateInput, isForStartDate)
  );

  const [updateTodoDate] = useMutation(UPDATE_DUEDATE, {
    update: (data) => {
      setOpenEditDueDate(false);
    },
  });

  const handleUpdateDueDate = (
    currentDueDate: string | number | Date | null
  ) => {
    if (isForStartDate) {
      updateTodoDate({
        variables: {
          updateTodoInput: {
            id: todoID as number,
            startDate: customDateEvent(currentDueDate),
          },
        },
      });
    } else {
      updateTodoDate({
        variables: {
          updateTodoInput: {
            id: todoID as number,
            dueDate: customDateEvent(currentDueDate),
          },
        },
      });
    }
  };

  const handleOpenEditDueDate = () => {
    setOpenEditDueDate(true);
  };

  const handleSetDueDate = (newDueDate: dueDateProps) => {
    setDueDate(newDueDate);
    if (isEditMode) {
      handleUpdateDueDate(newDueDate.value);
    } else {
      setOpenEditDueDate(false);
    }
    if (getDueDate) {
      getDueDate(newDueDate);
    }
  };

  const handleClose = () => {
    setOpenEditDueDate(false);
  };

  return (
    <Box>
      <Box onClick={handleOpenEditDueDate}>{children}</Box>
      <Dialog
        open={openEditDueDate}
        onClose={handleClose}
        sx={{
          background: '#0000004D',
          px: 0,
          py: 0,
          zIndex: 9999,
        }}
        BackdropProps={{ invisible: true }}
      >
        <DialogContainer sx={{ px: 0, pt: 1 }}>
          <CalendarDueDate
            dueDate={dueDate}
            handleSetDueDate={handleSetDueDate}
          />
        </DialogContainer>
      </Dialog>
    </Box>
  );
}
