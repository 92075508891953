import { IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import CustomBox from '../CustomBox/CustomBox';
import { useIntl, FormattedMessage } from 'react-intl';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

const formatLanguageCode = (code: string) => {
  if (code.includes('-')) {
    return code;
  } else {
    switch (code.toLowerCase()) {
      case 'fr':
        return 'fr-FR';
      case 'en':
        return 'en-US';
      default:
        return 'en-US';
    }
  }
};
const SpeechToText = () => {
  const { listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const { locale } = useIntl();
  const languageCode = formatLanguageCode(locale);
  //start listening recognition
  const startListening = () =>
    SpeechRecognition.startListening({
      continuous: true,
      language: languageCode,
    });

  //start or stop listening
  const toggleListening = listening
    ? SpeechRecognition.stopListening
    : startListening;

  return (
    <>
      {browserSupportsSpeechRecognition && (
        <CustomBox onClick={toggleListening}>
          {listening ? (
            <CustomTooltip
              arrow
              title={<FormattedMessage id="chat.stop.record.audio" />}
            >
              <IconButton
                sx={{
                  // Blink animation
                  animation: 'blinking 1s infinite',
                  '@keyframes blinking': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.5 },
                    '100%': { opacity: 1 },
                  },
                }}
                type="button"
                aria-label="Recording"
              >
                <StopIcon color="error" fontSize="small" />
              </IconButton>
            </CustomTooltip>
          ) : (
            <CustomTooltip
              arrow
              title={<FormattedMessage id="chat.start.record.audio" />}
            >
              <IconButton type="button" aria-label="Recording">
                <MicIcon color="error" fontSize="small" />
              </IconButton>
            </CustomTooltip>
          )}
        </CustomBox>
      )}
    </>
  );
};

export default SpeechToText;
