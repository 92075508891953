import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { graphql } from '../gql/gql';

const GET_DATASOURCE_SESSION = graphql(`
  query GetOneTodoChatSessionAutoReply($sessionId: Float!) {
    getOneTodoChatSession(sessionId: $sessionId) {
      id
      autoReplyLMM
    }
  }
`);

const useGetChatSessionAutoReply = () => {
  const params = useParams();
  const { data, loading } = useQuery(GET_DATASOURCE_SESSION, {
    variables: {
      sessionId: +(params?.sessionId || 0),
    },
  });

  return {
    data,
    loading,
  };
};

export default useGetChatSessionAutoReply;
