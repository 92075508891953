import { Typography, TypographyProps, styled } from '@mui/material';
import CustomTooltip from '../CustomToolTip/CustomToolTip';

export interface CustomTypographyProps extends TypographyProps {
  addTooltip?: boolean;
  color?: string;
  tooltipTitle?: string;
  maxCharacters?: number;
  maxLines?: number;
}

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'initial',
}));

const CustomTypography: React.FC<CustomTypographyProps> = ({
  children,
  addTooltip,
  color,
  tooltipTitle,
  maxCharacters,
  maxLines,
  ...props
}) => {
  if (addTooltip) {
    return (
      <CustomTooltip title={tooltipTitle || children}>
        <TypographyStyled
          {...props}
          sx={{
            color: (theme) => (color ? color : theme.palette.text.primary),
          }}
        >
          {children}
        </TypographyStyled>
      </CustomTooltip>
    );
  }

  return (
    <TypographyStyled
      {...props}
      sx={{
        ...props.sx,
        ...(maxCharacters && {
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: maxLines, // The number of lines to show
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          maxWidth: maxCharacters + 'ch',
        }),
      }}
    >
      {children}
    </TypographyStyled>
  );
};

export default CustomTypography;
