import { useQuery } from '@apollo/client';
import { graphql } from '../../../../gql';
import useSendNewMessage from '../../hooks/useSendNewMessage';
import { Mixpanel } from '../../../../mixpanel/mixpanel';

const GET_FOLLOW_UP_QUESTIONS = graphql(`
  query GetfollowUpQuestions($sessionId: Float!) {
    getfollowUpQuestions(sessionId: $sessionId)
  }
`);

const useFollowUpQuestion = ({
  onMessageSent,
  sessionId,
  todoId,
}: {
  onMessageSent(data: any): void;
  sessionId: number;
  todoId: number;
}) => {
  const { data } = useQuery(GET_FOLLOW_UP_QUESTIONS, {
    variables: {
      sessionId: sessionId,
    },
    fetchPolicy: 'network-only',
  });
  const { addNewMessage } = useSendNewMessage({
    taskID: todoId,
    onMessageSent,
  });
  const onSendNewFollowUpQuestion = (question: string) => {
    Mixpanel.track('Click Follow up question', {
      sessionId,
    });
    addNewMessage({
      variables: {
        newMessageData: {
          message: question,
          sessionId,
        },
      },
    });
  };
  const followUpQuestions = data?.getfollowUpQuestions;
  return { onSendNewFollowUpQuestion, followUpQuestions };
};
export default useFollowUpQuestion;
