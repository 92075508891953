import React from 'react';
import { Mixpanel } from '../../mixpanel/mixpanel';
import ChatContainerLayout from './ChatContainerLayout/ChatContainerLayout';

interface IProps {
  todoId: number;
}

export const ChatContext = React.createContext<{
  isInifiniteChat: boolean;
  extraConfig: { [key: string]: any };
  files: File[] | any[];
  openDialogSwitchBot: boolean;
  openDrawerConfigWhenMobile: string;
  setFiles: (params: any) => void;
  onChangeExtraConfig: (name: string, value: string) => void;
  resetExtraConfig: () => void;
  handleOpenDialogSwitchBot: () => void;
  handleOpenDrawerConfigWhenMobile: () => void;
  botTagSlug: string;
  setBotTagSlug: (value: string) => void;
}>({
  files: [],
  isInifiniteChat: false,
  extraConfig: {},
  onChangeExtraConfig: () => {
    console.warn('onChangeExtraConfig function is not initialized');
  },
  setFiles: () => {
    console.warn('setFiles function is not initialized');
  },
  resetExtraConfig: () => {
    console.warn('resetExtraConfig function is not initialized');
  },
  openDialogSwitchBot: false,
  handleOpenDialogSwitchBot: () => {
    console.warn('handleOpenDialogSwitchBot function is not initialized');
  },
  openDrawerConfigWhenMobile: '',
  handleOpenDrawerConfigWhenMobile: () => {
    console.warn(
      'handleOpenDrawerConfigWhenMobile function is not initialized'
    );
  },
  botTagSlug: '',
  setBotTagSlug: () => {
    console.warn('setBotTagSlug function is not initialized');
  },
});

const Chat: React.FC<IProps> = ({ todoId }) => {
  React.useEffect(() => {
    Mixpanel.track('Chat page view', {
      todoId,
    });
  }, [todoId]);

  return <ChatContainerLayout todoId={todoId} />;
};

export default React.memo(Chat);
