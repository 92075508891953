import React from 'react';
import { FC, useContext } from 'react';
import STTab from '../../../common/STTab/STTab';
import CustomBox from '../../../common/CustomBox/CustomBox';
import {
  ProviderSkeleton,
  SkeletonContent,
} from '../../../common/Skeleton/Skeleton';
import BotExtraConfig from './BotExtraConfig/BotExtraConfig';
import { ChatContext } from '../../Chat';
import TodoInfo from './TodoInfo/TodoInfo';
import ToDoDescription from '../ToDoDescription/ToDoDescription';
import useGetChatSessionDataSource from '../../hooks/useGetChatSessionDataSource';
import ChatSessionDataSources from './ChatSessionDataSources/ChatSessionDataSources';
import { styled } from '@mui/material/styles';

const StyledCustomBox = styled(CustomBox)(() => ({
  maxHeight: 'calc(100vh - 60px)',
  height: '100%',
  width: '100%',
  overflow: 'auto',
}));
interface IProps {
  isForChat: boolean;
  todoId: number;
}

const TodoDetailsContainer: FC<IProps> = ({ isForChat, todoId }) => {
  const chatContext = useContext(ChatContext);
  const { data, loading, pdfUrl, shouldDisplayDataSource } =
    useGetChatSessionDataSource({
      skip: !isForChat,
    });

  const descriptionLabel = chatContext.isInifiniteChat
    ? 'todo.tab.notes'
    : 'todo.tab.description';
  const tabsData = [];

  if ((data?.getOneTodoChatSession?.defaultBot?.formFields || []).length > 0) {
    tabsData.push({
      component: (
        <BotExtraConfig
          formFields={
            data?.getOneTodoChatSession?.defaultBot?.formFields as any
          }
        />
      ),
      label: 'todo.tab.botUI',
      id: 'todo.tab.botUI',
    });
  }

  if (chatContext.isInifiniteChat) {
    if (shouldDisplayDataSource) {
      <StyledCustomBox
        sx={{
          height: '100vh',
          width: '100%',
        }}
      >
        <ChatSessionDataSources
          dataSources={data}
          todoId={todoId}
          pdfUrl={pdfUrl}
        />
      </StyledCustomBox>;
    }
  }

  // If shouldDisplayDataSource is true, then we display the PDFViewer
  if (shouldDisplayDataSource && isForChat) {
    tabsData.push({
      component: (
        <StyledCustomBox>
          <ChatSessionDataSources
            dataSources={data}
            todoId={todoId}
            pdfUrl={pdfUrl}
          />
        </StyledCustomBox>
      ),
      label: 'todo.tab.documentPreview',
      id: 'todo.tab.documentPreview',
    });
  }

  if (!chatContext.isInifiniteChat) {
    tabsData.push({
      component: (
        <StyledCustomBox>
          <TodoInfo todoId={todoId} isForChat={isForChat} />
        </StyledCustomBox>
      ),

      label: 'todo.tab.info',
      id: 'todo.tab.info',
    });
  }

  // if (isForChat || chatContext.isInifiniteChat) {
  //   tabsData.push({
  //     component: (
  //       <CustomBox>
  //         <Bots todoId={todoId} />
  //       </CustomBox>
  //     ),
  //     label: 'todo.tab.bots',
  //     id: 'todo.tab.bots',
  //   });
  // }

  if (!chatContext.isInifiniteChat) {
    tabsData.push({
      component: (
        <StyledCustomBox>
          <ToDoDescription todoID={todoId} />
        </StyledCustomBox>
      ),
      label: descriptionLabel,
      id: descriptionLabel,
    });
  }

  if(loading) {
    return null
  }

  return <STTab tabs={tabsData} />;
};

export default React.memo(TodoDetailsContainer);
