import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';


const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  margin: '12px 0px 12px 0px',
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2)
  },
}));

export default function DividerChoice() {
   return (
    <Root>
      <Divider>Or</Divider>
    </Root>
  );
}