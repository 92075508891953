import React, { useState, useRef } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import {
  ArrowBack,
  ArrowForward,
  Refresh,
  ArrowDownward,
  ArrowUpward,
  ZoomIn,
  ZoomOut,
  OpenInFull,
  CopyAllOutlined,
} from '@mui/icons-material';
import CustomDialog from '../CustomDialog/CustomDialog';
import MermaidDiagram, {
  ControlsWrapper,
  DiagramControlIcon,
} from './MermaidDiagram'; // Adjust the import path as needed
import CustomBox from '../CustomBox/CustomBox';
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomButton from '../CustomButton/CustomButton';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';

interface MermaidProps {
  chart: string;
}

const Mermaid: React.FC<MermaidProps> = ({ chart }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { displayMessage } = useDisplayMessage();

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };
  const handleCopy = () => {
    displayMessage({ message: 'code.copied', type: 'success' });
  };

  return (
    <>
      <CustomBox position="relative">
        <MermaidDiagram chart={chart} key="1" />
        <CustomBox
          sx={{
            // Display in corner right top
            position: 'absolute',
            top: 48,
            right: 0,
          }}
        >
          <ControlsWrapper>
            <DiagramControlIcon onClick={toggleDialog}>
              <OpenInFull fontSize="small" />
            </DiagramControlIcon>
            <CopyToClipboard text={chart} onCopy={handleCopy}>
              <DiagramControlIcon onClick={() => {}}>
                <CopyAllOutlined fontSize="small" />
              </DiagramControlIcon>
            </CopyToClipboard>
          </ControlsWrapper>
        </CustomBox>
      </CustomBox>
      {isDialogOpen ? (
        <CustomDialog
          open={isDialogOpen}
          handleClose={toggleDialog}
          //title="Mermaid Diagram"
          maxWidth="lg"
          contentChildren={
            <Box position="relative" sx={{ width: '100%', height: '100%' }}>
              <MermaidDiagram height="100%" chart={chart} key="2" />
            </Box>
          }
        />
      ) : null}
    </>
  );
};

export default React.memo(Mermaid);
