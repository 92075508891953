import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useDisplayMessage from '../../GlobalMessage/useDisplayMessage';
import useGetBotName from '../../../../Hooks/useGetBotName';

const UPDATE_USER_BOT_CONFIG = gql`
  mutation UpdateUserBotConfig(
    $botId: Float!
    $prompt: String
    $temperature: Float
  ) {
    updateUserBotConfig(
      botId: $botId
      prompt: $prompt
      temperature: $temperature
    ) {
      id
      botId
      temperature
      userId
      systemMessage
    }
  }
`;

const GET_USER_BOT_CONFIG = gql`
  query GetUserBotConfig($botId: Float!) {
    getUserBotConfig(botId: $botId) {
      botId
      id
      temperature
      systemMessage
      userId
    }
  }
`;

const useCustomTemperature = ({
  botId,
  onUpdated,
}: {
  botId: number;
  onUpdated: () => void;
}) => {
  const intl = useIntl();
  const { botData } = useGetBotName({ botId });
  const [currentValues, setCurrentValues] = useState<any>({});
  const [updateUserBotConfig, { loading }] = useMutation(
    UPDATE_USER_BOT_CONFIG
  );
  const { displayMessage } = useDisplayMessage();
  const { data } = useQuery(GET_USER_BOT_CONFIG, {
    fetchPolicy: 'network-only',
    variables: {
      botId,
    },
  });

  useEffect(() => {
    setCurrentValues({
      temperature: data?.getUserBotConfig?.temperature || 0,
      prompt: data?.getUserBotConfig?.systemMessage || '',
    });
  }, [data]);

  const botName = botData?.name;

  const isDisableConfirm =
    loading ||
    (currentValues.prompt == data?.getUserBotConfig?.systemMessage &&
      currentValues.temperature == data?.getUserBotConfig?.temperature) ||
    !currentValues.prompt ||
    currentValues.prompt.replace(/ /g, '') === '';

  const onManageBotConfig = () => {
    updateUserBotConfig({
      variables: {
        botId,
        prompt: currentValues.prompt,
        temperature: currentValues.temperature,
      },
      update() {
        onUpdated();
        displayMessage({
          type: 'success',
          message: 'message.manage.config.success',
        });
      },
    });
  };

  const fields = [
    {
      type: 'RANGE',
      formName: 'temperature',
      description: intl.formatMessage({
        id: 'custom.ai.temperature.description',
      }),
      rangeStep: 0.1,
      rangeMax: 1,
      rangeMin: 0,
    },
    {
      type: 'TEXTAREA',
      formName: 'prompt',
      label: intl.formatMessage({
        id: 'custom.ai.system.prompt.title',
      }),
      description: intl.formatMessage({
        id: 'custom.ai.system.prompt.description',
      }),
    },
  ];

  const handleChange = (name: string, value: string) => {
    setCurrentValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return {
    fields,
    botName,
    loading,
    handleChange,
    currentValues,
    isDisableConfirm,
    onManageBotConfig,
  };
};

export default useCustomTemperature;
