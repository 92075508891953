import { FC } from 'react';
import CustomButton, { CustomButtonProps } from '../CustomButton/CustomButton';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../CustomTypography/CustomTypography';

interface IProps extends CustomButtonProps {
  intlId: string;
}

const STButton: FC<IProps> = ({ intlId, ...rest }) => {
  return (
    <CustomButton {...rest}>
      <CustomTypography>
        <FormattedMessage id={intlId} />
      </CustomTypography>
    </CustomButton>
  );
};

export default STButton;
