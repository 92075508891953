import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material';
import CustomUnreadnotification from '../../CustomUnreadnotification/CustomUnreadnotification';
import ContentEditable from 'react-contenteditable';
import CustomTypography from '../../CustomTypography/CustomTypography';
import { SvgIconComponent } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import CheckBox from '../../../MyDay/ListTasks/Task/CheckBox/CheckBox';
import CustomBox from '../../CustomBox/CustomBox';
import ProjectViewsIcon from '../../../Project/ProjectDashboard/ProjectViews/ProjectViewsIcon/ProjectViewsIcon';
import BotAvatar from '../../../Chat/Description/Bots/Bot/BotTitleContent/BotAvatar/BotAvatar';

type IconColor =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type CustomItemBackgroundColorType = {
  hover?: string;
  active?: string;
  default?: string;
};
type IconSize = 'inherit' | 'large' | 'medium' | 'small';
interface Item {
  id: number;
  primaryText: string;
  createdAt?: any;
  viewCode?: string;
  userProfil?: string;
  secondaryText?: string;
  avatarImg?: string; // optional avatarImg property
  unreadNotificationsCount?: number;
  ListItemLeft?: React.ReactNode; // optional LeftItem property
  actions?: Action[]; // optional actions property
  onItemTextClick?: (item: Item) => void; // optional onItemTextClick property
  onItemClick?: (item: Item) => void; // optional onItemClicked property
  href?: string; // optional href property
  onChangeCheckbox?(id: number): void;
  checkValue?: boolean;
}
interface Action {
  disabled?: boolean;
  iconColor?: IconColor;
  iconSize?: IconSize;
  icon: SvgIconComponent;
  tooltipIntlId: string;
  onClick: (item: Item) => void;
}
interface STListItemProps {
  item: Item;
  itemTextStyle?: React.CSSProperties;
  selectedItemId?: number;
  isContentEditableDisabled?: boolean;
  isPrimaryTextFocused?: boolean;
  isForView?: boolean;
  isPrimaryTextDisplayInMultiline?: boolean;
  onPrimaryTextEdit?(e: any): void;
  isItemTextCheckedToDelete?: boolean;
  isPrimaryEditable?: boolean;
  isViewChecBox?: boolean;
  itemWidth?: number | string;
  customItemBackgroundColor?: CustomItemBackgroundColorType;
  displayAction?: 'ONSELECT' | 'ALWAYS';
}
const STListItem = ({
  item,
  itemTextStyle,
  isContentEditableDisabled,
  isItemTextCheckedToDelete,
  onPrimaryTextEdit,
  isPrimaryEditable,
  selectedItemId,
  isPrimaryTextFocused,
  displayAction,
  isViewChecBox,
  isForView,
  itemWidth,
  customItemBackgroundColor,
  isPrimaryTextDisplayInMultiline,
}: STListItemProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const style = {
    lineHeight: theme.spacing(2),
    border: 'none',
    outline: 'none',
    maxWidth: '85%',
    overflow: 'hidden',
    whiteSpace: isPrimaryTextDisplayInMultiline ? 'normal' : 'nowrap',
    transition: 'all 0.2s',
    cursor: 'pointer',
    ...(isPrimaryTextFocused && {
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      maxWidth: '85%',
      borderRadius: theme.spacing(0.5),
      whiteSpace: isPrimaryTextDisplayInMultiline ? 'normal' : 'nowrap',
      padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    }),
  };
  return (
    <ListItem
      key={item.id}
      sx={{
        '&:hover': {
          backgroundColor: (theme) =>
            customItemBackgroundColor?.hover ??
            theme.palette.customColor.hoverColor,
        },
        ...(selectedItemId === item.id && {
          backgroundColor: (theme) =>
            customItemBackgroundColor?.active ??
            theme.palette.customColor.scrollColor,
        }),
        ...(customItemBackgroundColor?.default &&
          selectedItemId !== item.id && {
            backgroundColor: customItemBackgroundColor?.default,
          }),
        cursor: 'pointer',
        minHeight: 40,
        borderRadius: 2,
        width: itemWidth ? itemWidth : null,
        paddingY: 0,
        marginTop: '6px',
      }}
      onClick={() => {
        if (item.onItemClick) {
          item.onItemClick(item);
        }
      }}
    >
      {item.unreadNotificationsCount && item?.unreadNotificationsCount > 0 ? (
        <CustomUnreadnotification sx={{ position: 'absolute', left: 2 }} />
      ) : null}
      {isViewChecBox && (
        <CustomBox ml={-2}>
          <CheckBox
            onChange={() => {
              if (item.onChangeCheckbox) {
                item.onChangeCheckbox(item.id);
              }
            }}
            Check={item.checkValue as boolean}
          />
        </CustomBox>
      )}
      {item.avatarImg ? (
        <ListItemAvatar>
          <Avatar alt={item.primaryText} src={item.avatarImg} />
        </ListItemAvatar>
      ) : item.ListItemLeft ? (
        item.ListItemLeft
      ) : null}
      {isForView && (
        <CustomBox sx={{ mt: theme.spacing(0.5), mr: theme.spacing(0.5) }}>
          <ProjectViewsIcon code={item.viewCode} />
        </CustomBox>
      )}

      <ListItemText
        style={{
          marginTop: theme.spacing(-2),
          marginBottom: theme.spacing(-2),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...itemTextStyle,
        }}
        primary={
          isPrimaryEditable ? (
            <ContentEditable
              key={item.id}
              html={item.primaryText}
              disabled={item.id === selectedItemId && isContentEditableDisabled}
              onChange={(e: any) => onPrimaryTextEdit?.(e)}
              onKeyDown={(event) => {
                // Prevent line breaks when pressing Enter.
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              style={
                item.id === selectedItemId
                  ? style
                  : {
                      whiteSpace: isPrimaryTextDisplayInMultiline
                        ? 'normal'
                        : 'nowrap',
                      border: 'none',
                      outline: 'none',
                    }
              }
              tagName="p"
              inputMode="text"
              aria-multiline={false}
            />
          ) : (
            <CustomTypography addTooltip tooltipTitle={item.primaryText} noWrap>
              {item.primaryText}
            </CustomTypography>
          )
        }
        secondary={
          item.secondaryText ? (
            <CustomTypography noWrap>{item.secondaryText}</CustomTypography>
          ) : null
        }
        onClick={() => {
          if (item.onItemTextClick) {
            item.onItemTextClick(item);
          }
        }}
      />
      {displayAction === 'ONSELECT' &&
      selectedItemId === item.id &&
      isContentEditableDisabled &&
      !isItemTextCheckedToDelete ? (
        <ListItemSecondaryAction>
          {item?.actions?.map((item: any) =>
            item?.actions.map((action: any, index: number) => (
              <Tooltip
                key={`action-${index}`}
                arrow
                title={intl.formatMessage({ id: action.tooltipIntlId })}
              >
                <action.icon
                  sx={{
                    ml: theme.spacing(1),
                    mt: theme.spacing(0.5),
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    action.onClick();
                  }}
                  color={action.iconColor || 'action'}
                  fontSize={action.iconSize || 'small'}
                />
              </Tooltip>
            ))
          )}
        </ListItemSecondaryAction>
      ) : displayAction === 'ONSELECT' &&
        selectedItemId === item.id &&
        (!isContentEditableDisabled || isItemTextCheckedToDelete) ? (
        <ListItemSecondaryAction>
          {item?.actions?.map((item: any) =>
            item.confirmationActions?.map((action: any, index: number) => (
              <Tooltip
                key={`action-${index}`}
                arrow
                title={intl.formatMessage({ id: action.tooltipIntlId })}
              >
                <action.icon
                  sx={{
                    ml: theme.spacing(1),
                    mt: theme.spacing(0.5),
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    action.onClick();
                  }}
                  color={action.iconColor || 'action'}
                  fontSize={action.iconSize || 'small'}
                />
              </Tooltip>
            ))
          )}
        </ListItemSecondaryAction>
      ) : (
        displayAction === 'ALWAYS' && (
          <ListItemSecondaryAction>
            {item?.actions?.map((action, index) => (
              <Tooltip
                key={`action-${index}`}
                arrow
                title={intl.formatMessage({ id: action.tooltipIntlId })}
              >
                <IconButton
                  disabled={Boolean(action.disabled)}
                  aria-label={action.tooltipIntlId}
                  onClick={() => {
                    action.onClick(item);
                  }}
                >
                  <action.icon
                    color={action.iconColor || 'action'}
                    fontSize={action.iconSize || 'small'}
                  />
                </IconButton>
              </Tooltip>
            ))}
          </ListItemSecondaryAction>
        )
      )}
    </ListItem>
  );
};
export default STListItem;
