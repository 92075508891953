import React, { useMemo } from 'react';
import { Badge, Divider, useTheme } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import ListTasks from '../../../../MyDay/ListTasks/ListTasks';
import {
  Bot as BotType,
  ChatDataSource,
  Todo,
  Project,
} from '../../../../../gql/graphql';
import SearchBotList from './SearchBotList/SearchBotList';
import SearchDatasourcesList from './SearchDatasourcesList/SearchDatasourcesList';
import STChip from '../../../../common/STChip/STChip';
import { sectionSlug } from '../useSearch';
import { useIntl } from 'react-intl';
import ProjectCard from '../../../../ProjectListPage/ProjectListView/ProjectCard/ProjectCard';
import EmptyResult from './EmptyResult/EmptyResult';

interface GlobalSearchContentProps {
  todos: Todo[];
  bots: BotType[];
  projects: Project[];
  dataSource: ChatDataSource[];
  selectedSection: sectionSlug;
  handleSelectSection(section: sectionSlug): void;
}
interface SectionContentProps<T> {
  selectedSection: boolean;
  sectionData: T[];
  renderContent: (data: T[]) => JSX.Element;
}
function SectionContent<T>({
  selectedSection,
  sectionData,
  renderContent,
}: SectionContentProps<T>) {
  return selectedSection ? (
    sectionData?.length > 0 ? (
      renderContent(sectionData)
    ) : (
      <CustomBox height={'60vh'}>
        <EmptyResult />
      </CustomBox>
    )
  ) : null;
}

const GlobalSearchContent = ({
  todos,
  bots,
  projects,
  dataSource,
  selectedSection,
  handleSelectSection,
}: GlobalSearchContentProps) => {
  const theme = useTheme();
  const intl = useIntl();
  
  const sections = useMemo(() => {
    const unsortedSections = [
      {
        title: intl.formatMessage({ id: 'search.match.bots' }),
        key: sectionSlug.bots,
        count: bots?.length || 0,
      },
      {
        title: intl.formatMessage({ id: 'search.match.todos' }),
        key: sectionSlug.todos,
        count: todos?.length || 0,
      },
      {
        title: intl.formatMessage({ id: 'search.match.project' }),
        key: sectionSlug.project,
        count: projects?.length || 0,
      },
      {
        title: intl.formatMessage({ id: 'search.match.data.source' }),
        key: sectionSlug.dataSources,
        count: dataSource?.length || 0,
      },
    ];
  
    return unsortedSections.sort((a, b) => b.count - a.count);
  }, [todos, bots, dataSource, projects]);

  const handleChipClick = (section: sectionSlug) => () => {
    handleSelectSection(section);
  };

  if (
    todos.length < 1 &&
    bots.length < 1 &&
    projects.length < 1 &&
    dataSource.length < 1
  ) {
    return <EmptyResult />;
  }
  return (
    <CustomBox>
      <CustomBox
        sx={{
          display: 'flex',
          minWidth: '100%',
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
        }}
      >
        {sections?.map(({ key, count, title }) => (
          <Badge key={key} badgeContent={count} color="secondary">
            <STChip
              sx={{
                borderRadius: theme.spacing(1),
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                background:
                  selectedSection === key
                    ? theme.palette.customColor.scrollColor
                    : 'transparent',
              }}
              onClick={handleChipClick(key)}
              label={title}
              variant={'outlined'}
            />
          </Badge>
        ))}
      </CustomBox>
      <CustomBox my={theme.spacing(1)}>
        <SectionContent
          selectedSection={selectedSection === sectionSlug.todos}
          sectionData={todos}
          renderContent={(data) => (
            <ListTasks tasks={data} taskRoutePrefix="" />
          )}
        />
        <SectionContent
          selectedSection={selectedSection === sectionSlug.bots}
          sectionData={bots}
          renderContent={(data) => <SearchBotList bots={data} />}
        />

        <SectionContent
          selectedSection={selectedSection === sectionSlug.dataSources}
          sectionData={dataSource}
          renderContent={(data) => (
            <CustomBox>
              <Divider />
              <SearchDatasourcesList dataSource={data} />
            </CustomBox>
          )}
        />
        <SectionContent
          selectedSection={selectedSection === sectionSlug.project}
          sectionData={projects}
          renderContent={(data) => (
            <CustomBox>
              {data?.map((project: Project) => (
                <CustomBox key={project.id} mb={theme.spacing(1)}>
                  <ProjectCard
                    project={project}
                    isForSearch={true}
                  />
                </CustomBox>
              ))}
            </CustomBox>
          )}
        />
      </CustomBox>
    </CustomBox>
  );
};

export default React.memo(GlobalSearchContent);
