import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Chip, Popover, styled } from '@mui/material';

import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomSearchInput from '../../../common/CustomSearchInput/CustomSearchInput';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import STDivider from '../../../common/STDivider/STDivider';

import { SyncStatus } from '../../../../gql/graphql';

import FilterIcon from './Icons/FilterIcon';
import DatasourceCategoryMenulist from './Menus/DatasourceCategoryMenulist';
import DatasourceStatusMenulist from './Menus/DatasourceStatusMenulist';

interface IProps {
  onChangeInputSearch?: (value: string) => void;
  onChangeCategory?: (categoryName: string) => void;
  handleChangeStatus?: (status: SyncStatus) => void;
  canUseFilter?: boolean;
  dataSourcesSyncStatus?: SyncStatus[];
  dataSourcesCategories?: string[];
}

const DatasourceSearchBarWithFilter: React.FC<IProps> = ({
  onChangeInputSearch,
  onChangeCategory,
  handleChangeStatus,
  canUseFilter = true,
  dataSourcesSyncStatus,
  dataSourcesCategories,
}: IProps) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [queryString, setQueryString] = useState('');
  const [latestStatus, setLatestStatus] = useState<SyncStatus>();
  const [latestCategory, setLatestCategory] = useState<string>();

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(e.target.value);
    onChangeInputSearch?.(e.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const canFilterCategory = (dataSourcesCategories?.length ?? 0) > 1;
  const canFilterStatus = (dataSourcesSyncStatus?.length ?? 0) > 1;

  const showFilter = canFilterCategory || canFilterStatus;

  return (
    <CustomBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 1,
      }}
    >
      <CustomSearchInput
        searchValue={queryString}
        onChangeInputSearch={handleChangeInputSearch}
        searchInputPlaceholder={intl.formatMessage({
          id: 'table.datasource.searchbar.placeholder',
        })}
      />
      {canUseFilter && showFilter && (
        <StyledChip
          label={<FormattedMessage id="common.filter" />}
          variant="outlined"
          onClick={handleClick}
          icon={<FilterIcon />}
        />
      )}
      <StyledPopover
        id={id}
        open={open}
        elevation={2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal
      >
        {canFilterCategory && (
          <StyledCustomBox>
            <CustomTypography>
              <FormattedMessage id="datasource.filter.category" />:
            </CustomTypography>
            <DatasourceCategoryMenulist
              handleChangeCategory={(category) => {
                onChangeCategory?.(category);
                setLatestCategory(category);
              }}
              categories={dataSourcesCategories}
              latestCategoryFilter={latestCategory}
            />
          </StyledCustomBox>
        )}

        {canFilterCategory && canFilterStatus && <StyledDivider />}

        {canFilterStatus && (
          <StyledCustomBox>
            <CustomTypography>
              <FormattedMessage id="datasource.filter.indexation" />:
            </CustomTypography>
            <DatasourceStatusMenulist
              handleChangeStatus={(status) => {
                handleChangeStatus?.(status);
                setLatestStatus(status);
              }}
              dataSourcesSyncStatus={dataSourcesSyncStatus}
              latestFilter={latestStatus}
            />
          </StyledCustomBox>
        )}
      </StyledPopover>
    </CustomBox>
  );
};

export default DatasourceSearchBarWithFilter;

const StyledChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0px 4px 0px 4px',
  height: '36px',
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main,
  fontSize: '13px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  '& .MuiChip-label': {
    paddingLeft: '2px',
    paddingRight: '1px',
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiPaper-root': {
    width: 300,
    padding: theme.spacing(1),
    rowGap: theme.spacing(1),
    background:
      theme.palette.mode !== 'dark' ? '#fff' : theme.palette.primary.main,
  },
}));

const StyledCustomBox = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  columnGap: theme.spacing(1),
  width: '100%',
  '&:hover': {
    opacity: 1,
    cursor: 'default',
    color: theme.palette.text.primary,
  },
}));

const StyledDivider = styled(STDivider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
