import * as React from 'react';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import ListItems from '../ListItems/ListItems';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import CancelIcon from '../../../../common/CustomDialog/CancelIcon/CancelIcon';
import Box from '@mui/system/Box/Box';
import useWindowDimensions from '../../../../../Hooks/useWindowDimensions';
interface props {
  onClose?(): void;
}
const DrawerSetting = ({ onClose }: props) => {
  const [open, setOpen] = React.useState(false);
  const { width } = useWindowDimensions();
  React.useEffect(() => {
    if (Number(width) < 600) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [width]);
  return (
    <List
      component="nav"
      style={{
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
    >
      <ListItem sx={{ mt: 2 }}>
        <CustomTypography variant="body1" color="black">
          <FormattedMessage id="user.profil.settings" defaultMessage={'Settings'} />
        </CustomTypography>
        {open ? (
          <Box ml={'auto'}>
            <CancelIcon onClose={() => onClose?.()} />
          </Box>
        ) : null}
      </ListItem>
      <ListItem >
        <ListItems onClose={() => onClose?.()} />
      </ListItem>
    </List>
  );
};

export default DrawerSetting;
