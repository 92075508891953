import { useParams } from 'react-router-dom';
import { graphql } from '../../../gql';
import { useMutation, ApolloCache } from '@apollo/client';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import useConfirmDialog from '../../../Hooks/useConfirmDialog';
import { useIntl } from 'react-intl';
import {
  DeleteChatSessionMutation, Exact
} from '../../../gql/graphql';

const DELETE_CHAT_SESSION = graphql(`
  mutation DeleteChatSession($id: Float!) {
    deleteTodoChatSession(id: $id) {
      id
      title
    }
  }
`);

const useDeleteSession = () => {
  const params = useParams<{ sessionId?: string }>();
  const intl = useIntl();
  const { displayConfirmDialog } = useConfirmDialog();

  const [deleteSession] = useMutation<
    DeleteChatSessionMutation,
    Exact<{ id: number }>
  >(DELETE_CHAT_SESSION, {
    update(cache: ApolloCache<any>, { data }) {
      if (!data?.deleteTodoChatSession) {
        return;
      }

      Mixpanel.track('Delete chat session', {});

      cache.modify({
        fields: {
          getTodoChatSessions(existingData: any = {}, { readField }) {
            const updatedSessions = existingData.sessions.filter(
              (sessionRef: any) =>
                readField('id', sessionRef) !== data.deleteTodoChatSession.id
            );

            return {
              ...existingData,
              sessions: updatedSessions,
              totalCount: existingData.totalCount - 1,
            };
          },
        },
      });
    },
  });

  const handleDeleteSession = () => {
    displayConfirmDialog({
      message: intl.formatMessage({ id: 'deleteSession.confirm' }),
      onConfirm: () => {
        deleteSession({ variables: { id: +(params.sessionId || 0) } });
      },
    });
  };

  return { handleDeleteSession };
};

export default useDeleteSession;
