import CustomBox from '../../../../../../common/CustomBox/CustomBox';
import CustomButton from '../../../../../../common/CustomButton/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ListPrompts from '../ListPrompts';

interface props {
  handleClickNewCustomPrompt(): void;
  handleSelectedPrompt(id: number): void;
  prompts: any[];
  defaultSelectedId: number;
  loading: boolean;
}
const CustomPromtsStep1 = ({
  handleClickNewCustomPrompt,
  handleSelectedPrompt,
  defaultSelectedId,
  prompts,
  loading,
}: props) => {
  return (
    <>
      {!loading && (
        <CustomBox my={1} pr={1} display="flex" mb={1}>
          <CustomBox flexGrow={1} />
          <CustomButton
            intlId="button.create.custom.prompt"
            variant="contained"
            startIcon={<AddIcon />}
            color='inherit'
            onClick={handleClickNewCustomPrompt}
            sx={{ width: '100%' }}
          />
        </CustomBox>
      )}
      <ListPrompts
        handleClick={handleSelectedPrompt}
        isForCustomPrompt={true}
        prompts={prompts}
        defaultSelectedId={defaultSelectedId}
        loading={loading}
      />
    </>
  );
};
export default CustomPromtsStep1;
