import React, { useEffect, useRef, useState } from 'react';
import mermaid from 'mermaid';
import CustomBox from '../CustomBox/CustomBox';
import { Box, IconButton, styled } from '@mui/material';

interface MermaidDiagramProps {
  chart: string;
  height?: string;
}
const useMermaidDiagram = ({ chart }: MermaidDiagramProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [key, setKey] = React.useState(0); // State to manage re-render of the diagram
  const [scale, setScale] = useState(1); // State to manage zoom level
  const [translateX, setTranslateX] = useState<number>(0);
  const [translateY, setTranslateY] = useState<number>(0);
  // Loading the diagram
  const [loading, setLoading] = useState(false);

  const renderDiagram = async () => {
    if (ref.current) {
      const uniqueId = `mermaid-svg-${key}-${Math.random()
        .toString(36)
        .substr(2, 9)}`;

      try {
        setLoading(true);
        const { svg } = await mermaid.render(uniqueId, chart);
        ref.current.innerHTML = svg;

        // Apply zoom scale
        const svgElement = ref.current.querySelector('svg');
        if (svgElement) {
          svgElement.style.transform = `scale(${scale})`;
          svgElement.style.transformOrigin = 'top left'; // Adjust origin if necessary
          svgElement.style.transformOrigin = 'top left';
          svgElement.style.margin = 'auto'; // Ensure SVG is centered
          svgElement.style.display = 'block'; // Center block element within flex
          // Move the diagram based on the translateX and translateY values
          svgElement.style.transform += ` translate(${translateX}px, ${translateY}px
        )`;
        }
        // Loading is complete
        setLoading(false);
      } catch (error) {
        //Consider that the diagram is not complete
        setLoading(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    renderDiagram();
  }, [chart, key, scale, translateX, translateY]);
  const handleRefresh = () => {
    setKey((prevKey) => prevKey + 1); // Trigger re-render of the diagram
    setScale(1); // Reset scale
    setTranslateX(0); // Reset translateX
    setTranslateY(0); // Reset translateY
  };
  const handleZoom = (factor: number) => {
    setScale((prevScale) => prevScale * factor); // Update scale based on zoom factor
  };
  const handleMoveLeft = () => setTranslateX((prev) => prev - 20);
  const handleMoveRight = () => setTranslateX((prev) => prev + 20);
  const handleMoveUp = () => setTranslateY((prev) => prev - 20);
  const handleMoveDown = () => setTranslateY((prev) => prev + 20);
  return {
    ref,
    handleRefresh,
    handleZoom,
    handleMoveLeft,
    handleMoveRight,
    handleMoveUp,
    handleMoveDown,
    loading,
  };
};

export default useMermaidDiagram;
