import { FC } from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import UserAvatar from '../../../common/UserAvatar/UserAvatar';
import { FormattedMessage, useIntl } from 'react-intl';
import { keyframes, styled } from '@mui/material';

const loaderAnimation = keyframes`
  50%  {box-shadow: 10px 0 0 1.5px, 20px 0 0 3.5px, 30px 0 0 1.5px;}
  100% {box-shadow: 10px 0 0 0, 20px 0 0 1.5px, 30px 0 0 3.5px;}
`;

const TypingIndicator = styled(CustomBox)`
  width: 3px;
  height: 3px;
  aspect-ratio: 1;
  margin-top: 4px;
  border-radius: 50%;
  box-shadow: 10px 0 0 3.5px, 20px 0 0 1.5px, 30px 0 0 0;
  animation: ${loaderAnimation} 1s infinite alternate linear;
`;

const UserTypingStyledBox = styled(CustomBox)(({ theme }) => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

interface IProps {
  theUser: any;
}

const SingleBotRealtimeUpdate: FC<IProps> = ({ theUser }) => {
  const intl = useIntl();
  const isFrench = intl.locale.startsWith('fr');
  const inProgressText = isFrench
    ? theUser?.bot?.inProgressTextFR || theUser?.bot?.baseBot?.inProgressTextFR
    : theUser?.bot?.inProgressText || theUser?.bot?.baseBot?.inProgressText;

  return (
    <CustomBox
      sx={{
        background: (theme) => theme.palette.customColor.messageBackground,
        borderRadius: (theme) => theme.spacing(1),
        padding: (theme) => theme.spacing(1),
      }}
    >
      <UserTypingStyledBox>
        <CustomBox>
          <UserAvatar
            userProfil={theUser?.profilePicture || '/default-bot.png'}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography variant="body2" fontWeight="bold">
            @{theUser?.firstName}
          </CustomTypography>
        </CustomBox>
        <CustomBox>
          {theUser && inProgressText ? (
            <CustomTypography variant="body2">
              {inProgressText}
            </CustomTypography>
          ) : (
            theUser && (
              <CustomTypography variant="body2">
                <FormattedMessage
                  id="chat.userIsTyping"
                  defaultMessage="is responding"
                />
              </CustomTypography>
            )
          )}
        </CustomBox>
        <TypingIndicator />
      </UserTypingStyledBox>
    </CustomBox>
  );
};

export default SingleBotRealtimeUpdate;
