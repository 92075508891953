/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageItem from './ImageItem/ImageItem';
import FileItem from './FileItem/FileItem';
import VideoItem from './VideoItem/VideoItem';
import AudioItem from './AudioItem/AudioItem';
import CustomBox from '../../../common/CustomBox/CustomBox';

export interface FileProps {
  size: any;
  url: string;
  type: string;
  title: string;
  rows?: number;
  cols?: number;
}

interface props {
  files?: FileProps[];
}

export const customRender = (item: FileProps, index: number) => {
  const type =
    item.type.indexOf('/') > -1 ? item.type.split('/')[0] : item.type;
  const estensionFile = item.title.slice(
    ((item.title.lastIndexOf('.') - 1) >>> 0) + 2
  );

  switch (type) {
    case 'image':
      return <ImageItem item={item} key={index} />;
      break;
    case 'video':
      return <VideoItem item={item} key={index} />;
      break;
    case 'audio':
      return (
        <>
          <FileItem item={item} extension={estensionFile} type={undefined} />
          <AudioItem item={item} key={index} />
        </>
      );

      break;

    default:
      return (
        <FileItem item={item} extension={estensionFile} type={undefined} />
      );
  }
};

export default function Attachments({ files }: props) {
  const regroupedFiles = {
    images: [] as any,
    videos: [] as any,
    others: [] as any,
  };
  files?.map((file: any) => {
    const type =
      file.type.indexOf('/') > -1 ? file.type.split('/')[0] : file.type;
    if (type === 'image') {
      regroupedFiles.images.push(file);
    } else if (type === 'video') {
      regroupedFiles.videos.push(file);
    } else {
      regroupedFiles.others.push(file);
    }
  });

  return (
    <CustomBox>
      {regroupedFiles.videos.length ? (
        <ImageList
          sx={{ width: '100%', height: 'auto' }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {regroupedFiles.videos?.map((item: FileProps, index: number) => (
            <>{customRender(item, index)}</>
          ))}
        </ImageList>
      ) : null}

      {regroupedFiles.images.length ? (
        <CustomBox>
          <ImageList sx={{ width: '100%', height: 'auto' }} gap={5} cols={4}>
            {regroupedFiles.images?.map((item: FileProps, index: number) => (
              <>{customRender(item, index)}</>
            ))}
          </ImageList>
        </CustomBox>
      ) : null}
      {regroupedFiles.others.length ? (
        <CustomBox
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            height: 'auto',
          }}
        >
          {regroupedFiles.others?.map((item: FileProps, index: number) => (
            <>{customRender(item, index)}</>
          ))}
        </CustomBox>
      ) : null}
    </CustomBox>
  );
}
