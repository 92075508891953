import { useContext } from 'react';
import { ChatContext } from '../Chat';
import { ContentType, FormField } from '../../../gql/graphql';
import { useIntl } from 'react-intl';
import useDropFiles from './useDropFiles';
import useChatForm from '../BodyChat/FormAddMessage/useChatForm';
import { Mixpanel } from '../../../mixpanel/mixpanel';

export const useContextFormChat = () => {
  const intl = useIntl();

  const {
    isInifiniteChat,
    extraConfig,
    files,
    openDialogSwitchBot,
    openDrawerConfigWhenMobile,
    onChangeExtraConfig,
    resetExtraConfig,
    setFiles,
    handleOpenDialogSwitchBot,
    handleOpenDrawerConfigWhenMobile,
    botTagSlug,
    setBotTagSlug,
  } = useContext(ChatContext);

  const { defaultBot } = useChatForm();

  const verifyRequiredExtraConfig = (formFields: FormField[]): boolean => {
    const hasError: any = {};
    formFields.forEach((formField: FormField) => {
      if (
        formField.required &&
        (extraConfig[formField.formName] === null ||
          `${extraConfig[formField.formName]}`.trim() === '')
      ) {
        hasError[formField.formName] = intl.formatMessage({
          id: 'common.required',
        });
      }
    });

    onChangeExtraConfig('errors', hasError as any);

    return Object.keys(hasError).length === 0;
  };

  const handleChangeExtraConfig = (name: string, value: any) => {
    onChangeExtraConfig(name, value);
    // remove error
    const ErrorExtraConfig = extraConfig?.errors || {};
    delete ErrorExtraConfig[name];
    onChangeExtraConfig('errors', ErrorExtraConfig);
  };

  const { onDrop: onUploadFile } = useDropFiles({
    contentTypes: defaultBot?.supportedContentTypes as ContentType[],
    setFiles,
  });

  const handleToogleDialogSwitchBot = () => {
    Mixpanel.track('Form Chat - Switch Bot Clicked', {});
    handleOpenDialogSwitchBot();
    if (openDialogSwitchBot) {
      setBotTagSlug('');
    }
  };

  return {
    handleChangeExtraConfig,
    verifyRequiredExtraConfig,
    resetExtraConfig,
    extraConfig,
    isInifiniteChat,
    files,
    setFiles,
    onUploadFile,
    openDialogSwitchBot,
    handleToogleDialogSwitchBot,
    openDrawerConfigWhenMobile,
    handleOpenDrawerConfigWhenMobile,
    botTagSlug,
    setBotTagSlug,
  };
};
