import { useTheme } from '@mui/material';
import * as React from 'react';

const PapperClipAttache = () => {
    const theme = useTheme()
    const color = theme.palette.mode === 'dark' ? "white" : "#34324A"
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_121_658)">
                    <path d="M16.68 9.32001L10.293 15.707C10.1055 15.8946 9.8512 16 9.586 16H8C7.73478 16 7.48043 15.8946 7.29289 15.7071C7.10536 15.5196 7 15.2652 7 15V13.414C7.00009 13.1485 7.1042 12.8936 7.29 12.704L14.32 5.51401C14.6792 5.14661 15.1077 4.85413 15.5807 4.6535C16.0536 4.45287 16.5617 4.34808 17.0755 4.3452C17.5893 4.34231 18.0986 4.4414 18.5738 4.63671C19.049 4.83201 19.4807 5.11967 19.844 5.48301L19.916 5.55501C20.287 5.92307 20.5812 6.36117 20.7814 6.84387C20.9817 7.32658 21.0841 7.84426 21.0826 8.36685C21.0811 8.88945 20.9758 9.40654 20.7728 9.88809C20.5698 10.3696 20.2731 10.8061 19.9 11.172C17.542 13.481 14.312 16.695 12.462 18.541C11.5245 19.4756 10.2547 20.0003 8.931 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7957 3 17V14.071C3.00002 12.7452 3.52662 11.4736 4.464 10.536L10.68 4.32001" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_121_658">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}

export default PapperClipAttache 