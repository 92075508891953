export const getFileExtension = (url: string): string => {
  // Create a new URL object

  try {
    const urlObj = new URL(url);

    // Get the pathname from the URL
    const pathname = urlObj.pathname;

    // Extract the file extension
    const extension = pathname.split('.').pop();

    // If the last part is the same as the pathname, it means there is no extension
    return `${extension}`;
  } catch (error) {
    return '';
  }
};
