import { useCallback, useMemo, useState } from 'react';
import useGetSubscriptionPlans from '../../../../Hooks/useGetSubsriptionPlans';
import { filter } from 'lodash';
import useGetSubscriptionContext from '../../../Context/SubscriptionContext/useGetSubscriptionContext';

const usePricingPlansContent = () => {
  const {
    isFreePlan,
    planCode: currentPlanCode,
    currentUserSubscription,
  } = useGetSubscriptionContext();
  const [isAnnual, setIsAnnual] = useState(
    currentUserSubscription?.isYearly || isFreePlan
  );
  const { data } = useGetSubscriptionPlans();

  const filteredPlans = useMemo(() => {
    return filter(data?.getSubscriptionPlans, { isYearly: isAnnual });
  }, [data, isAnnual]);
  const handleChange = useCallback(
    (_event: React.MouseEvent<HTMLElement>, annual: boolean) => {
      setIsAnnual(annual);
    },
    []
  );
  const TOGGLE_DATA = [
    { value: false, intlId: 'toggle.monthly' },
    { value: true, intlId: 'toggle.annual' },
  ];
  return {
    isAnnual,
    filteredPlans,
    handleChange,
    TOGGLE_DATA,
    currentPlanCode,
  };
};
export default usePricingPlansContent;
