import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';

export default function CheckedItem() {
    return (
        <ListItemIcon>
            <CheckIcon sx={{ fontSize: 18, ml: 1 }} />
        </ListItemIcon>
    );
}