import { LinkProps } from '@mui/material';
import { FC } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

interface IProps extends LinkProps {
  to: string;
}

const STLink: FC<IProps> = ({ children, to, ...rest }) => {
  return (
    <Link
      underline="hover"
      to={to}
      color="inherit"
      component={RouterLink}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default STLink;
