import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql/gql';
import useGetMe from '../../../Hooks/useGetMe';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import useGetPurchaseCreditUrl from '../../../Hooks/useGetPurchaseCreditUrl';

const GET_SWIFTASK_TOKEN_PRICES = graphql(`
  query GetSwiftaskTokenPrices {
    swiftaskTokenPrices {
      id
      price
      description
      paymentLink
      description
      buttonText
      quantity
      btnColor
      percentageGain
    }
  }
`);

const useSwiftaskTokenPrices = () => {
  const { data, error, loading } = useQuery(GET_SWIFTASK_TOKEN_PRICES, {});
  const { getPurchaseCreditUrl } = useGetPurchaseCreditUrl();
  const { email } = useGetMe();
  const onClickPurchase = (id: number) => {
    Mixpanel.track('Click on purchase credit button', {
      email,
      id,
    });
    getPurchaseCreditUrl({
      variables: {
        swiftaskTokenPriceId: id,
      },
    });
  };

  return {
    data,
    error,
    swiftaskTokenPrices: data?.swiftaskTokenPrices || [],
    loading,
    currentUserEmail: email,
    onClickPurchase,
  };
};

export default useSwiftaskTokenPrices;
