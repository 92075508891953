import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, useTheme } from '@mui/material';
import * as React from 'react';
import { StyledTextField } from '../../Auth/AccountForm/EmailInput/EmailInput';
export interface propsPasswordInput {
  password: string;
  name: string;
  placeholder: string | undefined;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  required?: boolean;
}
const PasswordIconContainer = styled.div`
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
const PasswordInput = ({
  onChange,
  placeholder,
  password,
  name,
  required,
}: propsPasswordInput) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const togglePasswordVisibility = () => {
    setShowPassword((prevState: boolean) => !prevState);
  };

  const color =
    theme.palette.mode === 'dark'
      ? theme.palette.primary.main
      : theme.palette.grey[100];
  return (
    <StyledTextField
      margin="normal"
      required={required}
      type={showPassword ? 'text' : 'password'}
      value={password}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PasswordIconContainer onClick={togglePasswordVisibility}>
              {showPassword ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </PasswordIconContainer>
          </InputAdornment>
        ),
      }}
      fullWidth
      onChange={(event: any) => onChange(event)}
      color="secondary"
      id="password"
      placeholder={placeholder}
      name={name}
      autoComplete="password"
      inputProps={{
        'data-testid': 'password-input',
        style: {
          backgroundColor: color,
          color: theme.palette.text.primary,
        },
      }}
    />
  );
};

export default PasswordInput;
