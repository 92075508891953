import * as React from 'react';

interface DataSourceIconProps {
  color: string;
}

const DataSourceIcon: React.FC<DataSourceIconProps> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 -4 50 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Database Icon"
      role="img"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} stroke={color} strokeWidth="2">
          <path
            d="M25.8,13.5 C23.2,13.5 0.4,13.3 0.4,7 C0.4,0.7 23.2,0.5 25.8,0.5 C28.4,0.5 51.2,0.7 51.2,7 C51.2,13.3 28.4,13.5 25.8,13.5 Z M3.5,7 C4.7,8.3 12.7,10.5 25.8,10.5 C38.9,10.5 47,8.3 48.1,7 C46.9,5.7 38.9,3.5 25.8,3.5 C12.7,3.5 4.6,5.6 3.5,7 Z"
          />
          <rect
            x="48.2"
            y="7"
            width="3"
            height="37.4"
          />
          <rect
            x="0.4"
            y="7"
            width="3"
            height="37.4"
          />
          <path
            d="M25.8,26.6 C19.8,26.6 0.4,26.1 0.4,19.3 L3.4,19.3 C3.4,21.1 11.9,23.6 25.8,23.6 C39.7,23.6 48.2,21.1 48.2,19.3 L51.2,19.3 C51.2,26.1 31.7,26.6 25.8,26.6 Z"
          />
          <path
            d="M25.8,39.1 C19.8,39.1 0.4,38.6 0.4,31.8 L3.4,31.8 C3.4,33.6 11.9,36.1 25.8,36.1 C39.7,36.1 48.2,33.6 48.2,31.8 L51.2,31.8 C51.2,38.5 31.7,39.1 25.8,39.1 Z"
          />
          <path
            d="M25.8,51.5 C19.8,51.5 0.4,51 0.4,44.2 L3.4,44.2 C3.4,46 11.9,48.5 25.8,48.5 C39.7,48.5 48.2,46 48.2,44.2 L51.2,44.2 C51.2,50.9 31.8,51.5 25.8,51.5 Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default DataSourceIcon;