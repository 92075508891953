import { styled, keyframes } from '@mui/material/styles';
import CustomBox from '../../common/CustomBox/CustomBox';
import { Box } from '@mui/material';
import config from '../../../config/config';

// Define the keyframes for the animation
const expandWidth = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 0%; // Animate to 100% width
  }
`;

export const StyledChatHistory = styled(CustomBox)<{
  position: 'relative' | 'absolute';
}>(({ theme, position }) => ({
  position: position,
  backgroundColor: theme.palette.customColor.chatSessionBackground,
  zIndex: config.zIndex.NIVEAU_5,
  width: theme.spacing(35), // Set initial width to 0%
  animation: `${expandWidth} 0.25s ease-in-out forwards`, // Add the animation
  height: '100vh',
  top: 0,
  boxSizing: 'border-box',
  boxShadow: theme.shadows[2],
  paddingBottom: theme.spacing(1),
  // This sets the final width after animation
  [theme.breakpoints.down('xs')]: {
    display: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

export const OnClickOutside = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(0.5px)',
  WebkitBackdropFilter: 'blur(0.5px)', // For Safari
  zIndex: config.zIndex.NIVEAU_4,
}));

// Usage in a component
// You can toggle the 'expanded' class based on your component's state
