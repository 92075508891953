import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../../../../gql';
import STInputBase from '../../../../../common/STInputBase/STInputBase';
import { debounce } from 'lodash';

interface EditLabelProps {
  title?: string | null;
  todoID?: number;
  disabled?: boolean;
}

const UPDATE_TITLE = graphql(`
  mutation updateTodoTitle($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      title
    }
  }
`);

const EditLabel: React.FC<EditLabelProps> = ({ todoID, title, disabled = false }) => {
  const [newTitle, setNewTitle] = useState(title || '');
  const [isEditing, setIsEditing] = useState(false);
  const [updateTodoTitle] = useMutation(UPDATE_TITLE);

  useEffect(() => {
    setNewTitle(title || '');
  }, [title]);

  const handleUpdate = useCallback(debounce((value: string) => {
    if (value.trim()&&todoID) {
      updateTodoTitle({
        variables: {
          updateTodoInput: { id: todoID, name: value },
        },
      });
    }
  }, 500), [todoID, updateTodoTitle]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewTitle(value);
    handleUpdate(value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (!newTitle.trim()) {
      setNewTitle(title || '');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <Grid container sx={{ overflowWrap: 'anywhere', maxWidth: '100%' }}>
      <Grid item xs={12}>
        <STInputBase
          id={`task-${todoID}`}
          value={newTitle}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onClick={() => !disabled && setIsEditing(true)}
          inputMode="text"
          fullWidth
          size="small"
          readOnly={disabled}
          multiline={disabled}
          sx={{
            cursor: 'pointer',
            border: isEditing && !disabled ? '1px solid' : 'none',
            borderRadius: '4px',
            fontSize: '13px',
            padding: isEditing && !disabled ? '0 5px' : '0',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditLabel;