import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import STMenu from '../../../../../../common/STMenu/STMenu';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined';
import { useIntl } from 'react-intl';
import React from 'react';
import TodoLabelColor from '../../TodoLabelColor/TodoLabelColor';
import MenuItem from '../MenuItem/MenuItem';

interface props {
  defaultColor?: string;
  labelId: number;
  handleOpenRenameLabel(): void;
}
const LabelTagContext = ({
  defaultColor,
  labelId,
  handleOpenRenameLabel,
}: props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openColor, setOpenColor] = React.useState(false);
  const open = Boolean(anchorEl);
  const intl = useIntl();
  const handleOpenChangeColor = () => {
    setOpenColor(true);
  };
  const menuItems = [
    {
      component: (
        <MenuItem
          label={intl.formatMessage({ id: 'context.menu.rename.label' })}
        >
          <ModeOutlinedIcon fontSize="small" />
        </MenuItem>
      ),
      onClick: () => {
        setAnchorEl(null);
        handleOpenRenameLabel();
      },
    },
    {
      component: (
        <MenuItem
          label={intl.formatMessage({ id: 'context.menu.change.color' })}
        >
          <InvertColorsOutlinedIcon fontSize="small" />
        </MenuItem>
      ),
      onClick: () => {
        handleOpenChangeColor();
        setAnchorEl(null);
      },
    },
  ];
  const handleOpenContext = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColor = () => {
    setOpenColor(false);
  };
  return (
    <>
      <MoreHorizOutlinedIcon
        className="icon-button"
        onClick={(e: any) => handleOpenContext(e)}
        fontSize="small"
      />
      <STMenu menuItems={menuItems} anchorEl={anchorEl} open={open} />
      <TodoLabelColor
        openColor={openColor}
        labelId={labelId}
        defaultColor={defaultColor}
        handleCloseColor={handleCloseColor}
      />
    </>
  );
};
export default LabelTagContext;
