import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { graphql } from '../../gql';
import SubmitButton from '../Auth/AccountForm/SubmitButton/SubmitButton';
import LogoIcon from '../Auth/LogoIcon/LogoIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import AlertText from '../common/AlertText/AlertText';
import { Mixpanel } from '../../mixpanel/mixpanel';
import { BoxStyled, ButtonWrapper, Root } from './EmailConfirm/EmailConfirm';
import { useNavigate } from 'react-router-dom';
import CustomTypography from '../common/CustomTypography/CustomTypography';
import useGetMe from '../../Hooks/useGetMe';
import LogoutButton from '../common/LogoutButton/LogoutButton';

const RESEND = graphql(/* GraphQL */ `
  mutation Resend {
    resendConfirmationEmail
  }
`);

const EmailConfirmation: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data } = useGetMe();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  // Mixpanel Email Confirmation Page Viewed

  useEffect(() => {
    Mixpanel.track('Email Confirmation Request Page View', {});
  }, []);

  const [resend, { loading, error }] = useMutation(RESEND);
  const onSuccess = () => {
    setOpenSnackBar(true);
  };

  const resendEmail = () => {
    resend({
      onCompleted: onSuccess,
    });
  };

  return (
    <Root maxWidth="sm">
      <BoxStyled>
        <LogoIcon />
        <CustomTypography variant="h4" align="center" my={2} gutterBottom>
          <FormattedMessage
            id={'confirmation.title'}
            defaultMessage={'Email Confirmation'}
          />
        </CustomTypography>
        {openSnackBar && (
          <AlertText
            message={intl.formatMessage({
              id: 'snackbar.success.resendEmail.label',
              defaultMessage: 'snackbar.success.resendEmail.label',
            })}
            severity="success"
          />
        )}
        {error && (
          <AlertText
            message={intl.formatMessage({
              id: 'snackbar.error.resendEmail.label',
              defaultMessage: 'An error has occurred, please try again.',
            })}
            severity="error"
          />
        )}
        <CustomTypography variant="h6" align="center" gutterBottom>
          <FormattedMessage
            id={'confirmation.description.h6'}
            defaultMessage={`Thank you for signing up with us using  email address.`}
          />
        </CustomTypography>
        <CustomTypography variant="body1" align="center" gutterBottom>
          ({data?.me?.email})
        </CustomTypography>
        <CustomTypography variant="body1" align="center" gutterBottom>
          <FormattedMessage
            id={'confirmation.description.body1'}
            defaultMessage={
              ' Please check your email and follow the instructions to confirm it.If you do not find the confirmation email in your inbox, please check your spam or junk mail folder.'
            }
          />
        </CustomTypography>

        <ButtonWrapper>
          <SubmitButton
            onSubmit={resendEmail}
            disable={loading}
            isLoading={loading}
            buttonValue={
              <FormattedMessage
                id={'submit.button.ResendEmail'}
                defaultMessage={'Resend email'}
              />
            }
          />
        </ButtonWrapper>
        {/** Logout button */}
        <Box>
          <LogoutButton />
        </Box>
      </BoxStyled>
    </Root>
  );
};

export default EmailConfirmation;
