import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import CustomBox from '../CustomBox/CustomBox';
import CustomDialog from '../CustomDialog/CustomDialog';

interface STDialogVideoType{
    open: boolean;
    handleClose: () => void;
    videoUrl?:string
}
const STDialogVideo = ({open,handleClose,videoUrl}:STDialogVideoType) => {
    const intl=useIntl()
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      title={intl.formatMessage({id:'attibute.bot.demos'})}
      contentChildren={<CustomBox>
        <ReactPlayer
          width={'100%'}
          height={'400px'}
          controls={true}
          url={videoUrl}
          preload="metadata"/>
      </CustomBox>}
    />
  );
};
export default STDialogVideo;
