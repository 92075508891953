import { FC } from 'react';
import { graphql } from '../../gql/gql';
import { useLazyQuery } from '@apollo/client';

const GET_AUTH_URL = graphql(
  `
    query GetExternalAppAuthUrl($appSlug: String!) {
      getExternalAppAuthUrl(appSlug: $appSlug)
    }
  `
);

const useGetExternalAppAuthUrl = () => {
  // USE LAZY QUERY
  const [getExternalAppAuthUrl, { data, loading: loadingGetAuthUrl }] =
    useLazyQuery(GET_AUTH_URL, {
      fetchPolicy: 'network-only',
    });
  return {
    getExternalAppAuthUrl,
  };
};

export default useGetExternalAppAuthUrl;
