import { useCallback, useEffect, useState } from 'react';

const useScroolButton = ({
  ref,
}: {
  ref: React.RefObject<HTMLDivElement>;
  messages?: any[];
}) => {
  const [isDisplayScrollButton, setIsDisplayScrollButton] = useState(false);

  const handleScrollBottom = useCallback(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [ref]);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const target = e.currentTarget;
      const { scrollTop, scrollHeight, clientHeight } = target;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;

      setIsDisplayScrollButton(!atBottom);
    },
    [setIsDisplayScrollButton]
  );

  return {
    onScroll,
    handleScrollBottom,
    isDisplayScrollButton,
  };
};

export default useScroolButton;
