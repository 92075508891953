import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import { SortOrder } from '../../../gql/graphql';

const GET_TODO_ACTIVITY = graphql(`
  query TodoActivity($todoId: Float!, $orderBy: [TodoActivityOrderByWithRelationInput!]) {
    todo(id: $todoId) {
      id
      todoActivities(orderBy: $orderBy)  {
        id
        createdAt
        action
        actionFR
        mentionedUser {
          id
          firstName
          lastName
          username
        }
        user {
          firstName
          lastName
          id
          profilePicture
        }
      }
    }
  }
`);

const useGetTodoActivity = ({ todoId }: { todoId: number }) => {
  const { data, error, loading } = useQuery(GET_TODO_ACTIVITY, {
    variables: {
      todoId,
      orderBy: [
        {
          createdAt: SortOrder.Desc
        }
      ]
    },
  });
  return { data, error, loading };
};
export default useGetTodoActivity;
