import { useMutation } from '@apollo/client';
import { graphql } from '../gql/gql';
const SIGNED_URL = graphql(`
  mutation GetPutSignedUrl($fileName: String!) {
    getFilePutSignedUrl(fileName: $fileName) {
      key
      url
      uuid
    }
  }
`);

const useGetPutSignedUrl = () => {
  const [getFilePutSignedUrl] = useMutation(SIGNED_URL);
  return { getFilePutSignedUrl };
};

export default useGetPutSignedUrl;
