import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CustomBox from '../CustomBox/CustomBox';

function STProgressCircular(props: CircularProgressProps & { value: number }) {
  return (
    <CustomBox sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <CustomBox
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">{`${Math.round(
          props.value
        )}%`}</Typography>
      </CustomBox>
    </CustomBox>
  );
}
export default STProgressCircular;
