import { useQuery } from '@apollo/client';
import { graphql } from '../gql';
import React from 'react';
import { StepContext } from '../components/Prompts/Prompts';

const GET__PROMPT = graphql(`
  query FindFirstPromptsOrThrow($where: PromptsWhereInput) {
    findFirstPromptsOrThrow(where: $where) {
      id
      title
      fieldsPrompts
      description
    }
  }
`);


const useGetOnePrompt = () => {
  const { idPrompts } = React.useContext(StepContext);
  const { data,loading } = useQuery(GET__PROMPT, {
    variables: {
      where: {
        id: {
          equals: idPrompts,
        },
      },
    },
  });

  return { loading, data };
};

export default useGetOnePrompt;
