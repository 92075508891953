import { useLocation } from 'react-router-dom';
import { isInfiniteChat } from '../utils/isInfiniteChat';

const useInfiniteChat = () => {
  const route = useLocation();

  return isInfiniteChat(route.pathname);
};

export default useInfiniteChat;
