import React from 'react';
import { InputBase, InputBaseProps } from '@mui/material';

interface IProps extends InputBaseProps {}

const STInputBase: React.FC<IProps> = ({ ...rest }) => {
  return <InputBase {...rest} />;
};

export default STInputBase;
