import React, { FC } from 'react';
import { List, ListProps } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import STListItem from './STListItem/STListItem';
import useGroupSTList from './useGroupSTList';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomBox from '../CustomBox/CustomBox';
type IconColor =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type IconSize = 'inherit' | 'large' | 'medium' | 'small';
interface Action {
  disabled?: boolean;
  iconColor?: IconColor;
  iconSize?: IconSize;
  icon: SvgIconComponent;
  tooltipIntlId: string;
  onClick: (item: Item) => void;
}
type CustomItemBackgroundColorType = {
  hover?: string;
  active?: string;
  default?: string;
};

interface Item {
  id: number;
  primaryText: string;
  secondaryText?: string;
  viewCode?: string;
  userProfil?: string;
  createdAt?: any;
  avatarImg?: string; // optional avatarImg property
  unreadNotificationsCount?: number;
  ListItemLeft?: React.ReactNode; // optional LeftItem property
  actions?: Action[]; // optional actions property
  onItemTextClick?: (item: Item) => void; // optional onItemTextClick property
  onItemClick?: (item: Item) => void; // optional onItemClicked property
  href?: string; // optional href property
  onChangeCheckbox?(id: number): void;
  checkValue?: boolean;
}
type groupByType = 'createdAt' | 'updatedAt';
interface Props extends ListProps {
  items: Item[];
  itemTextStyle?: React.CSSProperties;
  selectedItemId?: number;
  isContentEditableDisabled?: boolean;
  isPrimaryTextFocused?: boolean;
  onPrimaryTextEdit?(e: any): void;
  isForView?: boolean;
  isItemTextCheckedToDelete?: boolean;
  isPrimaryEditable?: boolean;
  displayAction?: 'ONSELECT' | 'ALWAYS';
  groupBy?: groupByType;
  isViewChecBox?: boolean;
  isDisplayBotAvatar?: boolean;
  itemWidth?: number | string;
  isPrimaryTextDisplayInMultiline?: boolean;
  customItemBackgroundColor?: CustomItemBackgroundColorType;
}

const STList: FC<Props> = ({
  items,
  itemTextStyle,
  isContentEditableDisabled,
  isItemTextCheckedToDelete,
  onPrimaryTextEdit,
  isDisplayBotAvatar,
  isPrimaryEditable,
  selectedItemId,
  isViewChecBox,
  isPrimaryTextFocused,
  displayAction,
  itemWidth,
  groupBy,
  isForView,
  isPrimaryTextDisplayInMultiline,
  customItemBackgroundColor,
  ...rest
}) => {
  const groupeditems = useGroupSTList(items, groupBy);
  return (
    <List {...rest}>
      {groupeditems?.length
        ? groupeditems.map((data) => (
            <CustomBox key={data.name}>
              {data.items.length ? (
                <CustomTypography
                  fontWeight={600}
                  my={1}
                  sx={{
                    color: (theme) => theme.palette.grey[200],
                    marginLeft: (theme) => theme.spacing(2),
                  }}
                >
                  {data.name}
                </CustomTypography>
              ) : null}
              <>
                {data.items.map((item: any) => (
                  <STListItem
                    isForView={isForView}
                    item={item}
                    itemWidth={itemWidth}
                    customItemBackgroundColor={customItemBackgroundColor}
                    isViewChecBox={isViewChecBox}
                    itemTextStyle={itemTextStyle}
                    isContentEditableDisabled={isContentEditableDisabled}
                    isItemTextCheckedToDelete={isItemTextCheckedToDelete}
                    onPrimaryTextEdit={onPrimaryTextEdit}
                    isPrimaryEditable={isPrimaryEditable}
                    selectedItemId={selectedItemId}
                    isPrimaryTextFocused={isPrimaryTextFocused}
                    displayAction={displayAction}
                  />
                ))}
              </>
            </CustomBox>
          ))
        : items?.map((item) => (
            <STListItem
              isForView={isForView}
              itemWidth={itemWidth}
              item={item}
              itemTextStyle={itemTextStyle}
              customItemBackgroundColor={customItemBackgroundColor}
              isContentEditableDisabled={isContentEditableDisabled}
              isItemTextCheckedToDelete={isItemTextCheckedToDelete}
              onPrimaryTextEdit={onPrimaryTextEdit}
              isViewChecBox={isViewChecBox}
              isPrimaryEditable={isPrimaryEditable}
              selectedItemId={selectedItemId}
              isPrimaryTextFocused={isPrimaryTextFocused}
              displayAction={displayAction}
              isPrimaryTextDisplayInMultiline={isPrimaryTextDisplayInMultiline}
            />
          ))}
    </List>
  );
};

export default STList;
