import { FC } from 'react';
import CustomDialog from '../../../../common/CustomDialog/CustomDialog';
import { FormattedMessage } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import SwiftaskCreditPurshase from '../../../../SwiftaskToken/SwiftaskCreditPurshase/SwiftaskCreditPurshase';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const PurchaseCreditDialog: FC<IProps> = ({ open, onClose }) => {
  return (
    <CustomDialog
      open={open}
      handleClose={onClose}
      maxWidth="md"
      title={<FormattedMessage id="purchase.credit.dialog.title" />}
      contentChildren={
        <CustomBox
          sx={{
            padding: (theme) => theme.spacing(2),
          }}
        >
          <SwiftaskCreditPurshase />
        </CustomBox>
      }
    />
  );
};

export default PurchaseCreditDialog;
