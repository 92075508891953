import Box from '@mui/material/Box/Box';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material';
import { SearchIconWrapper } from '../../Layout/Header/Search/Search';
import SearchInput from './SearchInput/SearchInput';
import Loading from '../Loading/Loading';

const SearchBox = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    fontSize: '13px',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.background.paper,
    backgroundColor: theme.palette.customColor.cardBackground,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  };
});

export interface searchTodoInputProps {
  searchValue: string;
  autoFocus?: boolean;
  loading?: boolean;
  onChangeInputSearch?(e: React.ChangeEvent<HTMLInputElement>): void;
  searchInputPlaceholder?: string;
}
const CustomSearchInput = ({
  searchValue,
  searchInputPlaceholder,
  onChangeInputSearch,
  autoFocus,
  loading,
}: searchTodoInputProps) => {
  return (
    <Box>
      <SearchBox>
        <SearchIconWrapper>
          {loading ? (
            <Loading type="circular" color="inherit" size={24} />
          ) : (
            <SearchIcon />
          )}
        </SearchIconWrapper>
        <SearchInput
          autoFocus={autoFocus}
          placeholder={searchInputPlaceholder}
          searchValue={searchValue}
          onChangeInputSearch={(e: any) =>
            onChangeInputSearch && onChangeInputSearch(e)
          }
        />
      </SearchBox>
    </Box>
  );
};
export default CustomSearchInput;
