import { Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import CustomButton from '../../../common/CustomButton/CustomButton';

interface props {
  handleOpen(): void;
  isCompact: boolean;
}
const AddProjectButton = ({ handleOpen, isCompact }: props) => {
  return (
    <CustomButton
      startIcon={<Add />}
      onClick={handleOpen}
      variant="contained"
      sx={{
        fontSize: '0.85rem',
        minWidth: '140px',
      }}
    >
      <FormattedMessage id="project.list.add" />
    </CustomButton>
  );
};
export default AddProjectButton;
