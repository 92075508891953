import MenuList from '@mui/material/MenuList';
import DownloadIcon from '@mui/icons-material/Download';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material';
import CustomMenu from '../../../../common/CustomMenu/CustomMenu';
import ItemContext from '../../../../common/CustomMenu/ItemContext/ItemContext';

const MenuListStyled = styled(MenuList)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: '4px 0px 4px 0px',
}));
interface props {
  anchorEl: null | HTMLElement;
  handleClose(): void;
  handleDelete: () => void;
  isMessageFile?: boolean;
  handleDownloadFiles: () => void;
}
export default function ContextMenu({
  anchorEl,
  handleClose,
  handleDelete,
  isMessageFile,
  handleDownloadFiles,
}: props) {
  // Download loading state

  const open = Boolean(anchorEl);
  const handleDownload = async (e: any) => {
    handleClose();
    handleDownloadFiles();
  };

  return (
    <CustomMenu anchorEl={anchorEl} handleClose={handleClose} open={open}>
      <MenuListStyled>
        {isMessageFile && (
          <ItemContext
            handleAction={handleDownload}
            label="context.menu.download"
            defaultMessage="Download"
          >
            <DownloadIcon fontSize="small" />
          </ItemContext>
        )}
        <ItemContext
          label="context.menu.delete"
          handleAction={() => {
            handleClose();
            handleDelete();
          }}
          defaultMessage="Delete"
        >
          <Delete fontSize="small" />
        </ItemContext>
      </MenuListStyled>
    </CustomMenu>
  );
}
