import { Todo } from '../../../../../gql/graphql';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import UserAsignee from '../UserAsignee/UserAsignee';
import { ChatBubbleOutlineRounded as Chat } from '@mui/icons-material';
interface IProps {
  todo?: Todo;
  handleClickTask(e:any):void
}
const IconBox = ({ todo,handleClickTask }: IProps) => {
  return (
    <>
      <CustomBox
        display={'flex'}
        px={2}
        pt={(theme) => theme.spacing(1.7)}
        mb="auto"
      >
        <Chat
          onClick={handleClickTask}
          sx={{ opacity: 0, transition: 'opacity 0.3s ease' }}
          className="chat-icon"
          fontSize="small"
        />
        <CustomBox ml={0.5}>
          <UserAsignee todo={todo} />
        </CustomBox>
      </CustomBox>
    </>
  );
};
export default IconBox;
