// React FC component with message id as props
// And query to get message by id
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { graphql } from '../../../../gql';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import { IconButton, styled } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const StyledBoxReply = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
}));

// Right side box with close icon button
const StyledBoxClose = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
}));

// Styled span bold for user name with material ui theme
const StyledSpanBold = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginLeft: '5px',
}));

const GET_MESSAGE_BY_ID = graphql(`
  query getMessageById($where: TodoChatMessageWhereInput) {
    findFirstTodoChatMessage(where: $where) {
      id
      content
      sentBy {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`);

interface IProps {
  messageId: number;
  onClose: () => void;
}

const MessageToReply: React.FC<IProps> = ({ messageId, onClose }) => {
  const { data, loading, error } = useQuery(GET_MESSAGE_BY_ID, {
    variables: {
      where: {
        id: {
          equals: messageId,
        },
      },
    },
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return null;
  }
  if (error) {
    return null;
  }

  // Material ui component to diplay "Replying to User firstname lastname"
  return (
    <StyledBoxReply>
      <Box>
        <FormattedMessage id="chat.replyingTo" defaultMessage="Replying to" />
        <StyledSpanBold>
          {data?.findFirstTodoChatMessage?.sentBy?.firstName}{' '}
          {data?.findFirstTodoChatMessage?.sentBy?.lastName}
        </StyledSpanBold>
        <br />
        <FormattedMessage
          id="chat.replyingTo.indication"
          defaultMessage="Replying to"
        />
        {/* Close icon button */}
      </Box>

      <StyledBoxClose>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </StyledBoxClose>
    </StyledBoxReply>
  );
};

export default MessageToReply;
